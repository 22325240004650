import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  checkIsReadOrWrite,
  checkIsRequired,
  getCurrentPageName,
  months,
  PERMISSION_NAMES,
  REGEX_DIGIT_ONLY,
  t,
} from "utils/constants";

import { validateDecimalEngLang, validateDecimalFrenchLang } from "utils/support";
import Style from "./GridTextfield.style";

export default function GridTextfield(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  /* Conflict Resolution Candidate
    const {
      formSteps: { isEditField, activeStep },
      userProfileEdit,
    } = useSelector(({ createUserProfile }) => createUserProfile);
   */
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInputFocused, setInputFocus] = useState(false);
  const inputElRef = useRef(null);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write
  /* Conflict Resolution Candidate
    const getUserDetails = useSelector(({ userHome }) => userHome.userDetails);
    const { isSignedIn } = useAuth();
   */

  const {
    name = "",
    type = "text",
    label = "",
    subLabel = "",
    defaultValue = "",
    rules,
    placeholder,
    InputProps,
    stepNumber,
    gridMd = 4,
    customClass = "",
    inputClass = "",
    multiline = false,
    disabled = false,
    validation,
    matchWith = "passphrase",
    privId = "",
    isViewOnly = "",
    customeStyle = {},
    customTextFieldStyle = {},
    isShowOld = false,
    showNullValue = "",
    RevisionDate = "",
    onKeyDown,
    gridStyles = "",
    refField,
    textAppend = "",
    customTextClassName = "",
    onKeyUp = "",
    onBlur = undefined,
    onCopy,
    /* Conflict Resolution Candidate
        emailError = " ",
      } = props;
    
      const d = RevisionDate ? new Date(RevisionDate) : new Date();
      const revisionDate = `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
     */
    value: fieldValue,
    labelStyle = {},
    rows,
    autoComplete = true,
    componentName,
    fieldName,
    trimLeadingSpaces = false,
    customTrigger = () => { },
    boxLabelClass = "",
    isQuantity = false,
    isTransferId = false,
    positiveTwoDecimalRegex = false,
    bigIntLimitCheck = false,
    fieldProps,
    tooltipIcon = false,
    tooltipTitle = "",
    tooltipPlacement = "right",
    onPaste = () => { },
    hideErrorMsg = false,
    isRequiredMsg="",
    // Set default noop (no-operation) function for onChangeHandler to prevent impact on diffrent parent components.
    onChangeHandler = () => { },
    disabledLabel = false
  } = props;
  const { fieldPermissions } = useSelector(({ entityApplication }) => entityApplication);
  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);

  const isReadOnlyisReadOnly = useMemo(() => {
    return checkIsReadOrWrite(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.READ;
  }, [fieldPermissions]);
  const isRequired = useMemo(() => {
    return checkIsRequired(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.MANDATORY;
  }, [fieldPermissions]);

  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

  let readOnly = false;
  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  // This will set read only field

  if (rules && label && rules.required) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }
  /* Conflict Resolution Candidate
    const insetValue = `${t(t1, "CONFIRM_PASSPHRASE") === label ? "-16px auto auto 0px !important" : "-25px auto auto 0px !important"
      }`;
   */

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      PopperProps={{ container: inputElRef.current?.children[0].children[0], placement: "right-start" }}
      open={isInputFocused}
      {...props}
      classes={{ popper: className }}
      placement="right-start"
      arrow
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #797979",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 11,
      // inset:insetValue,
      position: "relative",
      borderRadius: 0,
      paddingRight: "5px",
      width: "100%",
      maxWidth: "100%",
      "& .MuiBox-root": {
        padding: "0",
        margin: "8px 0",
      },
      "& .MuiTooltip-arrow": {
        all: "unset",
        border: "solid #797979",
        left: "-9px",
        top: `${t(t1, "CONFIRM_PASSPHRASE") === label ? "16px !important" : "23px !important"}`,
        borderWidth: "0 1px 1px 0",
        display: "inline-block",
        padding: "8px",
        transform: "rotate(135deg) !important",
        backgroundColor: "#fff",
      },
    },
  }));

  // Popoer config
  /* Conflict Resolution Candidate
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
   */

  const handleFocusEvent = (e, type) => {
    if (type === "focus") {
      setInputFocus(true);
    }

    if (type === "blur") {
      setInputFocus(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      padding: "1px 7px",
      border: "1px solid #000",
      borderRadius: "0px",
    },
  }));

  const inputProps = {
    "data-testid": name,
    ...(autoComplete === "off" && {
      autoComplete: "off",
    }),
    ...(!autoComplete && {
      autoComplete: "new-password",
      form: {
        autocomplete: "off",
      },
    }),
    ...fieldProps,
  };

  return (
    <Grid
      item
      md={gridMd}
      sx={{ minHeight: activeStep !== 4 || !isViewOnly ? "" : "65px", height: isViewOnly ? "auto" : "", ...gridStyles }}
    >
      <Box
        component="div"
        className={boxLabelClass || "label-wrapper"}
        sx={labelStyle}
      >
        {label ? (
          <InputLabel
            className={customClass}
            htmlFor="customize-textfield"
            sx={[Style?.FieldLabel, customeStyle]}
            disabled={disabledLabel}
            onDragStart={(e) => e.preventDefault()}
          >
            {label}
            {rules?.required ? (
              <span
                // variant="body2"
                style={{
                  display: "inline-block",
                  color: "#CA2C1C",
                  ml: 0.3,
                  fontWeight: 700,
                }}
              >
                *
              </span>
            ) : null}
            {subLabel ? <Box component="span">{subLabel}</Box> : null}
            {tooltipIcon ? (
              <Tooltip
                title={tooltipTitle}
                placement={tooltipPlacement}
              >
                <ErrorIcon
                  sx={{
                    color: "#4d8da4",
                    fontSize: "20px",
                    ml: 1,
                    mb: -0.5,
                  }}
                />
              </Tooltip>
            ) : null}
          </InputLabel>
        ) : null}
      </Box>
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly ? (
        <>
          {!textAppend && (
            <Typography
              className={customTextClassName}
              variant="body2"
              sx={fieldValue || getValues(name) ? Style.valuetext : Style.NAText}
            >
              {t(t1, fieldValue) || getValues(name) || "N/A"}
            </Typography>
          )}

          {textAppend?.length > 0 &&
            textAppend?.map((item, index) => (
              <Typography
                variant="body2"
                sx={Style.valuetext}
                key={`termsLink_${index}`}
              >
                <a
                  href={item?.url?.replace("#", "%23")}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  style={
                    index === textAppend.length - 1
                      ? { marginBottom: "20px !important", color: "#4d8da4", fontSize: "14px", fontWeight: 600 }
                      : { color: "#4d8da4", fontSize: "14px", fontWeight: 600 }
                  }
                  rel="noreferrer"
                >
                  {t(t1, "TERMS_AND_CONDITIONS_TEXT")} - {item?.name}
                </a>
              </Typography>
            ))}
        </>
      ) : (
        <Controller
          name={name}
          type={type}
          defaultValue={defaultValue}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <div
              ref={inputElRef}
              style={{ position: "relative" }}
            >
              <TextField
                // Adding onChangeCapture to execute a function on entering Inputs 
                onDrop={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
                onChangeCapture={onChangeHandler}
                autoComplete="new-password"
                inputRef={refField}
                disabled={readOnly || disabled}
                multiline={multiline}
                rows={rows}
                sx={[Style.formField, customTextFieldStyle]}
                type={type}
                step="0.01"
                className={`${inputClass} ${t(t1, "CONFIRM_PASSPHRASE") === label ? "password-label" : ""}`}
                id={name}
                variant="outlined"
                placeholder={placeholder}
                InputProps={{ ...InputProps, inputProps }}
                value={defaultValue || field.value}
                onFocus={(e) => handleFocusEvent(e, "focus")}
                onBlurCapture={(e) => {
                  handleFocusEvent(e, "blur");
                  onBlur?.(e);
                }}
                onPaste={onPaste}
                onCopy={onCopy}
                /* Conflict Resolution Candidate
                error={Boolean(fieldState.error || emailError !== ' ')}
                onKeyDown={onKeyDown}
                onKeyUp={({ target: value }) => onKeyUp && onKeyUp(value)}
                helperText={fieldState?.error?.message || emailError}
 */
                error={Boolean(fieldState.error)}
                onKeyDown={onKeyDown}
                onKeyUp={({ target: value }) => onKeyUp && onKeyUp(value)}
                helperText={hideErrorMsg ? fieldState?.error?.message : fieldState?.error?.message || " "}
                {...field}
                onChange={(e) => {
                  let { value } = e.target;
                  if (trimLeadingSpaces) {
                    value = value.trimStart();
                  }
                  if (isQuantity && REGEX_DIGIT_ONLY.test(value)) {
                    value = value.replace(REGEX_DIGIT_ONLY, "");
                  }
                  if (isTransferId) {
                    value = value.replace(/^0+/, "");
                    value = value.replace(REGEX_DIGIT_ONLY, "");
                  }
                  if (positiveTwoDecimalRegex) {
                    if (selectedLanguage === "French") {
                      value = validateDecimalFrenchLang(value);
                    } else {
                      value = validateDecimalEngLang(value);
                    }
                  }
                  const regex = /^\d{0,18}$/;
                  if (!bigIntLimitCheck || regex.test(value)) field.onChange(value);
                  customTrigger();
                }}
              />
              {(t(t1, "PASSPHRASE") === label ||
                t(t1, "CONFIRM_PASSPHRASE") === label ||
                `${t(t1, "NEW")} ${t(t1, "PASSPHRASE")}` === label) &&
                label !== "" && (
                  <LightTooltip
                    title={
                      t(t1, "PASSPHRASE") === label || `${t(t1, "NEW")} ${t(t1, "PASSPHRASE")}` === label ? (
                        <Box
                          as="ul"
                          sx={{ margin: "10px 0", padding: "0 20px 0 10px" }}
                        >
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.length && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE1")}</span>
                          </Typography>
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.alphaNum && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE2")}</span>
                          </Typography>
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.upperLowercase && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE3")}</span>
                          </Typography>
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.number && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE4")}</span>
                          </Typography>
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.speciaChar && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE5")}</span>
                          </Typography>
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {validation?.nonPrinting && getValues(name)?.length > 0 ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_SUGGESTION_MESSAGE6")}</span>
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          as="ul"
                          sx={{ margin: "10px 0", padding: "0 20px 0 10px" }}
                        >
                          <Typography
                            as="li"
                            sx={Style.popperStyle}
                          >
                            {getValues()?.confirmPassphrase === getValues(matchWith) ? (
                              <CheckOutlinedIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon sx={{ color: "red" }} />
                            )}
                            <span style={{ paddingLeft: "10px" }}>{t(t1, "PASSWORD_MATCH_MESSAGE")}</span>
                          </Typography>
                        </Box>
                      )
                    }
                    sx={{ inset: "-24px auto auto 0px !important" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "5px" }}>
                      <InfoIcon sx={{ fontSize: "0" }} />
                    </Box>
                  </LightTooltip>
                )}
            </div>
          )}
        />
      )}
      {isViewOnly && (isShowOld || showNullValue) && (
        <StyledTooltip
          title={
            <Typography
              color="black"
              sx={{ fontSize: "14px" }}
            >
              {revisionDate}
            </Typography>
          }
        >
          <Box
            mt={1 / 4}
            sx={Style.updatedFieldBox}
          >
            <ErrorIcon sx={{ color: "#4d8da4", fontSize: "20px", mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: "hidden",
                  wordWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
              >
                {isShowOld || "N/A"}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}
