/* eslint-disable */
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { GridTextfield, CustomAutocomplete } from "components";
import { FormActions } from "components/EntityFormActions";
import { FormCard } from "components/FormCard";
import GridDatefield from "components/GridDatefield/GridDatefield";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useEffect, useRef, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { setLoader, setIsFormEditable, setModelMessage } from "store/commonSlice";
import { TextTruncate } from "components/TextTruncate";
import {
  getCountry,
  getEntityContactById,
  getNAICS,
  getOrganization,
  getPurpose,
  getUserClassification,
  resetSteps,
  saveContactFormData,
  setEntityType, setEntityID,
  setIdentifierCountry,
  setNextStepSelect,
  setSelectedClassification,
  setSelectedOrganization,
  setStateProvinceIdentifiers,
  setSubmittedOfSelectedStep,
  updateEntity,
} from "store/entityApplicationSlice";
import {
  COMPONENT_MODES,
  COMPONENT_NAMES,
  ENTITY_TYPE_NAMES,
  JURISDICTIONS_NAMES,
  getCurrentPageName,
  isEmpty,
  scrollToTop,
  t,
  validateSimpleDate,
  verifyIdentificationNumber,
  verifyIdentificationNumberWithoutSpecialCharacters,
  SopfDetailView,
  COUNTRY_KEY_NAMES,
  ERROR,
  countrySort,
} from "utils/constants";
import logger from "utils/logger";
import Style from "./EnterEntityStyle";
import UnsavedPrompt from "../../../../../components/UnsavedDialog";
import "../../ComponentTextTruncate.css";
import { Loader } from "components/Loader";
import { TroubleshootTwoTone } from "@mui/icons-material";

export default function EnterEntityInformation({
  displayHead = true,
  mode = COMPONENT_MODES.ADD,
  getEntityError = false,
  updateCardEdit = () => { },
  isReviewAndSubmit = false,
}) {
  const legalNameRef = useRef("");
  const operatingNameRef = useRef("");
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [pageLoader, setPageLoader] = useState(false);
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [stepDataEntityInfo, setStepDataEntityInfo] = useState({ editMode: false });
  const [stepDataIdentifier, setStepDataIdentifier] = useState({ editMode: false });
  const [hideRegion, setHideRegion] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    steps,
    selectedEntity,
    jurisdictions,
    Entities,
    Purpose,
    UserClassification,
    Country,
    StateProvince,
    StateProvinceforMailing,
    StateProvinceIdentifiers,
    selectedJurisdiction,
    Organization,
    SelectedOrganization,
    SelectedClassification,
    ShortEntityID,
    EditAccess,
    fieldPermissions,
  } = useSelector(({ entityApplication }) => entityApplication);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const userName =
    selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL
      ? `${userDetails?.Name} ${userDetails?.FamilyName}` || ""
      : "";

  const defaultValues = {
    legalName: userName,
    purposeOfAccount: "",
    userClassification: "",
    otherClassification: "",
    operationName: "",
    country: "",
    province: "",
    incorporationRegion: "",
    dateOfIncorporation: "",
    IDByAgency: "",
    IdentificationNumber: "",
    idNumberAssignedByTheCaliforniaSecretaryOfState: "",
    organisationType: "",
    otherOrganization: "",
    businessNumber: "",
    registryID: "",
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues,
  });
  const dispatch = useDispatch();
  const purposeData = Purpose?.map((each) => ({ name: t(t1, each.Name), Name: each.Name, value: each.ID }));
  const organizationData = Organization?.map((each) => ({ name: t(t1, each.Type), Name: each.Type, value: each.ID }));
  const countryData = useMemo(() => countrySort(t1, Country), [Country]);
  const stateProvinceData =
    StateProvinceIdentifiers?.length > 0 && !isEmpty(methods.getValues("country"))
      ? StateProvinceIdentifiers.map((each) => ({ name: t(t1, each.Name), value: each.ID }))
      : [];

  useEffectOnce(() => {
    if (mode !== COMPONENT_MODES.EDIT) scrollToTop();
    setPageLoader(true);
    Promise.allSettled([
      !Purpose.length ? dispatch(getPurpose()) : null,
      !Country.length ? dispatch(getCountry()) : null,
      !UserClassification.length ? dispatch(getUserClassification()) : null,
      !Organization.length ? dispatch(getOrganization()) : null,
      id && !isReviewAndSubmit ? dispatch(getEntityContactById(id, false)) : null,
    ])
      .then(() => {
        setDefaultValues();
      }).finally(() => {
        setPageLoader(false);
      });
  });

  let classificationData = [];
  if (UserClassification?.length > 0) {
    /* array updated to bring 'OTHER_PLEASE_SPECIFY_BELOW'*/
    const indexOfOPSB = UserClassification.findIndex((x) => x.Name === "OTHER_PLEASE_SPECIFY_BELOW");
    let UserClassificationModified = [...UserClassification];
    UserClassificationModified.push(UserClassificationModified.splice(indexOfOPSB, 1)[0]);
    classificationData = UserClassificationModified?.map((item) => ({ ID: item.ID, Name: item.Name }));
  }


  if (getEntityError && selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION) {
    getEntityError(methods.getValues("organisationType"));
  }


  const setDefaultValues = () => {
    const defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.ENTITY_INFORMATION).filledData;
    if (!isEmpty(defaultValuesFilled)) {
      methods?.reset(defaultValuesFilled);
    } else {
      methods?.reset(defaultValues);
    }
    if (defaultValuesFilled?.userClassification && classificationData) {
      const cObject = classificationData.find((item) => item.ID === defaultValuesFilled.userClassification);
      if (cObject?.Name === "OTHER_PLEASE_SPECIFY_BELOW") {
        dispatch(setSelectedClassification(""));
      }
    }
    if (defaultValuesFilled?.organisationType && organizationData) {
      const cObject = organizationData.find((item) => item.value === defaultValuesFilled.organisationType);
      if (cObject?.Name) {
        dispatch(setSelectedOrganization(cObject?.Name));
        methods.setValue("organisationType", cObject?.value);
      } else {
        methods?.setValue("otherOrganization", "");
      }
    }

    if (!isEmpty(SelectedOrganization) && organizationData) {
      const localObject = organizationData?.find((item) => item?.Name === SelectedOrganization);
      if (!isEmpty(localObject)) {
        methods.setValue("organisationType", localObject?.value);
      }
    }

    // After entity type change new value need to set.
    if (selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL) {
      methods.setValue("legalName", id ? defaultValuesFilled.legalName || userName : userName);
      methods?.setValue("operationName", "");
    }

    // 255 Characters taking more time to validate hence added.
    if (methods.getValues("legalName")) {
      setTimeout(() => {
        methods.trigger("legalName");
      }, 600);
    }
    if (methods.getValues("incorporationRegion")) {
      methods.trigger("incorporationRegion");
    }

    // If user change entity type GMPO to Covered then below fields must be empty.
    if (selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY && !isReviewAndSubmit) {
      methods?.setValue("organisationType", "");
      methods?.setValue("otherOrganization", "");
    }
  };

  const updateStateByCountry = (countryID, fromUserChange = false) => {
    if (fromUserChange) {
      methods.setValue("province", "");
      methods.setValue("incorporationRegion", "");
      methods.clearErrors("incorporationRegion");
    }

    const country = Country?.find((item) => item.ID === countryID);
    if (country) {
      if (country?.Name === COUNTRY_KEY_NAMES.UNITED_STATES || country?.Name === COUNTRY_KEY_NAMES.CANADA) {
        setHideRegion(true);
        methods.clearErrors("incorporationRegion");
        methods.clearErrors("province");
        dispatch(setStateProvinceIdentifiers(country.States));
        if (isReviewAndSubmit) {
          setTimeout(() => {
            methods.trigger("province");
          }, 500);
        }
      } else {
        setHideRegion(false);
        methods.clearErrors("province");
        dispatch(setStateProvinceIdentifiers([]));
        if (isReviewAndSubmit) {
          setTimeout(() => {
            methods.trigger("incorporationRegion");
          }, 500);
        }
      }
      dispatch(setIdentifierCountry(country.Name));
    }
  };

  useEffect(() => {
    if (Country && Country.length) {
      const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.ENTITY_INFORMATION).filledData;
      if (defaultValues?.country) {
        updateStateByCountry(defaultValues?.country);
      }
    }
  }, [Country]);

  const handleSubmitUpdateIdentifires = () => {
    dispatch(setLoader(true));
    const values = methods.getValues();
    const postObjectData = {
      incorporationStateOrProvince: values?.province,
      incorporationCountry: values?.country,
      dateOfIncorporation: values?.dateOfIncorporation,
      incorporationRegion: values?.incorporationRegion,
    };
    if (values?.businessNumber) {
      postObjectData.businessNumberAssignedByTheCanadaRevenueAgency = values?.businessNumber;
    }

    if (values?.registryID) {
      postObjectData.registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies = values?.registryID;
    }

    if (values?.IdentificationNumber) {
      postObjectData.governmentIssedTaxpayerOrEmployerIdentificationNumber = values?.IdentificationNumber;
    }
    if (values?.IDByAgency) {
      postObjectData.idNumberAssignedByIncorporatingAgency = values?.IDByAgency;
    }
    if (values?.idNumberAssignedByTheCaliforniaSecretaryOfState) {
      postObjectData.idNumberAssignedByTheCaliforniaSecretaryOfState =
        values?.idNumberAssignedByTheCaliforniaSecretaryOfState;
    }
    if (values?.UnitedBusinessID) {
      postObjectData.washingtonUnifiedBusinessIdentifierNumberUBI = values?.UnitedBusinessID;
    }
    if (values?.québecEnterpriseNumber) {
      postObjectData.québecEnterpriseNumber = values.québecEnterpriseNumber;
    }
    if (values?.dunsNumber) {
      postObjectData.dunsNumber = values.dunsNumber;
    }

    updateEntity(
      {
        identifiers: postObjectData,
      },
      id,
      false,
      {
        pageName: "Page_EntityRegistration",
        componentName: "identifiers",
      }
    )
      .then((response) => {
        handleEnableEditIdentifires(false);
        dispatch(getEntityContactById(id));
        dispatch(setLoader(false));
        dispatch(setIsFormEditable(false));
        updateCardEdit({ cardName: "IDENTIFIERS", isEditing: false });
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

  const handleSubmitUpdateEntityInfo = () => {
    dispatch(setLoader(true));
    const values = methods.getValues();
    const postObject = {
      legalName: values?.legalName,
    };

    /*OperationalName: values?.operationName*/
    if (values?.operationName) {
      postObject.operatingName = values?.operationName;
    }
    if (values?.otherClassification) {
      postObject.classificationDetails = values?.otherClassification;
    }
    if (values?.organisationType) {
      postObject.typeOfOrganization = values?.organisationType;
    }
    if (values?.otherOrganization) {
      postObject.classificationDetails = values?.otherOrganization;
    }
    if (values?.purposeOfAccount) {
      postObject.purposeOfAccount = values?.purposeOfAccount;
    }
    if (values?.userClassification) {
      postObject.userClassification = values?.userClassification;
    }
    updateEntity(
      {
        entity: postObject,
      },
      id,
      false,
      {
        pageName: "Page_EntityRegistration",
        componentName: "entity",
      }
    )
      .then(() => {
        dispatch(getEntityContactById(id));
        handleEnableEditEntityInfo(false);
        dispatch(setLoader(false));
        dispatch(setIsFormEditable(false));
        updateCardEdit({ cardName: "ENTITY_INFORMATION", isEditing: false });
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

  const showContactSaveError = (error) => {
    const data = {
      title: ERROR,
      message1: error?.response.data?.messageKey || error?.response?.data?.data,
      error: true,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
  };

  const onSubmit = async (data) => {
    const stepData = {};
    const latestStep = steps.map((item) => {
      if (item.selected) {
        stepData[item.key] = data;
        return { ...item, filledData: data };
      }
      stepData[item.key] = item.filledData;
      return item;
    });
    const countryIdMailingAddress = stepData?.CONTACT_INFORMATION?.mailingAddress?.country?.id;
    const countryIdPhysicalAddress = stepData?.CONTACT_INFORMATION?.physicalAddress?.country?.id;
    const newStepsData = {
      ...stepData,
      ...{
        CONTACT_INFORMATION: {
          ...stepData?.CONTACT_INFORMATION,
          ...{ mailingAddress: { ...stepData?.CONTACT_INFORMATION?.mailingAddress, country: countryIdMailingAddress } },
          ...{
            physicalAddress: { ...stepData?.CONTACT_INFORMATION?.physicalAddress, country: countryIdPhysicalAddress },
          },
        },
      },
    };
    dispatch(setLoader(true));

    saveContactFormData(
      {
        newStepsData,
        latestStep,
        selectedJurisdiction,
        selectedEntity,
        jurisdictions,
        Entities,
        Purpose,
        UserClassification,
        Country,
        StateProvince,
        StateProvinceforMailing,
        Organization,
      },
      null,
      id,
      fieldPermissions
    )
      .then((response) => {
        dispatch(setLoader(false));
        if (!isEmpty(response)) {
          dispatch(setNextStepSelect({ steps }));
          dispatch(getNAICS(response?.data));
          dispatch(getEntityContactById(response?.data));
          dispatch(setSubmittedOfSelectedStep(true));
        }
        return response;
      })
      .catch((error) => {
        dispatch(setLoader(false));
        showContactSaveError(error);
        logger({ error, name: "saveContactFormData" });

        if (error?.response?.data?.messageKey !== "Type of Organization is required") {
          dispatch(setSubmittedOfSelectedStep(false));
        }
      });
  };

  const handleEnableEditEntityInfo = (value) => {
    methods?.reset({}, { keepValues: true });
    setStepDataEntityInfo({ ...stepDataEntityInfo, editMode: value });
    dispatch(setIsFormEditable(true));
    updateCardEdit({ cardName: "ENTITY_INFORMATION", isEditing: value });
    if (selectedEntity === "GENERAL_MARKET_PARTICIPANT_ORGANIZATION" && !methods.getValues("organisationType")) {
      methods?.setError("organisationType", {
        type: "custom",
        message: "Type of Organization Is Required",
      })
    }
    methods?.trigger();
  };

  const handleEnableEditIdentifires = (value) => {
    methods?.reset({}, { keepValues: true });
    setStepDataIdentifier({ ...stepDataIdentifier, editMode: value });
    dispatch(setIsFormEditable(true));
    updateCardEdit({ cardName: "IDENTIFIERS", isEditing: value });
    methods?.trigger();
  };

  const setDefaultValuesEditCancel = (defaultIdentifier = false) => {
    let defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.ENTITY_INFORMATION).filledData;
    if (mode === COMPONENT_MODES.EDIT && defaultIdentifier) {
      if (!isEmpty(defaultValuesFilled)) {
        methods?.setValue("IdentificationNumber", defaultValuesFilled?.IdentificationNumber);
        methods?.setValue("IDByAgency", defaultValuesFilled?.idNumberAssignedByIncorporatingAgency);
        methods?.setValue(
          "idNumberAssignedByTheCaliforniaSecretaryOfState",
          defaultValuesFilled?.idNumberAssignedByTheCaliforniaSecretaryOfState
        );
        methods?.setValue("businessNumber", defaultValuesFilled?.businessNumber);
        methods?.setValue("registryID", defaultValuesFilled?.registryID);
        methods?.setValue("UnitedBusinessID", defaultValuesFilled?.UnitedBusinessID);
        methods?.setValue("québecEnterpriseNumber", defaultValuesFilled?.québecEnterpriseNumber);
        methods?.setValue("dunsNumber", defaultValuesFilled?.dunsNumber);
        methods?.setValue("dateOfIncorporation", defaultValuesFilled?.dateOfIncorporation);
        methods?.setValue("country", defaultValuesFilled?.country);
        methods?.setValue("province", defaultValuesFilled?.province);
        methods?.setValue("incorporationRegion", defaultValuesFilled?.incorporationRegion);

        if (defaultValuesFilled?.incorporationCountry?.id) {
          updateStateByCountry(defaultValuesFilled?.incorporationCountry?.id);
          methods?.setValue("country", defaultValuesFilled?.incorporationCountry?.id);
        }

        // Clear form wise error on reset
        methods?.clearErrors("IdentificationNumber");
        methods?.clearErrors("IDByAgency");
        methods?.clearErrors("idNumberAssignedByTheCaliforniaSecretaryOfState");
        methods?.clearErrors("businessNumber");
        methods?.clearErrors("registryID");
        methods?.clearErrors("UnitedBusinessID");
        methods?.clearErrors("québecEnterpriseNumber");
        methods?.clearErrors("dunsNumber");
        methods?.clearErrors("dateOfIncorporation");
        methods?.clearErrors("country");
        methods?.clearErrors("province");
        methods?.clearErrors("incorporationRegion");
        methods?.clearErrors("userClassification");
        methods?.clearErrors("otherClassification");
        methods?.clearErrors("otherOrganization");
        methods.trigger();
      }
    } else if (mode === COMPONENT_MODES.EDIT && !defaultIdentifier) {
      methods?.setValue("legalName", defaultValuesFilled?.legalName);
      methods?.setValue("operationName", defaultValuesFilled?.operationName);
      methods?.setValue("purposeOfAccount", defaultValuesFilled?.purposeOfAccount);
      methods?.setValue("userClassification", defaultValuesFilled?.userClassification);
      methods?.setValue("organisationType", defaultValuesFilled?.organisationType);

      // Clear form wise error on reset
      methods?.clearErrors("legalName");
      methods?.clearErrors("operationName");
      methods?.clearErrors("purposeOfAccount");
      methods?.clearErrors("userClassification");
      methods?.clearErrors("organisationType");
      methods?.clearErrors("userClassification");
      methods?.clearErrors("otherClassification");
      methods?.clearErrors("otherOrganization");
      methods.trigger("userClassification");
      methods.trigger();

      if (defaultValuesFilled?.userClassification && classificationData) {
        const cObject = classificationData.find((item) => item.ID === defaultValuesFilled.userClassification);
        if (cObject?.Name === "OTHER_PLEASE_SPECIFY_BELOW") {
          methods?.setValue("otherClassification", defaultValuesFilled?.otherClassification, { shouldValidate: true });
        } else {
          methods?.setValue("otherClassification", "", { shouldValidate: true });
          dispatch(setSelectedClassification(""));
          // setUserClassification("");
        }
      }

      if (defaultValuesFilled?.organisationType && organizationData) {
        const cObject = organizationData.find((item) => item.value === defaultValuesFilled.organisationType);
        if (cObject?.Name === "OTHER_PLEASE_SPECIFY_BELOW") {
          methods?.setValue("otherOrganization", defaultValuesFilled?.otherOrganization, { shouldValidate: true });
        } else {
          methods?.setValue("otherOrganization", "", { shouldValidate: true });
        }
        methods.setValue("organisationType", cObject?.value);
        dispatch(setSelectedOrganization(cObject?.Name));
      }

      if (isEmpty(defaultValuesFilled?.organisationType) && isEmpty(defaultValuesFilled.otherClassification)) {
        methods?.setValue("otherClassification", "", { shouldValidate: true });
        dispatch(setSelectedOrganization(""));
      }
    }
  };

  const [cleartDirtyFields, setClearDirtyFields] = useState();

  useEffect(() => {
    setClearDirtyFields(
      (methods && !methods?.formState?.isValid) ||
      !Object.values(methods?.formState?.dirtyFields || {}).some(Boolean)
    );
  }, [methods?.formState]);

  const handleEditCancel = () => {
    methods?.reset({}, { keepValues: true });
    setDefaultValuesEditCancel();
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: "ENTITY_INFORMATION", isEditing: false });
    methods?.trigger();
  };

  const resetData = () => {
    dispatch(resetSteps());
    dispatch(setEntityType(""));
    dispatch(setEntityID(0));
    navigate("/start-entity-registration");
  };

  const handleEditCancelIdentifier = () => {
    methods?.reset({}, { keepValues: true });
    setDefaultValuesEditCancel(true);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: "IDENTIFIERS", isEditing: false });
    methods.clearErrors();
    methods?.trigger();
  };

  const viewOnlyEnityInfo = mode === COMPONENT_MODES.EDIT && !stepDataEntityInfo.editMode;
  const viewOnlyIdentifier = mode === COMPONENT_MODES.EDIT && !stepDataIdentifier.editMode;

  const {
    legalName,
    operationName,
    otherOrganization,
    organisationType,
    IDByAgency,
    idNumberAssignedByTheCaliforniaSecretaryOfState,
    IdentificationNumber,
    incorporationRegion,
    province,
    washingtonUnifiedBusinessIdentifierNumberUBI,
    québecEnterpriseNumber,
    registryID,
    businessNumber,
    dateOfIncorporation,
    dunsNumber,
    UnitedBusinessID,
  } = methods?.formState?.errors;
  let CoveredGMPOEntityInfo = false
  useEffect(() => {
    CoveredGMPOEntityInfo = legalName || operationName || otherOrganization || organisationType;

  }, [legalName, operationName, otherOrganization, organisationType])

  const CoveredGMPOIdentifiers =
    IDByAgency ||
    idNumberAssignedByTheCaliforniaSecretaryOfState ||
    IdentificationNumber ||
    incorporationRegion ||
    province ||
    washingtonUnifiedBusinessIdentifierNumberUBI ||
    québecEnterpriseNumber ||
    registryID ||
    businessNumber ||
    dateOfIncorporation ||
    dunsNumber ||
    UnitedBusinessID;

  const conditionToShowOtherClassification =
    classificationData.find((item) => item.ID === methods.getValues("userClassification"))?.Name ===
    "OTHER_PLEASE_SPECIFY_BELOW";

  const {
    legalName: isLegalNameDirty,
    operationName: isOperationNameDirty,
    otherOrganization: isOtherOrganizationDirty,
    organisationType: isOrganisationTypeDirty,
    IDByAgency: isIDByAgencyDirty,
    idNumberAssignedByTheCaliforniaSecretaryOfState: isIdNumberAssignedByTheCaliforniaSecretaryOfStateDirty,
    IdentificationNumber: isIdentificationNumberDirty,
    incorporationRegion: isIncorporationRegionDirty,
    province: isProvinceDirty,
    washingtonUnifiedBusinessIdentifierNumberUBI: isWashingtonUnifiedBusinessIdentifierNumberUBIDirty,
    québecEnterpriseNumber: isQuébecEnterpriseNumberDirty,
    registryID: isRegistryIDDirty,
    businessNumber: isBusinessNumberDirty,
    dateOfIncorporation: isDateOfIncorporationDirty,
    dunsNumber: isDunsNumberDirty,
    UnitedBusinessID: isUnitedBusinessIDDirty
  } = methods?.formState.dirtyFields


  const isEntityInformationFormDirty = [isLegalNameDirty, isOperationNameDirty, isOtherOrganizationDirty, isOrganisationTypeDirty].some(e => e)
  const isEntityIdentifierDirty = [
    isIDByAgencyDirty,
    isIdNumberAssignedByTheCaliforniaSecretaryOfStateDirty,
    isIdentificationNumberDirty,
    isIncorporationRegionDirty,
    isProvinceDirty,
    isWashingtonUnifiedBusinessIdentifierNumberUBIDirty,
    isQuébecEnterpriseNumberDirty,
    isRegistryIDDirty,
    isBusinessNumberDirty,
    isDateOfIncorporationDirty,
    isDunsNumberDirty,
    isUnitedBusinessIDDirty,
  ].some(e => e)

  if (selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL) {
    return (
      <FormProvider {...methods}>
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div
              className="contentTextCardTwo"
              style={Style.contentTextCard}
            >
              <TextTruncate
                content={t(t1, "FOOTER_DISCLAIMER_CONTENT")}
                animate
              />
            </div>
          </Typography>
        )}
        <div
          className={`STEP_CLASSNAME_${COMPONENT_NAMES.ENTITY_INFORMATION} MODE_${mode} ${viewOnlyEnityInfo ? "entity-viewonly" : ""
            }`}
        >
          <FormCard
            customClass={"customFormCard"}
            title={t(t1, "ENTITY_INFORMATION")}
            indicateRequired
            description={t(t1, "INDICATE_REQUIRED_FIELDS")}
            setDefaultValues={setDefaultValues}
            stepNumber={2}
            mode={mode}
            handleEnableEdit={handleEnableEditEntityInfo}
            handleSubmitUpdate={handleSubmitUpdateEntityInfo}
            cancelEntityIdentifiers={handleEditCancel}
            saveDisabled={cleartDirtyFields || !isEntityInformationFormDirty}
            stepData={stepDataEntityInfo}
            stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
            editBtn
            customeStyle={Style.formCardPadding}
            isEditAccess={EditAccess}
          >
            <Grid
              container
              spacing={2.5}
              sx={{ mt: -0.25 }}
            >
              <Grid
                container
                spacing={2.5}
                sx={{ mt: 1.5, ml: "0rem" }}
              >
                {mode === COMPONENT_MODES.ADD ||
                  (!stepDataEntityInfo.editMode && (
                    <Grid
                      item
                      xs={4}
                      sx={{ mt: "-20px !important" }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 700, ml: 0 }}>{t(t1, "ENTITY_ID")}</Typography>

                      <Typography sx={{ fontSize: "14px", fontWeight: 400, mt: 0.4 }}>{ShortEntityID}</Typography>
                    </Grid>
                  ))}
                {SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-35px !important" }}
                  >
                    <GridTextfield
                      componentName="entity"
                      fieldName="legalName"
                      isViewOnly={viewOnlyEnityInfo}
                      name="legalName"
                      label={t(t1, "LEGAL_NAME")}
                      privId="priv_txt_legalName"
                      InputProps={{
                        "aria-required": "true",
                      }}
                      stepNumber={2}
                      gridMd={12}
                      disabled
                    />
                  </Grid>,
                  "entity",
                  "legalName",
                  fieldPermissions
                )}
              </Grid>
              <Grid
                container
                spacing={2.5}
                sx={{ mt: 2, ml: "0rem" }}
                className="purpose-user-row"
              >
                {SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-35px !important" }}
                  >
                    <CustomAutocomplete
                      noValidate
                      componentName="entity"
                      fieldName="purposeOfAccount"
                      isViewOnly={viewOnlyEnityInfo}
                      viewName
                      onClose={() => methods.trigger("purposeOfAccount")}
                      privId="priv_txt_AccountPurpose"
                      name="purposeOfAccount"
                      noValueText={t(t1, "SELECT_PURPOSE")}
                      label={t(t1, "PURPOSE_OF_ACCOUNT")}
                      options={purposeData}
                      rules={{ required: t(t1, "PURPOSE_OF_ACCOUNT_REQUIRED") }}
                      isRequiredMsg={'ERR_PURPOSE_OF_ACCOUNT_IS_REQUIRED'}
                      InputProps={{
                        "aria-required": "true",
                      }}
                      className="input-group"
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "entity",
                  "purposeOfAccount",
                  fieldPermissions
                )}

                {SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-35px !important" }}
                  >
                    <CustomAutocomplete
                      noValidate
                      componentName="entity"
                      fieldName="userClassification"
                      isViewOnly={viewOnlyEnityInfo}
                      viewName
                      onClose={() => methods.trigger("userClassification")}
                      privId="priv_txt_UserClassification"
                      name="userClassification"
                      noValueText={t(t1, "SELECT_CLASSIFICATION")}
                      label={t(t1, "USER_CLASSIFICATION")}
                      options={classificationData.map((each) => ({ name: t(t1, each.Name), value: each.ID }))}
                      rules={{ required: t(t1, "USER_CLASSIFICATION_REQUIRED")  }}
                      isRequiredMsg={'ERR_USER_CLASSIFICATION_IS_REQUIRED'}
                      InputProps={{
                        "aria-required": "true",
                      }}
                      className="input-group"
                      stepNumber={2}
                      gridMd={12}
                      handleOnChange={(e) => {
                        const cObject = classificationData.find((item) => item.ID === e?.value);
                        dispatch(setSelectedClassification(cObject?.Name));
                        if (cObject?.Name !== "OTHER_PLEASE_SPECIFY_BELOW") {
                          methods?.clearErrors("otherClassification");
                          methods?.setValue("otherClassification", "");
                        }
                      }}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "entity",
                  "userClassification",
                  fieldPermissions
                )}

                {SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-4px !important" }}
                  >
                    {SelectedClassification === "OTHER_PLEASE_SPECIFY_BELOW" || conditionToShowOtherClassification ? (
                      <Grid
                        item
                        className="other-classification"
                      >
                        <GridTextfield
                          noValidate
                          isViewOnly={viewOnlyEnityInfo}
                          placeholder={t(t1, "OTHER_CLASSIFICATION")}
                          name="otherClassification"
                          label={t(t1, "OTHER_CLASSIFICATION")}
                          privId="priv_txt_otherClassification"
                          rules={{
                            required: t(t1, "USER_CLASSIFICATION_REQUIRED") ,
                            validate: (v) =>
                              methods?.getValues("otherClassification")
                                ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, "ERR_OTHER_CLASSIFICATION_MIN_LENGTH_CHAR"), t(t1, "ERR_OTHER_CLASSIFICATION_MAX_LENGTH_CHAR"), t(t1, "ERR_INVALID_OTHER_CLASSIFICATION"), { minLength: 3, maxLength: 100 })
                                : null,
                          }}
                          isRequiredMsg={'ERR_USER_CLASSIFICATION_IS_REQUIRED'}
                          InputProps={{
                            "aria-required": "true",
                          }}
                          trimLeadingSpaces
                          stepNumber={2}
                          gridMd={12}
                          disabled={!EditAccess}
                        />
                      </Grid>
                    ) : null}
                  </Grid>,
                  "entity",
                  "classificationDetails",
                  fieldPermissions
                )}
              </Grid>
            </Grid>
          </FormCard>

          {mode === COMPONENT_MODES.ADD && (
            <FormActions
              handleSubmit={(data) => {
                if (id) {
                  onSubmit(data);
                } else {
                  dispatch(setNextStepSelect({ steps }));
                }
              }}
              stepNumber={3}
              continueDisabled={!methods?.formState.isValid}
              onExitClick={() => setIsExitInProgress(true)}
              onCancelExit={() => setIsExitInProgress(false)}
            />
          )}
        </div>
        <UnsavedPrompt id={id}
          when={!isExitInProgress}
          title={t(t1, "WARNING")}
          message={t(t1, "CONFIRM_LEAVE_PAGE")}
          onSuccess={resetData}
        />
      </FormProvider>
    );
  }

  if (selectedEntity
    === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY ||
    selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION
  ) {
    return (
      <FormProvider {...methods}>
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div
              className="contentTextCardTwo"
              style={Style.contentTextCard}
            >
              <TextTruncate
                content={t(t1, "FOOTER_DISCLAIMER_CONTENT")}
                animate
              />
            </div>
          </Typography>
        )}
        <div
          className={`STEP_CLASSNAME_${COMPONENT_NAMES.ENTITY_INFORMATION} MODE_${mode} ${viewOnlyEnityInfo ? "entity-viewonly" : ""
            }`}
        >
          <FormCard
            customClass={"customFormCard entity-info"}
            title={t(t1, "ENTITY_INFORMATION")}
            indicateRequired
            description={t(t1, "INDICATE_REQUIRED_FIELDS")}
            setDefaultValues={setDefaultValues}
            stepNumber={3}
            mode={mode}
            handleEnableEdit={handleEnableEditEntityInfo}
            handleSubmitUpdate={handleSubmitUpdateEntityInfo}
            cancelEntityIdentifiers={handleEditCancel}
            saveDisabled={cleartDirtyFields || !isEntityInformationFormDirty}
            stepData={stepDataEntityInfo}
            stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
            isEditAccess={EditAccess}
          >
            <Grid
              container
              spacing={1.5}
              sx={{ mt: 0 }}
            >
              {mode === COMPONENT_MODES.ADD ||
                (!stepDataEntityInfo.editMode && (
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-5px" }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 700, ml: 0 }}>{t(t1, "ENTITY_ID")}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: 400, ml: 0 }}>{ShortEntityID}</Typography>
                    </Box>
                  </Grid>
                ))}

              {SopfDetailView(
                <Grid
                  item
                  xs={4}
                  sx={{ mt: "-25px !important" }}
                >
                  <GridTextfield
                    isViewOnly={viewOnlyEnityInfo}
                    componentName="entity"
                    fieldName="legalName"
                    name="legalName"
                    label={t(t1, "LEGAL_NAME")}
                    rules={{
                      required: `${t(t1, "LEGAL_NAME")}`,
                      validate: (v) =>
                        methods?.getValues("legalName")
                          ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, "ERR_LEGAL_NAME_MIN_LENGTH_CHAR"), t(t1, "ERR_LEGAL_NAME_MAX_LENGTH_CHAR"), t(t1, "ERR_INVALID_LEGAL_NAME"), { minLength: 3, maxLength: 255 })
                          : null,
                    }}
                    isRequiredMsg={'LEGAL_NAME_REQUIRED'}
                    trimLeadingSpaces
                    stepNumber={2}
                    gridMd={12}
                    refField={legalNameRef}
                    disabled={!EditAccess}
                    customTextClassName={viewOnlyEnityInfo ? "viewOnlyTextareaContent" : ""}
                  />
                </Grid>,
                "entity",
                "legalName",
                fieldPermissions
              )}

              {SopfDetailView(
                <Grid
                  item
                  xs={4}
                  sx={{ mt: "-25px !important" }}
                >
                  <GridTextfield
                    noValidate
                    componentName="entity"
                    fieldName="operatingName"
                    isViewOnly={viewOnlyEnityInfo}
                    name="operationName"
                    label={t(t1, "OPERATING_NAME")}
                    rules={{
                      required: t(t1, "OPERATING_NAME"),
                      validate: (v) =>
                        methods?.getValues("operationName")
                          ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, "ERR_OPERATING_NAME_MIN_LENGTH_CHAR"), t(t1, "ERR_OPERATING_NAME_MAX_LENGTH_CHAR"), t(t1, "ERR_INVALID_OPERATING_NAME"), { minLength: 3, maxLength: 255 })
                          : null,
                    }}
                    isRequiredMsg={'OPERATING_NAME_REQUIRED'}
                    trimLeadingSpaces
                    stepNumber={2}
                    gridMd={12}
                    refField={operatingNameRef}
                    disabled={!EditAccess}
                    customTextClassName={viewOnlyEnityInfo ? "viewOnlyTextareaContent" : ""}
                  />
                </Grid>,
                "entity",
                "operatingName",
                fieldPermissions
              )}
            </Grid>
            {selectedEntity === "GENERAL_MARKET_PARTICIPANT_ORGANIZATION" && (
              <Grid
                container
                spacing={2.5}
                sx={{ mt: 0 }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{ mt: "-25px !important" }}
                >
                  <CustomAutocomplete
                    noValidate
                    componentName="entity"
                    fieldName="organisationType"
                    isViewOnly={viewOnlyEnityInfo}
                    viewName
                    onClose={() => methods.trigger("organisationType")}
                    privId="entity_typeOfOrganization"
                    name="organisationType"
                    noValueText={t(t1, "SELECT_ORGANIZATION_TYPE")}
                    label={t(t1, "TYPE_OF_ORGANIZATION")}
                    options={organizationData}
                    rules={{ required: t(t1, "TYPE_OF_ORGANIZATION") }}
                    isRequiredMsg={'ERR_TYPE_OF_ORGANIZATION_IS_REQUIRED'}
                    handleOnChange={(e) => {
                      const cObject = organizationData.find((item) => item?.value === e?.value);
                      dispatch(setSelectedOrganization(cObject?.Name));
                      methods?.setValue("otherOrganization", "");
                      if (cObject?.Name !== "OTHER_PLEASE_SPECIFY_BELOW") {
                        methods?.setValue("otherOrganization", "");
                        methods?.clearErrors("otherOrganization");
                      }
                      if (cObject?.Name === "OTHER_PLEASE_SPECIFY_BELOW" && isReviewAndSubmit) {
                        setTimeout(() => {
                          methods?.trigger("otherOrganization");
                        }, 200);
                      }
                    }}
                    InputProps={{
                      "aria-required": "true",
                    }}
                    className="input-group"
                    stepNumber={2}
                    gridMd={12}
                    disabled={!EditAccess}
                  />
                </Grid>

                {SelectedOrganization === "OTHER_PLEASE_SPECIFY_BELOW" ? (
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-25px !important" }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="entity"
                      fieldName="otherClassification"
                      isViewOnly={viewOnlyEnityInfo}
                      placeholder={`${t(t1, "OTHER_ORG_PLEASE_SPECIFY")}...`}
                      name="otherOrganization"
                      label={t(t1, "OTHER_ORGANIZATION")}
                      privId="priv_txt_otherClassification"
                      rules={{
                        required: t(t1, "OTHER_ORGANIZATION"),
                        validate: (v) =>
                          methods?.getValues("otherOrganization")
                            ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1,t(t1, "ERR_OTHER_ORGANIZATION_MIN_LENGTH_CHAR"),t(t1, "ERR_OTHER_ORGANIZATION_MAX_LENGTH_CHAR"),t(t1, "ERR_INVALID_OTHER_ORGANIZATION"), { minLength: 3, maxLength: 100 })
                            : null,
                      }}
                      isRequiredMsg={'ERR_OTHER_TYPE_OF_ORGANIZATION_IS_REQUIRED'}
                      InputProps={{
                        "aria-required": "true",
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                      customTextClassName={viewOnlyEnityInfo ? "viewOnlyTextareaContent" : ""}
                    />
                  </Grid>
                ) : null}
              </Grid>
            )}
          </FormCard>
        </div>
        <div
          className={`STEP_CLASSNAME_${COMPONENT_NAMES.ENTITY_INFORMATION} MODE_${mode} ${viewOnlyIdentifier ? "identifiers-viewonly" : ""
            }`}
        >
          <FormCard
            customClass={"customFormCard identifiers"}
            title={t(t1, "IDENTIFIERS")}
            indicateRequired
            description={t(t1, "INDICATE_REQUIRED_FIELDS")}
            setDefaultValues={setDefaultValues}
            stepNumber={3}
            mode={mode}
            handleEnableEdit={handleEnableEditIdentifires}
            handleSubmitUpdate={handleSubmitUpdateIdentifires}
            cancelEntityIdentifiers={handleEditCancelIdentifier}
            saveDisabled={cleartDirtyFields || !isEntityIdentifierDirty}
            stepData={stepDataIdentifier}
            stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE_IDENTIFIERS}
            customeStyle={viewOnlyIdentifier ? Style.formCardPaddingOnAdd : Style.onEditPage}
            isEditAccess={EditAccess}
          >
            <Grid
              container
              spacing={2.5}
              sx={{ mt: -0.15 }}
            >
              {SopfDetailView(
                <Grid
                  item
                  xs={4}
                  sx={{ mt: "-24px !important" }}
                >
                  <CustomAutocomplete
                    componentName="identifiers"
                    fieldName="incorporationCountry"
                    onClose={() => methods.trigger("country")}
                    isViewOnly={viewOnlyIdentifier}
                    viewName={viewOnlyIdentifier}
                    privId="priv_txt_select_ountry"
                    name="country"
                    noValueText={t(t1, "SELECT_COUNTRY")}
                    label={t(t1, "INCORPORATION_COUNTRY")}
                    options={countryData}
                    handleOnChange={(e) => {
                      updateStateByCountry(e?.value, true);
                      methods.trigger("entityType");
                    }}
                    rules={{ required: t(t1, "INCORPORATION_COUNTRY")}}
                    isRequiredMsg={'ERR_INCORPORATION_COUNTRY_IS_REQUIRED'}
                    InputProps={{
                      "aria-required": "true",
                      "aria-autocomplete": "none",
                    }}
                    className="input-group"
                    stepNumber={2}
                    gridMd={12}
                    disabled={!EditAccess}
                  />
                </Grid>,
                "identifiers",
                "incorporationCountry",
                fieldPermissions
              )}

              {hideRegion
                ? SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-24px !important" }}
                  >
                    <CustomAutocomplete
                      noValidate
                      componentName="identifiers"
                      fieldName="incorporationStateOrProvince"
                      isViewOnly={viewOnlyIdentifier}
                      onClose={() => methods.trigger("province")}
                      viewName={viewOnlyIdentifier}
                      privId="priv_txt_state_province"
                      name="province"
                      noValueText={t(t1, "SELECT_STATE_OR_PROVINCE")}
                      label={t(t1, "INCORPORATION_STATE_OR_PROVINCE")}
                      options={stateProvinceData}
                      rules={{ required: true }}
                      isRequiredMsg={'ERR_INCORPORATION_STATE_OR_PROVINCE_IS_REQUIRED'}
                      InputProps={{
                        "aria-required": "true",
                      }}
                      className="input-group"
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "incorporationStateOrProvince",
                  fieldPermissions
                )
                : SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: "-24px !important" }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="incorporationRegion"
                      isViewOnly={viewOnlyIdentifier}
                      name="incorporationRegion"
                      label={t(t1, "REGION")}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues("incorporationRegion")
                            ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1,t(t1, "ERR_REGION_MIN_LENGTH_CHAR"),t(t1, "ERR_REGION_MAX_LENGTH_CHAR"),t(t1, "ERR_INVALID_REGION"), { minLength: 3, maxLength: 255 })
                            : null,
                      }}
                      isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                      customTextClassName={viewOnlyIdentifier ? "viewOnlyTextareaContent" : ""}
                    />
                  </Grid>,
                  "identifiers",
                  "incorporationRegion",
                  fieldPermissions
                )}

              {SopfDetailView(
                <Grid
                  item
                  xs={4}
                  sx={{ mt: "-24px !important" }}
                >
                  <GridDatefield
                    noValidate
                    componentName="identifiers"
                    fieldName="dateOfIncorporation"
                    onClose={() => methods.trigger("dateOfIncorporation")}
                    isViewOnly={viewOnlyIdentifier}
                    name="dateOfIncorporation"
                    privId="priv_select_Date"
                    label={t(t1, "DATE_OF_INCORPORATION")}
                    EnableFutureDate
                    rules={{
                      required: true,
                      validate: (v) =>
                        methods?.getValues("dateOfIncorporation")
                          ? validateSimpleDate(v, `${t(t1, "DATE_OF_INCORPORATION")}`, t1,`${t(t1, "ERR_INVALID_DATE_OF_INCORPORATION")}`)
                          : null,
                    }}
                    isRequiredMsg={'ERR_DATE_OF_INCORPORATION_IS_REQUIRED'}
                    gridStyles={{
                      "& .MuiInputBase-root": {
                        height: "30px !important",
                      },
                      ".MuiInputBase-input": {
                        padding: "0px 14px",
                      },
                    }}
                    stepNumber={2}
                    gridMd={12}
                    disabled={!EditAccess}
                  />
                </Grid>,
                "identifiers",
                "dateOfIncorporation",
                fieldPermissions
              )}
            </Grid>

            <Grid
              container
              spacing={2.5}
              sx={Style.gridLayoutColumnStyling}
            >
              {SopfDetailView(
                <Grid
                  item
                  xs={4}
                  sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "-16px !important" }}
                >
                  <GridTextfield
                    noValidate
                    componentName="identifiers"
                    fieldName="governmentIssedTaxpayerOrEmployerIdentificationNumber"
                    isViewOnly={viewOnlyIdentifier}
                    name="IdentificationNumber"
                    label={t(t1, "GOVERNMENT_ISSUED_TAXPLAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER")}
                    rules={{
                      required: t(
                        t1,
                        selectedJurisdiction === "CALIFORNIA" || selectedJurisdiction === "WASHINGTON_STATE"
                          ? "THIS_FIELD_IS_REQUIRED"
                          : ""
                      ),
                      validate: (v) =>
                        methods?.getValues("IdentificationNumber")
                          ? verifyIdentificationNumber(v, ``, t1, { minLength: 3, maxLength: 20 })
                          : null,
                    }}
                    isRequiredMsg={"THIS_FIELD_IS_REQUIRED"}
                    trimLeadingSpaces
                    stepNumber={2}
                    gridMd={12}
                    disabled={!EditAccess}
                  />
                </Grid>,
                "identifiers",
                "governmentIssedTaxpayerOrEmployerIdentificationNumber",
                fieldPermissions
              )}
              {[JURISDICTIONS_NAMES.CALIFORNIA, JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(selectedJurisdiction) &&
                SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{
                      mt: viewOnlyIdentifier
                        ? "-30px !important"
                        : selectedJurisdiction === "QUEBEC"
                          ? "-37px"
                          : "-16px !important",
                    }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="idNumberAssignedByIncorporatingAgency"
                      isViewOnly={viewOnlyIdentifier}
                      name="IDByAgency"
                      label={t(t1, "ID_NUMBER_ASSIGNED_BY_INCORPORATING_AGENCY")}
                      rules={{
                        required: t(
                          t1,
                          selectedJurisdiction === "CALIFORNIA" || selectedJurisdiction === "WASHINGTON_STATE"
                            ? "THIS_FIELD_IS_REQUIRED"
                            : ""
                        ),
                        validate: (v) =>
                          methods?.getValues("IDByAgency")
                            ? verifyIdentificationNumber(v, ``, t1,`${t(t1, "ERR_ID_NUMBER_MIN_LENGTH_CHAR")}`,`${t(t1, "ERR_ID_NUMBER_MAX_LENGTH_CHAR")}`,`${t(t1, "ERR_INVALID_ID_NUMBER")}`, { minLength: 3, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={"THIS_FIELD_IS_REQUIRED"}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "idNumberAssignedByIncorporatingAgency",
                  fieldPermissions
                )}

              {selectedJurisdiction === "CALIFORNIA" &&
                SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "-16px !important" }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="idNumberAssignedByTheCaliforniaSecretaryOfState"
                      isViewOnly={viewOnlyIdentifier}
                      name="idNumberAssignedByTheCaliforniaSecretaryOfState"
                      label={t(t1, "ID_NUMBER_ASSIGNED_BY_THE_CALIFORNIA_SECRETARY_OF_STATE")}
                      rules={{
                        required: t(t1, "THIS_FIELD_IS_REQUIRED"),
                        validate: (v) =>
                          methods?.getValues("idNumberAssignedByTheCaliforniaSecretaryOfState")
                            ? verifyIdentificationNumber(v, ``, t1,`${t(t1, "ERR_ID_NUMBER_CAL_MIN_LENGTH_CHAR")}`,`${t(t1, "ERR_ID_NUMBER_CAL_MAX_LENGTH_CHAR")}`,`${t(t1, "ERR_ID_NUMBER_CAL_MAX_LENGTH_CHAR")}`, { minLength: 3, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={'THIS_FIELD_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "idNumberAssignedByTheCaliforniaSecretaryOfState",
                  fieldPermissions
                )}

              {[
                JURISDICTIONS_NAMES.QUEBEC,
                JURISDICTIONS_NAMES.WASHINGTON_STATE,
                JURISDICTIONS_NAMES.NOVA_SCOTIA,
                JURISDICTIONS_NAMES.CALIFORNIA,
              ].includes(selectedJurisdiction) &&
                SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{
                      mt: viewOnlyIdentifier
                        ? "-30px !important"
                        : selectedJurisdiction === "QUEBEC"
                          ? "-37px"
                          : "-16px !important",
                      paddingTop: viewOnlyIdentifier ? "46px!important" : undefined
                    }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="dunsNumber"
                      isViewOnly={viewOnlyIdentifier}
                      name="dunsNumber"
                      label={t(t1, "DUNS_NUMBER")}
                      rules={{
                        required: t(
                          t1,
                          selectedJurisdiction === JURISDICTIONS_NAMES.QUEBEC ? "THIS_FIELD_IS_REQUIRED" : ""
                        ),
                        validate: (v) =>
                          methods?.getValues("dunsNumber")
                            ? verifyIdentificationNumber(v, ``, t1,`${t(t1, "ERR_DUNS_NUMBER_MIN_LENGTH_CHAR")}`,`${t(t1, "ERR_DUNS_NUMBER_MAX_LENGTH_CHAR")}`,`${t(t1, "ERR_INVALID_DUNS_NUMBER")}`, { minLength: 9, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={"THIS_FIELD_IS_REQUIRED"}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "dunsNumber",
                  fieldPermissions
                )}

              {selectedJurisdiction === "WASHINGTON_STATE" &&
                SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "-16px" }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="washingtonUnifiedBusinessIdentifierNumberUBI"
                      isViewOnly={viewOnlyIdentifier}
                      name="UnitedBusinessID"
                      label={t(t1, "WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE")}
                      rules={{
                        required: t(t1, "THIS_FIELD_IS_REQUIRED"),
                        validate: (v) =>
                          methods?.getValues("UnitedBusinessID")
                            ? verifyIdentificationNumber(v, ``, t1,`${t(t1, "ERR_WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE_MIN_LENGTH_CHAR")}`,`${t(t1, "ERR_WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE_MAX_LENGTH_CHAR")}`,`${t(t1, "ERR_INVALID_WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE")}`, { minLength: 9, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={'THIS_FIELD_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "washingtonUnifiedBusinessIdentifierNumberUBI",
                  fieldPermissions
                )}

              {selectedJurisdiction === JURISDICTIONS_NAMES.QUEBEC &&
                SopfDetailView(
                  <Grid
                    item
                    xs={4}
                    sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "-37px" }}
                  >
                    <GridTextfield
                      noValidate
                      componentName="identifiers"
                      fieldName="québecEnterpriseNumber"
                      isViewOnly={viewOnlyIdentifier}
                      name="québecEnterpriseNumber"
                      label={t(t1, "QUEBEC_ENTERPRISE_NUMBER")}
                      rules={{
                        required: t(t1, "THIS_FIELD_IS_REQUIRED"),
                        validate: (v) =>
                          methods?.getValues("québecEnterpriseNumber")
                            ? verifyIdentificationNumber(v, ``, t1,`${t(t1, "ERR_QUEBEC_ENTERPRISE_NUMBER_MIN_LENGTH_CHAR")}`,`${t(t1, "ERR_QUEBEC_ENTERPRISE_NUMBER_MAX_LENGTH_CHAR")}`,`${t(t1, "ERR_INVALID_QUEBEC_ENTERPRISE_NUMBER")}`, { minLength: 10, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={'THIS_FIELD_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={!EditAccess}
                    />
                  </Grid>,
                  "identifiers",
                  "québecEnterpriseNumber",
                  fieldPermissions
                )}

              {selectedJurisdiction === "NOVA_SCOTIA" && (
                <>
                  {SopfDetailView(
                    <Grid
                      item
                      xs={4}
                      sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "" }}
                      className="ns-grid"
                    >
                      <GridTextfield
                        noValidate
                        componentName="identifiers"
                        fieldName="registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies"
                        isViewOnly={viewOnlyIdentifier}
                        name="registryID"
                        label={t(
                          t1,
                          "REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES"
                        )}
                        rules={{
                          required: t(t1, "THIS_FIELD_IS_REQUIRED"),
                          validate: (v) =>
                            methods?.getValues("registryID")
                              ? verifyIdentificationNumber(v, ``, t1,`${t(
                              t1,
                                "ERR_REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES_MIN_LENGTH_CHAR"
                              )}`,
                              `${t(
                              t1,
                                "ERR_REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES_MAX_LENGTH_CHAR"
                              )}`,
                              `${t(
                              t1,
                                "ERR_INVALID_REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES"
                              )}`, { minLength: 9, maxLength: 20 })
                              : null,
                        }}
                        isRequiredMsg={'THIS_FIELD_IS_REQUIRED'}
                        trimLeadingSpaces
                        stepNumber={2}
                        gridMd={12}
                        disabled={!EditAccess}
                      />
                    </Grid>,
                    "identifiers",
                    "registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies",
                    fieldPermissions
                  )}
                  {SopfDetailView(
                    <Grid
                      item
                      xs={4}
                      sx={{ mt: viewOnlyIdentifier ? "-30px !important" : "" }}
                      className="ns-grid"
                    >
                      <GridTextfield
                        noValidate
                        componentName="identifiers"
                        fieldName="businessNumberAssignedByTheCanadaRevenueAgency"
                        isViewOnly={viewOnlyIdentifier}
                        name="businessNumber"
                        label={t(t1, "BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY")}
                        privId="priv_txt_legalName"
                        rules={{
                          required: t(t1, "THIS_FIELD_IS_REQUIRED"),
                          validate: (v) =>
                            methods?.getValues("businessNumber")
                              ? verifyIdentificationNumber(v, ``,t1,`${t(t1, "ERR_BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY_MIN_LENGTH_CHAR")}` ,`${t(t1, "ERR_BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY_MAX_LENGTH_CHAR")}` ,`${t(t1, "ERR_INVALID_BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY")}` , { minLength: 9, maxLength: 20 })
                              : null,
                        }}
                        isRequiredMsg={'THIS_FIELD_IS_REQUIRED'}
                        InputProps={{
                          "aria-required": "true",
                        }}
                        trimLeadingSpaces
                        stepNumber={2}
                        gridMd={12}
                        disabled={!EditAccess}
                      />
                    </Grid>,
                    "identifiers",
                    "businessNumberAssignedByTheCanadaRevenueAgency",
                    fieldPermissions
                  )}
                </>
              )}
            </Grid>
          </FormCard>
        </div>
        {mode === COMPONENT_MODES.ADD && (
          <FormActions
            handleSubmit={(data) => {
              if (id) {
                onSubmit(data);
              } else {
                dispatch(setNextStepSelect({ steps }));
              }
            }}
            stepNumber={3}
            continueDisabled={!methods?.formState.isValid}
            onExitClick={() => setIsExitInProgress(true)}
            onCancelExit={() => setIsExitInProgress(false)}
          />
        )}
        <UnsavedPrompt id={id}
          when={!isExitInProgress && displayHead}
          title={t(t1, "WARNING")}
          message={t(t1, "CONFIRM_LEAVE_PAGE")}
          onSuccess={resetData}
        />
        {pageLoader && <Loader loader={pageLoader} />}
      </FormProvider>
    );
  }
}
