import styled from "@emotion/styled";
import ErrorIcon from "@mui/icons-material/Error";
import { Autocomplete, Box, CircularProgress, Grid, ListItemText, TextField, Tooltip, createFilterOptions, tooltipClasses } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentPageName, months, t } from "utils/constants";
import CustomPopper from "components/_CustomPopper/customPopper";
import Style from "../GridSelectSearch/GridSelectSearch.style";

export default function ReactHookFormSelectSearch(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name,
    type = "text",
    label = "",
    rules,
    // noValueText,
    placeholder = "",
    options,
    loading = false,
    InputProps,
    // onChange,
    // defaultValue,
    // labelValue = "",
    gridMd = 4,
    disabled = false,
    className = "",
    isViewOnly = "",
    isAstricDisplay = false,
    isShowOld = false,
    RevisionDate = "",
    privId = "",
    customeStyle = "",
    tooltipIcon = false,
    tooltipTitle = "",
    tooltipPlacement = "right",
    viewModeValue = null,
    requiredMessage = "",
    isRequiredMsg=''
  } = props;
  /* Conflict Resolution Candidate

  const d = RevisionDate ? new Date(RevisionDate) : new Date();
  const revisionDate = `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
 */
  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  let readOnly = false;
  if (privId === "") {
    if (PrivAttributes?.[name]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === "RO";
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === "NA") {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === "RO";
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  if (rules && rules?.required && !label && requiredMessage) {
    rules.required = `${t(t1, requiredMessage)}`;
  }


  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      padding: "1px 7px",
      border: "1px solid #000",
      borderRadius: "0px",
    },
  }));
  /* Conflict Resolution Candidate
  return (
    <Grid item md={gridMd} className={className} sx={{ ...customeStyle,"& .MuiOutlinedInput-input": { color:  ['',undefined].includes(getValues(`${name}`)) ? "#ccc !important": '#333' } }}>
 */
    const filterOptions = createFilterOptions({
      ignoreAccents: false,
      stringify: option => option?.name?.toString(),
    });
  return (
    <Grid
      item
      md={gridMd}
      className={className}
      sx={{ ...customeStyle }}
    >
      {label ? (
        <InputLabel
          disabled={disabled}
          htmlFor="customize-select"
          sx={{ ...Style.FieldLabel, color: "common.textColor" }}
        >
          {label}
          {rules?.required || isAstricDisplay ? (
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                color: "common.red",
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
          {tooltipIcon ? (
            <Tooltip
              title={tooltipTitle}
              placement={tooltipPlacement}
            >
              <ErrorIcon
                sx={{
                  color: "#4d8da4",
                  fontSize: "20px",
                  ml: 1,
                  mb: -0.5,
                }}
              />
            </Tooltip>
          ) : null}
        </InputLabel>
      ) : null}
      {isViewOnly || readOnly ? (
        <Typography
          variant="body2"
          sx={{ paddingLeft: viewModeValue ? "0px" : Style.valuetext }}
        >
          {/* {viewModeValue || getValues(name) || <Controller */}
          {viewModeValue || <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disabled={disabled}
                data-testid={props['data-testid']}
                filterOptions={filterOptions}
                getOptionDisabled={(option) => option.isDisabled }
                id={`${name}-react-hook-form-select-search`}
                sx={{
                  ...Style.formField,
                  "&.MuiAutocomplete-root.fc-search .MuiOutlinedInput-input": {
                    backgroundColor: disabled ? "#aaa" : "#fff", // Change the background color for the input element
                  },
                  ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
                    "-webkit-text-fill-color": "#FFFFFF",
                  }
                }}
                options={options}
                loading={loading}
                onBlur={onBlur}
                className="fc-search"
                autoHighlight
                getOptionLabel={(option) => option.name ?? ""}
                getOptionSelected={(option, value) => option.name === value.name}
                ListboxProps={{
                  style: {
                    maxHeight: 190,
                  },
                }}
                renderInput={(params) => (
                  <TextField
                  onDragStart={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                    error={Boolean(fieldState.error)}
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      ...InputProps,
                      style: {
                        ...params.InputProps.style,
                        backgroundColor: disabled ? "#aaa" : params.inputProps?.style?.backgroundColor, // Use inputProps?.style?.backgroundColor for the enabled state
                      },
                     
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                onChange={(event, values) => onChange(values)}
                {...field}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={value}
                renderOption={(props, item) => (
                  <li {...props} key={item.key}>
                    <ListItemText>{item.name}</ListItemText>
                  </li>
                )}
              />
          )}
        />}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disabled={disabled}
                data-testid={props['data-testid']}
                filterOptions={filterOptions}
                getOptionDisabled={(option) => option.isDisabled}
                id={`${name}-react-hook-form-select-search`}
                sx={{
                  ...Style.formField,
                  "&.MuiAutocomplete-root.fc-search .MuiOutlinedInput-input": {
                    backgroundColor: disabled ? "#ccc" : "#fff", // Change the background color for the input element
                  },
                }}
                options={options}
                loading={loading}
                onBlur={onBlur}
                className="fc-search"
                autoHighlight
                getOptionLabel={(option) => option.name ?? ""}
                getOptionSelected={(option, value) => option.name === value.name}
                ListboxProps={{
                  style: {
                    maxHeight: 190,
                  },
                }}
                renderInput={(params) => (
                  <TextField
                  onDragStart={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                    error={Boolean(fieldState.error)}
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      ...InputProps,
                      style: {
                        ...params.InputProps.style,
                        backgroundColor: disabled ? "#ccc" : params.inputProps?.style?.backgroundColor, // Use inputProps?.style?.backgroundColor for the enabled state
                      },
                  
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                onChange={(event, values) => onChange(values)}
                {...field}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={value}
                renderOption={(props, item) => (
                  <div {...props} key={item.key}>
                    {item.name}
                  </div>
                )}
              />

              <FormHelperText error>{fieldState?.error?.message || " "}</FormHelperText>
            </>
          )}
        />
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography
              color="black"
              sx={{ fontSize: "14px" }}
            >
              {revisionDate}
            </Typography>
          }
        >
          <Box
            mt={1 / 4}
            sx={Style.updatedFieldBox}
          >
            <ErrorIcon sx={{ color: "#4d8da4", fontSize: "20px", mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {isShowOld}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}
