import { createSlice } from "@reduxjs/toolkit";
import {
  SET_UP_JURI_ACCOUNTS_COMPONENTS,
  SET_UP_JURI_ACCOUNTS_PAGES,
  transformComplianceInstru,
  transformDefaultAcc,
  transformLanguages,
  transformTransfersList,
} from "pages/SetUpJurisdictionAccounts/support";
import { APIS, SUCCESS} from "utils/constants";
import fetcher, { fetcherGet, fetcherPatch, fetcherPost, fetcherPut } from "utils/fetcher";
import { setLoader } from "./commonSlice";

const initialState = {
  jurisdictionInfo: { isLoading: false, data: [] },
  defaultAccounts: { isLoading: false, data: null },
  languageAccName: { isLoading: false, data: [] },
  instrumentsTransfers: { isLoading: false, data: [] },
  complianceInstrumentsList: { isLoading: false, data: [] },
  transfersList: { isLoading: false, data: [] },
  allTransfers: { isLoading: false, data: [] },
  isLoadingAccountUpdate : false,
};

const setJuriAccountsSlice = createSlice({
  name: "setJuriAccounts",
  initialState,
  reducers: {
    setJurisdictionInfo: (state, action) => {
      state.jurisdictionInfo = action.payload;
    },
    setDefaultAccounts: (state, action) => {
      state.defaultAccounts = action.payload;
    },
    setLanguageAccName: (state, action) => {
      state.languageAccName = action.payload;
    },
    setInstrumentsTransfers: (state, action) => {
      state.instrumentsTransfers = action.payload;
    },
    setComplianceInstrumentsList: (state, action) => {
      state.complianceInstrumentsList = action.payload;
    },
    setTransfersList: (state, action) => {
      state.transfersList = action.payload;
    },
    setAllTransfers: (state, action) => {
      state.allTransfers = action.payload;
    },
    setIsLoadingAccountUpdate: (state, action) => {
      state.isLoadingAccountUpdate =  action.payload;
    }
  },
});

export default setJuriAccountsSlice.reducer;

export const {
  setJurisdictionInfo,
  setDefaultAccounts,
  setLanguageAccName,
  setInstrumentsTransfers,
  setComplianceInstrumentsList,
  setTransfersList,
  setAllTransfers,
  setIsLoadingAccountUpdate
} = setJuriAccountsSlice.actions;

export function getJurisdictionInfoAPI(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setJurisdictionInfo({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.JURISDICTIONS}?jurisdictionId=${jurisdictionId}`, {}, {});
      dispatch(
        setJurisdictionInfo({
          isLoading: false,
          data: response.data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setJurisdictionInfo({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getDefaultAccountsAPI(
  jurisdictionId,
  pageName = SET_UP_JURI_ACCOUNTS_PAGES.SET_UP_JURI_ACCOUNTS,
  componentName = SET_UP_JURI_ACCOUNTS_COMPONENTS.DEFAULT_ACCOUNTS
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setDefaultAccounts({ isLoading: true, data: null }));
    try {
      const response = await fetcherGet(`${APIS.GET_DEFAULT_ACCOUNTS}/${jurisdictionId}`, {}, { pageName, componentName });
      const {data, metaData, fieldAccess} = response
      dispatch(
        setDefaultAccounts({
          isLoading: false,
          data: {
            data: transformDefaultAcc(data),
            metaData,
            fieldAccess,
          }
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setDefaultAccounts({ isLoading: false, data: null }));
      dispatch(setLoader(false));
    }
  };
}

// get compliance instruments list
export function getComplianceInstrumentsAPI() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setComplianceInstrumentsList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_TYPE_OF_INSTRUMENTS}`, {}, {});
      // const response = typeOfInstrumentsMock;
      dispatch(
        setComplianceInstrumentsList({
          isLoading: false,
          data: transformComplianceInstru(response?.data),
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setComplianceInstrumentsList({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

// get transfers list
export function getTransfersAPI() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTransfersList({ isLoading: true, data: [] }));
    dispatch(setAllTransfers({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_ALL_TYPE_OF_TRANSFERS}`, {}, {});
      // const response = typeOfTransfersMock;
      dispatch(
        setTransfersList({
          isLoading: false,
          data: transformTransfersList(response.data),
        })
      );
      dispatch(
        setAllTransfers({
          isLoading: false,
          data: response.data,
        })
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransfersList({ isLoading: false, data: [] }));
      dispatch(setAllTransfers({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

// to get the default instruments and transfers
export function getDefaultInstrumentsTransfersAPI(payload, pageDetails = {}) {
  return async (dispatch) => {
    dispatch(
      setInstrumentsTransfers({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherPost(`${APIS.GET_DEFAULT_INSTRUMENTS_TRANSFERS}`, payload, pageDetails);
      // const response = instrumentsTransfersMockData;
      dispatch(
        setInstrumentsTransfers({
          isLoading: false,
          data: response.data,
        })
      );
    } catch (error) {
      dispatch(
        setInstrumentsTransfers({
          isLoading: false,
          data: [],
        })
      );
    }
  };
}

// save the Jurisdiction accounts
export function saveJuriAccountsAPI(payload, pageDetail) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.SAVE_JURISDICTION_ACCOUNTS}`, "PUT", payload, false, {}).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// add default Jurisdiction accounts
export function addJurisdictionAccountAPI(
  payload,
  jurisdictionId,
  pageDetail = {
    pageName: SET_UP_JURI_ACCOUNTS_PAGES.ADD_DEFAULT_JURISDICTION_ACCOUNTS,
    componentName: SET_UP_JURI_ACCOUNTS_COMPONENTS.ADD_DEFAULT_ACCOUNT,
  }
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(
      `${APIS.ADD_DEFAULT_JURI_ACCOUNTS}/${jurisdictionId}/defaultJuriAccount`,
      "POST",
      payload,
      false,
      pageDetail
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get languages from jurisdictionId
export function getLanguageAccNameAPI(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(
      setLanguageAccName({
        isLoading: true,
        data: [],
      })
    );
    try {
      const response = await fetcherGet(
        `${APIS.GET_ACC_NAME_LANGUAGES}/${jurisdictionId}/getAccountNameLanguage`,
        {},
        {}
      );
      // const response = languagesMockRes;
      dispatch(setLoader(false));
      dispatch(
        setLanguageAccName({
          isLoading: false,
          data: transformLanguages(response.data),
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setLanguageAccName({ isLoading: false, data: [] }));
    }
  };
}

// Add New Jurisdiction Account
export function addNewJuriAccountAPI(
  jurisdictionId,
  payload,
  pageDetail = {
    pageName: SET_UP_JURI_ACCOUNTS_PAGES.ADD_NEW_JURISDICTION_ACCOUNTS,
    componentName: SET_UP_JURI_ACCOUNTS_COMPONENTS.ADD_NEW_JURISDICTION_ACCOUNT,
  }
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.ADD_NEW_JURI_ACCOUNT}`.replace(":jurisdictionId", jurisdictionId),
      payload,
      pageDetail
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}
// update Account Status

export function updateJurisdictionAccountStatus(payload) {
  return async (dispatch) => {
  dispatch(setIsLoadingAccountUpdate(true));
  return fetcherPatch(`${APIS.UPDATE_ACCOUNT_STATUS}`, payload).finally(()=>{
     dispatch(setIsLoadingAccountUpdate(false));
  })
};
};

export function proposeJurisdictionStatus(jurisdictionId, payload) {
  return async () => fetcherPost(`${APIS.PROPOSE_JURISDICTION_STATUS}`.replace(":jurisdictionId", jurisdictionId), payload, 
  { pageName: "Page_ManageJurisdictionStatus",
    componentName: "postProposeStatusChange"
  });
};
