import { BrowserRouter, useLocation, useRoutes } from "react-router-dom";
import React, { Suspense, useEffect, useState, useLayoutEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { I18nextProvider } from "react-i18next";
import { AppProvider } from "context/AppContext";
import { routes } from "config/routes";
import { theme } from "config";
import { Layout } from "core/Layout";
import { fetchUpdatedCMSPages } from "store/cmsSlice";
import { enLanguage, getCurrentORGID, getCurrentPageName, PAGES, REACT_APP_MUI_LICENSE_KEY } from "utils/constants";
import { LoadingScreen } from "components/LoadingScreen";
import i18n from "config/lang/i18n";
import { setLoader } from "store/commonSlice";
import { ErrorBoundary } from "components";
import { Loader } from "components/Loader";
import { AuthProvider } from "context/AwsContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import enCommon from "config/lang/locales/en/common.json";
import { MotionLazyContainer } from "./components/MotionLazyContainer";
import "./App.css";
import { ProgressBarStyle } from "./components/ProgressBar";
import store  from "./store/store";

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);

function Routes() {
  // Router hooks and methods
  const location = useLocation();
  const dispatch = useDispatch();

  // Constants
  let currentPageName = PAGES?.find((eachPage) => eachPage?.path?.split("/*")?.[0] === location?.pathname)?.name; // To find current page name from constants

  if(!currentPageName){
    currentPageName = PAGES?.find((eachPage) => eachPage?.path?.split("/")?.[1] === location?.pathname.split('/')[1])?.name; // To find current page name from constants

  }
  localStorage?.setItem("pageName", currentPageName);
  // Before API load english translation added - for Automation testing
  // localStorage?.setItem(currentPageName, JSON.stringify(enCommon));
  // Redux states
  const { loader } = useSelector(({ common }) => common);
  const { entities } = useSelector(({ entityProfile }) => entityProfile);
  const { jurisdictionDetails } = useSelector(({ jurisdiction }) => jurisdiction);
  // React states
  const [langLabelsData, setLangLabelsData] = useState(localStorage.getItem(currentPageName) || {});
  const [reRenderComp, setReRenderComp] = useState(false);

  // scroll to top on every route changes
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // To set current pagename when shifting tab
    const setPageNameOnInteraction = () => {
      const currentPage = PAGES?.find((eachPage) => eachPage?.path?.split("/*")?.[0]===`/${location?.pathname.split("/")?.[1]}`)?.name; // To find current page name from constants changing this to match current routes registered in constansts
      localStorage.setItem("pageName", currentPage);
      i18n.loadNamespaces(currentPage || '');
      i18n.changeLanguage(`${getCurrentORGID()}#${enLanguage()}`);
    }

    // Detects when a browser tab becomes active/inactive.
    document.addEventListener("visibilitychange", setPageNameOnInteraction);

    return () => {
      document.removeEventListener("visibilitychange", setPageNameOnInteraction);
    }
  }, [location, i18n, entities?.data, jurisdictionDetails]);

  /* Conflict resolution candidate
  }, [location.pathname]);
  */

  // This useEffect will call while locations get changes and call the page wise data
  useEffectOnce(() => {
    // const { pathname } = location;
    // i18n.loadNamespaces([pathname.replace("/", "")]);
    // i18n.loadNamespaces(localStorage.getItem("pageName") || "Page_LandingPageKnown");
    // i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    // i18n?.reloadResources();

    // check if timestamp present if yes then call api to fetch updated pages
    dispatch(fetchUpdatedCMSPages());

    console.log("I18n log (Will be removed)", i18n);
  }, []);

  // const fetchLabelsData = async () => {
  //   dispatch(setLoader(true));

  //   const jsonData = await fetch(
  //     `${process.env.REACT_APP_API_URL}/cms/details?OrgID=00101&PageLanguage=English&Page=${currentPageName}` // &Page={{ns}}  ${localStorage.getItem("pageName")}
  //   ).then((response) => {
  //     // console.log(response);
  //     return response.json();
  //   }).catch((error) => {
  //     console.log(error);
  //     dispatch(setLoader(false));
  //   });

  //   setLangLabelsData(JSON.stringify(jsonData));
  // }

  // useLayoutEffect(() => {

  //   // i18n.addResourceBundle('English', currentPageName, i18n?.store?.data?.English?.Page_LandingPageKnown);

  //   fetchLabelsData();
  //   // console.log(langLabelsData);
  //   if (Object.keys(langLabelsData)?.length > 0) {
  //     localStorage?.setItem(currentPageName, langLabelsData);
  //     setTimeout(() => {
  //       dispatch(setLoader(false));
  //     }, 1000);
  //     // console.log(localStorage.getItem(currentPageName));
  //   }

  //   // i18n.loadNamespaces(localStorage.getItem("pageName") || "Page_LandingPageKnown");
  //   // i18n.changeLanguage(localStorage.getItem("i18nextLng") || "English");
  //   // i18n?.reloadResources();
  // }, [langLabelsData]);

  return useRoutes(routes);
}

export default function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <MotionLazyContainer>
          <Suspense fallback={<Loader loader />}>
            <I18nextProvider i18n={i18n}>
              <AppProvider
                value={{
                  routes,
                }}
              >
                <Provider store={store}>
                  <ThemeProvider theme={theme}>
                    <BrowserRouter>

                      {/* <ProgressBarStyle /> */}
                      <CssBaseline />
                      <Layout>
                        <Routes />
                      </Layout>

                    </BrowserRouter>
                  </ThemeProvider>
                </Provider>
              </AppProvider>
            </I18nextProvider>
          </Suspense>
        </MotionLazyContainer>
      </AuthProvider>
    </ErrorBoundary>
  );
}
