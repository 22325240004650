import { useAuthentication } from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import { CreateEntityUserRegistration } from "pages/EntityRegistration/Registration";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  checkIsEditAccess,
  getAlternateContact,
  setFacilitiesList,
  getEntityContactById,
  setEditAccess,
  setAlternateContact,
  getEnityFacilityById,
  getRepresentative,
  getFieldPermission,
  updateLock,
  resetSteps, resetRepresentative, setEntityType
} from "store/entityApplicationSlice";
import { getActionStatus, getRequestStatus, getRepresentativeStatus } from "store/entityProfileSlice";

import {
  t,
  isEmpty,
  USER_ROLES,
  USER_ROLE_TYPES,
  isRolesAssigned,
  COMPONENT_NAME_FOR_ROLE,
  getCurrentPageName,
  POLLING_INTERVAL
} from "utils/constants";
import { setModelMessage } from "store/commonSlice";
import usePrevious from "../../hooks/usePrevious";

export default function EntityRegistration() {
  const { id } = useParams();
  const navigate = useNavigate();
  const url = id ? `/entity-registration/${id}` : "/entity-registration";
  const { steps, isLocked, EditAccess, fieldPermissions, EntitiesData } = useSelector(({ entityApplication }) => entityApplication);
  useAuthentication({ redirectTo: url });
  const [isManuallyLocked, setIsManuallyLocked] = useState(false);
  const dispatch = useDispatch();
  const previousId = usePrevious(id);
  const [isDataReset, setIsDataReset] = useState(false);

  const { t: t1 } = useTranslation(getCurrentPageName());
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isIndividualUser, isJurisdictionRegistrarUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR]
      ),
    }),
    [userDetails]
  );

  let alternateContactPersonPageDetail = { componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON, pageName: "Page_EntityProfile" };
  if (isJurisdictionRegistrarUser) {
    alternateContactPersonPageDetail = { componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON, pageName: "Page_JurisdictionEntityProfile" }
  }

  useEffect(() => {
    // if (id) {
    //   dispatch(getLockStatus(id));
    //   return () => {
    //     // unlock
    //     if (isManuallyLocked) {
    //       dispatch(setLoader(true));
    //       dispatch(updateLock(id, false));
    //     }
    //   };
    // }
  }, [isManuallyLocked]);

  useEffect(() => {
    if (!id && previousId && !isDataReset) {
      dispatch(resetRepresentative());
      dispatch(resetSteps());
      dispatch(setEntityType(""));
      setIsDataReset(true);
    } else if (id && !previousId && isDataReset) {
      dispatch(getEntityContactById(id, true));
      dispatch(getEnityFacilityById(id, { componentName: "addedFacilities", pageName: "Page_EntityProfile" }));
      dispatch(getRepresentative(id));
      dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
      dispatch(checkIsEditAccess(id));
      setIsDataReset(false);
    }
  }, [id, previousId, isDataReset])

  const ConfirmNavigation = () => {
    useEffect(() => {
      const handlePopState = (event) => {
        navigate("/home");
      };
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [navigate]);
  }

  useEffect(() => {
  }, [isLocked]);

  let pageDetail = { componentName: "addedFacilities", pageName: "Page_EntityProfile" };

  useEffectOnce(() => {
    dispatch(getFieldPermission({ pageName: "Page_EntityRegistration", componentName: 'entity,identifiers,physical,contact,facility,addedFacilities,addedFacilitie,mailing,entityContact,facilityContact,addRepresentatives,facilityMailingAddress,facilityPhysicalAddress,addRepresentative' }));

    dispatch(getActionStatus());
    dispatch(getRepresentativeStatus())
    dispatch(getRequestStatus());

    if (id) {
      dispatch(getEntityContactById(id, true));
      if (isJurisdictionRegistrarUser) {
        pageDetail = { componentName: "addedFacilities", pageName: "Page_JurisdictionEntityProfile" }
      }
      dispatch(getEnityFacilityById(id, pageDetail))
      dispatch(getRepresentative(id))
      dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
      dispatch(checkIsEditAccess(id));
    }
    else {
      dispatch(setEditAccess(true))
      dispatch(setAlternateContact());
      dispatch(setFacilitiesList())
    }
  });

  useEffect(() => {
    if (id && EntitiesData?.data?.isLocked) {
      const data = {
        title: t(t1, "CANNOT_PROCEED"),
        message1: t(t1, "APPLICATION_LOCKED"),
        error: true,
        isDefaultDisplay: true,
      };
      dispatch(setModelMessage(data));
    }
    let intervalId
    if (id && !EntitiesData?.data?.isLocked) {
      dispatch(updateLock(id, true));

      intervalId = setInterval(() => {
        // dispatch(updateLock(id, true)); // Set is_locked to true as per your requirement
      }, POLLING_INTERVAL);
    }
    return () => intervalId && clearInterval(intervalId);
  }, [id, EntitiesData?.data?.isLocked]);

  ConfirmNavigation();

  return <div>{!isEmpty(fieldPermissions) && <CreateEntityUserRegistration />}</div>;
}
