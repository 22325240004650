import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import { NotificationSettingsTable } from "components/NotificationSettingsTable";
import { CustomizeButton } from "components/CustomizeButton";
import { getConfigurations, SubmitHomeConfigurations, submitNotificationSettingsConfig } from "store/configSlice";
import { DialogMessage } from "components";
import { setDialogMessage, setLoader } from "store/commonSlice";
import { getJurisdictionLangugages } from "store/cmsSlice";
import { FormProvider, useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import { Loader } from "components/Loader";
import { EmailLanguagePreferenceSelector } from "./EmailLangaugePreferenceSelector";
import {
  getCurrentPageName,
  t,
  DEFAULT_ORG_ID,
  JSA_ROLE
} from "../../utils/constants";
import Style from "./styles";

export function NotificationSettings(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { notificationSettingsConfig: { role_details: notiSettingsInfo }, config } = useSelector(({ config }) => config);
  const { juriLangWithPageData: { languages } } = useSelector(({ cms }) => cms);
  // use this to show default language code in selector
  const defaultLanguageSelected = {
    value: config?.['notification-settings']?.jurisdiction_email_language_code?.AttributeValue,
  }
  // get langauge name from language code from language array
  if (languages) {
    defaultLanguageSelected.name = languages?.find(lang => lang.languageCode === defaultLanguageSelected.value)?.name
  }
  const [rowData, setRowData] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const isJSAUser = userDetails?.RoleDetail?.Role.filter((item) => [JSA_ROLE].includes(item?.name))?.length > 0;
  const { selectedJurisdictionId, selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const dispatch = useDispatch();
  const { dialogMessage } = useSelector(({ common }) => common);
  const [modalDataObj, setModalDataObj] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const defaultValues = {
    languagePage: defaultLanguageSelected
  };

  const method = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues
  });

  const { handleSubmit } = method;

  useEffect(() => {
    dispatch(getJurisdictionLangugages({
      OrgID: selectedJurisdiction || DEFAULT_ORG_ID
    }
    ));
    dispatch(setLoader(false))
  }, [selectedJurisdictionId])

  const resetModifiedData = () => {
    setRowData(initialTableData());
  }

  const initialTableData = () => {
    return notiSettingsInfo?.map((row, index) => {
      return {
        key: index,
        id: row?.role?.id,
        role: row?.role?.name,
        emails: row?.emails,
      }
    })
  }

  useEffect(() => {
    if (notiSettingsInfo?.length > 0) {
      setRowData(initialTableData());
    }
  }, [notiSettingsInfo]);

  const handleCancel = () => {
    dispatch(setDialogMessage(true));
  }

  const onChangeValue = (event) => {
    setSelectedLanguage(event);
  }
  const submitNotificationSettingsData = () => {
    let tempNotificationSettings = [...notiSettingsInfo];
    tempNotificationSettings = tempNotificationSettings.map((roleInfo, index) => {
      return {
        ...roleInfo,
        emails: rowData[index]?.emails
      }
    });
    dispatch(submitNotificationSettingsConfig(selectedJurisdictionId, {
      jurisdiction_id: selectedJurisdictionId,
      role_details: tempNotificationSettings
    }));
  }

  useEffect(() => {
    if (languages && languages?.length > 0 && !method?.getValues("languagePage")) {
      method.setValue("languagePage", defaultLanguageSelected);
    }
  }, [languages, config])

  const handleEmailPreferenceCancel = () => {
    method.resetField("languagePage");
    method.setValue("languagePage", defaultLanguageSelected);
  }

  const fetchUpdatedConfigDetails = (isAPIError) => {
    setDisplayLoader(false);
    if (!isAPIError) {
      setSelectedLanguage();
      dispatch(getConfigurations(selectedJurisdiction));
    }
  }

  const handleEmailPreferenceSave = () => {
    setDisplayLoader(true);
    const langaugeSelected = method.getValues("languagePage");
    const data = {};
    data['notification-settings'] = {
      jurisdiction_email_language_code: {
        AttributeValue: langaugeSelected?.value
      }
    }
    dispatch(SubmitHomeConfigurations(data, selectedJurisdiction, true, fetchUpdatedConfigDetails))
  }

  return (
    <>
      <Grid
        item
        sm={12}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 600, textTransform: "uppercase" }}
          gutterBottom
        >
          {t(t1, "NOTIFICATION_SETTINGS")}
        </Typography>
        <div className="paneldetail">
          <Accordion sx={Style.accordianStyling} className="notificationAccordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              className="custompanel"
              id="panel5a-header"
              sx={{ minHeight: "43px !important", maxHeight: "43px !important" }}
            >
              <Typography>{t(t1, "JURISDICTION_EMAIL_PREFERENCE")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t(t1, "JURISDICTION_EMAIL_PREFERENCE_MAIN_CONTENT")}</Typography>
              <FormProvider {...method}>
                <form onSubmit={handleSubmit(handleEmailPreferenceSave)}>
                  <Box sx={{ display: "flex" }}>
                    <Grid container spacing={2.5}>
                      <Grid item>
                        <InputLabel htmlFor="customize-select" sx={Style.FieldLabel}>
                          {t(t1, "EMAIL_LANGUAGE_PREFERENCE")}
                          <Typography
                            variant="body2"
                            sx={{
                              display: "inline-block",
                              color: "common.red",
                              ml: 0.3,
                              fontWeight: 700,
                            }}
                          >
                            *
                          </Typography>
                        </InputLabel>
                        <EmailLanguagePreferenceSelector methods={method} languages={languages} rules={{ required: `${t(t1, "ERR_LANGUAGE_IS_REQUIRED")}` }} onChangeValue={onChangeValue} disabled={!isJSAUser}/>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                      container
                      sx={Style.bottomGridBox}
                    >
                      <Grid
                        item
                        className="bottomBtn"
                        sx={{ display: 'flex' }}
                      >
                        <CustomizeButton
                          disableElevation
                          variant="outlined"
                          csName="rc"
                          name="cancel"
                          privId="priv_btn_cancel"
                          label={t(t1, "CANCEL")}
                          disabled={!isJSAUser}
                          customStyle={{backgroundColor: isJSAUser ? 'white' : 'gray', border: isJSAUser? '1px solid #C7C8CA': 0, color: isJSAUser ? '#4D8DA4' : '', '&:hover': {
                            backgroundColor: 'white',
                            color: '#4D8DA4',
                        }}}
                          handleClick={handleEmailPreferenceCancel}
                        />
                        <CustomizeButton
                          disableElevation
                          variant="contained"
                          label={t(t1, "SAVE")}
                          name="save"
                          handleClick={handleEmailPreferenceSave}
                          disabled={selectedLanguage === defaultLanguageSelected?.value || !selectedLanguage}
                          privId="priv_btn_Save"
                        />
                      </Grid>
                    </Grid>
                </form>
              </FormProvider>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={Style.accordianStyling} className="notificationAccordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              className="custompanel"
              id="panel5a-header"
              sx={{ minHeight: "43px !important", maxHeight: "43px !important" }}
            >
              <Typography>{t(t1, "JURISDICTION_USER_EMAILS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="default-accounts-main">
                <Box>
                  <Box>
                    <NotificationSettingsTable
                      methods={props.methods}
                      modalDataObj={modalDataObj}
                      setModalDataObj={setModalDataObj}
                      rowData={rowData}
                      setRowData={setRowData}
                    />
                    <Grid
                      container
                      sx={Style.bottomGridBox}
                    >
                      <Grid
                        item
                        className="bottomBtn"
                        sx={{ display: 'flex' }}
                      >
                        <CustomizeButton
                          disableElevation
                          variant="outlined"
                          csName="rc"
                          name="cancel"
                          privId="priv_btn_cancel"
                          label={t(t1, "CANCEL")}
                          handleClick={handleCancel}
                        />
                        <CustomizeButton
                          disableElevation
                          variant="contained"
                          label={t(t1, "SAVE")}
                          name="save"
                          handleClick={submitNotificationSettingsData}
                          privId="priv_btn_Save"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      {dialogMessage ? (
        <DialogMessage
          warning
          title={t(t1, "WARNING")}
          message1={t(t1, "DIALOG_ERASE_MESSAGE")}
          handleModalClick={resetModifiedData} />
      ) : null}
      {displayLoader && <Loader loader />}
    </>
  );
}
