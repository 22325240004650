import { Container, Grid, IconButton, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PageTitle } from "components";
import { CustomizeButton } from "components/CustomizeButton";
import { FormCard } from "components/FormCard";
import { Loader } from "components/Loader";
import useAuth from "hooks/useAuth";
import { useAuthentication } from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import { SubmitDocuments } from "pages/CreateUserProfile/components/SubmitDocuments";
import { getFileUrl } from "store/configSlice";
import {
  ActiveStatus,
  defaultORGID,
  DenyStatus,
  docFilesPageName,
  enLanguage,
  formatLongDate,
  getCurrentPageName,
  getFormLabels,
  HomePageName,
  InactiveStatus,
  isEmpty,
  JurisdictionUser,
  PendingStatus,
  RevisionStatus,
  t,
  MarketMonitor,
  FSAHome,
  AuctionAdministrator,
  getCMSKey,
  ENTITY_ACCOUNT_TYPES,
} from "utils/constants";
import Style from "./styles";
import UserApplicaiton from "./UserApplications";


function UserHome() {
  useAuthentication({ redirectTo: "/home" });
  const { t: t1 } = useTranslation(getCurrentPageName(), { useSuspense: true });
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ContextAPI states and methods
  const { isSignedIn } = useAuth();

  // Redux States
  const { loader } = useSelector(({ common }) => common);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const TopParagraph = [
    {
      text: t(t1, "SUBMITTED_DOCUMENT_USER_REFERENCE_CODE"),
      boldText: userDetails?.ReferenceCode?.toUpperCase(),
    },
    {
      text: t(t1, "SUBMITTED_DOCUMENT_KEEP_A_RECORD"),
    },
    {
      text: t(t1, "SUBMITTED_DOCUMENT_FORMS_MUST_BE_PRINTED"),
    },
  ];

  useEffect(() => {
    if (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser && isSignedIn) {
      navigate("/jurisdiction-user");
    } else if (userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
      navigate("/fsa-home");
    } else if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor) {
      navigate("/market-monitor");
    } else if (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator) {
      navigate("/auction-administrator");
    }
  }, [userDetails]);

  const initialFunctionCall = () => {
    const getDocFilePayload = {
      OrgID: selectedJurisdiction || defaultORGID,
      QueryType: getFormLabels,
      PageLanguage: enLanguage(),
      Page: docFilesPageName,
    };
    dispatch(getFileUrl(getDocFilePayload));
  };

  useEffectOnce(() => {
    initialFunctionCall();
  }, []);
  return (
    <Container
      maxWidth="xl"
      sx={Style.Spacing}
    >
      <Grid
        container
        spacing={2.5}
      >
        <Box sx={{ maxWidth: "95%", margin: "0 auto" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <PageTitle
              sx={Style.TitalText}
              title={t(t1, "LOGIN_WELCOME") || "Welcome!"}
            />
          </Box>
       
          <Typography sx={Style.InfoText}>{t(t1, "LOGIN_WELCOME_CONTENT")}</Typography>
        </Box>
        {userDetails?.RequestStatus &&
          isEmpty(userDetails?.HistoryData?.UserApproved) && (
            <Box sx={Style.StatusText}>
              {t(t1, "REGISTRATION_APPLICATION_STATUS")}
              {userDetails?.RequestStatus !== RevisionStatus && <b> {t(t1, getCMSKey('UserAccountStatus', userDetails?.ApplicantAccountStatus)) || ""}</b>}
              {userDetails?.RequestStatus === RevisionStatus &&
                userDetails?.ApplicantAccountStatus === PendingStatus && (
                  <b>
                    {t(t1, getCMSKey('UserAccountStatus', userDetails?.ApplicantAccountStatus))} -
                    <Typography
                      variant="p"
                      sx={{ color: "common.red", fontWeight: 600 }}
                    >{`${t(t1, "REQUEST_REVISION")}`}</Typography>
                  </b>
                )}
            </Box>
          )}
        {!userDetails?.IsUser &&
          (userDetails?.RequestStatus === PendingStatus ||
            (userDetails?.ApplicantAccountStatus === PendingStatus && userDetails?.RequestStatus !== RevisionStatus)) &&
          userDetails?.ReferenceCode && (
            <Grid>
              <Box sx={{ paddingTop: "50px" }}>
                {TopParagraph?.filter((e, i) => {
                  return e?.text !== "";
                }).length > 2 && <PageTitle title={`2. ${t(t1, "SUBMITTED_DOCUMENT_SUBMIT_REQUIRED_DOCUMENTS")}`} />}
                {!isEmpty(userDetails?.ReferenceCode) && (
                  <SubmitDocuments
                    parentPageName={HomePageName}
                    startingContent={TopParagraph}
                    submitedBy={
                      <>
                        {t(t1, "DOCUMENTS_MUST_BE_SUBMITTED_BY")}{" "}
                        {userDetails?.RequestExpiryTime ? formatLongDate(userDetails?.RequestExpiryTime) : ""}
                      </>
                    }
                  />
                )}
              </Box>
            </Grid>
          )}
        {[RevisionStatus]?.includes(userDetails?.RequestStatus) && (
          <Box sx={{ marginTop: "50px", width: "100%" }}>
            <FormCard
              indicateRequired={false}
              customClass={"home-form-card"}
            >
              <Typography
                as="p"
                sx={{ fontSize: "16px", mb: 1 / 2, fontWeight: 700 }}
              >
                {t(t1, "REVISION_USER_HOME_TXT1")}:
              </Typography>
              {userDetails?.HistoryData?.RequestRevision?.Comment.split("\n")?.length > 1 ? (
                userDetails?.HistoryData?.RequestRevision?.Comment?.split("\n")?.map(
                  (item) =>
                    item !== "" && (
                      <Typography
                        as="p"
                        sx={{ ml: "42px" }}
                      >
                        - {item}
                      </Typography>
                    )
                )
              ) : (
                <Typography
                  as="p"
                  sx={{
                    pl: "42px",
                    minWidth: '100%',
                    wordWrap: 'break-word'
                  }}
                >
                  - {userDetails?.HistoryData?.RequestRevision?.Comment}
                </Typography>
              )}
              <Typography
                as="p"
                sx={{ mt: 2 }}
              >
                {t(t1, "REVISION_USER_HOME_TXT2")}.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <CustomizeButton
                  handleClick={() => navigate("/view-user-details")}
                  customStyle={{ minWidth: "254px", fontSize: "14px" }}
                  disableElevation
                  variant="contained"
                  type="submit"
                  label={t(t1, "MAKE_REVISIONS_BTN")}
                  name="priv_btn_SaveRole"
                />
              </Box>
            </FormCard>
          </Box>
        )}
        {userDetails?.ApplicantAccountStatus !== undefined &&
          userDetails?.ApplicantAccountStatus === ActiveStatus &&
          userDetails?.ApplicantAccountStatus !== InactiveStatus &&
          ![DenyStatus]?.includes(userDetails?.RequestStatus) && (
            <Box sx={{ marginTop: "10px", width: "100%", textAlign: "center" }}>
              <UserApplicaiton />
            </Box>
          )}
      </Grid>
      {loader && <Loader loader={loader} />}
    </Container>
  );
}
export default UserHome;
