import { createSlice } from "@reduxjs/toolkit";
import fetcher, { fetcherGet, fetcherPatch, fetcherPost } from "utils/fetcher";
import { APIS, COMPONENT_NAME_EVENT, COMPONENT_NAME_EVENT_APPLICATION, COMPONENT_NAME_FOR_ROLE, FSI_COMPONENT_NAMES } from "utils/constants";
import { setLoader } from "./commonSlice";

const initialState = {
  eventApplicationDetails: { isLoading: false, data: [] },
  eventApplicationType: { isLoading: false, data: [] },
  entityDetails: { isLoading: false, data: [] },
  applicationEvents: { isLoading: false, data: [] },
  eventStatusHistory: { isLoading: false, data: [] },
  formsOfBidGuarantee: { isLoading: false, data: [] },
  selectedEntityEventDetail: {
    isLoading: false,
    data: [],
  },
  Country: [],
  receivingEntityDetailsAR: { isLoading: false, data: [] },
  initiateParticipation: { isLoading: false, data: [] },
  auctionReserveSaleInfo: { isLoading: false, data: [] },
  eventAppList: { isLoading: false, data: [] },

};

const eventApplicationSlice = createSlice({
  name: "eventApplication",
  initialState,
  reducers: {
    setEventApplicationDetails: (state, action) => {
      state.eventApplicationDetails = action.payload;
    },
    setApplicationEventType: (state, action) => {
      state.eventApplicationType = action.payload;
    },
    setApplicationEvents: (state, action) => {
      state.applicationEvents = action.payload;
    },
    setFormsOfBidGuarantee: (state, action) => {
      state.formsOfBidGuarantee = action.payload;
    },
    setEntityDetails: (state, action) => {
      state.entityDetails = action.payload;
    },
    setCountry: (state, action) => {
      state.Country = action.payload;
    },
    setEventStatusHistory: (state, action) => {
      state.eventStatusHistory = action.payload;
    },
    // get
    setSelectedEntityEventDetail: (state, action) => {
      state.selectedEntityEventDetail = action.payload;
    },

    setReceivingEntityDetailsAR: (state, action) => {
      state.receivingEntityDetailsAR = action.payload;
    },
    setInitiateParticipation: (state, action) => {
      state.initiateParticipation = action.payload;
    },
    setAuctionReserveSaleInfo: (state, action) => {
      state.auctionReserveSaleInfo = action.payload;
    },
    setEventAppList: (state, action) => {
      state.eventAppList = action.payload;
    },
    // end get
  },
});

export default eventApplicationSlice.reducer;
export const {
  setEventApplicationDetails,
  setApplicationEventType,
  setFormsOfBidGuarantee,
  setEntityDetails,
  setApplicationEvents,
  setCountry,
  setEventStatusHistory,
  // get
  setSelectedEntityEventDetail,
  setReceivingEntityDetailsAR,
  setInitiateParticipation,
  setAuctionReserveSaleInfo,
  setEventAppList
  // end get
} = eventApplicationSlice.actions;

// GET Application Events
export function getApplicationEvents(jurisdictionId, ENTITY_ID, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_CREATION, pageName: PageName };
  return (dispatch) => {
    dispatch(setApplicationEvents({ isLoading: true, data: [] }));
    const url = APIS.EVENT_API;
    fetcher(`${url}/${jurisdictionId}/entity/${ENTITY_ID}/jurisdiction-events`, "GET", {}, false, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setApplicationEvents({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setApplicationEvents({ isLoading: false, data: [] }));
      });
  };
}

// GET Application Event Type
export function getApplicationEventType(PageName) {
  const pageDetails = { componentName: [COMPONENT_NAME_EVENT.EVENT_CREATION, FSI_COMPONENT_NAMES.REPORT, FSI_COMPONENT_NAMES.CREATE_REPORT_RECORDS], pageName: PageName }
  return (dispatch) => {
    dispatch(setApplicationEventType({ isLoading: true, data: [] }));
    const url = APIS.GET_EVENT_TYPE;

    fetcher(`${url}`, "GET", {}, false, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setApplicationEventType({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setApplicationEventType({ isLoading: false, data: [] }));
      });
  };
}

// GET Application Tiers Type
export function getFormsOfBidGuarantee(ComponentName, PageName) {
  const pageDetails = { componentName: ComponentName, pageName: PageName };
  return (dispatch) => {
    dispatch(setFormsOfBidGuarantee({ isLoading: true, data: [] }));

    fetcherGet(`${APIS.GET_BID_GUARANTEE}`, {}, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setFormsOfBidGuarantee({ isLoading: false, data: response.data }));
        } else {
          dispatch(setFormsOfBidGuarantee({ isLoading: false, data: [] }));
        }
      })
      .catch((err) => {
        dispatch(setFormsOfBidGuarantee({ isLoading: false, data: [] }));
      });
  };
}



export function getEntity(ENTITY_ID) {
  return (dispatch) => {
    dispatch(setEntityDetails({ isLoading: true, data: [] }));
    fetcherGet(
      `${APIS.ENTITY}/${ENTITY_ID}`,
      {},
      {
        pageName: "Page_EntityProfile",
        componentName:
          "entityDetails,generalEntityDetails,identifiers,physicalAddress,mailingAddress,additionalContactInformation",
      }
    )
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setEntityDetails({ isLoading: false, data: response?.data }));
        }
      })
      .catch((err) => {
        dispatch(setEntityDetails({ isLoading: false, data: [] }));
      });
  };
}

// GET Country and state
export function getCountry() {
  return (dispatch) => {
    fetcherGet(APIS.ENTITY_COUNTRIES, {})
      .then((response) => {
        dispatch(setCountry(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
// POST Event Apllication Form
export function addEventApplication(payload, ENTITY_ID, jurisdictionId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_CREATION, pageName: PageName };
  return async () => fetcherPost(`${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application`, {
    ...payload,
  },
    pageDetails);
}

// GET Event Apllication Form
export function getEventApplicationDetails(ENTITY_ID, APPLICATION_ID, jurisdictionId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_VIEW, pageName: PageName };
  return (dispatch) => {
    dispatch(setEventApplicationDetails({ isLoading: true, data: [] }));
    fetcher(
      `${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application/${APPLICATION_ID}`,
      "GET",
      {},
      false,
      pageDetails
    )
      .then((response) => {
        dispatch(setEventApplicationDetails({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setEventApplicationDetails({ isLoading: false, data: [] }));
      });
  };
}

// EDIT Event Apllication Form
export function updatetEventApplicationDetails(payload, ENTITY_ID, jurisdictionId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_CREATION, pageName: PageName };
  return async () => fetcherPatch(`${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application`, payload, pageDetails);
}

// Cancel Event Apllication
export function handleDeleteEventApplication(APPLICATION_ID, ENTITY_ID, jurisdictionId, PageName) {
  return () => {
    const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_CANCELLATION, pageName: PageName };
    return fetcher(`${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application/${APPLICATION_ID}`, "DELETE", {}, false, pageDetails)
      .finally(() => { });
  };
}

//  GET Event Application Status History
export function getSelectedEventStatusHistoryData(ENTITY_ID, APPLICATION_ID, jurisdictionId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_VIEW, pageName: PageName };
  return (dispatch) => {
    dispatch(setEventStatusHistory({ isLoading: true, data: [] }));
    fetcher(
      `${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application/${APPLICATION_ID}/history`,
      "GET",
      {},
      false,
      pageDetails
    )
      .then((response) => {
        dispatch(setEventStatusHistory({ isLoading: false, data: response.data }));
      })
      .catch((err) => {
        dispatch(setEventStatusHistory({ isLoading: false }));
      });
  };
}
//  GET Last Filled Event Application
export function getLastFilledApplication(payload, ENTITY_ID, jurisdictionId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_CREATION, pageName: PageName };
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.EVENT_API}/${jurisdictionId}/entity/${ENTITY_ID}/event-application/last-filled`, {
      ...payload,
    }, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}


// GET For MFS View Page
export function getMFSFinancialSericesDetails(jurisdictionId, EVENT_ID, PageName) {
  const pageDetails = { componentName: FSI_COMPONENT_NAMES.FSI_VIEW_DETAILS, pageName: PageName };
  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setSelectedEntityEventDetail({ isLoading: true, data: [] }));
    fetcher(
      `${APIS.EVENT_API}/${jurisdictionId}/fsi?eventId=${EVENT_ID}`,
      "GET",
      {},
      false,
      pageDetails
    )
      .then((response) => {
        dispatch(setSelectedEntityEventDetail({ isLoading: false, data: response }));
      })
      .catch((err) => {
        dispatch(setSelectedEntityEventDetail({ isLoading: false, data: [] }));
      });
  };
}



export function getReceivingEntityDetailsAR(
  entityId,
  pageDetails = { componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE, pageName: "Page_EntityProfile" }
) {
  return async (dispatch) => {
    dispatch(setReceivingEntityDetailsAR({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.REPRESENTATIVES}/${entityId}`, {}, pageDetails);
      dispatch(setReceivingEntityDetailsAR({ isLoading: false, data: response?.data }));
    } catch (error) {
      dispatch(setReceivingEntityDetailsAR({ isLoading: false, data: [] }));
    }
  };
}

export function fetchInitiateParticipation(entityId, jurisdictionId) {
  const pageDetails = { componentName: "auctionOrReserveSale", pageName: "Page_EntityProfile" };
  return async (dispatch) => {
    dispatch(setInitiateParticipation({ isLoading: true, data: [] }));
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`events/api/initiate-participation/${jurisdictionId}/entity/${entityId}`, {}, pageDetails);
      dispatch(setInitiateParticipation({ isLoading: false, data: response?.data }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setInitiateParticipation({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    };
  };
}


export function fetchAuctionReserveSaleInfo(entityId, PageName) {
  const pageDetails = { componentName: "auctionOrReserveSale", pageName: PageName };
  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setAuctionReserveSaleInfo({ isLoading: true, data: [] }));
    fetcherGet(`events/api/disclosure-info/${entityId}`, {}, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setAuctionReserveSaleInfo({ isLoading: false, data: response?.data }));
          dispatch(setLoader(false));

        }
      })
      .catch((err) => {
        dispatch(setAuctionReserveSaleInfo({ isLoading: false, data: [] }));
        dispatch(setLoader(false));
      });
  };
}

export function fetchEventApplications(id, jurisdictionId, PageName) {
  const pageDetails = { componentName: "auctionOrReserveSale", pageName: PageName };
  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEventAppList({ isLoading: true, data: [] }));
    fetcherGet(`${APIS.EVENT_API}/${jurisdictionId}/entity/${id}/event-application`, {}, pageDetails)
      .then((response) => {
        if (response.messageKey === "SUCCESS") {
          dispatch(setEventAppList({ isLoading: false, data: response?.data }));
          dispatch(setLoader(false));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setEventAppList({ isLoading: false, data: [] }));
      });
  };
}

export function updatetDisclosureInfo(entityId, consent, PageName) {
  const pageDetails = { componentName: "auctionOrReserveSale", pageName: PageName };
  return async () => fetcherPatch(`events/api/disclosure-info/${entityId}/${consent}`, {}, pageDetails);
}
