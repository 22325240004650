import { MenuItem, Select, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Style from "components/GridTextfield/GridTextfield.style";

export function CustomNumberTextField(props) {
  const { name, rules, value: defaultVal, regex } = props;
  const { control, setValue } = useFormContext();

  const handleValidInput = (event, field) => {
    const { value } = event.target;
   
    if (regex.test(value)) {
      field.onChange(value);
    } else {
      field.onChange(defaultVal ?? "");
    }
  };

  useEffect(() => {
    if (!defaultVal) {
      setValue(name, "");
    }
  }, [defaultVal, name, setValue]);

  return (
    <Controller
      name={name}
      type={"text"}
      rules={{ ...rules }}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          type="text"
          inputProps={{ style: { textAlign: "center" } }}
          sx={{
            ...Style.formField,
            "& .Mui-error:not(.Mui-focused) fieldset": { borderWidth: "1px", borderStyle: "solid" },
          }}
          error={Boolean(fieldState.error)}
          {...field}
          value={defaultVal}
          onChange={(e) => {
            handleValidInput(e, field);
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          onWheel={(event) => event.target.blur()}
        />
      )}
    />
  );
}

export function CustomSelectField(props) {
  const { name, rules, type, options, value: defaultVal, placeholder } = props;
  const { control, setValue } = useFormContext();
  
  useEffect(() => {
    if (!defaultVal) {
      setValue(name, "");
    }
  }, [defaultVal, name, setValue]);

  const handleValidInput = (event, field) => {
    const { value } = event.target;
    field.onChange(value ?? "");
  };

  return (
    <Controller
      name={name}
      type={type}
      rules={{ ...rules }}
      control={control}
      render={({ field, fieldState }) => (
        <Select
          sx={{
            "& .MuiOutlinedInput-input": { padding: 0, p: 0.6, fontSize: "0.875rem", width: "8rem" },
          }}
          error={Boolean(fieldState.error)}
          {...field}
          value={defaultVal}
          onChange={(e) => handleValidInput(e, field)}
          displayEmpty
        >
          <MenuItem value="">{placeholder}</MenuItem>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
