import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_ERROR_MSG, getCurrentPageName, t } from "utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { getJurisdictions } from "store/entityApplicationSlice";
import { deleteJurisdiction, getJurisdictions as getAllJurisdictionsOrg, setDeleteJurisdictionId } from "store/jurisdictionSlice";
import { CustomizeButton } from "components/CustomizeButton";
import { useNavigate } from "react-router-dom";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import { setModelMessage } from "store/commonSlice";
import { getjurisdictionPLStyle } from "store/onboardJurisdictionSlice";
import MUITableGrid from "../../../components/MUITableGrid/MUITableGrid";
import { ManageJurisdictionSchema } from "./columnSchema";

function ManageJurisdictionSection() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const columns = React.useMemo(() => [...ManageJurisdictionSchema(t1)], [t1]);
  const jurisdictions = useSelector(({ entityApplication }) => entityApplication.jurisdictions);
  const deleteJurisdictionId=useSelector(({jurisdiction})=> jurisdiction.deleteJurisdictionId)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPeripheralPending = useMemo(()=> jurisdictions?.find((jurisdiction)=> !jurisdiction.isPeripheralSetupDone),[jurisdictions])


  useEffect(() => {
    dispatch(setDeleteJurisdictionId(null))
    dispatch(getJurisdictions());
    dispatch(getAllJurisdictionsOrg());
    dispatch(getjurisdictionPLStyle());
  }, [dispatch]);


  const handleDeleteJurisdiction= useCallback(()=>{
    dispatch(deleteJurisdiction(deleteJurisdictionId)).then(() => {
      dispatch(setDeleteJurisdictionId(null))
      dispatch(
        setModelMessage({
          title: t(t1, "SUCCESS_TITLE"),
          message1: t(t1, "DELET_SUCESS"),
        })
      );
      dispatch(getJurisdictions());
    })
    .catch((error) => {
      dispatch(
        setModelMessage({
          error: true,
          title: t(t1, "ERROR_TITLE"),
          message1: `${error.response?.data?.data || DEFAULT_ERROR_MSG}`,
        })
      );
    });
  },[deleteJurisdictionId, dispatch, t1])

  const handleDeleteDialogClose= useCallback(()=>{
    dispatch(setDeleteJurisdictionId(null))
  },[dispatch])
  
  const rows = useMemo(() => jurisdictions.map((data) => ({ id: data.ID, ...data })), [jurisdictions]);

  const getRowClass= useCallback((params)=> (params.row?.isPeripheralSetupDone === null || params.row?.isPeripheralSetupDone ===false)? 'disabled-datagrid-row':"",[])

  return (
    <>
      <Typography
        sx={{
          fontWeight: 600,
          background: "#4d8da4",
          color: "#fff",
          textAlign: "left",
          paddingLeft: "1.5rem",
          marginBottom: "0px",
          fontSize: "16px",
          paddingTop: "10px",
          paddingBottom: "10px",
          height: "44px",
          textTransform: "uppercase",
        }}
        variant="h4"
        gutterBottom
      >
        {t(t1, "MANAGE_JURISDICTIONS")}
      </Typography>
      <List className="commonlistul">
        {/* {PrivAttributes?.priv_grid_pendingUsers?.PrivRole !== "NA" && ( */}
        <ListItem className="pending-accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  height: "43px",
                }}
              >{`${t(t1, "ALL_JURISDICTION")}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <CustomizeButton
                  variant="contained"
                  disableElevation
                  label={t(t1, "ONBOARD_NEW_JURISDICTION")}
                  name="current-holding-details"
                  customStyle={{
                    minHeight: "30px",
                    minWidth: "80px",
                    width: "150px",
                    height: "40px", 
                    whiteSpace: "normal", // Allow text to wrap
                    wordWrap: "break-word", // Wrap long words to the next line
                    marginBottom: "20px",
                    padding: "2px",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}
                  disabled={!!isPeripheralPending}
                  handleClick={() => { navigate('/onboard-new-jurisdiction') }}
                />
              </Box>
              <Box sx={{ paddingBottom: "20px" }}>
              <MUITableGrid
                rows={rows}
                columns={columns}
                isExport
                isSearch
                isSettings
                count={4}
                fileName={t(t1, "ALL_JURISDICTION")}
                initialSortedFields={[{ field: "statusUpdateDate", sort: "desc" }]}
                enablePrintView
                customPinnedColumns={{ left: ["jurisdictionId"], right: ["quickActions"] }}
                getRowClassName={getRowClass}
              />
              </Box>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        {/* )} */}
      </List>
      {!!deleteJurisdictionId && (
        <CustomeDialogMessage
          title={t(t1, "WARNING")}
          continueBtn={t(t1, "DELETE")}
          open={!!deleteJurisdictionId}
          message1={t(t1, "DELETE_MODAL_DESCRIPTION")}
          borderColor="#ca2c1c"
          handleContinue={handleDeleteJurisdiction}
          handleModalCancel={handleDeleteDialogClose}
          handleClose={handleDeleteDialogClose}
        />)
      }
    </>
  );
}

export default ManageJurisdictionSection;
