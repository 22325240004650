import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CheckCircle } from "assets/icons/CheckCircle";
import { FormActions } from "components/FormActions";
import { FormCard } from "components/FormCard";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  t,
  JURISDICTIONS,
  getCurrentPageName,
  scrollToTop,
  getAllLang,
  getFormLabels,
  getAllLabels,
  docFilesPageName,
  enLanguage,
  LandingPageName,
  defaultORGID,
  isEmpty,
  DEFAULT_LANGUAGE_CODE,
/* Conflict Resolution Candidate
  getCurrentORGID,
 */
} from "utils/constants";
import { setJurisdiction, setLanguage } from "store/jurisdictionSlice";
import { useEffectOnce } from "hooks/useEffectOnce";
import { getCMSDetails, getFileUrl } from "store/configSlice";
import _ from "lodash";
import { setActiveStep, setFilledData, setSubmittedState } from "store/createUserProfileSlice";
import { Loader } from "components/Loader";
import { checkIsAuthenticated, getUserDetails } from "store/authSlice";
import useAuth from "hooks/useAuth";
import { Autocomplete, TextField } from "@mui/material";
import Style from "./SelectJurisdiction.style";

const ITEM_HEIGHT = 27;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectJurisdiction() {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;
  const { Jurisdictions, selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { user } = useSelector(({ auth }) => auth);
/* Conflict Resolution Candidate
  const { cmsLanguagePage } = useSelector(({ config }) => config);
 */
  const { PrivAttributes } = user;
  const {
    filledData,
    submitted,
    formSteps: { isEditField, activeStep },
    prePopulatedDetails: { data },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const {user: loggedUser } = useAuth();
  const dispatch = useDispatch();

  useEffectOnce(() => {
    scrollToTop();
  });

  const defaultValues = submitted?.[1]
    ? filledData?.[1]
    : {
        jurisdiction: "",
      };
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (!_.isEmpty(data) && activeStep === 1) {
      dispatch(setSubmittedState({ step: activeStep, data: true }));
      dispatch(
        setFilledData({
          step: activeStep,
          data: {
            jurisdiction: {
              OrgID: data?.org_id,
              OrgName: data?.Jurisdiction?.display_name,
            },
          },
        })
      );
      handleChange({ OrgID: data?.org_id, OrgName: data?.Jurisdiction?.display_name });

      if (activeStep === 1 && !checkIsAuthenticated() && isEmpty(loggedUser)) {
        dispatch(getUserDetails({ jurisdiction: data?.org_id, pageName: "Page_UserRegistration" }));
      }
      dispatch(setActiveStep(2));
    }
  }, [data,Jurisdictions]);

  // function for the api call on change of jurisdiction in the user-registration pages
  const handleChange = (e) => {
    const OrgID = e?.OrgID;
    localStorage.setItem("jurisdiction", OrgID);
    localStorage.setItem("languageCode", DEFAULT_LANGUAGE_CODE);
    dispatch(setJurisdiction(OrgID));
    // api call for get the languages on the change jurisdiction
    const LanguageParams = {
      OrgID,
      QueryType: getAllLang,
    };
    dispatch(getCMSDetails(LanguageParams));

    // set the language "English" on the jurisdiction change
    // if(prevOrgID !== OrgID)
    // {
    dispatch(setLanguage("English"));
    i18n.changeLanguage(`${OrgID}#English`);
    //  }

    // api call for selected jurisdiction
    const getDocFilePayload = {
      OrgID,
      QueryType: getFormLabels,
      PageLanguage: "English" || enLanguage(),
      Page: docFilesPageName,
    };
    dispatch(getFileUrl(getDocFilePayload));

/* Conflict Resolution Candidate
  };

  useEffect(() => {

    const languageName = cmsLanguagePage?.data?.Languages?.find((e) =>e?.PageLanguage === localStorage.getItem('i18nextLng')) ? localStorage.getItem('i18nextLng'): 'English';
    localStorage.setItem("i18nextLng", languageName);
    dispatch(setLanguage(languageName));
    i18n.changeLanguage(`${getCurrentORGID()}#${languageName}`);
  }, [cmsLanguagePage]);
 */

  };

  const setDefaultValues = () => {
    methods?.reset(defaultValues);
  };

  if (PrivAttributes?.priv_select_Jurisdiction?.PrivRole === "NA") {
    return null;
  }
  const readOnly = PrivAttributes?.priv_select_Jurisdiction?.PrivRole === "RO";

  if(!_.isEmpty(data) && activeStep === 1){
    return <Loader loader={!!true} />
  }

  return (
    <FormProvider {...methods}>
      <FormCard
        customClass={"customFormCard"}
        title={!isEditField[1] && activeStep === 4 ? t(t1, "SELECTED_JURISDICTION") : t(t1, "SELECT_JURISDICTION")}
        indicateRequired={false}
        setDefaultValues={setDefaultValues}
        stepNumber={1}
        customeStyle={{ marginBottom: "20px !important" }}
      >
        <FormControl
          sx={{
            m: 1,
            ml: 0,
            width: 300,
            minHeight: `${activeStep !== 4 ? "130px" : "40px"}`,
            "& .Mui-error": { marginLeft: 0 },
          }}
        >
          {(!isEditField[1] && activeStep === 4) || readOnly ? (
            SelectRenderValue(methods?.getValues("jurisdiction"), themeColor, t(t1, "SELECT_JURISDICTION"), t, t1)
          ) : (
            <Controller
              name="jurisdiction"
              control={methods?.control}
              rules={{ required: `${t(t1, "ERR_JURISDICTION_IS_REQUIRED")}` }}
              render={({ field, fieldState }) => (
                <>
                  <Autocomplete
                    id="select_jurisdiction"
                    options={Jurisdictions.filter((jurisdiction) => jurisdiction.IsDefault === false)}
                    clearText=""
                    closeText=""
                    openText=""
                    fullWidth
                    sx={{
                      width: 1,
                      color: "common.black",
                      "&.fc-search": {
                        "& .MuiOutlinedInput-input": {
                          background: '#fff',
                          color: 'common.black',
                          minHeight: '25px',
                          py: 0,
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '6px'
                        }
                      },
                      "& .fc-select-rounded": {
                        borderRadius: "4px"
                      },
                      ".MuiOutlinedInput-input": {
                        p: 0.2,
                        fontSize: "0.875rem",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        borderColor: "common.GrayColor",
                      },
                      "& .MuiOutlinedInput-root": {
                        p: '2px',
                        height:'30px',
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: '4px',
                        },
                        "&.MuiInputBase-root.Mui-focused": {
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "common.black",
                            borderWidth: 1,
                            borderRadius: '4px',
                  
                          },
                        },
                      },
                    }}
                    onBlur={field?.onBlur}
                    className="fc-search"
                    ListboxProps={{ style: { maxHeight: 130 } }}
                    autoHighlight
                    onChange={(_, data) => {
                      field?.onChange(data);
                      handleChange(data);
                      return t(t1 ,data?.OrgName);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {t(t1,option?.OrgName)}
                      </Box>
                    )}
                    getOptionLabel={(item) => t(t1,item.OrgName)}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(fieldState?.error)}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t(t1, 'SELECT_JURISDICTION'),
                        }}
                        margin="normal"
                        variant="outlined"
                        sx={{ mt: 0, width: '300px', mb: 0}}
                      />
                    )}
                  />

                  {fieldState?.error?.message && (
                    <FormHelperText error>
                      {fieldState?.error ? fieldState?.error?.message : " "}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          )}
        </FormControl>
      </FormCard>
      <FormActions stepNumber={1} />
    </FormProvider>
  );
}

function SelectRenderValue(selected, color, defaultValue, t, t1) {
  if (selected) {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        <Typography component="div" key={selected} sx={Style.inputStyle}>
          <CheckCircle color={color} />
          <Box sx={{ ml: 0.5 }}>{`${t(t1, `${selected.OrgName}`)}`}</Box>
        </Typography>
      </Box>
    );
  }
  return defaultValue;
}
