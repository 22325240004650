import _ from "lodash";
import {
  COMMENT_MAX_CHAR_LENGTH,
  CUSTOM_INPUT_ALLOWED_PATTERN,
  formatDateUpdated,
  statusTypes,
  t,
} from "utils/constants";
import { addMethod, array, object, string } from "yup";

export const ADMINISTRATIVE_TRANSFER_PAGE = "Page_ManageAdministrativeTransfers";

export const COMPONENTS = {
  INITIATE_TRANSFER: "initiateTransfer",
  UPDATE_ACCOUNT_NUMBER: "updateAccountNumber",
  TRANSFERS_LIST: "getAllTransfers",
  TRANSFER_HOLDINGS: "getTransferHoldings",
  SAVE_TRANSFER_HOLDINGS: "saveTransferHoldings",
  PROPOSE_TRANSFER: "proposeTransfer",
  TRANSFER_HISTORY: "getTransferHistory",
  UPDATE_TRANSFER_STATUS: "updateTransferStatus",
  REPLENISHMENT_ACCOUNT_DETAILS: "getReplenishmentAccountDetails"
};

export const TRANSFER_ACCOUNT_TYPES = {
  JURISDICTION: "JURISDICTION_ACCOUNT",
  ENTITY: "ENTITY_ACCOUNT",
};

export const STATUSES = {
  SAVED: "SAVED",
  PROPOSED: "PROPOSED",
  APPROVED: "APPROVED",
  REQUEST_REVISION: "REVISIONS_REQUESTED",
  DENIED: "DENIED",
  CANCELLED: "CANCELLED",
  COMPLETE: "COMPLETE",
};

export const ERROR_CODES = {
  STALE_DATA: "ERROR_CLUSTER_TRANSFER_001",
};

export const listHoldingsProposedQuantityError = "LIST_HOLDINGS_PROPOSED_QUANTITY_ERROR";

export const RECEIVING_ACCOUNT_ERRORS = {
  ACCOUNT_CLOSED_ERROR: "ACCOUNT_CLOSED_ERROR",
  TRANSFERRING_ACCOUNT_STATUS_ERROR: "TRANSFERRING_ACCOUNT_STATUS_ERROR",
  ACCOUNT_NOT_EXISTS_ERROR: "ACCOUNT_NOT_EXISTS_ERROR",
  ACCOUNT_INVALID_ERROR: "ACCOUNT_INVALID_ERROR",
  ERROR_CLUSTER_TRANSFER_001: "STALE_DATA_ERROR",
  HL_CHECK_FAIL: "HL_CHECK_FAIL",
  STOP_ALL_FUTURE_TRANSFERS_ADMIN_AND_BATCH: "STOP_ALL_FUTURE_TRANSFERS_ADMIN_AND_BATCH",
  STOP_ALL_TRANSFERS_IN_PROGRESS_FOR_PROPOSAL_ADMIN_AND_BATCH: "STOP_ALL_TRANSFERS_IN_PROGRESS_FOR_PROPOSAL_ADMIN_AND_BATCH",
  STOP_ALL_TRANSFERS_IN_PROGRESS_FOR_APPROVAL_FOR_ADMIN: "STOP_ALL_TRANSFERS_IN_PROGRESS_FOR_APPROVAL_FOR_ADMIN",
  STOP_ALL_PROPOSED_TRANSFERS_ADMIN: "STOP_ALL_PROPOSED_TRANSFERS_ADMIN",
  TRANSFER_CANNOT_APPROVED: "TRANSFER_CANNOT_APPROVED"
};

export const transformClusterTransfer = (response) => ({
  ...response,
  data: response.data.map((cluster, idx) => ({
    ...cluster,
    clusterID: cluster?.id,
    clusterId: cluster?.clusterId,
    id: cluster?.transfer?.id || idx,
    transferId: cluster?.transfer?.transferId,
    transferType: cluster?.transferType?.name,
    transferTypeId: cluster?.transferType?.id,
    transferringAccountType: cluster?.transferringJurisdiction?.jurisdictionAccount?.accountNumber
      ? TRANSFER_ACCOUNT_TYPES.JURISDICTION
      : TRANSFER_ACCOUNT_TYPES.ENTITY,
    receivingAccountType: cluster?.receivingJurisdiction?.jurisdictionAccount?.accountNumber
      ? TRANSFER_ACCOUNT_TYPES.JURISDICTION
      : TRANSFER_ACCOUNT_TYPES.ENTITY,
    transferringAccountNumber:
      cluster?.transferringJurisdiction?.jurisdictionAccount?.accountNumber ||
      `${cluster?.transferringEntity?.entityAccounts?.accountNumber || ""}-${cluster?.transferringEntity?.entityAccounts?.accountNumberIncrement || ""
      }`,
    transferringJurisdictionAccountId: cluster?.transferringJurisdiction?.jurisdictionAccount?.id,
    transferringType: cluster?.transferringJurisdiction?.jurisdictionAccount?.accountTypeLanguage
      ? cluster.transferringJurisdiction.jurisdictionAccount.accountTypeLanguage?.find(
        (item) => item.language_code === localStorage.getItem("languageCode")
      )?.account_name || ""
      : cluster?.transferringJurisdiction?.jurisdictionAccount?.accountType ||
      `${cluster?.transferringEntity?.entityAccounts?.accountType}`,
    transferringAccountName: cluster?.transferringJurisdiction?.name || cluster?.transferringEntity?.legalName,
    receivingAccountNumber:
      cluster?.receivingJurisdiction?.jurisdictionAccount?.accountNumber ||
      `${cluster?.receivingEntity?.entityAccounts?.accountNumber || ""}-${cluster?.receivingEntity?.entityAccounts?.accountNumberIncrement || ""
      }`,
    receivingType: cluster?.receivingJurisdiction?.jurisdictionAccount?.accountTypeLanguage
      ? cluster.receivingJurisdiction.jurisdictionAccount.accountTypeLanguage?.find(
        (item) => item.language_code === localStorage.getItem("languageCode")
      )?.account_name || ""
      : cluster?.receivingJurisdiction?.jurisdictionAccount?.accountType ||
      `${cluster?.receivingEntity?.entityAccounts?.accountType}`,
    receivingAccountName: cluster?.receivingJurisdiction?.name || cluster?.receivingEntity?.legalName,
    transferringEntityID: cluster?.transferringEntity?.entityId,
    transferringEntityOperatingName:
      cluster?.transferringEntity?.operatingName || cluster?.transferringJurisdiction?.name,
    transferringEntityAccountID: cluster?.transferringEntity?.entityAccounts?.id,
    receivingEntityID: cluster?.receivingEntity?.entityId,
    receivingEntityOperatingName: cluster?.receivingEntity?.operatingName || cluster?.receivingJurisdiction?.name,
    receivingEntityAccountID: cluster?.receivingEntity?.entityAccounts?.id,
    status: cluster?.clusterStatus?.name,
    transferStatus: cluster?.transferStatus?.name,
    updatedAt: cluster?.updatedAt,
    transferUpdatedAt: cluster?.transferStatus?.updatedAt,
    comment: cluster?.comment,
    jurisdictionId:
      cluster?.receivingJurisdiction?.id || cluster?.receivingEntity?.entityAccounts?.receivingEntityJurisdictionId,
  })),
});

export const transformClusterTransferHoldings = (response) => ({
  ...response,
  data: response.data
  .map((holding, index) => ({
    ...holding,
    rowId: index,
    id: holding?.Transfer?.SerializationMapping?.id || holding?.id,
    transactionId: holding?.Transfer?.transactionId,
    transferId: holding?.Transfer?.id,
    holdingId: holding?.holding_id,
    vintage: holding?.holdings?.vintage_year,
    type: holding?.holdings?.issuance_type,
    subType: holding?.holdings?.sub_type?.name,
    offsetType: holding?.holdings?.offset_type?.name,
    offsetProjectId: holding?.holdings?.project_id,
    specialCategory: holding?.holdings?.special_category?.name,
    quantity: holding?.holdings?.quantity,
    availableQuantity: holding?.holdings?.available_holding_quantity,
    updatedDate: holding?.last_updated,
    proposedQuantity: holding?.Transfer?.SerializationMapping?.quantity || holding?.Transfer?.quantity_proposed || "",
    price: holding?.price || "",
    currency: holding?.jurisdiction_currency_id || "",
    start: holding?.Transfer?.SerializationMapping?.start_serialization_number || "",
    end: holding?.Transfer?.SerializationMapping?.end_serialization_number || "",
  })),
});

export const transformClusterApprovals = (response) => ({
  ...response,
  data: response.data.map((cluster) => ({
    id: cluster?.id,
    displayId: cluster?.clusterId,
    keyInformation: cluster?.comment,
    type: cluster?.transferType?.name?.toUpperCase(),
    status: cluster?.clusterStatus?.name,
    lastUpdatedAt: formatDateUpdated(cluster?.updatedAt) || "",
  })),
});

addMethod(string, "bigInt", function bigInt(message) {
  return this.matches(/^\d{0,18}$/, { message, name: "bigInt" });
});

export const holdingsValidationSchema = (t1, selectedLanguage) =>
  object().shape({
    holdingDetails: array().of(
      object().shape({
        proposedQuantity: string()
          .nullable()
          .bigInt(`${t(t1, "ERR_PROPOSED_QUANTITY_IS_NOT_VALID_NUMERIC")}`)
          .required(`${t(t1, "ERR_PROPOSED_QUANTITY_IS_REQUIRED")}`)
          .test({
            name: "greaterThanZero",
            message: `${t(t1, "ERR_PROPOSED_QUANTITY_MUST_BE_GREATER_THAN_ZERO")}`,
            test: (value) => parseInt(value, 10) > 0,
          }),
        price: string()
          .nullable()
          .matches(selectedLanguage === "French" ? CUSTOM_INPUT_ALLOWED_PATTERN.floatFrench : CUSTOM_INPUT_ALLOWED_PATTERN.float, {
            message: `${t(t1, "ERR_INVALID_PRICE")}`,
            name: "priceDecimal",
          })
          .test({
            name: "greaterThanZero",
            message: `${t(t1, "ERR_PRICE_MUST_BE_GREATER_THAN_ZERO")}`,
            test: (value) => {
              if (value) {
                return parseFloat(value.replace(",", ".")) > 0;
              }
              return true;
            },
          })
          .test({
            name: "requiredPrice",
            message: `${t(t1, "ERR_PRICE_IS_REQUIRED")}`,
            test: (value, context) => {
              const { currency } = context.parent;
              return !(currency && !value);
            },
          }),
        currency: string()
          .nullable()
          .when("price", {
            is: (price) => Boolean(price),
            then: string()
              .required(`${t(t1, "ERR_CURRENCY_IS_REQUIRED")}`)
              .nullable(),
          }),
      })
    ),
    comment: string()
      .required(`${t(t1, "ERR_COMMENT_IS_REQUIRED")}`)
      .max(
        COMMENT_MAX_CHAR_LENGTH,
        `${t(t1, "ERR_COMMENTS_MAX_LENGTH_CHAR")}`
      )
      .nullable(),
  });

export const getFLPHeaders = (componentName) => ({ pageName: "Page_ManageAdministrativeTransfers", componentName });

export const mergeSequentialBlocks = (transfers) => {
  const groupTransfers = _.groupBy(transfers, "transferId");
  return Object.keys(groupTransfers).flatMap((transfers) => {
    const sortedBlocks = _.sortBy(groupTransfers[transfers], [(o) => BigInt(o.start || "0")])
    const transfersList = [];
    sortedBlocks.forEach((block) => {
      const recentBlock = transfersList[transfersList.length - 1]
      if (recentBlock && BigInt(block.start || "0") - BigInt(recentBlock.end || "0") === BigInt(1)) {
        const mergedQuantity = BigInt(recentBlock.proposedQuantity || "0") + BigInt(block.proposedQuantity || "0")
        recentBlock.end = block.end;
        recentBlock.proposedQuantity = mergedQuantity.toString();
      } else {
        transfersList.push(block);
      }

    })
    return transfersList
  })
}
