import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import { ButtonFill } from "components/ButtonFill";
import { ButtonOutline } from "components/ButtonOutline";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { t, getCurrentPageName, formatDate, ERROR, SUCCESS, APIS, LOCALIZED_STATE_NAMES, COUNTRY_KEY_NAMES } from "utils/constants";
import { setDialogMessage, setLoader, setModelMessage } from "store/commonSlice";
import { setReferenceCode, setNextActiveStep, resetCreaeUserProfileState, setRedirectionPath, fetchUserDetailsByLinkId, setPrePopulatedDetails, setIsLinkExpired } from "store/createUserProfileSlice";
import { getReferenceCode, getUserRegDetails } from "store/userHomeSlice";
import { CustomizeButton } from "components/CustomizeButton";
import { useLocation } from "react-router-dom";
import fetcher from "utils/fetcher";
import { LinkExpired } from "components/LinkExpired";
import { getCMSTnC } from "store/cmsSlice";

export default function FormActions({ stepNumber }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');
  const { signUp } = useAuth();
  const {
    filledData,
    isLinkExpired
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { defaultFileUrl } = useSelector(({ config }) => config);
  const { securityQuestions } = useSelector(({ common }) => common);

  const [acceptTAndC, setAcceptTAndC] = useState(false);
  const [filteredForms, setFilteredForms] = useState([]);

  const stateNameToKey = (name) => {
    const stateKey = Object.keys(LOCALIZED_STATE_NAMES).find(key => LOCALIZED_STATE_NAMES[key] === name);
    return stateKey;
  }

  const securityQuestionToKey = (name) => {
    // debugger; // eslint-disable-line
    const questionKey = securityQuestions.find(question => question.value === name)?.key
    return questionKey;
  }



  useEffect(() => {
    const filterForms = defaultFileUrl?.data?.filter((eachFile) =>
      ["WCI_TERMS_OF_USE"]?.includes(eachFile?.SK?.split("#")?.pop())
    );
    setFilteredForms(filterForms);
  }, [defaultFileUrl]);

  const {
    formSteps: { activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);

  if (activeStep === 4) {
    return null;
  }

  const handleCancel = () => {
    /* Conflict Resolution Candidate
        dispatch(setRedirectionPath({route : "/" ,jurisdiction :""}))
     */
    dispatch(setDialogMessage(true));
  };

  const handleFinish = () => {
    dispatch(setModelMessage(true));
  };

  const handleRegister = async (data) => {
    dispatch(getCMSTnC());
    let finalEmployer;
    if (typeof employer === "string") {
      finalEmployer = filledData?.[2]?.employer?.trim() || '';
    } else {
      finalEmployer = filledData?.[2]?.employer?.map(emp => {
        return {
          title: emp?.title?.trim() || emp?.title
        }
      }
      )
      finalEmployer = JSON.stringify(finalEmployer);
    }
    const UserProfile = {
      createUserID: data.createUserID,
      passphrase: data.passphrase,
      email: filledData?.[2]?.email?.trim(),
      firstName: filledData?.[2]?.firstName.trim(),
      middleName: filledData?.[2]?.middleName.trim(),
      lastName: filledData?.[2]?.lastName.trim(),
      roleName: filledData?.[2]?.roleName,
      referenceCode: filledData?.[2]?.referenceCode,
      // birthDate: `${filledData?.[2]?.year}-${filledData?.[2]?.month > 9 ? filledData?.[2]?.month : `0${filledData?.[2]?.month}`
      //   }-${filledData?.[2]?.day > 9 ? filledData?.[2]?.day : `0${filledData?.[2]?.day}`}`,
      birthDate: filledData?.[2].date ? formatDate(filledData?.[2].date, "-") : '',
      telephoneNumber: `${filledData?.[2]?.telephoneNumber.trim()}`,
      salutation: filledData?.[2]?.salutation.trim(),
      suffix: filledData?.[2]?.suffix.trim(),
      mobilePhone: filledData?.[2]?.mobilePhone.trim(),
      emailLanguagePreference: `${filledData?.[2]?.emailLanguagePreference}`,
      employer: finalEmployer,
      department: filledData?.[2]?.department,
      faxNumber: filledData?.[2]?.faxNumber.trim(),
      orgId: filledData?.[1]?.jurisdiction.OrgID,
      ext: filledData?.[2]?.ext.trim(),
      jobTitle: filledData?.[2]?.jobTitle?.trim(),
      resStreet1: filledData?.[3]?.primaryResidence.street1.trim(),
      resStreet2: filledData?.[3]?.primaryResidence.street2.trim(),
      resCity: filledData?.[3]?.primaryResidence.city.trim(),
      resState: stateNameToKey(filledData?.[3]?.primaryResidence.stateOrProvince), // `${filledData?.[3]?.primaryResidence.stateOrProvince.trim()}`,
      resRegion: filledData?.[3]?.primaryResidence.region.trim(),
      resCountry: filledData?.[3]?.primaryResidence.country,  // `${filledData?.[3]?.primaryResidence.country.trim()}`,
      resPostalCode: filledData?.[3]?.primaryResidence.postalCode.trim(),
      mailStreet1: filledData?.[3]?.mailingAddress.street1.trim(),
      mailStreet2: filledData?.[3]?.mailingAddress.street2.trim(),
      mailCity: filledData?.[3]?.mailingAddress.city.trim(),
      mailState: stateNameToKey(filledData?.[3]?.mailingAddress.stateOrProvince), // `${filledData?.[3]?.mailingAddress.stateOrProvince.trim()}`,
      mailRegion: filledData?.[3]?.mailingAddress.region.trim(),
      mailCountry: filledData?.[3]?.mailingAddress.country,  // `${filledData?.[3]?.mailingAddress.country.trim()}`,
      mailPostalCode: filledData?.[3]?.mailingAddress.postalCode.trim(),
      securityQuestion1: securityQuestionToKey(data.securityQuestion1.trim()),    // data.securityQuestion1.trim(),
      securityQuestion2: securityQuestionToKey(data.securityQuestion2.trim()),
      securityQuestion3: securityQuestionToKey(data.securityQuestion3.trim()),
      answer1: data.answer1.trim(),
      answer2: data.answer2.trim(),
      answer3: data.answer3.trim(),
      acceptTAndC: filledData?.[4]?.acceptTAndC.toString(),
    };
    dispatch(setLoader(true));
    if (linkId) {
      try {
        const res = await fetcher(`${APIS.FETCH_USER_DETAILS}/${linkId}`, "GET", {}, false, {});
        dispatch(setLoader(false));
        if (!res?.data?.is_link_valid) {
          dispatch(setIsLinkExpired(true));
          return;
        };
        // if InitialUser Role does not match with older Role, whoe the toaster error msg
        if(res?.data?.InitialUsers?.Roles?.name !== UserProfile?.roleName ||
          res?.data?.InitialUsers?.first_name !== UserProfile?.firstName ||
          res?.data?.InitialUsers?.last_name !== UserProfile?.lastName
          ) {
          const data = {
            title: ERROR,
            message1: t(t1, "REFRESH_ERROR"),
            error: true,
            isDefaultDisplay: true,
          };
          dispatch(setModelMessage(data));
          return
        }
      } catch (error) {
        dispatch(setLoader(false));
        dispatch(setIsLinkExpired(true));
        return;
      }
    }
    signUp(UserProfile)
      .then((cognitoUser) => {
        dispatch(getReferenceCode({ UID: cognitoUser?.userSub, linkId })); // To get user details using UID
        const data = {
          title: SUCCESS,
          message1: "REG_MSG_SUCCESS",
          error: false,
        };
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
        dispatch(setNextActiveStep());
      })
      .catch((error) => {
        dispatch(setLoader(false));
        if(error?.message?.includes('INVALID_REFERENCE_CODE')) {
          dispatch(setIsLinkExpired(true));
        } else {
          const data = {
            title: ERROR,
            message1: error.message,
            error: true,
            isDefaultDisplay: true,
          };
          // Added to display email already exists
          if (error?.message?.includes("MSG_ERR_EMAIL_ALREADY_EXISTS")) {
            data.message1 = "MSG_ERR_EMAIL_ALREADY_EXISTS";
          } // Added to display userid already exists
          else if (error?.message?.includes("MSG_ERR_USERNAME_ALREADY_EXISTS")) {
            data.message1 = "MSG_ERR_USERNAME_ALREADY_EXISTS";
          }
          dispatch(setModelMessage(data));
        }
      });
  };

  const handleTAndC = (e) => {
    const { checked } = e.target;
    setAcceptTAndC(checked);
  };

  if (isLinkExpired) {
    return <LinkExpired />;
  }

  return (
    <Box
      sx={{
        mt: 5,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {activeStep === 5 && (
        <>
          <FormControlLabel
            style={{ pointerEvents: "none" }}
            sx={{
              mr: "-5px",
              "& .MuiFormControlLabel-label": { color: "text.primary", fontWeight: 600, fontSize: "14px" },
            }}
            control={<Checkbox onChange={handleTAndC} style={{ pointerEvents: "auto" }} checked={acceptTAndC} />}
            label={t(t1, "WCI_TERMS_AND_CONDITIONS")}
          />
          <a
            href={filteredForms?.[0]?.LabelPublished?.replace("#", "%23")}
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: "9px", color: "#4d8da4", fontSize: "14px", fontWeight: 600 }}
          >
            {t(t1, "WCI_TERMS_OF_USE")}
          </a>{" "}
        </>
      )}
      <Box sx={{ ml: "auto" }}>
        {activeStep !== 6 && (
          // <ButtonOutline styleSx={{ mr: 1 }} handleClick={handleCancel}>
          //   {t(t1, "CANCEL")}
          // </ButtonOutline>
          <CustomizeButton
            customStyle={{ mr: 1 }}
            variant="outlined"
            csName="rc"
            label={t(t1, "CANCEL")}
            name="priv_modal_Cancel"
            handleClick={handleCancel}
          />
        )}
        <ButtonFill
          name="priv_btn_Continue"
          stepNumber={stepNumber}
          handleFinish={handleFinish}
          handleRegister={handleRegister}
          acceptTAndC={acceptTAndC}
          styleSx={{ height: "40px" }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            activeStep === 6 ? t(t1, "FINISH") : activeStep === 5 ? t(t1, "REGISTER") : t(t1, "CONTINUE")
          }
        </ButtonFill>
      </Box>
    </Box>
  );
}
