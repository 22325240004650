import { Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';
import { GridFileUpload } from "components";
import { CustomizeButton } from "components/CustomizeButton";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { t, getCurrentPageName, MAX_UPLOAD_FILE_SIZE, scrollToTop, allowedFileUploadFormats, BATCH_TRANSFER_TYPES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setModelMessage } from "store/commonSlice";
import { addTransfersBulk, getBatchHoldings, getBatchTransferAccountDetails, getBatchandAccountDetailsById, setFileUploadErrors } from "store/batchTransferSlice";
import { useParams } from "react-router-dom";
import { getErrorMessage } from "pages/ManageBatchTransfer/constants";
import Style from "./styles";
import { convertFileToJson } from "./util";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "20px",
  border: "1px solid #333333",
  "&:focus-visible": {
    outline: "none",
  },
};

function UploadModal(props) {
  const { open, handleClose, transferType, accountDetailPCRA } = props;
  const params = useParams();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { currencyList } = useSelector(({ batchTransfer }) => batchTransfer)
  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);

  const defaultValues = {
    document: "",
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, watch, formState } = methods;

  const enteredFile = watch("document");

  const fileType = enteredFile?.name?.split('.')?.pop()?.toLowerCase()

  const onSubmit = async () => {
    dispatch(setFileUploadErrors([]))
    let rowCount;
    try {
      const jsonData = await convertFileToJson(enteredFile, t1, fileType, currencyList, transferType?.name, accountDetailPCRA, selectedLanguage);
      rowCount = jsonData.length;
      dispatch(addTransfersBulk({ jsonData, id: params.id, transferTypeId: transferType?.id }, enteredFile?.name))
        .then(() => {
          dispatch(
            setModelMessage({
              title: t(t1, "SUCCESS_TITLE"),
              message1: `${t(t1, "MSG_SUC_FILE_WITH_RECORD_SUCCESS_MSG" , {rowCount : `${rowCount}`})}`,
            })
          );
          methods.reset({});
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id));
        })
        .catch((e) => {
          const errorList = getErrorMessage(e?.response?.data?.data, t1, accountDetailPCRA, rowCount)
          dispatch(setFileUploadErrors(errorList));
          scrollToTop()
        });
    } catch (error) {
      const errorList = [{ messageKey: error?.message }];
      dispatch(setFileUploadErrors(errorList));
      scrollToTop()
    } finally {
      handleClose();
    }
  };

  const handleCancel = () => {
    methods.reset(defaultValues);
    handleClose();
  };
  const disabled =
    !(enteredFile instanceof File) ||
    Object.keys(formState.errors).length !== 0 ||
    enteredFile?.size > MAX_UPLOAD_FILE_SIZE ||
    !allowedFileUploadFormats.includes(fileType)


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      privId="priv_modal_CmsDoc"
    >
      <Box
        sx={{
          ...style,
          width: 750,
          height: 450,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={Style.modalHeading}>
            <Typography sx={Style.modalHeadingText}>{t(t1, "MODAL_UPLOAD_DOCUMENT")}</Typography>
          </Box>
          <Typography variant="body2">{t(t1, transferType?.name === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? "MODAL_UPLOAD_BODY" : "MODAL_UPLOAD_BODY_PCRA")}</Typography>
          <Box sx={{ display: "flex", paddingTop: "10px"}}>
            <InfoIcon sx={{color: "#4d8da4", width: "20px", height: "20px"}} /><Typography sx={{ paddingLeft: "5px", fontSize: "0.875rem", fontWeight: "600"}}>{t(t1, "MJT_MAX_UPLOAD_WARNING")}</Typography>
          </Box>
        </Box>
        <Box sx={{ color: "#CA2C1C", display: "flex", justifyContent: "center" }}>
          {enteredFile?.size > MAX_UPLOAD_FILE_SIZE && (
            <Typography>
              {enteredFile?.size > MAX_UPLOAD_FILE_SIZE ? t(t1, "FILE_SIZE_EXCEEDED_MESSAGE") : " "}{" "}
            </Typography>
          )}
        </Box>
        <Box sx={{ color: "#CA2C1C", display: "flex", justifyContent: "center" }}>
          {fileType && !allowedFileUploadFormats.includes(fileType) && (
            <Typography>
              {t(t1, "FILE_FORMAT_NOT_CORRECT")}{" "}
            </Typography>
          )}
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mt: 1 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={12}
                  sx={Style.mainBox}
                >
                  <Box sx={Style.box1}>
                    <GridFileUpload
                      name="document"
                      privId=""
                      label={t(t1, "BROWSE_FILES")}
                      labelStyle={Style.labelStyle}
                      variant="contained"
                      acceptFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Box>
                </Grid>
                <Grid
                  items
                  md={12}
                  sx={{ mt: 2, display: "flex", justifyContent: "space-between", paddingLeft: "16px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", color: "#4d8da4", width: "50%" }}>
                    {enteredFile !== "" && (
                      <Typography>
                        {t(t1, "FILE_SELECTED")} : {enteredFile?.name}{" "}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      color: "#4d8da4",
                      width: "50%",
                    }}
                  >
                    <CustomizeButton
                      variant="outlined"
                      csName="rc"
                      label={t(t1, "BTN_CANCEL")}
                      name="priv_btn_Cancel"
                      privId="Priv_btn_DocModalCancel"
                      handleClick={handleCancel}
                      customStyle={Style.customButton}
                    />
                    <CustomizeButton
                      variant="contained"
                      csName="submitBtn"
                      type="submit"
                      label={t(t1, "BTN_UPLOAD")}
                      name="priv_btn_Save"
                      privId="Priv_btn_DocModalSave"
                      disabled={disabled}
                      customStyle={{ ...Style.customButton, marginRight: 0, marginLeft: '8px' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}

export default UploadModal;
