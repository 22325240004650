import { isBefore, startOfDay } from "date-fns";
import dayjs from "dayjs";
import _ from 'lodash'
import { JURISDICTION_STATUS, LOCALIZED_COUNTRY_NAMES, t } from "utils/constants";

export const PURCHASE_LIMIT_STYLE = [
  { label: "PERCENTAGE", value: "Percentage" },
  { label: "VALUE", value: "Value" },
];

export const ONBOARD_JURISDICTION_MIN_DATE = "2010-01-01";
export const minimumDateInDatePicker = new Date("2009-12-31");

export const JURISDICTION_NAME_MIN_LENGTH = 3;
export const SHORT_CODE_MIN_LENGTH = 2;
export const SHORT_CODE_MAX_LENGTH = 3;

export const ALPHA_NUMERIC_STARTS_WITH_ALPHABET = /^[a-zA-Z][a-zA-Z0-9]*$/;

export const validateJurisdictionName = (value, jurisdictions, message) =>
  jurisdictions.find((jurisdiction) => jurisdiction?.displayName?.toLowerCase() === value?.toLowerCase())
    ? message
    : true;

export const validateJurisdictionShortCode = (value, jurisdictions, message) =>
  jurisdictions.find((jurisdiction) => jurisdiction?.shortCode?.toLowerCase() === value?.toLowerCase())
    ? message
    : true;

// function to convert api response into multiple select dropdown input
export const transformResponseToDropdown = (data) =>
  _.uniqBy(data, 'id')?.map((item) => ({
    value: item?.id,
    name: item?.name,
    displayName: item?.display_name,
  }));

export function transformTextToUppercase(input) {
  // Convert text to uppercase and replace spaces with underscores
  const str =input.trim()
  const transformedText = str.toUpperCase().replace(/ /g, "_");

  return transformedText;
}

export function transformCountryResponse(data) {
  return sortCountries(
    data?.map((item) => ({
      ...item,
      name: formatTextWithSpacesAndCapitalize(item?.name),
    }))
  );
}

export function formatTextWithSpacesAndCapitalize(text) {
  return text
    ?.replace(/_/g, " ")
    ?.toLowerCase()
    ?.replace(/\b\w/g, (char) => char?.toUpperCase());
}

export function transformResponse(string) {
  const words = string.split(" ");

  const transformedWords = words.map((word) => {
    if (word.includes("_")) {
      return formatTextWithSpacesAndCapitalize(word);
    }
    return word;
  });

  return transformedWords.join(" ");
}

function sortCountries(arr) {
  arr.sort((a, b) => {
    if (a.name === LOCALIZED_COUNTRY_NAMES.UNITED_STATES) return -1;
    if (b.name === LOCALIZED_COUNTRY_NAMES.UNITED_STATES) return 1;
    if (a.name === LOCALIZED_COUNTRY_NAMES.CANADA) return -1;
    if (b.name === LOCALIZED_COUNTRY_NAMES.CANADA) return 1;
    return a.name.localeCompare(b.name);
  });
  return arr;
}

export function transformJurisdictionInoResponse(data) {
  return {
    jurisdictionDisplayName: data?.displayName,
    shortCode: data?.shortCode,
    jurisdictionId: data?.jurisdictionId,
    dateOfJoining: dayjs(data?.dateOfJoining),
    addressLine1: data?.JurisdictionInformation?.addressLine1,
    addressLine2: data?.JurisdictionInformation?.addressLine2,
    city: data?.JurisdictionInformation?.city,
    Country: {
      id: data?.JurisdictionInformation?.Country?.ID,
      name: formatTextWithSpacesAndCapitalize(data?.JurisdictionInformation?.Country?.Name),
    },
    region: data?.JurisdictionInformation?.region,
    State: {
      id: data?.JurisdictionInformation?.State?.ID,
      name: formatTextWithSpacesAndCapitalize(data?.JurisdictionInformation?.State?.Name),
    },
    Status: data?.Status,
    zipCode: data?.JurisdictionInformation?.zipCode,
    purchaseLimitParameter: data?.purchaseLimitParameter,
  };
}

export function checkMinDate(date, minDate, message) {
  const normalizedDate1 = startOfDay(date);
  const normalizedDate2 = startOfDay(minDate);
  return isBefore(normalizedDate1, normalizedDate2) ? message : null;
}

export function transformJurisdictionCurrencyAndLanguageResponse({ languageData, currencyData }) {
  const languageDetailsIds = languageData?.map((lang) => lang?.LanguageDetails?.id);
  const currencyDetailIds = currencyData?.map((curr) => curr?.currencyDetailId);
  return { languageDetailsIds, currencyDetailIds };
}

export function isInOnboardingStatus(status){
  return status===JURISDICTION_STATUS.ONBOARDING
}

export function isInUnlinkedStatus(status){
  return status===JURISDICTION_STATUS.UNLINKED
}

export function isInPreLinkedStatus(status) {
  return status === JURISDICTION_STATUS.PRE_LINKED;
}

export function isInLinkedStatus(status) {
  return status === JURISDICTION_STATUS.LINKED;
}

export function isInLinkageStatus(status){
  return [JURISDICTION_STATUS.PRE_LINKED, JURISDICTION_STATUS.LINKED].includes(status);
}

export function isInProposal(status){
  return status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_LINKED || status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_PRELINKED || status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_UNLINKED 
}

export function isInRevisionRequested(status){
  return status === JURISDICTION_REQUEST_STATUS.REVISIONS_REQUESTED
}

export function isInOffboardedStatus(status){
  return status===JURISDICTION_STATUS.OFFBOARDED
}

export function isInProposalForJSA(status){
  return status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_PRELINKED || status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_UNLINKED 
}

export function isInProposalForAuthority(status) {
  return status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_LINKED
}

export function isInProposalForLinked(status) {
  return status === JURISDICTION_REQUEST_STATUS.PROPOSED_FOR_LINKED
}

export function allowCancel(requestStatus){
    return isInProposal(requestStatus) || isInRevisionRequested(requestStatus) 
}

export const PAGE_SETUP_NEW_JURISDICTION = "Page_SetupNewJurisdiction";
export const COMPONENT_SETUP_NEW_JURISDICTION = "setupNewJurisdiction";
export const PAGE_EDIT_JURISDICTION = "Page_EditJurisdiction";
export const COMPONENT_EDIT_JURISDICTION = "EditJurisdiction";

export const JURISDICTION_REQUEST_STATUS = {
  PROPOSED_FOR_PRELINKED: "PROPOSED_FOR_PRELINKED",
  PROPOSED_FOR_UNLINKED: "PROPOSED_FOR_UNLINKED",
  PROPOSED_FOR_LINKED: "PROPOSED_FOR_LINKED",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  REVISIONS_REQUESTED: "REVISIONS_REQUESTED",
};

export const PAGE_MANAGE_JURISDICTION_DETAILS = "Page_ManageJurisdictionDetails";
export const PAGE_JURISIDTCION_ACCOUNT_DETAILS="Page_JurisdictionAccountDetails"

export const COMPONENT_REVISIONS_REQUESTED = "RevisionsRequested";
export const COMPONENT_CANCEL_PROPOSED_STATUS = "cancelProposedStatus";
export const COMPONENT_JURISDICTION_DETAILS="JurisdictionDetails";
export const COMPONENT_APPROVE_STATUS = "approveJurisdictionStatus";

export const MAX_CURRENCY_ALLOWED = 5;
export const MAX_LANGUAGE_ALLOWED = 5;

export const transformLinkedJurisdictions = (selectedLinkedJurisdiction,t1) => 
  selectedLinkedJurisdiction?.map(item => t(t1,item?.name))
    ?.reduce((a, b) => a !== "" ? `${a}, ${b}` : `${b}`, "")
