import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Container, Typography, List, ListItem, Link, ListItemText, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FormCard } from "components/FormCard";
import { PageTitle } from "components/PageTitle";
import { t, getCurrentPageName, ENTITY_FLOW } from "utils/constants";
import {
  resetSteps, setEntityType, setEntityID
} from "store/entityApplicationSlice";
import { ApplicationChecklist } from "../../../../EntityProfile/components/ApplicationChecklist/ApplicationChecklist";
import { ApplicationWithAttestations } from "../../../../EntityProfile/components/ApplicationChecklist/ApplicationWithAttestations";
import { useEffectOnce } from "../../../../../hooks/useEffectOnce";
import { getEntityById } from "../../../../../store/entityProfileSlice";
import Pdf from "../../../../../assets/pdfs/CAG_sample_pdf.pdf";
import UnsavedPrompt from "../../../../../components/UnsavedDialog";

export default function SubmitRequiredDocuments({ displayHead = true }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isReadMore, setIsReadMore] = useState(true);
  const checklistRef = useRef();
  const attestationRef = useRef();
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const { entities } = useSelector(({ entityProfile }) => entityProfile);

  const { EntityID, updatedAt } = entities || {};

  useEffectOnce(() => {
    dispatch(getEntityById(id));
  }, []);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  
  const resetData = () => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(""));
      dispatch(setEntityID(0));
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={{ padding: "0" }}
    >
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={12}
        >
          <Box sx={{ pt: 3, p: 2.5, bgcolor: "#ffffff" }}>
            {/* <PageTitle title={t(t1, 'SUBMIT_REQUIRED_DOCUMENTS')} /> */}
            <Typography className="card-title">{t(t1, "SUBMIT_REQUIRED_DOCUMENTS")}</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
              dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
            </Typography>
            <br />
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
              dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
            </Typography>
            <List sx={{ py: 1.5 }} style={{ pointerEvents: "none" }}>
              <ListItem
                sx={{ cursor: "pointer", padding: "0 0 0 22px", alignItems: "flex-start" }}
              >
                <ListItemText className="formlink-wrapper">
                  <Link
                    href={`/account-application-checklist-form/${id}?pdfName=${t(t1, "ACCOUNT_APPLICATION_CHECKLIST_FORM")}`}
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                    tabIndex={-1}
                  >
                    <Typography sx={{ fontSize: "18px", color: "#333" }}style={{ pointerEvents: "none" }} >
                      {t(t1, "ACCOUNT_APPLICATION_CHECKLIST_FORM")}
                    </Typography>
                  </Link>
                </ListItemText>
                <Link
                   href={`/account-application-checklist-form/${id}`}
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                  >
                  <FileDownloadIcon className="download-icon"  style={{ pointerEvents: "auto" }} />
                </Link>
              </ListItem>
              <ListItem
                sx={{ cursor: "pointer", padding: "0 0 0 22px", alignItems: "flex-start" }}
              >
                <ListItemText className="formlink-wrapper">
                <Link
                href={`/account-application-attestation-form/${id}?pdfName=${t(t1, "ACCOUNT_APPLICATION_CHECKLIST_FORM")}`}
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                    tabIndex={-1}
                  >
                    <Typography sx={{ fontSize: "18px", color: "#333" }} style={{ pointerEvents: "none" }}>
                      {t(t1, "ACCOUNT_APPLICATION_WITH_ATTESTATIONS_FORM")}
                    </Typography>
                  </Link>
                </ListItemText>
                <Link
                    href={`/account-application-attestation-form/${id}`}
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                  >
                  <FileDownloadIcon className="download-icon"  style={{ pointerEvents: "auto" }} />
                </Link>
              </ListItem>
              <ListItem
                sx={{ cursor: "pointer", padding: "0 0 0 22px", alignItems: "flex-start" }}
              >
                <ListItemText className="formlink-wrapper">
                <Link
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                    tabIndex={-1}
                  >
                    <Typography sx={{ fontSize: "18px", color: "#333" }} style={{ pointerEvents: "none" }}>
                      {t(t1, "CORPORATE_ASSOCIATIONS_AND_STRUCTURE_FORM")}
                    </Typography>
                  </Link>
                </ListItemText>
                <Link
                    href={Pdf}
                    sx={{ textDecoration: "none" }}
                    target='_blank'
                    without
                  >
                  <FileDownloadIcon className="download-icon"  style={{ pointerEvents: "auto" }} />
                </Link>
              </ListItem>
            </List>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
              dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "40px",
              "& .MuiButtonBase-root": {
                width: "150px",
                marginTop: "5px",
                fontWeight: 400,
                border: "none",
                boxShadow: "none",
                "&:hover": {
                  background: "#4d8da4",
                  boxShadow: "none",
                },
              },
            }}
          >
            <Button
              style={{ marginRight: "0px!important", marginTop: "0", height:"40px" }}
              variant="contained"
              name="priv_btn_Search"
              label="FINISH"
              color="primary"
              onClick={() => navigate(`/entity-profile/${id}`)}
            >
              FINISH
            </Button>
          </Box>
        </Grid>
      </Grid>
      <UnsavedPrompt id={id} when={!isExitInProgress} title={t(t1, "WARNING")} message={t(t1, "CONFIRM_LEAVE_PAGE")} />        
    </Container>
  );
}
