import CryptoJS from "crypto-js";

import _ from "lodash";
import { convertToReadableFormat } from "pages/SetupInitialUser/support";
import {
  APPROVAL_TYPES,
  ENTITY_ACCOUNT_STATUSES,
  ENTITY_TRANSFER_TYPES,
  ENTITY_TRANSFER_TYPES_FE,
  JURISDICTION_ORG_NAMES,
  NOT_AVAILABLE_FROM_LIST_TEXT,
  OTR_STATUSES_FE,
  PRICE_MAX_CHAR_LIMIT,
  REPRESENTATIVE_STATUS,
  SECRET_KEY,
  formatDateUpdated,
  formatNumber,
  displayStatusTypes,
  JURISDICTION_TRANSFER_TYPES_BE,
  formatDateUpdatedWithoutTZ,
  t
} from "./constants";

export const replaceUnderscoreWithSpace = (str) => (str ? capitalizeFirstLetterOfWord(str?.replaceAll("_", " ")) : "");

export const transformProjectSites = (states) =>
  states?.map((state) => ({
    name: capitalizeFirstLetterOfWord(replaceUnderscoreWithSpace(state?.Name)),
    id: state?.ID,
  }));

export const transformActiveAR = (list) => list?.filter((ar) => ar?.status === REPRESENTATIVE_STATUS.ACTIVE);

export const transformEntityTransfersHoldings = (data) =>
  data
    ?.map((item, idx) => ({
      id: `TransfersHoldings_${idx}`,
      holdingId: item?.id,
      vintage: item?.vintage_year,
      jurisdiction: item?.jurisdiction_name,
      type: item?.issuance_type,
      subType: item?.sub_type,
      offsetType: item?.offset_type,
      offsetProjectID: item?.project_id,
      specialCategory: item?.special_category,
      quantity: item?.quantity,
      last_updated: item?.last_updated,
      start: item?.start_serialization_code,
      end: item?.end_serialization_code,
      availableHoldingQuantity: item?.available_holding_quantity,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

// func: To transform res in data-grid format
export const transformHoldingsRes = (data) =>
  data
    ?.map((item, idx) => ({
      id: item?.id || idx,
      holdingId: item?.id,
      vintage: item?.vintage_year,
      jurisdiction: item?.jurisdiction?.display_name,
      type: item?.issuance_type,
      subType: item?.sub_type?.name,
      offsetType: item?.offset_type?.name,
      offsetProjectId: item?.project_id,
      specialCategory: item?.special_category?.name,
      quantity: item?.quantity,
      last_updated: item?.last_updated,
      start: item?.start_serialization_code,
      end: item?.end_serialization_code,
      availableHoldingQuantity: item?.available_holding_quantity,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

// func: To transform res in data-grid format
export const transformHoldingsViewRes = (data) =>
  data
    ?.map((item, idx) => ({
      id: `${item?.id}_${idx}`,
      holdingId: item?.id,
      vintage: item?.vintage,
      jurisdiction: item?.jurisdiction,
      type: item?.type,
      subType: item?.subType?.name,
      offsetType: item?.offsetType,
      offsetProjectID: item?.offsetProjectID,
      specialCategory: item?.specialCategory?.name,
      quantity: item?.quantity,
      last_updated: item?.updatedAt,
      start: item?.start,
      end: item?.end,
      availableHoldingQuantity: item?.available_holding_quantity,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

export const transformHoldingsResReplenishment = (data) =>
  data
    ?.map((item, idx) => ({
      id: item?.id || idx,
      holdingId: item?.id,
      vintage: item?.vintage,
      jurisdiction: item?.jurisdiction,
      type: item?.type,
      subType: item?.subType?.name,
      offsetType: item?.offsetType,
      offsetProjectID: item?.offsetProjectID,
      specialCategory: item?.specialCategory?.name,
      quantity: item?.quantity,
      last_updated: item?.last_updated,
      start: item?.start_serialization_code,
      end: item?.end_serialization_code,
      availableHoldingQuantity: item?.availableHoldingQuantity,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

export const transformJurisdictionUserHoldingsRes = (data) =>
  data
    ?.map((item, idx) => ({
      holdingId: item?.id,
      vintage: item?.vintage_year,
      jurisdiction: item?.jurisdiction_name,
      type: item?.issuance_type,
      subType: item?.sub_type,
      offsetType: item?.offset_type,
      offsetProjectId: item?.project_id,
      specialCategory: item?.special_category,
      quantity: item?.quantity,
      id: idx + 1,
      last_updated: item?.last_updated,
      start: item?.start_serialization_code,
      end: item?.end_serialization_code,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

// func: To transform res in data-grid format
export const transformTransferRes = (data) =>
  data
    ?.map((item) => ({
      clusterUUID: item?.clusterUUID,
      clusterID: item?.clusterID || item?.clusterId,
      transferID: item?.transferID,
      transferType: item?.transferType,
      eventDate: new Date(item?.eventDate),
      status: getStatusVal(item?.status),
      transferringAccountName:
        item?.transferType === "AllowanceIssuance" || item?.transferType === "OffsetIssuance"
          ? ""
          : item?.transferringAccountName,
      receivingAccountName: item?.receivingAccountName,
      quantityTransferred: item?.quantityTransferred
        ? `${item?.transferringAccountFlag ? "-" : ""}${item?.quantityTransferred}`
        : "",
      quantity: item?.quantity,
      id: item?.id || item?.transferID,
      quantityProposed: item?.quantityProposed
        ? `${item?.transferringAccountFlag ? "-" : ""}${item?.quantityProposed}`
        : "",
      entityUUIDorJurisdictionUUID: item?.entityUUIDorJurisdictionUUID,
      entityAccountUUIDOrJurisdictionAccountUUID: item?.entityAccountUUIDOrJurisdictionAccountUUID,
    }))
    .sort((a, b) => b.eventDate - a.eventDate);

export const transformEntityTransferRes = (data) => {
  const newData = data
    ?.map((item) => ({
      clusterID: item?.clusterUUID,
      clusterId: item?.cluster_id,
      transferId: item?.transfer_id,
      transferType: item?.transfer_type,
      eventDate: item?.event_date,
      status: getStatusVal(item?.status),
      transferringAccountName: item?.transferring_account_name,
      receivingAccountName: item?.receiving_account_name,
      quantityTransferred: item?.quantity_transferred
        ? `${item?.transferring_account_flag ? "-" : ""}${item?.quantity_transferred}`
        : "",
      quantity: item?.quantity,
      id: item?.transfer_id,
      quantityProposed: item?.quantity_proposed
        ? `${item?.transferring_account_flag ? "-" : ""}${item?.quantity_proposed}`
        : "",
    }))
    .sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));

  return newData;
};

// func: To transform res in vintage year dropdown options format
export const transFormVintageYearToDropdownOptions = (data) =>
  data
    .map((item) => ({
      id: item.budgetYear || item.vintageYear,
      name: item.budgetYear?.toString() || item.vintageYear?.toString(),
    }))
    ?.sort((a, b) => a.id - b.id);

// send jurisdictionUUID
export const getJurisdictionUUID = () => localStorage.getItem("jurisdictionID");

export const getLanguageCode = () => localStorage.getItem("languageCode");

// send the formatted status value
export const getStatusVal = (val) => {
  let value = null;
  if (val?.includes("COMPLETE")) {
    value = "Complete";
  } else if (val?.includes("PROPOSE")) {
    value = "Proposed";
  } else if (val?.includes("REVISION_REQUESTED") || val?.includes("REVISIONS_REQUESTED")) {
    value = "Revisions Requested";
  } else if (val?.includes("ISSUANCE_DENIED") || val?.includes("DENIED")) {
    value = "Denied";
  } else if (val?.includes("APPROVED")) {
    value = "Approved";
  } else if (val?.includes("CANCELLED")) {
    value = "Cancelled";
  } else if (val?.includes("DECLINE")) {
    value = "Declined";
  } else if (val?.includes("ACCEPT")) {
    value = "Accepted";
  } else if (val?.includes("EXPIRED")) {
    value = "Expired";
  } else if (val?.includes("DELETED")) {
    value = "Deleted";
  } else if (val?.includes("SAVED")) {
    value = "Saved";
  }
  return value ?? val;
};

// send the formatted transfer type
export const formatTransferType = (val) => {
  switch (val) {
    case APPROVAL_TYPES.ALLOWANCE_ISSUANCE:
      return "Issuance";
    case APPROVAL_TYPES.OFFSET_ISSUANCE:
      return "Issuance";
    case APPROVAL_TYPES.ADMINISTRATIVE:
      return "ADMINISTRATIVE_TRANSFER_TYPE";
    case APPROVAL_TYPES.JURISDICTION_BATCH_TRANSFER:
      return "JURISDICTION_BATCH_TRANSFER";
    case APPROVAL_TYPES.REPLENISHMENT:
      return "REPLENISHMENT_TRANSFER_TYPE";
    default:
      return val;
  }
};

export const generateDetailsPath = (id, type) => {
  let path;

  switch (type) {
    case APPROVAL_TYPES.ALLOWANCE_ISSUANCE:
      path = `/allowance-issuance-details/${id}`;
      break;
    case APPROVAL_TYPES.OFFSET_ISSUANCE:
      path = `/offset-issuance-details/${id}`;
      break;
    case APPROVAL_TYPES.OFFSET_TRACKING_RECORD:
      path = `/offset-management/tracking-record/details/${id}`;
      break;
    case APPROVAL_TYPES.ADMINISTRATIVE:
      path = `/administrative-transfers/${id}`;
      break;
    case APPROVAL_TYPES.JURISDICTION_BATCH_TRANSFER:
      path = `/batch-transfer/${id}`;
      break;
    case APPROVAL_TYPES.HOLDING_LIMIT:
      path = `/holding-limits/${id}/view`;
      break;
    case APPROVAL_TYPES.REPLENISHMENT:
      path = `/replenishment-transfers/${id}`;
      break;
    case APPROVAL_TYPES.BATCH_EXEMPTION:
      path = `/limited-exemptions/batch/${id}`;
      break;
    case APPROVAL_TYPES.BUDGET_RECORD:
      path = `/budgets/${localStorage.getItem("jurisdictionID")}/propose?scrollTo=pbr_view`;
      break;
    case APPROVAL_TYPES.JURISDICTION_STATUS:
      path = `/manage-jurisdiction-details/${localStorage.getItem("jurisdictionID")}`
      break;
    // Add more cases for additional types
    default:
      path = "";
  }

  return path;
};

export const getFormattedDate = (date) => (date ? date?.slice(0, 10)?.replaceAll("-", "/") : "");

export function capitalizeFirstLetter(inputString, restLowerCase = true) {
  if (!inputString || typeof inputString !== "string") return null;
  if (restLowerCase) return inputString[0].toUpperCase() + inputString.slice(1).toLowerCase();
  return inputString[0].toUpperCase() + inputString.slice(1);
}

export function capitalizeFirstLetterOfWord(inputString) {
  if (!inputString || typeof inputString !== "string") return null;
  return inputString
    .split(" ")
    .map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return "";
    })
    .join(" ");
}

export const getInputVal = (val, enteredVal, matchWith) => {
  if (enteredVal && val === matchWith) return enteredVal;
  if (!enteredVal && val === matchWith) return "";
  return val;
};

// send the formatted compliance instrument type
export const getComplianceInstrumentType = (val) => {
  if (val?.includes("offset")) return "Offset";
  if (val?.includes("allowance")) return "Allowance";
  return "";
};

export const getDefaultValue = (data, ele) => {
  if (!_.isEmpty(data)) {
    // eslint-disable-next-line eqeqeq
    const found = data?.filter((item) => item?.name == ele);
    return !_.isEmpty(found) ? found[0] : null;
  }
  return null;
};

export function transformMCIApprovalsRes(data) {
  return data?.map((item) => ({
    id: item?.transferId || "",
    keyInformation: item?.keyInformation || "",
    type: item?.type,
    status: "PROPOSED",
    lastUpdatedAt: formatDateUpdated(item?.lastUpdatedAt) || "",
  }));
}

export function transformProposedAuthorityBudgetRecords(data) {
  return data?.map((item) => ({
    id: item?.budgetYear || "",
    keyInformation: displayStatusTypes.APPROVALS_BR_KEY_INFO,
    type: displayStatusTypes.APPROVALS_BR_TYPE,
    status: item?.Status?.name,
    lastUpdatedAt: formatDateUpdated(item?.updatedAt) || "",
  }));
}

export const transformMRThistory = (data) =>
  data
    ?.map((item, idx) => ({
      ...item,
      id: idx + 1,
      updatedAt: formatDateUpdated(item?.updatedAt),
    }))
    ?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));

export function transformOTRApprovalsRes(data) {
  return data?.map((item) => ({
    id: item?.otrId || "",
    keyInformation: item?.keyInformation || "",
    type: item?.type,
    status: item?.status?.name,
    lastUpdatedAt: formatDateUpdated(item?.updatedAt) || "",
  }));
}

export function transformStatusChange(data) {
  return data?.map((item, idx) => ({
    id: idx + 1,
    accountStatus: ENTITY_ACCOUNT_STATUSES[item.Status.name] || "",
    comment: item.comment,
    updatedAt: formatDateUpdatedWithoutTZ(item.updated_at),
    updatedBy: item.last_updated_by,
  }));
}

export const getTransformedHistoryData = (data) =>
  data
    ?.map((item, index) => ({
      id: `${item?.id}_${index}` || `${item?.exchangeCode}_${index}` || index,
      exchangeContractDescriptionCodeEnglish: `${item?.exchangeCode} - ${item?.exchangeContractDescriptionCodeEnglish}`,
      exchangeContractDescriptionCodeFrench: `${item?.exchangeCode} - ${item?.exchangeContractDescriptionCodeFrench}`,
      displayedInDropdown: item.displayedInDropdown === true ? "YES" : "NO",
      comments: item?.comments,
      updatedDate: item?.updatedDate,
      lastUpdatedBy: item?.lastUpdatedBy,
    }))
    .reverse();

// send the data which has displayIn_dropdown true
export const getFilteredData = (data) => data?.filter((item) => item?.displayedInDropdown === true || item?.id === "");

// format the ecrypted/descrypted string, eg: to send encrypted url we dont require special characters below
export const encryptStr = (str) => {
  const encrypt = CryptoJS.AES.encrypt(str, SECRET_KEY).toString();
  const encoded = CryptoJS.enc.Base64.parse(encrypt).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const decryptStr = (str) => {
  const decoded = CryptoJS.enc.Hex.parse(str).toString(CryptoJS.enc.Base64);
  const decrypted = CryptoJS.AES.decrypt(decoded, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export function getFormattedJurisdictionName(jurisdiction, jurisdictionOrgNames = JURISDICTION_ORG_NAMES) {
  return jurisdictionOrgNames[jurisdiction] || capitalizeFirstLetter(jurisdiction);
}

export function getFormattedAccountName(inputString, substitutionMap = JURISDICTION_ORG_NAMES) {
  if (!inputString) return null;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in substitutionMap) {
    if (Object.prototype.hasOwnProperty.call(substitutionMap, key)) {
      // Create a regular expression pattern to match the key
      const pattern = new RegExp(key, "gi"); // 'gi' for global and case-insensitive search
      // Replace occurrences of the key with the corresponding value
      inputString = inputString.replace(pattern, substitutionMap[key]);
    }
  }
  return inputString;
}

export const transformComplianceInstruments = (data, status) =>  {
  if(status?.includes("COMPLETE")) {
    return data?.map((item, idx) => ({
      id: idx + 1,
      transferID: item?.TransferId,
      jurisdiction: item?.Jurisdiction,
      vintageYear: item?.Vintage,
      type: ENTITY_TRANSFER_TYPES_FE[item?.Type] ?? item?.Type,
      subType: item?.Sub_Type,
      offsetType: item?.Offset_Type,
      offsetProjectID: item?.Offset_Project_Id,
      start: item?.start ?? 0,
      end: item?.end ?? 0,
      quantity: formatNumber(item?.quantity),
      specialCategory: item?.Special_Category,
    }));
}
}


// get VRT list transformer
export const transformVrtList = (data) =>
  data?.map((item, idx) => ({
    id: item?.transferId || idx + 1,
    transferId: item?.transferId,
    transferType: ENTITY_TRANSFER_TYPES_FE[item?.transferType] || item?.transferType,
    transferTypeForURL: ENTITY_TRANSFER_TYPES[item?.transferType] || item?.transferType,
    transferringAccountNumber: item?.transferringAccountNumber,
    transferringAccountName: item?.transferringAccountName,
    receivingAccountNumber: item?.receivingAccountNumber,
    receivingAccountName: item?.receivingAccountName,
    transferStatus: getStatusVal(item?.status),
    transferUpdatedAt: item?.statusDate,
    transferringEntityID: item?.entityUUID,
    transferringEntityAccountID: item?.entityAccountId,
    transferUUID: item?.transferUUID,
  }));

// **FLP** will send the property name and the permission as a value
export const getPermissionObj = (fieldAccess, compoName) => {
  if(compoName){
    const targetFieldAccess = fieldAccess?.find(
      (item) => item.componentName === compoName && !_.isEmpty(item.componentFields)
    );
    if (targetFieldAccess) {
      return targetFieldAccess.componentFields.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.fieldName]: curr.fieldPermission,
        }),
        {}
      );
    }
  }
  else{
    return fieldAccess?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.componentName]: curr?.componentFields?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.fieldName]: curr.fieldPermission,
          }),
          {}
        ),
      }),
      {}
    );
  }
  return {};
};

export const getFilteredExchangeAgree = (data, lang) => {
  let exchangeCodesList = [];

  if (data && data?.length > 0) {
    const displayedTrueRows = data.filter((item) => item.displayedInDropdown === true || item.id === "");

    exchangeCodesList = displayedTrueRows.map((item) => {
      const contractDescriptionCode =
        lang === "French"
          ? item.exchangeAndContractDesciptionCodesFrench
          : item.exchangeAndContractDesciptionCodesEnglish;
      return {
        id: item.id,
        name: `${item.exchangeCode} - ${contractDescriptionCode}`,
      };
    });
  }
  exchangeCodesList.push({ id: "", name: NOT_AVAILABLE_FROM_LIST_TEXT });

  return exchangeCodesList;
};

export function addDecimalToNumber(input) {
  const inputNumber = parseFloat(input);
  if (Number.isNaN(inputNumber) || !Number.isFinite(inputNumber)) {
    return input;
  }
  return inputNumber.toFixed(2);
}

export const formatNoToDecimalComma = (price) => {
  if (!price) return null;
  const splitedVal = price.toString().split(".");
  return `${formatNumber(splitedVal[0])}.${splitedVal[1]}`;
};

export const getActionByValue = (val) => (val === "SYSTEM" ? "System" : val);

export const setCopyrightYear = (copyright) => copyright.replace(/\d\d\d\d$/, new Date().getFullYear());

export const validateDecimalFrenchLang = (value) => {
  value = value.replace(/[^\d,]/g, "");
  // Remove leading zeros when there are more than one, except for "0" and numbers like "0.68"
  value = value.replace(/^(?!0+$)0+(?![,])/, "");
  // Split the value into integer and decimal parts
  const parts = value.split(",");
  // Ensure there is at most one decimal point
  if (parts.length > 2) {
    parts[1] = parts.pop();
  }
  // Limit the decimal part to two digits
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].substring(0, 2);
  }
  if (parts[0].length > PRICE_MAX_CHAR_LIMIT) {
    parts[0] = parts[0].substring(0, 13);
  }
  value = parts.join(",");
  return value;
};

export const validateDecimalEngLang = (value) => {
  value = value.replace(/[^\d.]/g, "");
  // Remove leading zeros when there are more than one, except for "0" and numbers like "0.68"
  value = value.replace(/^(?!0+$)0+(?![.])/, "");
  // Split the value into integer and decimal parts
  const parts = value.split(".");
  // Ensure there is at most one decimal point
  if (parts.length > 2) {
    parts[1] = parts.pop();
  }
  // Limit the decimal part to two digits
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].substring(0, 2);
  }
  if (parts[0].length > PRICE_MAX_CHAR_LIMIT) {
    parts[0] = parts[0].substring(0, 13);
  }
  value = parts.join(".");
  return value;
};

export const mergeSequentialBlocks = ({ data = [], isHoldings = false, isMJT = false }) => {
  const groupTransfers = _.groupBy(data, (item) => {
    if (isMJT) {
      return `${item.transferId}`;
    }
    if (isHoldings) {
      return `${item.holdingId}-${item.jurisdiction}`;
    }
    return `${item.transferId}-${item.jurisdiction}`;
  });
  return Object.keys(groupTransfers).flatMap((transfers) => {
    const sortedBlocks = _.sortBy(groupTransfers[transfers], [(o) => BigInt(o.start || "0")]);
    const transfersList = [];
    sortedBlocks.forEach((block) => {
      let recentBlock = transfersList[transfersList.length - 1];

      if (recentBlock && BigInt(block.start || "0") - BigInt(recentBlock.end || "0") === BigInt(1)) {
        const mergedQuantity =
          BigInt(recentBlock.quantity.replaceAll(",", "") || "0") + BigInt(block.quantity.replaceAll(",", "") || "0");
        recentBlock = { ...recentBlock, end: block.end, quantity: mergedQuantity.toString() };
        transfersList[transfersList.length - 1] = recentBlock;
      } else {
        transfersList.push(block);
      }
    });
    return transfersList;
  });
};

export const getIssuanceTypeKey = (item) => {
  let key = `${item.holdingId}-${item.jurisdiction}`;
  if (item.type === "Allowance") {
    key = `${key}-${item.type}-${item.vintage || item.subType}`;
  }
  if (item.type === "Offset") {
    key = `${key}-${item.type}-${item.vintage
      }-${item.offsetType?.toLowerCase()}-${item.offsetProjectId?.toLowerCase()}-${item.specialCategory?.toLowerCase() ?? ""
      }`;
  }
  return key;
};

export const getCombinedHoldings = (data) => {
  const holdingsInfoMap = new Map();
  data.forEach((item) => {
    const key = getIssuanceTypeKey(item);
    holdingsInfoMap.set(key, (holdingsInfoMap.get(key) || 0n) + BigInt(item?.quantity?.replace(/[\s,]/g, "")));
  });
  const updatedHoldings = data.reduce((result, item) => {
    const key = getIssuanceTypeKey(item);
    if (holdingsInfoMap.has(key) && BigInt(item?.quantity?.replace(/[\s,]/g, "")) > 0n) {
      const updatedItem = { ...item };
      updatedItem.quantity = holdingsInfoMap.get(key);
      holdingsInfoMap.delete(key);

      result.push(updatedItem);
    }
    return result;
  }, []);

  return [].concat(updatedHoldings);
};

export const formatUTC = (dateInt, addOffset = false) => {
  const date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") return date;
  const offset = addOffset ? date.getTimezoneOffset() : -date.getTimezoneOffset();
  const offsetDate = new Date();
  offsetDate.setTime(date.getTime() + offset * 60000);
  return offsetDate;
};

export function updateOTRRecords(idsToUpdate, records) {
  return records.map((record) => ({
    ...record,
    offset_tracking_record_details_flag: idsToUpdate.includes(record.transactionId)
      ? !record.offset_tracking_record_details_flag
      : record.offset_tracking_record_details_flag,
  }));
}


export const isTransferTypePresent = (accountDetailsInfo, str) => {   
  const transferTypeValues = _.uniqBy(
    accountDetailsInfo?.data?.data?.transferTypes?.filter((i) => JURISDICTION_TRANSFER_TYPES_BE[i?.transferTypeName]),
    "transferTypeId"
  )
  // eslint-disable-next-line no-restricted-syntax
  for (const item of transferTypeValues) {
    if (item.transferTypeName.toLowerCase() === str?.toLowerCase()) {
      return true;
    }
  }    
  return false;
};


// sorting holdings array based on given order
export function sortHoldingsTable(holdingsArr) {
  const customSortFn = (a, b) => {
      const fields = ['vintage', 'jurisdiction', 'type', 'subType', 'offsetType', 'offsetProjectID', 'specialCategory'];
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fields) {
        // Handling null values
        // eslint-disable-next-line no-continue
        if (a[field] === null && b[field] === null) continue;
        if (a[field] === null) return -1;
        if (b[field] === null) return 1;
        // Handling alphanumeric sorting for offsetProjectID
        if (field === 'offsetProjectID' ) {
            const alphaNumericComparison = a[field]?.localeCompare(b[field], 'en', { numeric: true });
            if (alphaNumericComparison !== 0) return alphaNumericComparison;
        } else {
            // Regular ascending sorting
            if (a[field] < b[field]) return -1;
            if (a[field] > b[field]) return 1;
        }
    }
  return a.specialCategory === b.specialCategory ? 0 : (a.specialCategory || '')?.localeCompare(b.specialCategory || '');
};

  try {
      const sortedArray = holdingsArr?.slice()?.sort(customSortFn);
      return sortedArray;
  } catch (error) {
      console.error("Error sorting array:", error);
      return holdingsArr; // Return the original holdingsArr in case of error
  }
}