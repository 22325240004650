import axios from "axios";
import { getAccessToken } from "store/authSlice";
import { getCurrentPageName, isEmpty } from "./constants";
import logger from "./logger";

const commonHeader = {
  "Content-Type": "application/json",
};
export default async function fetcher(
  request,
  // eslint-disable-next-line default-param-last
  method = "GET",
  data,
  isToken,
  headerOptions = {},
  baseUrl = process.env.REACT_APP_API_URL
) {
  const token = getAccessToken();
  headerOptions = addPageDetailsInHeader(headerOptions);
  const options = {
    url: `${baseUrl}/${request}`,
    method,
    data,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...headerOptions,
      "content-type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        /* Conflict Resolution Candidate
        if (process.env.REACT_APP_IS_LOGGER) {
          logger({ error });
        }
 */

        logger({ error });
        reject(error);
      });
  });
}

// common function of calling axios
async function call(options) {
  try {
    const token = getAccessToken();
    let authenticationObject = {};
    if (token) {
      authenticationObject = {
        Authorization: `Bearer ${token}`,
      };
    }
    options = { ...authenticationObject, ...options };
    return new Promise((resolve, reject) => {
      axios(options)
        .then((res) => {
          logger({ info: res.data });
          resolve(res.data);
        })
        .catch((error) => {
          if (!error.data) {
            error.data = { message: error.message };
          }
          logger({ error });
          reject(error);
        });
    });
  } catch (error) {
    logger({ error });
    return false;
  }
}
// this will accept string as request
// with http or https or only relative url after base url

function getUrl(request = "") {
  let url = "";
  if (typeof request === "string" && !request.includes("http") && process.env.REACT_APP_API_URL) {
    url = process.env.REACT_APP_API_URL;
  }
  return `${url}/${request}`;
}

// aceepts  request as  url which could be full url or relative url or service base url
// return promise
export async function fetcherPost(request, data, headerOptionsParam = {}) {
  try {
    const headerOptions = addPageDetailsInHeader(headerOptionsParam);
    const token = getAccessToken();
    const options = {
      url: getUrl(request),
      method: "POST",
      data,
      headers: {
        ...commonHeader,
        ...headerOptions,
        Authorization: `Bearer ${token}`,
      },
    };
    return call(options);
  } catch (error) {
    console.log("errorerrorerror", error);
    return logger({ error });
  }
}

export async function fetcherPut(request, data, headerOptionsParam = {}) {
  try {
    const token = getAccessToken();
    const headerOptions = addPageDetailsInHeader(headerOptionsParam);
    const options = {
      url: getUrl(request),
      method: "PUT",
      data,
      headers: {
        ...commonHeader,
        ...headerOptions,
        Authorization: `Bearer ${token}`,
      },
    };
    return call(options);
  } catch (error) {
    console.log("errorerrorerror put", error);

    return logger({ error });
  }
}

export async function fetcherPatch(request, data, headerOptionsParam = {}) {
  try {
    const token = getAccessToken();
    const headerOptions = addPageDetailsInHeader(headerOptionsParam);
    const options = {
      url: getUrl(request),
      method: "PATCH",
      data,
      headers: {
        ...commonHeader,
        ...headerOptions,
        Authorization: `Bearer ${token}`,
      },
    };
    return call(options);
  } catch (error) {
    return logger({ error });
  }
}

export async function fetcherDelete(request, data, headerOptions = {}) {
  try {
    const token = getAccessToken();

    const options = {
      url: getUrl(request),
      method: "DELETE",
      data,
      headers: {
        ...commonHeader,
        ...headerOptions,
        Authorization: `Bearer ${token}`,
      },
    };
    return call(options);
  } catch (error) {
    return logger({ error });
  }
}

function addPageDetailsInHeader(headerOptions) {
  if (!isEmpty(headerOptions.componentName)) {
    const pageName = getCurrentPageName();
    headerOptions["page-name"] = headerOptions.pageName || pageName;
    headerOptions["component-name"] = headerOptions.componentName;
    delete headerOptions.componentName;
    delete headerOptions.pageName;
  }

  return headerOptions;
}
// aceepts  request as  url which could be full url or relative url or service base url
// return promise
export async function fetcherGet(request, data, headerOptions = {}) {
  try {
    const token = getAccessToken();
    headerOptions = addPageDetailsInHeader(headerOptions);
    const headers = {
      ...commonHeader,
      ...headerOptions,
      Authorization: `Bearer ${token}`,
    };
    const options = {
      url: getUrl(request),
      method: "GET",
      params: data,
      headers,
    };
    return call(options);
  } catch (error) {
    return logger({ error });
  }
}
