import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { getGridDateOperators } from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { AccordionLink, GridTextfield } from "components";
import { CustomeDialogMessage } from "components/CustomeDialogMessage";
import { FormCard } from "components/FormCard";
import { Loader } from "components/Loader";
import { MUITableGrid } from "components/MUITableGrid";
import { betweenOperators } from "components/MUITableGrid/components/MUICustomFilterFunctions";
import { QuickLinksMenu } from "components/QuickLinksMenu";
import { useClickOutside } from "hooks";
import useAuth from "hooks/useAuth";
import { useAuthentication } from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { setModelMessage } from "store/commonSlice";
import { getJurisdictions } from "store/entityApplicationSlice";
import { CustomDialogMultiInput } from "components/CustomDialogMultiInput";
import useCommonFunc from "hooks/useCommonFunc";
import { handleUpdateEmailTelephoneDispatchFun } from "utils/quickLinksFunction";
import {
  getActionStatus,
  getEntities,
  getPendingEntities,
  getRepresentativeStatus,
  getRequestStatus,
  getSearchFacilitiesList,
} from "store/entityProfileSlice";
import { setSelectedJurisdictionId } from "store/jurisdictionSlice";
import { activeUserLogin, getSearchUserDetails, userActiveDeactiveLogin, userCloseAccount, userInactiveProfile, userResetPassphraseAndSecurityQA, userUnblockLockedUser, userUpdateEmailOrTelephone } from "store/userSlice";
import {
  ActiveStatus, answerField, ApplicantRole, CancelStatus, ClosedStatus, commentField, DenyStatus, displayEmployerList,
  ENTITY_APPLICTION_STATUS_NAMES, formatDate, getCurrentPageName, getEntityAccountTypeNamesForFilterDropdown, getEntityTypeNamesForFilterDropdown,
  getJurisdictionName, InactiveStatus, isRolesAssigned, IsTerminalState, JURISDICTION_ORG_NAMES, JURISDICTION_USER_ACTION_FORM_FIELDS, LoginPermanentLocked,
  LoginTemporaryLocked, matchOrg, onlyAlphaNumWithSpacePattern, PendingStatus, ProfileAccessOnly, RegistrarRole, ResetPassphraseStatus,
  RevisionStatus, ShowInlineAddressForTable, ShowInlineAddressJUCaps, ShowInlineEntityAddress, showOnlyPermittedFields, t, translateRows,
  USER_DETAILS_CHANGE_APPROVED_STATUS, USER_DETAILS_CHANGE_CANCELLED_STATUS, USER_DETAILS_CHANGE_DENY_STATUS, USER_ROLE_TYPES, USER_ROLES, validatePhoneNumber,
  getJuriNamesForFilterDropdown, getAcoountStatusNamesForFilterDropdown, UserDetailsChangeCancelled, getFilterForColumn,
  PassphraseReset,
  getCMSKey,
  JURISDICTIONS_ID,
  getCMSKeySnakeCase,
  findJurisdictionName,
  getStatusBasedUrl,
  JURISDICTIONS_NAMES,
  USER_ACCOUNT_STATUSES
} from "utils/constants";
import { getJurisdictionCurrencyLanguageAndRequestStatus, getJurisdictionInformation } from "store/onboardJurisdictionSlice";
import { ACTIVATION, DEACTIVATION } from "pages/SetupInitialUser/support";
import { ManageBudgetSection } from "./components/ManageBudgetSection";
import Requests from "./components/Requests/Requests";
import JurisdictionAccounts from "./JurisdictionAccounts";
import Style from "./styles";
import useJurisdictionHome from "./useJurisdictionHome";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const handleUpdateEmailDispatchFun = (dispatch, UID, modifiedby, value, navigate, orgId, isAPICall = false) => {
  const updateEmailPayload = {
    action_update_email: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    email: value,
  };
  const flpData = { pageName: "Page_JurisdictionUserHome", componentName: "actionUpdateEmail" };
  dispatch(userUpdateEmailOrTelephone(updateEmailPayload, navigate, orgId, isAPICall, flpData));
};

export const handleResetPassphraseAndSecurityQAFun = (dispatch, UID, navigate, orgId, isAPICall = false) => {
  dispatch(userResetPassphraseAndSecurityQA(UID, navigate, orgId, isAPICall));
};

export const handleUnblockDispatchFun = (dispatch, UID, modifiedby, navigate, orgId, isAPICall = false) => {
  const unblockUserPayload = {
    action_update_user_account_block_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
  };
  const flpData = { pageName: "Page_JurisdictionUserHome", componentName: "actionUpdateUserAccountBlockStatus" };
  dispatch(userUnblockLockedUser(unblockUserPayload, navigate, orgId, isAPICall, flpData));
};

export const handleInactiveDispatchFun = (
  dispatch,
  username,
  UID,
  modifiedby,
  reasons,
  navigate,
  userRow,
  juriStatus,
  orgId = "",
  isAPICall = false
) => {
  const inactiveUserPayload = {
    username,
    enable: false,
    inactive_access: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    Reasons: reasons,
  };
  const historyPayload = {
    firstName: userRow?.Name,
    lastName: userRow?.FamilyName,
    emailAddress: userRow?.Email,
    roleName: userRow?.RoleName?.toUpperCase()?.replaceAll(" ", "_") || userRow?.RoleDetail?.Role[0]?.roleName?.toUpperCase()?.replaceAll(" ", "_"),
    jurisdictionName: userRow?.jurisdiction || userRow?.OrgDetails[0]?.OrgName.toUpperCase()?.replaceAll(" ", "_"),
    action_name: DEACTIVATION,
    userName: userRow?.UserID || userRow?.Username
  };
  dispatch(userInactiveProfile(inactiveUserPayload, historyPayload, juriStatus, navigate, orgId, isAPICall));
};

export const handleCloseAccountDispatchFun = (
  dispatch,
  UID,
  modifiedby,
  reasons,
  navigate,
  orgId,
  userRow = {},
  juriStatus = "",
  isAPICall = false
) => {
  const closeAccountPayload = {
    action_update_user_account_close_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    Comment: reasons,
  };
  const historyPayload = {
    firstName: userRow?.Name,
    lastName: userRow?.FamilyName,
    emailAddress: userRow?.Email,
    roleName: userRow?.RoleName?.toUpperCase()?.replaceAll(" ", "_") || userRow?.RoleDetail?.Role[0]?.roleName?.toUpperCase()?.replaceAll(" ", "_"),
    jurisdictionName: userRow?.jurisdiction || userRow?.OrgDetails[0]?.OrgName.toUpperCase()?.replaceAll(" ", "_"),
    action_name: DEACTIVATION,
    userName: userRow?.UserID || userRow?.Username
  };
  const flpData = { pageName: "Page_JurisdictionUserHome", componentName: "actionUpdateUserAccountCloseStatus" };
  dispatch(userCloseAccount(closeAccountPayload, historyPayload, juriStatus, navigate, orgId, isAPICall, flpData));
};

export const handleActiveDeactiveDispatchFun = (
  dispatch,
  UID,
  modifiedby,
  reason,
  navigate,
  orgId,
  juriStatus = "",
  userRow = {},
  isAPICall = false
) => {
  const activeDeactivePayload = {
    action_update_account_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    status: InactiveStatus,
    DenialReasons: reason,
  };
  const historyPayload = {
    firstName: userRow?.Name,
    lastName: userRow?.FamilyName,
    emailAddress: userRow?.Email,
    roleName: userRow?.RoleName?.toUpperCase()?.replaceAll(" ", "_") || userRow?.RoleDetail?.Role[0]?.roleName?.toUpperCase()?.replaceAll(" ", "_"),
    jurisdictionName: userRow?.jurisdiction || userRow?.OrgDetails[0]?.OrgName.toUpperCase()?.replaceAll(" ", "_"),
    action_name: DEACTIVATION,
    userName: userRow?.UserID || userRow?.Username
  };
  const flpData = { pageName: "Page_JurisdictionUserHome", componentName: "actionUpdateAccountStatus" };
  dispatch(userActiveDeactiveLogin(activeDeactivePayload, historyPayload, juriStatus, navigate, orgId, isAPICall, flpData));
};

export const handleActiveDispatchFun = (dispatch, UID, modifiedby, navigate, orgId, juriStatus = "", row = {}, isAPICall = false) => {
  const activateUserPayload = {
    action_update_account_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    status: ActiveStatus,
  };
  const flpData = { pageName: "Page_JurisdictionUserHome", componentName: "actionUpdateAccountStatus" };
  const historyPayload = {
    firstName: row?.Name,
    lastName: row?.FamilyName,
    emailAddress: row?.Email,
    roleName: row?.RoleName?.toUpperCase()?.replaceAll(" ", "_") || row?.RoleDetail?.Role[0]?.roleName?.toUpperCase()?.replaceAll(" ", "_"),
    jurisdictionName: row?.jurisdiction || row?.OrgDetails[0]?.OrgName.toUpperCase()?.replaceAll(" ", "_"),
    action_name: ACTIVATION,
    userName: row?.UserID || row?.Username
  };
  dispatch(activeUserLogin(activateUserPayload, historyPayload, juriStatus, navigate, orgId, isAPICall, flpData));
};

function JurisdictionUserHome() {
  useAuthentication({ redirectTo: "/jurisdiction-user" });
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { transformUsersList, transformInitialUsersJuriHome } = useJurisdictionHome();
  const { validateUniqueUser } = useAuth();
  const [emailError, setEmailError] = useState(null);
  const [UserRequestrows, setUserRequestrows] = useState([]);
  const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
  const [showConfirmResetPassphraseAndSecurity, setShowConfirmResetPassphraseAndSecurity] = useState(false); // to hide show resetpassphrase modal
  const [selectedUserId, setSelectedUserId] = useState(""); // selectedUserID for modal
  const [clickedUserIDName, setClickedUserIDName] = useState({});
  const scrollRef = useRef(null);
  const [openValue, setOpenValue] = useState(null);
  const [modaDataObj, setModaDataObj] = useState([]);
  const [showInactiveUser, setShowInactiveUser] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(true);
  const { searchUserDetails, searchPendingUserDetails, loadingUserDetails } = useSelector(({ user }) => user);
  const { selectedJurisdiction, Jurisdictions, loadingOrganizations, selectedJurisdictionId, jurisdictionDetails } = useSelector(({ jurisdiction }) => jurisdiction);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { loader } = useSelector(({ common }) => common);
  const subMenuRef = useRef(null);
  const { user, loadingPrivileges, loadingMenu } = useSelector(({ auth }) => auth);
  const { entitiesInit, pendingEntitiesInit, searchFacilitiesList, loadingActionStatus, loadingEntities, loadingSearchFacilitiesList, loadingPendingEntities, loadingRepresentativeStatus, loadingRequestStatus } = useSelector(({ entityProfile }) => entityProfile);
  const { actionStatus, requestStatus } = useSelector(({ entityProfile }) => entityProfile);
  const jurisdictions = useSelector(({ entityApplication }) => entityApplication.jurisdictions);
  const { loadingJurisdictions } = useSelector(({ entityApplication }) => entityApplication);
  const { user: logedInUser } = useAuth();
  const { PrivAttributes } = user;
  const userRows = [];
  const userPendingRows = [];
  const cookies = new Cookies();
  const [showEditTelephoneEmailDialog, setShowEditTelephoneEmailDialog] = useState(false);
  const [EntitiesRows, setEntitiesrows] = useState([]);
  const [PendingEntitiesRows, setPendingEntitiesrows] = useState([]);
  const entitiesRows = [];
  const pendingEntitiesRows = [];
  const { entityGridFilter } = useSelector(({ jurisdictionUserHomeEntityGridFilter }) => jurisdictionUserHomeEntityGridFilter);
  const [isEntityManagementAccordionOpen, setIsEntityManagementAccordionOpen] = useState(false)
  const { OFFSET_LINKS, JURISDICTION_MANAGEMENT_LINKS } = useCommonFunc(selectedJurisdictionId);
  const [userRow, setUserRow] = useState();

  const { isJurisdictionRegistrarUser } = useMemo(
    () => ({
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR]
      ),
    }),
    [userDetails]
  );

  const { isLoadingPage } = useMemo(() => {
    return {
      isLoadingPage: loadingOrganizations || loadingUserDetails || loadingEntities || loadingSearchFacilitiesList || loadingJurisdictions || loadingPendingEntities || loadingActionStatus || loadingRepresentativeStatus || loadingRequestStatus || loadingPrivileges || loadingMenu,
    };
  }, [loadingUserDetails, loadingEntities, loadingSearchFacilitiesList, loadingJurisdictions, loadingPendingEntities, loadingActionStatus, loadingRepresentativeStatus, loadingRequestStatus, loadingOrganizations, loadingPrivileges, loadingMenu]);

  const { isJurisdictionUser } = useMemo(
    () => ({
      isJurisdictionUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER]),
    }),
    [userDetails]
  );
  const { isAdmin, isAuthority, isJSA } = useJurisdictionUserRole();

  const dispatch = useDispatch();

  const defaultValues = {
    deactiveProfile: "",
    deactiveProfileReason: "",
    inctiveReasons: "",
    email: "",
    telephoneNumber: "",
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { watch, control, reset, resetField } = methods;
  const watchDeactiveProfile = watch("deactiveProfile", false);
  const { t: t1 } = useTranslation(getCurrentPageName(), { useSuspense: true });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffectOnce(() => {
    getSearchedUserList();
    getSearchedUserList(true);
    dispatch(getActionStatus());
    dispatch(getRepresentativeStatus());
    dispatch(getRequestStatus());

    if (location?.state?.showSuccessPopup) {
      const data = {
        title: t(t1, "SUCCESS"),
        message1: t(t1, "APPLICATION_CANCELLED_BY_JURISDICTION"),
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    }
  }, []);

  useEffect(() => {
    cookies.remove("selectedUserId");
  }, []);


  useEffect(() => {
    entitiesInit?.data?.forEach((item) => {
      entitiesRows.push({
        ...{ ...item },
        ...{ physicalAddress: ShowInlineEntityAddress(item?.physicalAddress, t, t1) },
      });
    });
    setEntitiesrows(entitiesRows);
  }, [entitiesInit]);

// adding formatted physical address in the pending entities
  useEffect(() => {
    pendingEntitiesInit?.data?.forEach((item) => {
      pendingEntitiesRows.push({
        ...{ ...item },
        ...{ physicalAddress: ShowInlineEntityAddress(item?.physicalAddress, t, t1) },
      });
    });
    setPendingEntitiesrows(pendingEntitiesRows);
  }, [pendingEntitiesInit]);

  useEffect(() => {
    if(jurisdictions.length && searchPendingUserDetails?.items?.length){
      searchPendingUserDetails?.items?.map((item) => {
        const jurisName = jurisdictions.find((each) => each.dynamoId === item?.OrgID);
        userPendingRows.push({
          id: item?.UID?.split("#")[1],
          Name: item?.Name,
          FamilyName: item?.FamilyName,
          jurisdiction: jurisName?.Name,
          Email: item?.Email,
          birthDate: formatDate(item?.BirthDate),
          UserStatus: item?.UserAccountStatus,
          RoleName: item?.RoleDetail?.Role?.map((eachRole) => t(t1, `${getCMSKeySnakeCase(`${eachRole.name}`)}`)).join(", "),
          AccountStatus: item?.ApplicantAccountStatus,
          ReferenceCode: item?.ReferenceCode,
          Employer: displayEmployerList(item?.Employer),
          UserID: item?.Username,
          RequestStatus: item?.RequestStatus,
          RoleType: item?.RoleDetail?.RoleType?.map((eachRole) => t(t1, `${getCMSKeySnakeCase(`${eachRole.name}`)}`)).join(", "),
          RegStartDate: formatDate(item?.RegistrationDate),
          PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
          UserLoginStatus: item?.UserLoginStatus,
          TelephoneNumberChangeRequest: item?.TelephoneNumberChangeRequest,
          EmailChangeRequest: item?.EmailChangeRequest,
          TelephoneNumber: item?.TelephoneNumber,
          IsUser: item?.IsUser,
        });
        return userPendingRows;
      });
      setUserRequestrows(userPendingRows);
    }
  }, [searchPendingUserDetails,jurisdictions]);

  const loggedinjurisdictionid = localStorage.getItem("jurisdictionID");
  useEffect(() => {
    dispatch(getJurisdictionInformation(loggedinjurisdictionid));
  }, [dispatch, loggedinjurisdictionid]);

  const { jurisdictioninformation } = useSelector(
    ({ onboardJurisdiction }) => onboardJurisdiction
  );

  const combineUsers = [
    ...(transformUsersList(searchUserDetails?.items) ?? []),
    ...(transformInitialUsersJuriHome() ?? [])
  ]

  const orgId = selectedJurisdiction || localStorage.getItem("jurisdiction");
  const currentJurisdiction = Jurisdictions.find((each) => each.OrgID === orgId);
  const currentJuri = currentJurisdiction?.OrgName === JURISDICTIONS_NAMES.WASHINGTON_STATE;
  const showNAICSManagement = loggedinjurisdictionid === JURISDICTIONS_ID.QUEBEC || loggedinjurisdictionid === JURISDICTIONS_ID.NOVA_SCOTIA;
  const getSearchedUserList = (searchPending = false) => {
    const data = {};
    data.OrgID = orgId;
    if (searchPending) {
      data.search_pending = true;
    }
    dispatch(getSearchUserDetails(data));
  };
  const handleClick = (id) => {
    if (id === openValue) {
      setOpenValue(null);
      setOpen(false);
    } else {
      setOpenValue(id);
      setOpen(true);
    }
  };

  useClickOutside(subMenuRef, () => setOpen(true));

  const deactiveProfileOptions = [
    { id: 1, name: t(t1, "DEACTIVATE_PROFILE_W_ACCESS") },
    { id: 2, name: t(t1, "DEACTIVATE_PROFILE_WO_ACCESS") },
  ];

  // update email & phone API call
  const handleUpdateEmailTelephone = (clickedUserId, telephone, email) => {
    setShowInactiveUser(false);
    setShowEditTelephoneEmailDialog(false);
    handleUpdateEmailTelephoneDispatchFun(
      dispatch,
      clickedUserId,
      logedInUser?.userId,
      telephone,
      email,
      navigate,
      orgId,
      true
    );
  };

  // reset passphrase and security q&a API call
  const handleResetPassphraseAndSecurityQA = () => {
    setShowConfirmResetPassphraseAndSecurity(false);
    //  handleResetPassphraseAndSecurityQAFun(dispatch, selectedUserId, logedInUser?.userId, navigate, orgId, true);
    handleResetPassphraseAndSecurityQAFun(dispatch, selectedUserId, navigate, orgId, true);
  };

  const confirmResetPassphraseAndSecurity = (userId) => {
    setShowConfirmResetPassphraseAndSecurity(true);
    setSelectedUserId(userId);
  };

  // Unblock user API call
  const handleUnblockUser = (clickedUserId) => {
    handleUnblockDispatchFun(dispatch, clickedUserId, logedInUser?.userId, navigate, orgId, true);
  };

  // deactive modal control and active API call
  const handleActiveDeactiveUserModal = (userStatus, clickedUserId, Username, row) => {
    if (userStatus === t(t1, "DEACTIVATE_USER_LOGIN")) {
      reset(defaultValues);
      setUserRow(row);
      setShowActivateDeactivateModal(true);
      setClickedUserIDName({ id: clickedUserId, name: Username });
    } else {
      handleActiveDispatchFun(dispatch, clickedUserId, logedInUser?.userId, navigate, orgId, jurisdictioninformation?.Status?.Name, row, true);
    }
  };

  // close account modal
  const handleCloseAccountModal = (clickedUserId, Username, row) => {
    const modalData = {};
    modalData.title = "INACTIVE_USER_CLOSE_ACCOUNT";
    modalData.message = "INACTIVE_USER_MESSAGE";
    modalData.buttonMessage = "INACTIVE_USER_MESSAGE1";
    modalData.buttonText = "CLOSE_ACCOUNT_MODAL_BUTTONTEXT";
    modalData.showComentText = true;
    modalData.textBoxName = "COMMENT";
    modalData.rules = {
      required: true,
      minLength: {
        value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MIN_CHAR,
        message: `${t(t1, "ERR_INACTIVATE_USER_REASONS_MIN_LENGTH_CHAR")}`,
      },
      maxLength: {
        value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MAX_CHAR,
        message: `${t(t1, "ERR_INACTIVATE_USER_REASONS_MAX_LENGTH_CHAR")}`,
      },
    };
    modalData.handleModalContinue = handleCloseAccount;
    modalData.privId = "priv_linkmodal_txt_user_closeaccount";
    setModaDataObj(modalData);
    setClickedUserIDName({ id: clickedUserId, name: Username, row });
    resetField("inctiveReasons");

    setEmailDisabled(false);
    setUserRow(row);
    setShowInactiveUser(true);
  };

  // Update email modal
  const handleEmailTelephoneUpdateModal = (clickedUserId, Username, TelephoneNumber) => {
    reset(defaultValues);
    setShowInactiveUser(false);
    setClickedUserIDName({ id: clickedUserId, name: Username, TelephoneNumber });
    setShowEditTelephoneEmailDialog(true);
  };

  // Close user account API call
  const handleCloseAccount = (userID, comment, userRow) => {
    handleCloseAccountDispatchFun(dispatch, userID, logedInUser?.userId, comment, navigate, orgId, userRow, jurisdictioninformation?.Status?.Name, true);
    setShowInactiveUser(false);
  };

  // Inactive user API call
  const handleInactiveUser = (id, reason, userRow) => {
    setShowInactiveUser(false);
    handleInactiveDispatchFun(dispatch, clickedUserIDName?.name, id, logedInUser?.userId, reason, navigate, userRow, jurisdictioninformation?.Status?.Name, orgId, true);
  };

  const handleInactiveModal = (clickedUserId, Username, row) => {
    const modalData = {};
    modalData.title = "INACTIVE_USER";
    modalData.message = "";
    modalData.buttonMessage = "";
    modalData.buttonText = "CONTINUE";
    modalData.showComentText = true;
    modalData.textBoxName = "INACTIVATE_USER_REASONS";
    modalData.handleModalContinue = handleInactiveUser;
    modalData.rules = {
      required: true,
      minLength: {
        value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MIN_CHAR,
        message: `${t(t1, "ERR_INACTIVATE_USER_REASONS_MIN_LENGTH_CHAR")}`,
      },
      maxLength: {
        value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MAX_CHAR,
        message: `${t(t1, "ERR_INACTIVATE_USER_REASONS_MAX_LENGTH_CHAR")}`,
      },
    };
    modalData.privId = "priv_linkmodal_txt_inactive_user";
    setEmailDisabled(false);
    setModaDataObj(modalData);
    resetField("inctiveReasons");
    setUserRow(row);
    setShowInactiveUser(true);
    setClickedUserIDName({ id: clickedUserId, name: Username });
  };

  // deactive and inactive login API call
  const handleActiveDeactiveLogin = () => {
    setShowActivateDeactivateModal(false);
    if (watchDeactiveProfile === t(t1, "DEACTIVATE_PROFILE_W_ACCESS")) {
      handleInactiveDispatchFun(
        dispatch,
        clickedUserIDName.name,
        clickedUserIDName.id,
        logedInUser?.userId,
        methods?.getValues("deactiveProfileReason"),
        navigate,
        userRow,
        jurisdictioninformation?.Status?.Name,
        orgId,
        true
      );
    } else {
      handleActiveDeactiveDispatchFun(
        dispatch,
        clickedUserIDName?.id,
        logedInUser?.userId,
        methods?.getValues("deactiveProfileReason"),
        navigate,
        orgId,
        jurisdictioninformation?.Status?.Name,
        userRow,
        true
      );
    }
  };

  const searchUserColumns = [
    {
      id: 1,
      field: "Name",
      headerName: t(t1, "FIRST_NAME"),
      width: 150,
      align: "center",
      hideable: false,
      renderCell: (cellValues) => {
        const userId = cellValues.row.id;
        if (cellValues?.row?.AccountStatus && cellValues.row.AccountStatus !== USER_ACCOUNT_STATUSES.NOT_ACTIVATED) {
          return (
            <Typography sx={Style.ClickLinkCenter}>
              <Link
                className="link-cell"
                href={`/jurisdiction-user-actions?id=${window.btoa(userId)}`}
                to={`/jurisdiction-user-actions?id=${window.btoa(userId)}`}
              >
                {cellValues?.row?.Name}
              </Link>
            </Typography>
          );
        }
        return cellValues?.row?.Name;
      },
    },
    { id: 2, field: "FamilyName", headerName: t(t1, "LAST_NAME"), width: 100, align: "center" },
    {
      id: 3,
      field: "jurisdiction",
      headerName: t(t1, "JURISDICTION"),
      width: 150,
      align: "center",
      valueGetter: (params) => t(t1,params.row.jurisdiction),
      renderCell: ({ row }) => <Typography variant="body2">{t(t1, `${row.jurisdiction}`)}</Typography>,
    },

    {
      id: 4,
      field: "Email",
      headerName: t(t1, "EMAIL"),
      width: 300,
      align: "center",
    },
    {
      id: 5,
      field: "birthDate",
      headerName: t(t1, "DATE_OF_BIRTH"),
      width: 150,
      align: "center",
      filterOperators: [...getGridDateOperators(), ...betweenOperators],
    },
    {
      id: 6,
      field: "UserStatus",
      headerName: t(t1, "USER_STATUS"),
      width: 150,
      align: "center",
      valueGetter: ({row}) => t(t1, `${getCMSKey('UserStatus', row?.UserLoginStatus)}`),
      renderCell: ({ row }) => <Typography variant="body2">{t(t1, `${getCMSKey('UserStatus', row?.UserLoginStatus)}`)}</Typography>,
    },
    {
      id: 7,
      field: "RoleName",
      headerName: t(t1, "ROLE"),
      width: 200,
      align: "center",
    },
    {
      id: 8,
      field: "AccountStatus",
      headerName: t(t1, "ACCOUNT_STATUS"),
      width: 200,
      align: "center",
      valueGetter: ({ row }) => t(t1, `${getCMSKey('UserAccountStatus', row.AccountStatus)}`),
      renderCell: ({ row }) => <Typography variant="body2">{t(t1, `${getCMSKey('UserAccountStatus', row.AccountStatus)}`)}</Typography>,
    },
    {
      id: 9,
      field: "ReferenceCode",
      headerName: t(t1, "USER_REFERENCE_CODE"),
      width: 200,
      align: "center",
      renderCell: (cellValues) => cellValues?.row?.ReferenceCode?.toUpperCase(),
    },
    {
      id: 10,
      field: "Employer",
      headerName: t(t1, "EMPLOYER"),
      width: 200,
      align: "center",
    },
    {
      id: 11,
      field: "UserID",
      headerName: t(t1, "USER_ID"),
      width: 200,
      align: "center",
    },
    {
      id: 12,
      field: "RequestStatus",
      headerName: t(t1, "REQUEST_STATUS"),
      width: 300,
      align: "center",
      renderCell: ({ row }) => <Typography variant="body2">{t(t1, `${getCMSKey('RequestStatus' ,row.RequestStatus)}`)}</Typography>,
    },
    {
      id: 13,
      field: "RoleType",
      headerName: t(t1, "ROLE_TYPE"),
      width: 200,
      align: "center",

    },
    {
      id: 14,
      field: "RegStartDate",
      headerName: t(t1, "REGISTRATION_START_DATE"),
      width: 200,
      align: "center",
      filterOperators: [...getGridDateOperators(), ...betweenOperators],
    },
    {
      id: 15,
      field: "PhysicalAddress",
      headerName: t(t1, "PHYSICAL_ADDRESS"),
      width: 400,
      align: "center",
    },
    {
      id: 16,
      field: "quickactions",
      hideable: false,
      sortable: false,
      filterable: false,
      headerName: t(t1, "QUICK_ACTIONS"),
      align: "center",
      disableExport: true,
      groupable: false,
      renderCell: (cellValues) => {
        const clickedUserId = cellValues?.id;
        const Username = cellValues?.row?.UserID;
        // Array of selected user roles
        const rolesArray = cellValues?.row?.RoleName?.split(", ");
        let userLoginStatus = t(t1, "ACTIVATE_USER_LOGIN");
        if (cellValues?.row?.UserLoginStatus === ActiveStatus && [ActiveStatus]?.includes(cellValues?.row?.UserStatus)) {
          if (cellValues?.row?.AccountStatus === LoginTemporaryLocked && !cellValues?.row?.IsUser) {
            userLoginStatus = t(t1, "ACTIVATE_USER_LOGIN");
          } else {
            userLoginStatus = t(t1, "DEACTIVATE_USER_LOGIN");
          }
        }
        // check if request status is pending then allow registrar to edit telephone/email
        // const allowedForPendingState = cellValues?.row?.RequestStatus === PendingStatus ?
        // PrivAttributes?.priv_UpdateEmail?.PrivRole : "NA"
        const allowedForPendingState = cellValues?.row?.RequestStatus === PendingStatus ||
          cellValues?.row?.AccountStatus === PendingStatus &&
          (cellValues?.row?.RequestStatus === USER_DETAILS_CHANGE_CANCELLED_STATUS ||
            cellValues?.row?.RequestStatus === USER_DETAILS_CHANGE_APPROVED_STATUS ||
            cellValues?.row?.RequestStatus === USER_DETAILS_CHANGE_DENY_STATUS) ?
          PrivAttributes?.priv_UpdateEmail?.PrivRole : "NA";
        let accessForUpdateEmailAndTelephone = "NA";
        // check if registrar then allow all access for quick actions menu
        const allowAccessAsRegistrar = userDetails?.RoleDetail?.Role?.filter((role) => [RegistrarRole].includes(role?.name))?.length > 0;
        // allow only for registrar
        if (allowAccessAsRegistrar) {
          if (cellValues?.row?.AccountStatus === LoginPermanentLocked && !(cellValues?.row?.TelephoneNumberChangeRequest || cellValues?.row?.EmailChangeRequest)) {
            if (!(!cellValues?.row?.IsUser && cellValues?.row?.RequestStatus === RevisionStatus)) {
              accessForUpdateEmailAndTelephone = true;
            }
          } else if ([InactiveStatus, ProfileAccessOnly, ClosedStatus]?.includes(cellValues?.row?.UserLoginStatus) || [ClosedStatus].includes(cellValues?.row?.AccountStatus)) {
            accessForUpdateEmailAndTelephone = "NA";
          } else {
            accessForUpdateEmailAndTelephone = (cellValues?.row?.TelephoneNumberChangeRequest || cellValues?.row?.EmailChangeRequest)
              || !IsTerminalState(cellValues?.row?.RequestStatus) ? allowedForPendingState : allowAccessAsRegistrar || "NA";
          }
        }

        // Role array of the individual user
        const quickLinkList = [
          {
            name: userLoginStatus,
            Access: [PendingStatus, DenyStatus]?.includes(cellValues?.row?.AccountStatus) ||
              (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === LoginPermanentLocked) ||
              (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === ResetPassphraseStatus) ||
              (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === LoginTemporaryLocked) ||
              (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === PassphraseReset)
              ? "NA"
              : allowAccessAsRegistrar || "NA",
            onLinkClick: () => handleActiveDeactiveUserModal(userLoginStatus, clickedUserId, Username, cellValues?.row),
          },
          {
            name: t(t1, "UNLOCK_USER"),
            Access:
              cellValues?.row?.AccountStatus !== LoginPermanentLocked
                ? "NA"
                : allowAccessAsRegistrar || "NA",
            onLinkClick: () => handleUnblockUser(clickedUserId),
          },
          {
            name: t(t1, "RESET_QUE_AND_PASS"),
            Access: [ClosedStatus]?.includes(cellValues?.row?.AccountStatus)
              ? "NA"
              : allowAccessAsRegistrar || "NA",
            onLinkClick: () => confirmResetPassphraseAndSecurity(clickedUserId),
          },
          {
            name: t(t1, "UPDATE_PHONE_AND_EMAIL"),
            Access: accessForUpdateEmailAndTelephone,
            onLinkClick: () => handleEmailTelephoneUpdateModal(clickedUserId, Username, cellValues?.row?.TelephoneNumber),
          },
          {
            name: t(t1, "INACTIVATE_USER"),
            Access:
              [PendingStatus, ProfileAccessOnly]?.includes(cellValues?.row?.AccountStatus) ||
                [InactiveStatus]?.includes(cellValues?.row?.UserStatus) || ([ApplicantRole].some((i) => rolesArray?.includes(i)))
                ? "NA"
                : allowAccessAsRegistrar || "NA",
            onLinkClick: () => handleInactiveModal(clickedUserId, Username, cellValues?.row),
          },
          {
            name: t(t1, "INACTIVE_USER_CLOSE_ACCOUNT"),
            Access: [DenyStatus, PendingStatus, ClosedStatus].includes(
              cellValues?.row?.AccountStatus
            ) ||
              (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === ResetPassphraseStatus) ||
              (!cellValues?.row?.IsUser && (cellValues?.row?.AccountStatus === LoginPermanentLocked ||
                cellValues?.row?.AccountStatus === LoginTemporaryLocked)) ||
                (!cellValues?.row?.IsUser && cellValues?.row?.AccountStatus === PassphraseReset)
              ? "NA"
              : allowAccessAsRegistrar || "NA",
            onLinkClick: () => handleCloseAccountModal(clickedUserId, Username, cellValues?.row),
          },
        ];
        // quick link display condition based on the privID
        const allPrivDenied = quickLinkList?.map((list) => list?.Access).every((access) => access === "NA" || access === "RO");
        return (
          matchOrg(cellValues?.row?.jurisdiction, findJurisdictionName(jurisdictionDetails, userDetails?.OrgID)?.Name) &&
          cellValues?.row?.AccountStatus &&
          cellValues.row.AccountStatus !== USER_ACCOUNT_STATUSES.NOT_ACTIVATED && 
          ![CancelStatus, USER_ACCOUNT_STATUSES.NOT_ACTIVATED].includes(cellValues.row.AccountStatus) &&
          !matchOrg(userDetails?.UID?.split("#")?.pop(), clickedUserId) &&
          !allPrivDenied && (
            <QuickLinksMenu
              subMenuRef={subMenuRef}
              iconClassName="dot-icon"
              popupClassName="quick-link-style"
              onMenuDotsClick={() => handleClick(cellValues.id)}
              openMenu={open && openValue === cellValues.id}
              linksList={quickLinkList}
              isDotIcon={!false}
            />
          )
        );
      },
    },
  ];

  const getEntityStatus = (EntityId) => {
    let resultPath = '/';
  
    const entity = entitiesInit.data.find((elem) => EntityId === elem.entityId);
    if (entity) {
      if (entity.entityStatus === ENTITY_APPLICTION_STATUS_NAMES.PENDING) {
        resultPath = `/jurisdiction-entity-actions/${entity.entityObjectId}`;
      } else {
        resultPath = `/entity-profile/${entity.entityObjectId}`;
      }
    }
  
    return resultPath;
  };
  

  const searchFacilityColumns = [
    {
      id: 0,
      field: "entityId",
      headerName: t(t1, "ENTITY_ID"),
      align: "center",
      flex: 1,
      hideable: false,
      groupable: false,
      renderCell: (params) => {
        const EntityId = params?.row?.entityId;
        const url = `${getEntityStatus(EntityId)}`; 
        return (
          <Link
            className="link-cell"
            to={url}
            sx={{ cursor: "pointer" }}
          >
            {EntityId}
          </Link>
        );
      },
    },
    {
      id: 1,
      field: "legalName",
      headerName: t(t1, "LEGAL_NAME"),
      flex: 1,
      align: "center",
    },
    {
      id: 2,
      field: "entityOperatingName",
      headerName: t(t1, "OPERATING_NAME"),
      flex: 1,
      align: "center",
    },
    {
      id: 3,
      field: "facilityId",
      headerName: t(t1, "FACILITY_ID"),
      flex: 1,
      align: "center",
      hideable: false,
      groupable: false,
      renderCell: (params) => {
        const FacilityId = params?.row?.facilityId;
        const url = `/entity-profile/${params?.row?.entityUuid || params?.row?.id}/facility-profile-details/${params?.row?.facilityUuid}`; 
        return (
          <Link
            className="link-cell"
            to={url}
            sx={{ cursor: "pointer" }}
          >
            {FacilityId}
          </Link>
        );
      },
    },
    {
      id: 4,
      field: "operatingName",
      headerName: t(t1, "FACILITY_OPERATING_NAME"),
      flex: 1,
      align: "center",
    },
    {
      id: 5,
      field: "ghgEmissionsReportingId",
      headerName: currentJuri ? t(t1, "EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID") : t(t1, "GHG_EMISSIONS_REPORTING_ID"),
      flex: 1,
      align: "center",
    },
    {
      id: 6,
      field: "naicsCode",
      headerName: t(t1, "NAICS_CODE"),
      flex: 1,
      align: "center",
      hideable: showNAICSManagement && true,
      filterable: showNAICSManagement && true,
    },
    {
      id: 7,
      field: "facilityStatus",
      headerName: t(t1, "FACILITY_STATUS"),
      flex: 1,
      align: "center",
      renderCell: (params) => <>{t(t1, params?.row?.facilityStatus)}</>,
      type: "singleSelect",
      valueOptions: ["Active", "Proposed", "Retired"],
    },
    {
      id: 8,
      field: "physicalAddress",
      headerName: t(t1, "PHYSICAL_ADDRESS"),
      flex: 3,
      align: "center",
    },
    {
      id: 9,
      field: "updatedBy",
      headerName: t(t1, "LAST_UPDATED_BY"),
      flex: 1,
      align: "center",
    },
    {
      id: 10,
      field: "legalNameHistory",
      headerName: "legalNameHistory",
      flex: 1,
      align: "center",
      hideable: false,
      sortable: false,
      filterable: false,
      disableExport: true,
    },
  ]

  const FilterForColumnData = getFilterForColumn(entitiesInit?.data, t, t1, ['entityType', 'entityStatus', 'jurisdiction', 'entityAccountType', 'accountStatus', 'requestStatus']);
  const searchEntityColumns = [
    {
      id: 1,
      cellClassName: "link-cell",
      field: "entityId",
      headerName: t(t1, "ENTITY_ID"),
      width: 150,
      align: "center",
      hideable: false,
      groupable: false,
      renderCell: (params) => {
        const EntityId = params?.row?.entityId;
        const url = `${getStatusBasedUrl(params?.row.entityStatus, userDetails, t, t1)}${params?.row?.entityObjectId}`;
        return (
          <Link
            className="link-cell"
            to={url}
            sx={{ cursor: "pointer" }}
          >
            {EntityId}
          </Link>
        );
      },
    },
    {
      id: 2,
      field: "legalName",
      headerName: t(t1, "LEGAL_NAME"),
      width: 150,
      align: "center",
      hideable: false,
      groupable: false,
      renderCell: (params) => {
        const url = `${getStatusBasedUrl(params?.row.entityStatus, userDetails, t, t1)}${params?.row?.entityObjectId}`;
        return (
          <Link
            className="link-cell"
            to={url}
            sx={{ cursor: "pointer" }}
          >
            {params?.row?.legalName}
          </Link>
        );
      },
    },
    {
      id: 3,
      field: "operatingName",
      headerName: t(t1, "OPERATING_NAME"),
      width: 150,
      align: "center",
    },

    {
      id: 4,
      field: "entityType",
      headerName: t(t1, "ENTITY_TYPE"),
      width: 200,
      align: "center",
      type: "singleSelect",
      valueOptions: FilterForColumnData?.entityType,
      renderCell: (params) => <>{t(t1, params?.row.entityType)}</>,
    },
    {
      id: 5,
      field: "entityStatus",
      headerName: t(t1, "ENTITY_STATUS"),
      align: "center",
      width: 200,
      type: "singleSelect",
      valueOptions: FilterForColumnData?.entityStatus,
      renderCell: (params) => <>{t(t1, params?.row.entityStatus)}</>,
    },
    {
      id: 14,
      field: "entityReferenceCode",
      headerName: t(t1, "REFERENCE_CODE"),
      width: 150,
      align: "center",
    },
    {
      id: 6,
      field: "jurisdiction",
      headerName: t(t1, "JURISDICTION"),
      width: 150,
      align: "center",
      type: "singleSelect",
      valueOptions: FilterForColumnData?.jurisdiction,
      renderCell: (params) => <>{t(t1, params?.row.jurisdiction)}</>,
    },
    {
      id: 7,
      field: "entityAccountNumber",
      headerName: t(t1, "ENTITY_ACCOUNT_NUMBER"),
      width: 200,
      align: "center",
      renderCell: (params) => {
        const entityId = params?.row?.entityObjectId;
        const accountId = params?.row?.accountId;
        return (
          <NavLink
            className="link-cell"
            component={RouterLink}
            to={`/entity-profile/${entityId}/account/${accountId}`}
          >
            {params?.row?.entityAccountNumber}
          </NavLink>
        );
      },
    },
    {
      id: 8,
      field: "entityAccountType",
      headerName: t(t1, "ENTITY_ACCOUNT_TYPE"),
      width: 200,
      align: "center",
      type: "singleSelect",
      valueOptions: FilterForColumnData?.entityAccountType,
      renderCell: (params) => <>{t(t1, params?.row.entityAccountType)}</>,
    },
    {
      id: 9,
      field: "accountStatus",
      headerName: t(t1, "ACCOUNT_STATUS"),
      width: 200,
      align: "center",
      renderCell: (params) => <>{t(t1, params?.row.accountStatus)}</>,
      type: "singleSelect",
      valueOptions: FilterForColumnData?.accountStatus
    },
    {
      id: 10,
      field: "requestStatus",
      headerName: t(t1, "REQUEST_STATUS"),
      width: 200,
      align: "center",
      type: "singleSelect",
      valueOptions: FilterForColumnData?.requestStatus,
      renderCell: (params) => <>{t(t1, params?.row?.requestStatus)}</>,
    },
    {
      id: 11,
      field: "physicalAddress",
      headerName: t(t1, "PHYSICAL_ADDRESS"),
      width: 400,
      align: "center",
      type: "string",
      filterable: true,
    },
    {
      id: 12,
      field: "arAvaUserId",
      headerName: t(t1, "AR_AVA_USER_ID"),
      width: 150,
      align: "center",
      renderCell: (params) => <>{t(t1, params?.row?.arAvaUserId ? params?.row?.arAvaUserId : "N/A")}</>,
    },
    {
      id: 13,
      field: "CAG",
      headerName: t(t1, "CAG_ID"),
      width: 150,
      align: "center",
      renderCell: (data) => {
        const id = data?.row?.CAGid;
        const groupId = data?.row?.CAG;
        return (
          <Link
            className="link-cell"
            to={`/corporate-associations/${id}`}
            sx={{ cursor: "pointer" }}
          >
            {groupId}
          </Link>
        );
      },
    },
    {
      id: 14,
      field: "legalNameHistory",
      headerName: "legalNameHistory",
      width: 150,
      align: "center",
      hideable: false,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        let array = [];
        if (params?.row?.legalNameHistory && typeof params?.row?.legalNameHistory === "object")
          array = params?.row?.legalNameHistory?.map((e) => e.toString());
        return <>{t(t1, array.join(" "))}</>;
      },
    },
  ];

  const executeScroll = () => scrollRef.current.scrollIntoView();

  useEffectOnce(() => {
    dispatch(getEntities({ componentName: "searchEntities" }));
    dispatch(getSearchFacilitiesList({ componentName: "searchFacilitiesList" }, t, t1));
    dispatch(getJurisdictions());
    dispatch(getPendingEntities({ componentName: "pendingRequest" }));
    
  });

  const pendingEntitiesRecords = PendingEntitiesRows;
   
  const hideColumns = {
    columnVisibilityModel: {
      entityReferenceCode: false,
      requestStatus: false,
      physicalAddress: false,
      arAvaUserId: false,
      CAG: false,
      legalNameHistory: false,
      entityOperatingName: false,
      updatedBy: false,
      naicsCode: showNAICSManagement && true,
      effectiveDate: false,
      retirementDate: false,
    },
  };




  useEffect(() => {
    const jurisdictionName = getJurisdictionName(Jurisdictions, userDetails?.OrgID)?.OrgName;
    const jurisdictionId = jurisdictions.find(
      (jurisdiction) => jurisdiction.displayName === jurisdictionName
    )?.ID;
    if (jurisdictionId) {
      dispatch(setSelectedJurisdictionId(jurisdictionId));
    }
  }, [Jurisdictions, dispatch, jurisdictions, userDetails?.OrgID]);


  const [entityFilter, setEntityFilter] = useState(null);
  const [entityGridKey, setEntityGridKey] = useState(0);

  useEffect(() => {
    setEntityFilter(entityGridFilter);
    // Updating key to Re-Render the MUITableGrid Component on applying different filter.
    setEntityGridKey((prevKey) => prevKey + 1);
  }, [entityGridFilter]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (entityGridFilter?.filter?.filterModel?.items?.[0]?.value === t(t1, "ENTITY_APPLICATION_PENDING") || 
        entityGridFilter?.filter?.filterModel?.items?.[0]?.value === t(t1, "ENTITY_CHANGE_PROPOSED")) {
      setIsEntityManagementAccordionOpen(true);
      // Added setTimeout for smooth scrool at entity management pending requests
      setTimeout(() => {
        scrollToElement("panel1a-header-2"); // ID of Entity management pending requests
      }, 1500);
    }
  }, [entityGridFilter]);
  
  useEffect(()=>{
    if(selectedJurisdictionId)
      dispatch(getJurisdictionCurrencyLanguageAndRequestStatus(selectedJurisdictionId));
  },[selectedJurisdictionId])

  return (
    <>
      <Container
        maxWidth="xl"
        sx={Style.spacing}
      >
        <FormProvider {...methods}>
          <Grid
            container
            spacing={2.5}
          >
            <Grid
              item
              sm={12}
              ref={scrollRef}
            >
              <FormCard indicateRequired={false}>
                <Box
                  sx={{ width: "100%" }}
                  className="tabs-main"
                >
                 {PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "NA" && (
                  <Box sx={{ borderBottom: 1, borderColor: "#4d8da4 !important" }}>
                    <Tabs
                      sx={{
                        "& .MuiButtonBase-root": {
                          marginRight: "20px",
                          maxWidth: "250px",
                          fontWeight: 400,
                          border: "1px solid #4d8da4",
                          borderBottom: "none",
                          minHeight: "auto",
                          color: "#4d8da4",
                          backgroundColor: "#e6eff2",
                          fontSize: "20px",
                          borderRadius: "8px 8px 0px 0px",
                          textTransform: "none",
                          padding: "8px 20px",
                        },
                        "& .MuiButtonBase-root.Mui-selected": {
                          marginRight: "20px",
                          maxWidth: "250px",
                          fontWeight: 400,
                          backgroundColor: "#4d8da4",
                          border: "1px solid #4d8da4",
                          borderRadius: "8px 8px 0px 0px",
                          borderBottom: "none",
                          minHeight: "auto",
                          color: "common.white",
                          fontSize: "20px",
                          textTransform: "none",
                          padding: "8px 20px",
                        },
                      }}
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label={`${t(t1, "PM_H6_SEARCH")} ${t(t1, "USERS")}`} />
                      <Tab label={`${t(t1, "SEARCH_ENTITIES")}`} />
                      <Tab label={`${t(t1, "SEARCH_FACILITIES")}`} />
                      {/* <Tab label="Entities" /> */}
                    </Tabs>
                  </Box>
                  )}
                  {PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "NA" && (
                    <TabPanel
                      value={value}
                      index={0}
                    >
                      <MUITableGrid
                        access={PrivAttributes?.priv_grid_searchUsers?.PrivRole}
                        rows={combineUsers ?? []}
                        columns={searchUserColumns}
                        count={combineUsers?.length}
                        isSearch
                        isExport
                        isSettings
                        initialSortedFields={[
                          { field: "Name", sort: "asc" },
                          { field: "FamilyName", sort: "asc" },
                        ]}
                        fileName={t(t1, "USERS")}
                      />
                    </TabPanel>
                  )}
                  {PrivAttributes?.priv_grid_searchEntities?.PrivRole !== "NA" && (
                    <TabPanel
                      value={value}
                      index={1}
                    >
                      <MUITableGrid
                        hideColumns={hideColumns}
                        access={PrivAttributes?.priv_grid_pendingUsers?.PrivRole}
                        hidePinnedColumns
                        rows={translateRows(
                          [...EntitiesRows],
                          [
                            "entityType",
                            "entityStatus",
                            "jurisdiction",
                            "entityAccountNumber",
                            "accountStatus",
                            "entityAccountType",
                            "requestStatus",
                            "arAvaUserId",
                            "entityReferenceCode",
                          ],
                          t,
                          t1
                        )}
                        columns={showOnlyPermittedFields(
                          searchEntityColumns,
                          entitiesInit.metaData,
                          entitiesInit.fieldAccess
                        )}
                        count={searchPendingUserDetails?.count}
                        isSearch
                        isExport
                        isSettings
                        initialSortedFields={[
                          { field: "Status", sort: "asc" },
                          { field: "EntityAccountNumber", sort: "asc" },
                        ]}
                        fileName={t(t1, "ENTITIES")}
                      />
                    </TabPanel>
                  )}
                  {PrivAttributes?.priv_grid_searchEntities?.PrivRole !== "NA" && (
                    <TabPanel
                      value={value}
                      index={2}
                    >
                      <MUITableGrid
                        hideColumns={hideColumns}
                        access={PrivAttributes?.priv_grid_pendingUsers?.PrivRole}
                        rows={translateRows(
                          [...searchFacilitiesList],
                          [],
                          t,
                          t1
                        )}
                        columns={searchFacilityColumns}
                        count={searchPendingUserDetails?.count}
                        isSearch
                        isExport
                        isSettings
                        hidePinnedColumns
                        initialSortedFields={[
                          { field: "Status", sort: "asc" },
                          { field: "EntityAccountNumber", sort: "asc" },
                        ]}
                        fileName={t(t1, "FACILITIES")}
                      />
                    </TabPanel>
                  )}
                </Box>
              </FormCard>
            </Grid>
            <Grid
              item
              sm={12}
            >
              {PrivAttributes?.priv_grid_userManagement?.PrivRole !== "NA" && (
                <>
                  <Typography
                    sx={{ fontWeight: 650, textTransform: "uppercase" }}
                    variant="h4"
                    gutterBottom
                  >
                    {t(t1, "USER_MANAGEMENT")}
                  </Typography>
                  <List className="commonlistul">
                    {PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "NA" && (
                      <ListItem
                        onClick={PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "RO" && executeScroll}
                        sx={{ cursor: "pointer" }}
                      >{`${t(t1, "SEARCH_USERS_TXT")}`}</ListItem>
                    )}
                    <ListItem className="pending-accordion">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{`${t(t1, "PENDING_REQUEST")}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MUITableGrid
                            access={PrivAttributes?.priv_grid_pendingUsers?.PrivRole}
                            rows={UserRequestrows ?? []}
                            columns={searchUserColumns}
                            count={searchPendingUserDetails?.count}
                            isSearch
                            isExport
                            isSettings
                            initialSortedFields={[
                              { field: "Name", sort: "asc" },
                              { field: "FamilyName", sort: "asc" },
                            ]}
                            fileName={t(t1, "USER_MANAGEMENT")}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </ListItem>
                    {/* )} */}
                  </List>
                  <Grid>
                    <Typography
                      sx={{ fontWeight: 650, textTransform: "uppercase" }}
                      variant="h4"
                      gutterBottom
                    >
                      {t(t1, "ENTITY_MANAGEMENT")}
                    </Typography>
                    <List className="commonlistul">
                      {PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "NA" && (
                        <ListItem
                          onClick={PrivAttributes?.priv_grid_searchUsers?.PrivRole !== "RO" && executeScroll}
                          sx={{ cursor: "pointer" }}
                        >
                          {t(t1, "SEARCH_ALL_ENTITIES")}
                        </ListItem>
                      )}
                      {/* {PrivAttributes?.priv_grid_pendingUsers?.PrivRole !== "NA" && ( */}
                      <ListItem className="pending-accordion">
                        <Accordion
                          expanded={isEntityManagementAccordionOpen}
                          onChange={() => setIsEntityManagementAccordionOpen((prevValue) => !prevValue)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header-2"
                          >
                            <Typography>{`${t(t1, "PENDING_REQUEST")}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <MUITableGrid
                              key={entityGridKey}
                              access={PrivAttributes?.priv_grid_pendingUsers?.PrivRole}
                              hideColumns={hideColumns}
                              rows={translateRows(
                                [...pendingEntitiesRecords],
                                ["entityType", "entityStatus", "jurisdiction", "requestStatus","entityAccountType","accountStatus"],
                                t,
                                t1
                              )}
                              columns={showOnlyPermittedFields(
                                searchEntityColumns,
                                pendingEntitiesInit.metaData,
                                pendingEntitiesInit.fieldAccess
                              )}
                              count={searchPendingUserDetails?.count}
                              isSearch
                              isExport
                              isSettings
                              initialSortedFields={[
                                { field: "Status", sort: "asc" },
                                { field: "EntityAccountNumber", sort: "asc" },
                              ]}
                              fileName={t(t1, "ENTITY_MANAGEMENT")}
                              customInitialState={entityFilter}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                      {/* )} */}
                    </List>
                  </Grid>
                  {showNAICSManagement && (
                    <>
                      <Typography
                        sx={{ fontWeight: 650, textTransform: "uppercase" }}
                        variant="h4"
                        gutterBottom
                      >
                        {t(t1, "NAICS_MANAGEMENT")}
                      </Typography>
                      <List className="commonlistul">
                        <AccordionLink to="/naics-code" customStyle={{width: '100%'}}>{`${t(t1, "NAICS_CODE_MANAGEMENT")}`}</AccordionLink>
                      </List>
                    </>
                  )}
                  {isJurisdictionRegistrarUser && (
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 650 }}
                        variant="h4"
                        gutterBottom
                      >
                        {t(t1, "AUCTION_RESERVE_SALE")}
                      </Typography>
                      <List className="commonlistul">
                        <AccordionLink to="/auction-reserve-sale" customStyle={{width: '100%'}}>{`${t(
                          t1,
                          "EVENT_FSA_SELECTION_AND_MANAGEMENT"
                        )}`}</AccordionLink>
                      </List>
                    </Grid>
                  )}

                  {/* ***********    Manage offsets Accordion    ********** */}

                  {(isAdmin || isAuthority || isJurisdictionRegistrarUser) && (
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 650, textTransform: "uppercase" }}
                        variant="h4"
                        gutterBottom
                      >
                        {t(t1, "MANAGE_OFFSETS")}
                      </Typography>
                      <List className="commonlistul">
                        {OFFSET_LINKS.map((link) => (
                          <AccordionLink
                            key={link.id}
                            to={link.path}
                            customStyle={{width: '100%'}}
                          >
                            {link.name}
                          </AccordionLink>
                        ))}
                      </List>
                    </Grid>
                  )}
                  {/* ***********    Jurisdiction Accounts Accordion    ********** */}
                  {(isAdmin || isAuthority) && <JurisdictionAccounts />}
                  <Requests />
                </>
              )}
              {isJurisdictionUser && (
                <Grid>
                  <Typography
                    sx={{ fontWeight: 650, textTransform: "uppercase" }}
                    variant="h4"
                    gutterBottom
                  >
                    {t(t1, "PURCHASE_LIMITS")}
                  </Typography>
                  <List className="commonlistul">
                    <AccordionLink to={`/purchase-limits/${localStorage.getItem("jurisdictionID")}`} customStyle={{width: '100%'}}>{`${t(t1, "VIEW_PURCHASE_LIMITS")}`}</AccordionLink>
                  </List>
                </Grid>
              )}
              {isJurisdictionUser && (
                <>
                  <Typography
                    sx={{ fontWeight: 650, textTransform: "uppercase" }}
                    variant="h4"
                    gutterBottom
                  >
                    {t(t1, "HOLDING_LIMITS")}
                  </Typography>
                  <List className="commonlistul">
                    <AccordionLink to="/holding-limits" customStyle={{width: '100%'}}>{`${t(t1, "VIEW_HOLDING_LIMITS")}`}</AccordionLink>
                    <AccordionLink to="/holding-limits/checks" customStyle={{width: '100%'}}>{`${t(t1, "MANAGE_HOLDING_LIMITS_CHECK_TITLE")}`}</AccordionLink>
                  </List>
                </>
              )}
              {(isAdmin || isAuthority) && <ManageBudgetSection />}
            </Grid>
          </Grid>
          {(isAdmin || isAuthority) && (
            <Grid>
              <Typography
                sx={{ fontWeight: 650, textTransform: "uppercase" }}
                variant="h4"
                gutterBottom
              >
                {t(t1, "MANAGE_LIMITED_EXEMPTIONS")}
              </Typography>
              <List className="commonlistul">
                <AccordionLink to="/limited-exemptions" customStyle={{width: '100%'}}>{`${t(t1, "LIMITED_EXEMPTIONS")}`}</AccordionLink>
                <AccordionLink to="/limited-exemptions/batches" customStyle={{width: '100%'}}>{`${t(t1, "BATCH_EXEMPTIONS")}`}</AccordionLink>
              </List>
            </Grid>
          )}
          {(isJSA || isAuthority) && (
            <Grid>
              <Typography
                sx={{ fontWeight: 650, textTransform: "uppercase" }}
                variant="h4"
                gutterBottom
              >
                {t(t1, "JURISDICTION_MANAGEMENT")}
              </Typography>
              <List className="commonlistul">
                {JURISDICTION_MANAGEMENT_LINKS.map((link) => (
                  <AccordionLink
                    key={link.id}
                    to={link.path}
                    customStyle={{width: '100%'}}
                  >
                    {link.name}{link.html}
                  </AccordionLink>
                ))}
              </List>
            </Grid>
          )}
          {showActivateDeactivateModal && (
            <CustomeDialogMessage
              title={t(t1, "DEACTIVATE_USER")}
              handleClose={() => setShowActivateDeactivateModal(false)}
              open={showActivateDeactivateModal}
              message1={t(t1, "DEACTIVATE_USER_TXT")}
              handleContinue={handleActiveDeactiveLogin}
              disableContinue={!watchDeactiveProfile || !methods.formState.isValid}
            >
              <Controller
                control={control}
                name="deactiveProfile"
                render={({ field }) => (
                  <RadioGroup
                    sx={Style.RadioGroupStyle}
                    {...field}
                  >
                    {deactiveProfileOptions.map((option, index) => (
                      <FormControlLabel
                        key={`${option.name}_${index}`}
                        sx={{ display: "block" }}
                        value={option.name}
                        control={<Radio name={option.name} checkedIcon={<CheckCircleIcon />} />}
                        label={option.name}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              <Grid
                item
                sx={{ mt: 1 }}
              >
                <GridTextfield
                  className="input-group"
                  inputClass=""
                  rules={{
                    required: true,
                    minLength: {
                      value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MIN_CHAR,
                      message: `${t(t1, "ERR_DEACTIVATE_USER_REASONS_MIN_LENGTH_CHAR")}`,
                    },
                    pattern: {
                      value: answerField,
                      message: `${t(t1, "ERR_INVALID_DEACTIVATE_USER_REASONS")}`,
                    },
                    maxLength: {
                      value: JURISDICTION_USER_ACTION_FORM_FIELDS.INACTIVE_USER_REASON_MAX_CHAR,
                      message: `${t(t1, "ERR_DEACTIVATE_USER_REASONS_MAX_LENGTH_CHAR")}`,
                    },
                  }}
                  label={t(t1, "DEACTIVATE_USER_REASONS")}
                  isRequiredMsg={'ERR_DEACTIVATE_USER_REASONS_IS_REQUIRED'}
                  name={"deactiveProfileReason"}
                  privId="priv_linkmodal_txt_active_deactive_user"
                  InputProps={{
                    "aria-required": "true",
                  }}
                />
              </Grid>
            </CustomeDialogMessage>
          )}
          {showInactiveUser && (
            <CustomeDialogMessage
              title={t(t1, modaDataObj?.title)} // {t(t1, "INACTIVE_USER")}
              message1={t(t1, modaDataObj?.message)}
              buttonMessage={t(t1, modaDataObj?.buttonMessage)}
              continueBtn={modaDataObj?.buttonText}
              handleClose={() => setShowInactiveUser(false)}
              open={showInactiveUser}
              handleContinue={() =>
                modaDataObj?.handleModalContinue(clickedUserIDName?.id, methods?.getValues("inctiveReasons")?.trim(), userRow)
              }
              disableContinue={!methods.formState.isValid || emailDisabled || methods?.getValues("inctiveReasons") === ""}
            >
              <Grid item>
                <GridTextfield
                  // gridMd={3}
                  customeClass=""
                  className="input-group"
                  inputClass=""
                  label={t(t1, modaDataObj?.textBoxName)}
                  name={"inctiveReasons"}
                  privId={modaDataObj?.privId}
                  rules={modaDataObj?.rules}
                  InputProps={{
                    "aria-required": "true",
                  }}
                />
              </Grid>
            </CustomeDialogMessage>
          )}
          {showEditTelephoneEmailDialog && (
            <CustomDialogMultiInput
              methods={methods}
              currentUserTelephone={clickedUserIDName?.TelephoneNumber}
              handleClose={() => setShowEditTelephoneEmailDialog(false)}
              open={showEditTelephoneEmailDialog}
              handleContinue={(telephoneNumber, email) =>
                handleUpdateEmailTelephone(clickedUserIDName?.id, telephoneNumber, email)
              }
            />
          )}
          {showConfirmResetPassphraseAndSecurity ? (
            <CustomeDialogMessage
              title={t(t1, "RESET_PASSPHRASE_AND_SECURITY_MODAL_TITLE")}
              handleClose={() => {
                setShowConfirmResetPassphraseAndSecurity(false);
              }}
              open={showConfirmResetPassphraseAndSecurity}
              handleContinue={handleResetPassphraseAndSecurityQA}
            >
              <Typography sx={{ mt: 0, marginBottom: "60px" }}>
                {t(t1, "RESET_PASSPHRASE_AND_SECURITY_MODAL_MESSAGE")}
              </Typography>
            </CustomeDialogMessage>
          ) : null}
        </FormProvider>
      </Container>
      <Loader loader={loader || isLoadingPage} />
    </>
  );
}

export default JurisdictionUserHome;
