import { useTheme } from "@emotion/react";
import { Box, Container, Divider, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Loader } from "components/Loader";
import { TermsConditionsModal } from "components/TermsConditionsModal";
import i18n from "config/lang/i18n";
import useAuth from "hooks/useAuth";
import { useAuthentication } from "hooks/useAuthentication";
import { getUserRegDetails } from "store/userHomeSlice";
import { getCurrentPageName, JurisdictionUser, parseOptions, ServiceProviderUser, IndividualUser, SYSTEM_USER, t,MarketMonitor ,FSAHome, AuctionAdministrator} from "utils/constants";

import Banner from "../Banner";
import Style from "./styles";

function LandingPageKnown() {
  const navigate = useNavigate();

  // please do not uncomment this line other wise it will throws error
  // useAuthentication({ redirectTo: "/home" })
  const { isSignedIn, user: logedInUser } = useAuth();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [updateTCModal, setUpdateTCModal] = useState(false);
  const { t: t1 } = useTranslation(getCurrentPageName(), {
    useSuspense: true,
  });
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  useEffect(() => {
    if (Object.keys(userDetails)?.length > 0 && isSignedIn) {
      if (userDetails?.AcceptTAndC === false || userDetails?.AcceptTAndU === false) {
        setUpdateTCModal(true)
      } else if (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) {
        navigate("/jurisdiction-user");
      } else if (userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
        navigate("/fsa-home");
      } else if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor) {
        navigate("/market-monitor");
      } else if (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator) {
        navigate("/auction-administrator");
      }else if ([SYSTEM_USER, IndividualUser].includes(userDetails?.RoleDetail?.RoleType[0]?.name)){
        navigate("/home");
      }

    } else {
      setUpdateTCModal(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (logedInUser)
      dispatch(getUserRegDetails({ UID: logedInUser?.userId }));
  }, [logedInUser])

  return (
    <>
      {PrivAttributes?.priv_banner_horizontal?.PrivRole !== "NA" && <Banner />}
      <Divider />
      <Container maxWidth="xl">
        <Stack className={Style.WelcomeText} direction="row" alignItems="center" sx={{ mb: 5, ...Style.WelcomeText }}>
          <Grid container spacing={0}>
            <Grid item lg={9} md={8} sm={12}>
              <Box sx={{ flexGrow: 1 }}>
                {PrivAttributes?.priv_h2_Welcome?.PrivRole !== "NA" && (
                  <Typography variant="h3" gutterBottom sx={Style.WelcomeTextTital}>
                    {/* {t(t1, "WELCOME")} */}
                    {t(t1, "WELCOME")}
                  </Typography>
                )}
                {PrivAttributes?.priv_h2_welcomeContent?.PrivRole !== "NA" && (
                  <Typography sx={{ color: "text.secondary", ...Style.WelcomeTextPara }}>
                    {/* {t(t1, "HOME_MAIN_CONTENT")} */}
                    {t(t1, "HOME_MAIN_CONTENT")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              {PrivAttributes?.priv_sec_NOTICES?.PrivRole !== "NA" && (
                <Box sx={Style.noticebox}>
                  {/* {parse(t(t1, "NOTICES"), parseOptions)} */}
                  <Typography variant="h4" gutterBottom sx={{ "& p": { margin: 0 } }}>
                    {t(t1, "NOTICES")}
                  </Typography>
                  {/* <List sx={{ borderBottom: "1px solid #4d8da4" }}>
                  <ListItem>Non-Specific Auction Data</ListItem>
                  <ListItem>Non-Specific Auction Data</ListItem>
                  <ListItem>Auction Dates</ListItem>
                </List>
                <List sx={{ borderBottom: "1px solid #4d8da4" }}>
                  <ListItem>Detailed Auction Requirements and Instructions</ListItem>
                  <ListItem>Relevant Dates</ListItem>
                </List>
                <List>
                  <ListItem>Auction Examples </ListItem>
                  <ListItem>Relevant Dates </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                  LABEL
                </Typography>
                <List>
                  <ListItem>Auction Examples </ListItem>
                  <ListItem>Relevant Dates </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                  LABEL
                </Typography>
                <List>
                  <ListItem>Auction Examples </ListItem>
                  <ListItem>Relevant Dates </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                  LABEL
                </Typography>
                <List>
                  <ListItem>Auction Examples </ListItem>
                  <ListItem>Relevant Dates </ListItem>
                </List>
                <Typography variant="h5" gutterBottom>
                  LABEL
                </Typography>
                <List>
                  <ListItem>Auction Examples </ListItem>
                  <ListItem>Relevant Dates </ListItem>
                </List> */}
                </Box>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Container maxWidth="xl">
        <Divider color={theme.palette.text.primary} />
      </Container>
      <Container maxWidth="xl">
        <Stack sx={Style.JurisdictionText} direction="row" alignItems="center">
          {PrivAttributes?.priv_h2_Jurisdiction_Specific_Content?.PrivRole !== "NA" && (
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: "text.secondary", ...Style.WelcomeTextPara }}>
                {t(t1, "JURISDICTION_SPECIFIC_CONTENT")}
              </Typography>

              {/* <Typography sx={Style.WelcomeTextTital} variant="h3" gutterBottom>
              - {t(t1, "JURISDICTION_SPECIFIC_CONTENT")} -
            </Typography>
            <Typography sx={{ color: "text.secondary", ...Style.WelcomeTextPara }}>
              {parse(t(t1, "HOME_MAIN_CONTENT"), parseOptions)}
            </Typography> */}
            </Box>
          )}
        </Stack>
      </Container>
      {updateTCModal && <TermsConditionsModal setUpdateTCModal={setUpdateTCModal} />}
    </>
  );
}

export default LandingPageKnown;
