import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
// import resourcesToBackend from "i18next-resources-to-backend";
import LocalStorageBackend from 'i18next-localstorage-backend'; // load from local storage
import {i18nPrefix} from "utils";


/* Conflict Resolution Candidate
import HttpBackend from "i18next-http-backend";

import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { checkIsAuthenticated } from "store/authSlice";
import { enLanguage, getCurrentORGID, getCurrentPageName, isEmpty } from "utils/constants";
 */
// import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import { checkIsAuthenticated } from "store/authSlice";
import { DEFAULT_LANGUAGE_CODE, DEFAULT_UNKNOWN_PAGE_ORG_ID, enLanguage, getAllData, isEmpty, PAGES } from "utils/constants";
import store from '../../store/store';
import deCommon from "./locales/de/common.json";
import deConfigSetting from "./locales/de/config-setting.json";
import deCreateUserProfile from "./locales/de/create-user-profile.json";
import deFaq from "./locales/de/faq.json";
import deLandingPageUnknown from "./locales/de/landingpage-unknown.json";
import deLogin from "./locales/de/login.json";
import dePrevilegeManagement from "./locales/de/previlege-management.json";
import deSubmittedDocuments from "./locales/de/submitted-documents.json";
import deSystemRoles from "./locales/de/system-roles.json";
import de from "./locales/de/translations.json";
import deUserHome from "./locales/de/user-home.json";
import enCommon from "./locales/en/common.json";
import enConfigSetting from "./locales/en/config-setting.json";
import enContentManagement from "./locales/en/content-management.json";
import enCreateUserProfile from "./locales/en/create-user-profile.json";
import enFaq from "./locales/en/faq.json";
import enLandingPageUnknown from "./locales/en/landingpage-unknown.json";
import enLogin from "./locales/en/login.json";
import enPrevilegeManagement from "./locales/en/previlege-management.json";
import enSubmittedDocuments from "./locales/en/submitted-documents.json";
import enSystemRoles from "./locales/en/system-roles.json";
import en from "./locales/en/translations.json";
import enUserHome from "./locales/en/user-home.json";
import frCommon from "./locales/fr/common.json";
import frConfigSetting from "./locales/fr/config-setting.json";
import frCreateUserProfile from "./locales/fr/create-user-profile.json";
import frFaq from "./locales/fr/faq.json";
import frLandingPageUnknown from "./locales/fr/landingpage-unknown.json";
import frLogin from "./locales/fr/login.json";
import frPrevilegeManagement from "./locales/fr/previlege-management.json";
import frSubmittedDocuments from "./locales/fr/submitted-documents.json";
import frSystemRoles from "./locales/fr/system-roles.json";
import fr from "./locales/fr/translations.json";
import frUserHome from "./locales/fr/user-home.json";


const bundledResources = {
  en: {
    translation: {
      ...en,
      ...enCommon,
      ...enCreateUserProfile,
      ...enSubmittedDocuments,
      ...enFaq,
      ...enLandingPageUnknown,
      ...enLogin,
      ...enUserHome,
      ...enPrevilegeManagement,
      ...enSystemRoles,
      ...enConfigSetting,
      ...enContentManagement,
    },
  },
  de: {
    translation: {
      ...de,
      ...deCommon,
      ...deCreateUserProfile,
      ...deSubmittedDocuments,
      ...deFaq,
      ...deLandingPageUnknown,
      ...deLogin,
      ...deUserHome,
      ...dePrevilegeManagement,
      ...deSystemRoles,
      ...deConfigSetting,
    },
  },
  fr: {
    translation: {
      ...fr,
      ...frCommon,
      ...frCreateUserProfile,
      ...frSubmittedDocuments,
      ...frFaq,
      ...frLandingPageUnknown,
      ...frLogin,
      ...frUserHome,
      ...frPrevilegeManagement,
      ...frSystemRoles,
      ...frConfigSetting,
    },
  },
};

// Get org id to fetch CMS page as per jurisdiction based on entity or other user
const getOrgId = () => {
  return new Promise((resolve, reject) => {
    let count = 0;
    if (window.location.pathname.includes('entity-profile')) {

      const interval = setInterval(() => {
        count += 1;
        const jurisdictionDetails = store.getState().jurisdiction.jurisdictionDetails;
        const juriName = store.getState().entityProfile?.entities?.data?.jurisdiction?.name;
        const selectedJuri = jurisdictionDetails?.find(juriItem => juriItem?.Name?.toUpperCase() === juriName?.toUpperCase())
        if (selectedJuri?.dynamoId) {
          clearInterval(interval)
          resolve(selectedJuri?.dynamoId?.split("#")?.pop())
        }
        if (count > 500) {
          clearInterval(interval)
          const orgID = !isEmpty(localStorage.getItem("jurisdiction"))
            ? localStorage.getItem("jurisdiction")?.split("#")?.pop()
            : "00101";
          resolve(orgID)
        }
      }, 2000);
      // this will handle flow to display labels based on selected jurisdiction in COJ n Cross flow
    } else if (window.location.pathname.includes('change-jurisdiction') || window.location.pathname.includes('cross-jurisdiction')) {
      let orgID;
      if (!isEmpty(localStorage.getItem("change_cross_jurisdiction"))) {
          orgID = localStorage.getItem("change_cross_jurisdiction")?.split("#")?.pop();
      } else {
        orgID = !isEmpty(localStorage.getItem("jurisdiction"))
            ? localStorage.getItem("jurisdiction")?.split("#")?.pop()
            : "00101";
      }
      resolve(orgID)
    } else {
      const orgID = !isEmpty(localStorage.getItem("jurisdiction"))
        ? localStorage.getItem("jurisdiction")?.split("#")?.pop()
        : DEFAULT_UNKNOWN_PAGE_ORG_ID;
      resolve(orgID)
    }
  });
}

const loadResources = async (lng, ns) => {
  /* Conflict Resolution Candidate
    const orgID = !isEmpty(getCurrentORGID())
      ? getCurrentORGID()?.split("#")?.pop()
      : "00101";
    const endpoint = checkIsAuthenticated() ? "/cms/details" : "/publiccms/details"
   */

  const orgID = await getOrgId();
  const languageCode = localStorage.getItem("languageCode") || DEFAULT_LANGUAGE_CODE;
  const endpoint = localStorage.getItem("access_token") ? "/cms/v2/api/details" : "/cms/v2/public/api/details"
  const reponse = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}?OrgID=ORG%23${orgID}&Page=${encodeURIComponent(ns)}&LanguageCode=${languageCode}`, { headers: { "authorization": `Bearer ${localStorage.getItem("access_token")}` } }) // &Page={{ns}}  ${localStorage.getItem("pageName")})
    // const reponse = await fetch('http://localhost:9000?language=en')
    .then(async (response) =>
    {

      const data = await response.json();
      if (data?.messageKey === "ERROR") {
        throw new Error(data?.message);
      }
      return data;
      }
      
    )
    .catch((error) => {
      console.log(error);
    });
  return reponse;
};

// Backend options for ChainedBackend library
const chainedBackendOptions = {
  backends: [LocalStorageBackend,HttpBackend],
  backendOptions: [{
    prefix: i18nPrefix,
    expirationTime: 300 * 24 * 60 * 60 * 1000, // 1 day
  },
    {
      loadPath: "{{lng}}|{{ns}}",
      request: (options, url, payload, callback) => {
        try {
          const [lng, ns] = url.split("|");

          const storedData = {
            /* Conflict Resolution Candidate
                        lng: enLanguage(),
                        ns: getCurrentPageName()
                      }
            
                      // console.log(url, storedData, "storedData", localStorage.getItem(ns) !== null);
             */
            lng: () => localStorage.getItem("i18nextLng"),
            ns: localStorage.getItem("pageName")
          }

          // console.log(ns,lng,storedData.lng());
          // !isEmpty(localStorage.getItem(ns)) &&
          // callback(null, {
          //   // data: JSON.stringify(response),
          //   data: localStorage.getItem(ns),
          //   status: 200,
          // });
          // if (ns !== "translation") {
          /* Conflict Resolution Candidate
                    if ((lng?.split('#')?.pop() === storedData.lng && localStorage.getItem(ns) !== null) || ns !== "Page") {
           */
          if ((lng?.split('#')?.pop() === storedData.lng() && localStorage.getItem(ns) !== null) || ns !== "Page") {
            loadResources(["en-GB", "en"].includes(lng?.split('#')?.pop()) ? "English" : lng?.split('#')?.pop(), ns).then((response) => {
              // console.log(response, typeof response);
              // localStorage.setItem(ns, JSON.stringify(response));
              callback(null, {
                // data: JSON.stringify(response),
                data: JSON.stringify(response),
                status: 200,
              });
            });
          }
          // }
        } catch (e) {
          console.error(e);
          callback(null, {
            status: 500,
          });
        }
      },
    },
  ],
};

// Backend options for HttpBackend library
const backOps = {
  // loadPath: `https://mocki.io/v1/8b15573f-492d-4c47-a628-153668cf9cb0`,
  // loadPath: `${process.env.REACT_APP_API_URL}/cms/details?PageLanguage={{lng}}&Page={{ns}}`, // &Page={{ns}} // Here page is use for current page(route)
  // queryStringParams: {
  //   OrgID: localStorage.getItem("jurisdiction")?.split("#")?.pop(),
  //   // Page: "Page_LandingPageKnown"
  // },
  // type: "backend",

  // crossDomain: false,

  // allowMultiLoading: false,

  loadPath: "{{lng}}|{{ns}}",

  request: (options, url, payload, callback) => {
    try {
      const [lng, ns] = url.split("|");
      // if (ns !== "translation") {
      loadResources(lng, ns).then((response) => {
        console.log(response);
        callback(null, {
          data: JSON.stringify(response),
          status: 200,
        });
      });
      // }
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

// Backend options for XHR library
const backendOptions = {
  type: "backend",
  crossDomain: false,
  allowMultiLoading: false,
  loadPath: `${process.env.REACT_APP_API_URL}/cms/details?OrgID=00101&PageLanguage=English`,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    // backend: {
    //   backends: [HttpBackend],
    //   backendOptions: [
    //     {
    //       // loadPath: `https://mocki.io/v1/8b15573f-492d-4c47-a628-153668cf9cb0`,
    //       // loadPath: `${process.env.REACT_APP_API_URL}/cms/details?PageLanguage={{lng}}&Page={{ns}}`, // &Page={{ns}} // Here page is use for current page(route)
    //       // queryStringParams: {
    //       //   OrgID: localStorage.getItem("jurisdiction")?.split("#")?.pop(),
    //       //   // Page: "Page_LandingPageKnown"
    //       // },
    //       loadPath: "{{lng}}|{{ns}}",
    //       request: (options, url, payload, callback) => {
    //         try {
    //           const [lng, ns] = url.split("|");
    //           loadResources(lng, ns).then((response) => {
    //             console.log(response);
    //             callback(null, {
    //               data: response,
    //               status: 200,
    //             });
    //           });
    //         } catch (e) {
    //           console.error(e);
    //           callback(null, {
    //             status: 500,
    //           });
    //         }
    //       },
    //       crossDomain: false,

    //       allowMultiLoading: false,
    //     },
    //   ],
    // },

    backend: chainedBackendOptions,

    // -------- Uncomment for local json file translation --------
    // ns: ["translation"], // common page name
    // defaultNS: "translation", // common page name
    // lng: "en",
    // fallbackLng: "en",

    // -------- Uncomment for Backend/API Translation --------
    ns: [], // common page name
    defaultNS: [], // common page name
    /* Conflict Resolution Candidate
        lng: `${getCurrentORGID()}#${enLanguage()}`, // Comment when need local translation
     */
    lng: enLanguage(), // Comment when need local translation
    // fallbackLng: "English", // Comment when need local translation
    fallbackLng: false, // do not load a fallback

    debug: false,
    preload: [],
    detection: { order: ["queryString", "cookie", "localStorage", "navigator"], caches: false }, // ['cookie']}
    interpolation: {
      escapeValue: false,
    },
    partialBundledLanguages: true,
    initImmediate: false,
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged',
      wait: true,
      // wait: false,
      // bindStore: "added removed",
      // nsMode: "default",
      // defaultTransParent: "div",
    },
    // reloadInterval: 1000,

    // -------- Uncomment for local json file translation --------
    // resources: {
    //   ...bundledResources,
    // },
  });

export default i18n;
