import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";

const AllReportsView = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AllReportsView,
    }))
  )
);
const EARPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EARPage,
    }))
  )
);

const EventADEReportAccordion = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventADEReportAccordion,
    }))
  )
);

const EventParticipantAccordion = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EventParticipantAccordion,
    }))
  )
);


const RegistryEntityFacilityReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.RegistryEntityFacilityReportView,
    }))
  )
);

const EntityLegalOpNameChangeReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EntityLegalOpNameChangeReportView,
    }))
  )
);

const FacilityGHGIdentifierChangeReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.FacilityGHGIdentifierChangeReportView,
    }))
  )
);

const FacilityLegalOpNameChangeReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.FacilityLegalOpNameChangeReportView,
    }))
  )
);



const QualifiedBiddersReconciliation = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.QualifiedBiddersReconciliation,
    }))
  )
);

const BidLimitationsReconciliationReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.BidLimitationsReconciliationReportPage,
    }))
  )
);

const EntityUserRelationshipsReconciliationReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.EntityUserRelationshipsReconciliationReportPage,
    }))
  )
);




const WiringInstructionsStatusReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.WiringInstructionsStatusView,
    }))
  )
);



export const AllReports = {
  path: "/all-reports",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_allreports"}>
          <AllReportsView />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const EARReports = {
  path: "/ear-reports",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_Link_ear_report"}>
          <EARPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const RegistryEntityFacilityReport = {
  path: "/registered-entity-facility",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={""}>
          <RegistryEntityFacilityReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const EventADER = {
  path: "/event-application-data-export-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_event_app_data_exp_report"}>
          <EventADEReportAccordion />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const EventParticipantReport = {
  path: "/event-participant-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_event_participant_report"}>
          <EventParticipantAccordion />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const EntityLegalOpNameChangeReport = {
  path: "/entity-legal-name-operating-name-change",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_entity_legal_name_operating_name_change"}>
          <EntityLegalOpNameChangeReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const FacilityGHGIdentifierChangeReport = {
  path: "/facility-ghg-identifier-change",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_facility_ghg_identifier_change"}>
          <FacilityGHGIdentifierChangeReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const FacilityLegalOpNameChangeReport = {
  path: "/facility-name-operating-name-change",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_facility_name_operating_name_change"}>
          <FacilityLegalOpNameChangeReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};


export const WiringInstructionsStatusReport = {
  path: "/wiring-instructions-status-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_wiring_inst_status_report"}>
          <WiringInstructionsStatusReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const QualifiedBiddersReconciliationReport = {
  path: "/qualified-bidders-reconciliation-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_BidLimitationsReconciliationReport"}>
          <QualifiedBiddersReconciliation />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const BidLimitationsReconciliationReport = {
  path: "/bid-limitations-reconciliation-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_BidLimitationsReconciliationReport"}>
          <BidLimitationsReconciliationReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const EntityUserRelationshipsReconciliationReport = {
  path: "/entity-user-relationships-reconciliation-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_BidLimitationsReconciliationReport"}>
          <EntityUserRelationshipsReconciliationReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};


export const ConsignmentWiringInstructionsReport = {
  path: "/fsa-wiring-instructions-status-report",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_fsa_home_reports"}>
          <WiringInstructionsStatusReportPage />
        </RoleBasedGuard>
      ),
    },
  ],
};

