import { Loader } from "components/Loader";
import { PermissionDenied } from "components/PermissionDenied";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { isInProposal } from "pages/CreateParticipatingJurisdiction/constants";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getJurisdictionCurrencyLanguageAndRequestStatus } from "store/onboardJurisdictionSlice";
import { getJurisdictionDetails } from "store/setInitialUserSlice";
import { JURISDICTION_STATUS } from "utils/constants";

export default function InitialUserWrapper({ children }) {
  const dispatch = useDispatch();
  const { jurisdictionId } = useParams();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { requestStatus } = useSelector(({ onboardJurisdiction }) => onboardJurisdiction);

  const { isWCIincAdmin } = useJurisdictionUserRole();

  const { jurisdictionDetails } = useSelector(({ setInitialUser }) => setInitialUser);

  useEffect(() => {
    dispatch(getJurisdictionDetails(jurisdictionId));
    dispatch(getJurisdictionCurrencyLanguageAndRequestStatus(jurisdictionId));
  }, [dispatch, jurisdictionId]);
  if (userDetails?.loading || jurisdictionDetails.isLoading) return <Loader loader={userDetails?.loading} />;

  if (!isWCIincAdmin) return <PermissionDenied />;

  if (jurisdictionDetails.data?.[0]?.Status?.Name !== JURISDICTION_STATUS.ONBOARDING || isInProposal(requestStatus?.name)) return <PermissionDenied />;

  return children;
}
