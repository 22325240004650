import useAuth from "hooks/useAuth";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentPageName, JurisdictionUser, t } from "utils/constants";
import "./Menu.css";

const useMenu = () => {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const { isSignedIn } = useAuth();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isAdmin, isAuthority, isJurisdictionUser } = useJurisdictionUserRole();
  const jurisdictionId = localStorage.getItem("jurisdictionID");

  // TODO: PrivId to be revisit and to add CMS keys
  const routesMenu = [
    {
      id: 1,
      name: "USER MANAGEMENT",
      privId: "priv_h6_USER_MANAGEMENT",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      routes: [
        {
          id: "simple-tabpanel-0",
          name: "Search Users",
          path: "/jurisdiction-user",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_lnk_Search_Users",
        },
      ],
    },
    {
      id: 4,
      name: "JURISDICTION MANAGEMENT",
      privId: "priv_btn_Home", // priv_h6_JURISDICTION_MANAGEMENT
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      routes: [
        {
          id: "jurisdictionDetails",
          name: "Jurisdiction Details",
          path: `/manage-jurisdiction-details/${jurisdictionId}`,
          display: (isSignedIn && isJurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home"
        },
        {
          id: "jurisdictionAccounts",
          name: "Jurisdiction Accounts",
          path: "/jurisdiction-accounts",
          display: (isSignedIn && isJurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // priv_lnk_Jurisdiction_Accounts
        },
        {
          id: "allowanceBudgets",
          name: "Allowance Budgets",
          path: `/budgets/${jurisdictionId}`,
          display: (isSignedIn && (isAdmin || isAuthority)) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // priv_lnk_Allowance_Budgets
        },
        {
          id: "issuanceRecords",
          name: "Issuance Records",
          path: "/issuance-records",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // priv_lnk_Issuance_Records
        },
        {
          id: "approvals",
          name: "My Approvals",
          path: "/approvals",
          display: !!isAuthority,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "batchTransfers",
          name: "Batch Transfers",
          path: "/batch-transfer",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // priv_lnk_Batch_Transfers
        },
        {
          id: "administrativeTransfers",
          name: "Administrative Transfers",
          path: "/administrative-transfers",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // priv_lnk_Administrative_Transfers
        },
        {
          id: "modifiedRecordsForTransfers",
          name: "Modified Records for Transfers",
          path: "/modified-records-for-transfers",
          display: (isSignedIn && isJurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Modified_Records_for_Transfers",
        },
        {
          id: "unique12",
          name: "More Options in Jurisdiction Management",
          path: "/more-options-in-jurisdiction-management",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home", // "priv_select_More_Options_in_Jurisdiction_Management",
          subRoutes: [
            {
              id: "holding-limits",
              name: "Holding Limits",
              path: "/holding-limits",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_lnk_Holding_Limits",
            },
            {
              id: "purchase-limits",
              name: "Purchase Limits",
              path: `/purchase-limits/${localStorage.getItem("jurisdictionID")}`,
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home",
            },
            {
              id: "limited-exemptions",
              name: "Limited Exemptions",
              path: "/limited-exemptions",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Limited_Exemptions",
            },
            {
              id: "limited-exemptions-batches",
              name: "Batch Exemptions",
              path: "/limited-exemptions/batches",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // priv_lnk_Batch_Exemptions
            },
            {
              id: "holding-limit-checks",
              name: "Holding Limit Checks",
              path: "/holding-limits/checks",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Holding_Limit_Checks",
            },
            {
              id: "cross-jurisdiction-transfer-permission",
              name: "Cross-Jurisdiction Transfer Permission",
              path: "/cross-jurisdiction-transfer-permission",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Cross-Jurisdiction_Transfer_Permission",
            },
          ],
        },
      ],
    },

    {
      id: 6,
      name: "AUCTION / RESERVE SALE",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_SETTINGS", // priv_h6_AUCTION_/_RESERVE_SALE
      routes: [
        {
          id: "event-creation-and-management",
          name: "Event Creation and Management",
          path: "/event-creation-and-management",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_SETTINGS",
        },
        {
          id: "event-applications",
          name: "Event Applications",
          path: "/event-applications",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Event_Applications",
        },
        {
          id: "event-application-report",
          name: "Event Applications (Data Export) Report",
          path: "/event-application-data-export-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Event_Applications_(Data_Export)_Report",
        },
        {
          id: "event-participant-report",
          name: "Event Participant Report",
          path: "/event-participant-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home",
        },
        {
          id: "event-application-management",
          name: "Event Application Management",
          path: "/event-application-management",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Event_Application_Management",
        },
        {
          id: "financial-services-delivery-instructions",
          name: "Financial Services Delivery Instructions",
          path: "/financial-services-delivery-instructions",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Financial_Services_Delivery_Instructions",
        },
        {
          id: "more-options-in-auction",
          name: "More Options in Auction / Reserve Sale",
          path: "/more-options-in-auction",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home", // "priv_select_More_Options_in_Auction_/_Reserve_Sale",
          subRoutes: [
            {
              id: "service-provider-selection-and-management",
              name: "Service Provider Selection And Management",
              path: "/service-provider-selection-and-management",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Event_FSA_Selection_and_Management",
            },
            {
              id: "entity-user-relationship-reconciliation-report",
              name: "Entity User Relationship Reconciliation Report",
              path: "/entity-user-relationships-reconciliation-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Entity_User_Relationship_Reconciliation_Report",
            },
            {
              id: "qualified-bidders-reconciliation-report",
              name: "Qualified Bidders Reconciliation Report",
              path: "/qualified-bidders-reconciliation-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Qualified_Bidders_Reconciliation_Report",
            },
            {
              id: "bid-limitations-reconciliation-report",
              name: "Bid Limitations Reconciliation Report",
              path: "/bid-limitations-reconciliation-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Bid_Limitations_Reconciliation_Report",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: t(t1, "ENTITY_MANAGEMENT"),
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_SETTINGS",
      routes: [
        {
          id: "account-applications",
          name: "Account Applications",
          path: "/jurisdiction-user",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_SETTINGS", // priv_lnk_Account_Applications
        },
        {
          id: "proposed-entity-changes",
          name: "Proposed Entity Changes",
          path: "/jurisdiction-user",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Proposed_Entity_Changes",
        },
        {
          id: "registered-entities",
          name: "Registered Entities",
          path: "/registered-entities",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_lnk_Registered_Entities",
        },
        {
          id: "corporate-associations",
          name: "Corporate Associations",
          path: "/corporate-associations",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_corporate_associations", // priv_lnk_Corporate_Associations
        },
        {
          id: "entity-accounts",
          name: "Entity Accounts",
          path: "/entity-accounts",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_lnk_Entity_Accounts",
        },
        {
          id: "exchange-contract-code/manage",
          name: "Exchange Management",
          path: "/exchange-contract-code/manage",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Exchange Management",
        },
      ],
    },

    {
      id: 5,
      name: "OFFSET MANAGEMENT",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_SETTINGS", // priv_h6_OFFSET MANAGEMENT
      routes: [
        {
          id: "offset-project-name-management",
          name: "Offset Project Name Management",
          path: "/offset-management/project-name/manage",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Offset_Project_Name_Management",
        },
        {
          id: "offset-project-operator-management",
          name: "Offset Project Operator Management",
          path: "/offset-management/project-operator/manage",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Offset_Project_Operator_Management",
        },
        {
          id: "verification-body-management",
          name: "Verification Body Management",
          path: "/offset-management/verification-body/manage",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Verification_Body_Management",
        },
        {
          id: "offset-issuance-report",
          name: "Offset Issuance Report",
          path: "/offset-management/issuance-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Offset_Issuance_Report",
        },
        {
          id: "offset-tracking",
          name: "Offset Tracking",
          path: "/offset-management/tracking-record/manage",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Offset_Tracking",
        },
      ],
    },

    {
      id: 7,
      name: "COMPLIANCE",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_SETTINGS", // priv_h6_COMPLIANCE
      routes: [
        {
          id: "batch-emissions",
          name: "Batch Emissions",
          path: "/emissions/batches",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_SETTINGS", // priv_lnk_Batch Emissions
        },
        {
          id: "emissions",
          name: "Emissions",
          path: "/emissions",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Emissions",
        },
        {
          id: "facility-emission-report",
          name: "Facility Emissions Report",
          path: "/facility-emission-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Facility_Emissions_Report",
        },
        {
          id: "event-participant-report",
          name: "Event Participant Report",
          path: "/event-participant-report",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Event_Participant_Report",
        },
        {
          id: "batch-true-up-quantity",
          name: "Batch True-Up Quantity",
          path: "/batch-true-up-quantity",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Batch_True-Up_Quantity",
        },
        {
          id: "true-up-quantity",
          name: "True-Up Qunatity",
          path: "/true-up-quantity",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_True-Up_Quantity",
        },
        {
          id: "obligation-management",
          name: "Obligation Management",
          path: "/obligation-management",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Obligation_Management",
        },
        {
          id: "more-options-in-compliance",
          name: "More Options in Compliance",
          path: "/more-options-in-compliance",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: true,
          privId: "priv_btn_Home", // "priv_select_More_Options_in_Compliance",
          subRoutes: [
            {
              id: "entity-approved-obligation-report",
              name: "Entity Approved Obligation Report",
              path: "/entity-approved-obligation-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Entity_Approved_Obligation_Report",
            },
            {
              id: "obligation-period-management",
              name: "Obligation Period Management",
              path: "/obligation-period-management",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Obligation_Period_Management",
            },
            {
              id: "compliance-status-details",
              name: "Compliance Status Details",
              path: "/compliance-status-details",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Compliance_Status_Details",
            },
            {
              id: "eligible-instruments-for-retirement",
              name: "Eligible Instruments for Retirement",
              path: "/eligible-instruments-for-retirement",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Eligible_Instruments_for_Retirement",
            },
            {
              id: "compliance-obligations",
              name: "Compliance Obligations (Data Export)",
              path: "/compliance-obligations",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Compliance_Obligations_(Data_Export)",
            },
            {
              id: "entity-obligation-period-holdings-report",
              name: "Entity Obligation Period Holdings Report",
              path: "/entity-obligation-period-holdings-report",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Entity_Obligation_Period_Holdings_Report",
            },
            {
              id: "total-quantity-retired-by-obligation-period",
              name: "Total Qunatity Retired by Obligation Period",
              path: "/total-quantity-retired-by-obligation-period",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Total_Quantity_Retired_by_Obligation_Period",
            },
            {
              id: "/summary-of-instruments-retired-by-obligation-period",
              name: "Summary of Instruments Retired by Obligation Period, Vitage and Type",
              path: "/summary-of-instruments-retired-by-obligation-period",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Summary_of_Instruments_Retired_by_Obligation_Period",
            },
            {
              id: "notifications",
              name: "Notifications",
              path: "/notifications",
              display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
              isSubmenu: false,
              privId: "priv_btn_Home", // "priv_lnk_Notifications",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "FACILITY MANAGEMENT",
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: "priv_btn_SETTINGS", // priv_h6_FACILITY_MANAGEMENT
      routes: [
        {
          id: "facility-management",
          name: "Facility Management",
          path: "/jurisdiction-user",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_Facility_Management",
        },
        {
          id: "naics-code",
          name: "NAICS Code Display Management",
          path: "/naics-code",
          display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
          isSubmenu: false,
          privId: "priv_btn_Home", // "priv_lnk_NAICS_Code_Display_Management",
        },
      ],
    },
  ];

  return {
    routesMenu,
  };
};

export default useMenu;
