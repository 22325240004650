import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
/* Conflict Resolution Candidate
import { getAllData, t, LANGUAGES, PAGES, getCurrentPageName, getJurisdictionName, getAllLang, isEmpty, getFormLabels, LandingPageName, enLanguage, defaultORGID, getCurrentORGID } from "utils/constants";
 */
import { getAllData, t, LANGUAGES, PAGES, getCurrentPageName, getJurisdictionName, getAllLang, isEmpty, getFormLabels, LandingPageName, enLanguage, defaultORGID, docFilesPageName } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getJurisdictions, getJurisdictionsDetails, getSelectedJurisdictionName, setJurisdiction } from "store/jurisdictionSlice";
import { useEffectOnce } from "hooks/useEffectOnce";
import { checkIsAuthenticated, getMenuAccess, getUserDetails } from "store/authSlice";
import { getCMSDetails, getFileUrl } from "store/configSlice";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { Typography } from "@mui/material";
import { setDialogMessage } from "store/commonSlice";
import { Loader } from "components/Loader";
import useCommonFunc from "hooks/useCommonFunc";
/* Conflict Resolution Candidate
import { Loader } from "components/Loader";
import { setRedirectionPath } from "store/createUserProfileSlice";
 */

export default function SelectJurisdiction(props) {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { roles } = useSelector(({ privilege }) => privilege);
  const { menu } = useSelector(({ auth }) => auth);
  const [loaderState, setLoaderState] = useState(false);
  const { Jurisdictions, selectedJurisdiction, jurisdictionDetails } = useSelector(({ jurisdiction }) => jurisdiction);
/* Conflict Resolution Candidate
  const {  loader } = useSelector(({ common }) => common);
  const { cmsLanguagePage } = useSelector(({ config }) => config);
  const { formSteps: { activeStep } } = useSelector(({ createUserProfile }) => createUserProfile);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [juri, setJuri] = useState(userDetails ? userDetails.OrgID : "");
  const [loaderState, setLoaderState] = useState(false);
  const { user, isSignedIn } = useAuth();
  const location = useLocation();

  const search = useLocation().search;
  const Code1 = new URLSearchParams(search)?.get("confirmation_code");
  const Code2 = new URLSearchParams(search)?.get("code")
 */
  const { formSteps: { activeStep },prePopulatedDetails: { data }, } = useSelector(({ createUserProfile }) => createUserProfile);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [juri, setJuri] = useState(userDetails ? userDetails.OrgID : "");
  const { user, isSignedIn } = useAuth();
  const location = useLocation();
  const { displayJurisdictionName } = useCommonFunc();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');

  const {
    loggedInJurisdiction = ""
  } = props

  const handleChange = (event) => {
/* Conflict Resolution Candidate
    const { value } = event.target;
    if (location.pathname === "/create-user-profile" && activeStep !== 6) {
      dispatch(setRedirectionPath({route : "/" ,jurisdiction : value}))
      dispatch(setDialogMessage(true))
    }
    else {
 */
    if (location.pathname === "/create-user-profile" && activeStep !== 6) {
      dispatch(setDialogMessage(true))
    }
    else {
      const { value } = event.target;
      dispatch(setJurisdiction(value));
      setJuri(value);
      const LanguageParams = {
        OrgID: value,
        QueryType: getAllLang,
      };
      dispatch(getCMSDetails(LanguageParams));

      dispatch(
        getFileUrl({
          OrgID: value || defaultORGID,
          PageLanguage: enLanguage(),
          Page: docFilesPageName,
          QueryType: getFormLabels,
        })
      );
/* Conflict Resolution Candidate
      setLoaderState(true);
 */
      setLoaderState(true);
      localStorage.setItem("i18nextLng", `English`);
      i18n.changeLanguage(`${value}#English`);
      setTimeout(() => setLoaderState(false), 3000)
    }
  };

  useEffect(() => {
    dispatch(getJurisdictions());
    dispatch(getJurisdictionsDetails());
    dispatch(getSelectedJurisdictionName(localStorage.getItem("jurisdiction")))
/* Conflict Resolution Candidate
  }, [dispatch, selectedJurisdiction]);

  useEffect(() => {
    if(Object.keys(cmsLanguagePage)?.length > 0){
      const languageName = cmsLanguagePage?.data?.Languages?.find((e) =>e?.PageLanguage === localStorage.getItem('i18nextLng')) ? localStorage.getItem('i18nextLng'): 'English';
      localStorage.setItem("i18nextLng", languageName);
      i18n.changeLanguage(`${getCurrentORGID()}#${languageName}`);
      setLoaderState(false)
    }
  }, [cmsLanguagePage]);

  useEffect(() => {
    const page = PAGES.find((page) => page.path === location.pathname);
    if (user && user.userId && !isEmpty(localStorage.getItem('jurisdiction'))) {
      if ((userDetails?.OrgDetails?.length === 1 && !isEmpty(userDetails?.OrgID) )) {dispatch(setJurisdiction(userDetails?.OrgID));}
      if( !isEmpty(userDetails?.OrgID) && userDetails?.OrgDetails?.length > 1 && !userDetails?.OrgDetails?.filter(orgDetail => orgDetail.OrgID === localStorage.getItem('jurisdiction').replace("ORG#","")).length > 0) {dispatch(setJurisdiction(userDetails?.OrgID));}
 */
  }, [dispatch, selectedJurisdiction]);

  useEffectOnce(() => {
    dispatch(getJurisdictionsDetails());
  }, []);

  useEffect(() => {
    const page = PAGES.find((page) => page.path === location.pathname);
    // console.log(selectedJurisdiction);
    if (user && user.userId && !isEmpty(selectedJurisdiction)) {
      if (!isEmpty(userDetails?.OrgID)) dispatch(setJurisdiction(userDetails?.OrgID));
      dispatch(getUserDetails({ jurisdiction: selectedJurisdiction, roleId: encodeURIComponent(`U#${user?.userId}`), pageName: getCurrentPageName() }));
    }

    if (!checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction)) dispatch(getUserDetails({ jurisdiction: selectedJurisdiction, pageName: getCurrentPageName() }));
/* Conflict Resolution Candidate
  }, [user, location, selectedJurisdiction,userDetails]);
 */
  }, [user, location, selectedJurisdiction]);

  useEffect(() => {
    if (user && user.userId) {
      if (!isEmpty(userDetails?.OrgID) && (Object.keys(menu)?.length === 0)) {
        dispatch(getMenuAccess(userDetails?.OrgID, encodeURIComponent(`U#${user.userId}`)));
      }
    }

    if (Object.keys(menu)?.length === 0 && !checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction)) {
      dispatch(getMenuAccess(selectedJurisdiction));
    }
  }, [user, userDetails?.OrgID, selectedJurisdiction]);

  if (linkId)
    return (
      <Typography
        variant="body2"
        sx={{ marginLeft: ".3rem" }}
      >
        {data?.Jurisdiction?.display_name}
      </Typography>
    );
    

  
  return (
    <FormControl>
      {/* Conflict Resolution Candidate
      {(isSignedIn || (Code1 || Code2)) ? (
        <Typography variant="body2" sx={{ marginLeft: ".3rem" }}>
          {`${Jurisdictions.filter((jurisdiction) => jurisdiction?.OrgID === getCurrentORGID())?.[0]?.OrgName}`}
*/}
      {isSignedIn ? (
        <Typography variant="body2" sx={{ marginLeft: ".3rem" }}>
          {displayJurisdictionName() ? t(t1, displayJurisdictionName()) : ''}          {/* {loggedInJurisdiction} */}
        </Typography>
      ) : (
        <Select
          labelId="simple-select-label"
          id="simple-select"
/* Conflict Resolution Candidate
          value={getCurrentORGID()}
 */
          value={localStorage.getItem("jurisdiction")}
          onChange={(e) => handleChange(e)}
          disabled={isSignedIn}
          sx={{
            fontSize: "0.875rem",
            color: "common.textColor",
            ml: 0.5,
            ".MuiSelect-select": {
              p: 0,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              outline: "none",
            },
          }}
        >
          {Jurisdictions &&
            Jurisdictions?.filter((jurisdiction) => jurisdiction.IsDefault === false)?.map((jurisdiction, index) => (
/* Conflict Resolution Candidate
              <MenuItem key={index} value={jurisdiction.OrgID}>
 */
              <MenuItem  key={`jurisdiction_option_${index}`} value={jurisdiction.OrgID}>
                {`${t(t1, `${jurisdiction.OrgName}`)}`}
              </MenuItem>
            ))}
        </Select>
      )}
      {loaderState && <Loader loader={loaderState} />}
    </FormControl>
  );
}
