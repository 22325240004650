const Style = {
  btnPrimary:{
    fontSize:"14px",
    boxShadow:"none",
    "&:hover":{
      backgroundColor:"#4d8da4",
      boxShadow:"none",
    }
  },
  btnSm:{
    minWidth:"80px",
    minHeight:"30px",
    textTransform:"uppercase",
    marginLeft:"0",
    border:"solid 1px #283459",
    padding:"0"
  },
  formCardWrapper: {
    p: 5 / 2,
    mb: 5 / 2,
    pb: 7 / 2,
    // minHeight: '250px',
    borderRadius: 0,
    "&.home-form-card": {
      borderRadius: 0,
      '&.hc-temp': {
        minHeight: '392px'
      }
    },
    "&.create-role-popup": {
      padding: '0',
    },
    '&.customFormCard': {
      paddingBottom:'40px !important',
      "& .MuiFormControlLabel-root .MuiTypography-root": {
        fontWeight: 400,
        fontSize: '14px',
      },
      "& .MuiTypography-caption": {
        marginBottom: '5px'
      }
    },
    '&.alert-info-card': {
      border: '3px solid',
      borderColor: 'common.red',
      minHeight: '213px',
      color: "common.red",
      '& .MuiTypography-h2': {
        marginBottom: "10px",
      }
    },
    '&.formCardPad40':{
      paddingBottom:"40px"
    },
    '&.formCardPad20':{
      paddingBottom:"20px"
    },
  },
  customCheckboxLabel: {
    fontWeight: 700
  }
};

export default Style;
