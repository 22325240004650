import { Loadable } from "components/Loadable";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { lazy } from "react";


const AuctionAdminPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AuctionAdminPage,
    }))
  )
);

const AuctionAdminAllReportPage = Loadable(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.AuctionAdminAllReport,
    }))
  )
);

export const AuctionAdminRoute = {
  path: "/auction-administrator",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_auction_administrator"}>
       <AuctionAdminPage/>
        </RoleBasedGuard>
      ),
    },
  ],
};

export const AuctionAdminAllReportRoute = {
  path: "/all-report-auction-administrator",
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={"priv_lnk_auction_administrator"}>
       <AuctionAdminAllReportPage/>
        </RoleBasedGuard>
      ),
    },
  ],
};
