import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react'

export default function Loader({ loader}) {
  return (
    <Backdrop
      sx={{ color: '#fff !important', zIndex: (theme) => theme.zIndex.drawer + 999}}
      open={loader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}