import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { ButtonOutline } from "components/ButtonOutline";
import { CustomizeButton } from "components/CustomizeButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setDialogMessage } from "store/commonSlice";
import { resetCreaeUserProfileState } from "store/createUserProfileSlice";
import { getCurrentPageName, t } from "utils/constants";
import Style from "./DialogMessage.style";

export default function DialogMessage({
  title,
  message1,
  /* Conflict Resolution Candidate
    message2 = "",
  */
  message2,
  warning = false,
  buttonMessage = true,
  handleModalClick = "",
  continueBtn = "",
  borderColor = "",
  customStyling = false,
  multipleErrorKeys = [],
  cancelBtn = "",
  hideCancelBtn = false,
  handleCancelCustom,
  handleModalCancel,
  isModalDisable=false
}) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  /* Conflict Resolution Candidate
    const navigate = useNavigate();
   */
  const theme = useTheme();
  const { dialogMessage } = useSelector(({ common }) => common);

  const handleClose = () => {
    // handle custom cancel modal if func present in props
    if (handleCancelCustom) {
      handleCancelCustom();
    }
    dispatch(setDialogMessage(false));
  };

  const handleContinue = () => {
    if (handleModalCancel) {
      handleModalCancel();
    }
    if (handleModalClick) handleModalClick();
    if (handleModalClick === "") dispatch(resetCreaeUserProfileState());
    if(!isModalDisable)dispatch(setDialogMessage(false));
  };
  const styleSx = warning ? { ...Style?.ModelWarning } : { ...Style?.ModelError };
  // console.log(customStyling);
  return (
    <Dialog
      sx={{
        ...Style?.ModelError,
        ...styleSx,
        /* Conflict Resolution Candidate
                ".messageModel": {
                  margin: "20px 25px",
                  borderRadius: 8,
                  //
                  "& .MuiTypography-subtitle1": {
                    fontSize: "24px",
                    marginBottom: "15px",
                  },
                  "& .bottom-btn": {
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "flex-end",
                    "@media (max-width: 1100px)": {
                      marginTop: "30px",
                    },
                    "& .MuiButton-root": {
                      // width: "150px",
                      height: "40px",
                      marginLeft: "10px",
                      boxShadow: "none",
                      // border: "1px solid #d7d7d7",
                      outline: 0,
                    },
                    "& .submitBtn": {
                      fontWeight: 400,
                      "&:hover": {
                        fontWeight: 650,
                      },
                    },
                  },
                },
        */
        ...Style?.messageModel,
      }}
      fullWidth
      maxWidth={"sm"}
      open={dialogMessage}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          // border: borderColor ? "2px solid" : "2px solid",
          border: "2px solid",
          borderColor: borderColor || `${theme.palette.common.red}`,
          borderRadius: "8px",
          padding: '20px',
        }}
        className={customStyling}
      >
        <Box className="messageModel">
          <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
            {`${title}`}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {message1}
          </Typography>
          {message2 ? (
            <Typography variant="body2" sx={{ mb: 1 }}>
              {message2}
            </Typography>
          ) : null}
          {multipleErrorKeys?.map((message) => {
            return (<Typography variant="body2" sx={{ mb: 1 }}>
              {message}
            </Typography>)
          })}
          {buttonMessage && <Typography variant="body2" sx={{ my: 4 }}>
            {buttonMessage || `${t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE")}`}
          </Typography>}
          {/* Conflict Resolution Candidate
          <Box sx={{ textAlign: "right", mb: 1 }} className="bottom-btn">
            <CustomizeButton
              variant="outlined"
              csName="rc"
              label={t(t1, "CANCEL")}
              name="priv_modal_Cancel"
              handleClick={handleClose}
              // customStyle={{marginRight:'0px !important'}}
        */}
          <Box sx={{ textAlign: "right", mb: 0 }} >
            {!hideCancelBtn && <CustomizeButton
              variant="outlined"
              csName="rc"
              label={cancelBtn || t(t1, "CANCEL")}
              name="priv_modal_Cancel"
              handleClick={handleClose}
              customStyling={{ width: "150px" }}
            />}
            <CustomizeButton
              csName="submitBtn"
              customStyle={continueBtn ? { minWidth: "auto", width: customStyling ? "150px" : "auto", fontSize: "14px" } : { marginLeft: 1 }}
              variant="contained"
              disableElevation
              type="submit"
              label={continueBtn || t(t1, "CONTINUE")}
              name="priv_modal_Continue"
              handleClick={handleContinue}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
