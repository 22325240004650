import { createSlice } from "@reduxjs/toolkit";
import {
  uploadSuccess,
  addUpdateLabels,
  getAllData,
  getNewLanguage,
  publishType,
  getAllLabels,
  getFormLabels,
  getAllPageLabels,
  defaultORGID,
  enLanguage,
  publishSavedData,
  getAllLang,
  docFilesPageName,
  LandingPageName,
  searchInOrg,
  APIS,
  ERROR,
  SUCCESS,
  SEARCH_TYPE,
  SEARCH_KEY_CATEGORY,
  DISPLAY_SECTION,
  DOCUMENT_TYPE,
  CMS_COMPARE_REPORT,
  DEFAULT_ORG_ID,
  PAGE_FOR_STATUSES,
  PAGE_FOR_PASSPHRASE,
  PAGE_FOR_ROLES,
  PAGE_FOR_ROLE_TYPES,
  PAGE_FOR_DENIAL_REASON,
  PAGE_FOR_JURISDICTIONS,
  PAGE_FOR_COUNTRIES,
  PAGE_FOR_STATES,
  DEFAULT_UNKNOWN_PAGE_ORG_ID,
  isEmpty,
  DEFAULT_LANGUAGE_CODE,
} from "utils/constants";
import { clearPage } from "utils";
import fetcher, { fetcherGet } from "utils/fetcher";
import { prepareFAQDataForSaveOrPublish } from "pages/CMS/components/CMSCommonComponent/utils";
import { prepareDataForSaveOrPublish, prepareDataForSaveOrPublishForPageContentSection, decode, prepareDataForSaveOrPublishDynamicColumn } from "pages/CMS/components/CMSLanguage_V2/utils";
import { setModelMessage, setLoader } from "./commonSlice";
import store from "./store";

const initialState = {
  config: {},
  cmsConfig: {},
  cmsLanguagePage: {},
  cmsLandingConfig: {},
  cmsDocConfig: {},
  defaultFileUrl: {},
  defaultImageUrl: {},
  fileUploadUrl: {},
  s3UploadedImg: {},
  fileUploaded: {},
  cmsFileDeleted: {},
  fileUploadedState: 0,
  fileUrlUploaded: {},

  juriLangWithPageData: {},

  searchDataByPage: [],
  searchDataByKey: [],
  searchDataByDisplayedValue: [],

  searchPageDisplayedValueData: [],
  searchPageDisplayedIconsData: [],

  searchPageCardsData: [],
  searchPageSuccessToastData: [],
  searchPageErrorToastData: [],
  searchPageOtherData: [],
  searchPageOtherErrorData: [],

  editedCardsData: {},
  editedSuccessToastsData: {},
  editedErrorToastsData: {},
  editedOtherCardsData: {},
  editedOtherErrorCardsData: {},

  legalDocumentData: {},
  formsDocumentData: {},
  resourcesDocumentData: {},

  pageContentSectionAllAPIs: {
    loading: false,
    counter: 0,
  },
  pageContentHasUnpublishedChanges: false,
  editedContactCardsData: {},
  editedFAQCardsData: {},
  faqData: {},

  passPhraseRules: {},
  cmsStatuses: {},

  reportData: [],
  denialReasonList: [],
  jurisdictionList: [],
  stateList: [],
  jurisdictionHasUnpublishedChanges: false,
  preview: {
    keyValues: {},
  },
  previewPageData: {} 
};

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setConfigData: (state, action) => {
      state.config = action.payload;
    },
    setCMSConfigData: (state, action) => {
      state.cmsConfig = action.payload;
    },
    setCMSLandingConfigData: (state, action) => {
      state.cmsLandingConfig = action.payload;
    },
    setCMSDocConfigData: (state, action) => {
      state.cmsDocConfig = action.payload;
    },
    setCMSLanguagePageData: (state, action) => {
      state.cmsLanguagePage = action.payload;
    },
    setDefaultFileUrl: (state, action) => {
      state.defaultFileUrl = action.payload;
    },
    setDefaultImageUrl: (state, action) => {
      state.defaultImageUrl = action.payload;
    },
    setFileUploadUrl: (state, action) => {
      state.fileUploadUrl = action.payload;
    },
    setUploadedImgToServer: (state, action) => {
      state.s3UploadedImg = action.payload;
    },
    setFileUploaded: (state, action) => {
      state.fileUploaded = action.payload;
    },
    /* Conflict Resolution Candidate
        setInitialFileUploadedState: (state, action) => {
          state.fileUploadedState = 0;
        },
        setFileUploadedState: (state, action) => {
          state.fileUploadedState += 1;
        },
        setFileDeletedState: (state, action) => {
          state.fileUploadedState -= 1;
        },
     */
    setFileUploadedState: (state, action) => {
      state.fileUploadedState += 1;
    },
    setFileUrlUploaded: (state, action) => {
      state.fileUrlUploaded = action.payload;
    },
    setCmsFileDeleted: (state, action) => {
      state.cmsFileDeleted = action.payload;
    },
    setJurisdictionLanguagesWithPageData: (state, action) => {
      state.juriLangWithPageData = action.payload;
    },
    setSearchDataByPage: (state, action) => {
      state.searchDataByPage = action.payload;
    },
    setSearchDataByKey: (state, action) => {
      state.searchDataByKey = action.payload;
    },
    setSearchDataByDisplayedValue: (state, action) => {
      state.searchDataByDisplayedValue = action.payload;
    },
    setSearchPageDisplayedValueData: (state, action) => {
      state.searchPageDisplayedValueData = action.payload;
    },
    setSearchPageDisplayedIconsData: (state, action) => {
      state.searchPageDisplayedIconsData = action.payload;
    },
    setSearchPageCardsData: (state, action) => {
      state.searchPageCardsData = action.payload;
    },
    setSearchPageSuccessToastData: (state, action) => {
      state.searchPageSuccessToastData = action.payload;
    },
    setSearchPageErrorToastData: (state, action) => {
      state.searchPageErrorToastData = action.payload;
    },
    setSearchPageOtherData: (state, action) => {
      state.searchPageOtherData = action.payload;
    },
    setSearchPageOtherErrorcardData: (state, action) => {
      state.searchPageOtherErrorData = action.payload;
    },

    setEditedCardsData: (state, action) => {
      state.editedCardsData = action.payload;
    },
    setEditedSuccessToastsData: (state, action) => {
      state.editedSuccessToastsData = action.payload;
    },
    setEditedErrorToastsData: (state, action) => {
      state.editedErrorToastsData = action.payload;
    },
    setEditedOtherCardsData: (state, action) => {
      state.editedOtherCardsData = action.payload;
    },
    setEditedOtherErrorCardsData: (state, action) => {
      state.editedOtherErrorCardsData = action.payload;
    },
    setLegalDocumentsData: (state, action) => {
      state.legalDocumentData = action.payload;
    },
    setFormsDocumentsData: (state, action) => {
      state.formsDocumentData = action.payload;
    },
    setResourcesDocumentsData: (state, action) => {
      state.resourcesDocumentData = action.payload;
    },
    setPageContentSectionAllAPIs: (state, action) => {
      const counter = state.pageContentSectionAllAPIs.counter + action.payload.counter;
      state.pageContentSectionAllAPIs = {
        counter,
        loading: counter > 0,
      };
    },

    setPageContentHasUnpublishedChanges: (state, action) => {
      state.pageContentHasUnpublishedChanges = action.payload;
    },
    setEditedContactCardsData: (state, action) => {
      state.editedContactCardsData = action.payload;
    },
    setFAQData: (state, action) => {
      state.faqData = action.payload;
    },
    setEditedFAQCardsData: (state, action) => {
      state.editedFAQCardsData = action.payload;
    },
    setPassphraseRules: (state, action) => {
      state.passPhraseRules = action.payload;
    },
    setCMSStatuses: (state, action) => {
      state.cmsStatuses = action.payload;
    },
    setCountriesList: (state, action) => {
      state.countriesList = action.payload;
    },
    setReportData: (state, action) => {
      state.reportData = action.payload;
    },
    setRolesData: (state, action) => {
      state.rolesData = action.payload;
    },
    setRoleTypesData: (state, action) => {
      state.roleTypesData = action.payload;
    },
    setDenialReasonList: (state, action) => {
      state.denialReasonList = action.payload;
    },
    setStateList: (state, action) => {
      state.stateList = action.payload;
    },
    setJurisdictionList: (state, action) => {
      state.jurisdictionList = action.payload;
    },
    setJurisdictionHasUnpublishedChanges: (state, action) => {
      state.jurisdictionHasUnpublishedChanges = action.payload;
    },
    setPreview: (state, action) => {
      state.preview.keyValues = {
        ...state.preview.keyValues,
        [action.payload.key]: action.payload.value,
      };
    },
    clearPreview: (state, action) => {
      state.preview.keyValues = {};
    },
    getPreview: (state, action) => {
      return state.preview;
    },
    setPreviewPageData: (state, action) => {
      state.previewPageData = action.payload;
    }
  },
});

export default cmsSlice.reducer;
export const {
  setConfigData,
  setCMSConfigData,
  setCMSLandingConfigData,
  setDefaultFileUrl,
  setCMSLanguagePageData,
  setCMSDocConfigData,
  setFileUploadUrl,
  setUploadedImgToServer,
  setFileUploaded,
  /* Conflict Resolution Candidate
    setInitialFileUploadedState,
    setFileUploadedState,
    setFileDeletedState,
   */
  setFileUploadedState,
  setFileUrlUploaded,
  setCmsFileDeleted,
  setDefaultImageUrl,

  setJurisdictionLanguagesWithPageData,

  setSearchDataByPage,
  setSearchDataByKey,
  setSearchDataByDisplayedValue,

  setSearchPageDisplayedValueData,
  setSearchPageDisplayedIconsData,

  setSearchPageCardsData,
  setSearchPageSuccessToastData,
  setSearchPageErrorToastData,
  setSearchPageOtherData,
  setSearchPageOtherErrorcardData,

  setEditedCardsData,
  setEditedSuccessToastsData,
  setEditedErrorToastsData,
  setEditedOtherCardsData,
  setEditedOtherErrorCardsData,

  setLegalDocumentsData,
  setFormsDocumentsData,
  setResourcesDocumentsData,
  setPageContentSectionAllAPIs,
  setPageContentHasUnpublishedChanges,

  setFAQData,
  setEditedContactCardsData,
  setEditedFAQCardsData,

  setPassphraseRules,
  setCMSStatuses,
  setRolesData,
  setRoleTypesData,
  setCountriesList,
  setStateList,
  setJurisdictionList,
  setReportData,
  setDenialReasonList,
  setJurisdictionHasUnpublishedChanges,
  setPreview,
  clearPreview,
  getPreview,
  setPreviewPageData

} = cmsSlice.actions;

export function uploadFileToServer(apiData, fileObj, callback) {
  const formData = new FormData();
  const fieldsKeys = Object.keys(apiData?.data?.fields);
  fieldsKeys.forEach((fieldKey) => {
    formData.append(fieldKey, apiData?.data?.fields[`${fieldKey}`]);
  })
  formData.append("file", fileObj);
  return (dispatch) => {
    fetch(`${apiData?.data?.url}`, {
      method: 'POST',
      body: formData,
    })
      .then(res => res)
      .then((response) => {
        console.log('response = ', response);
        const data = {
          title: SUCCESS,
          message1: 'FILE_UPLOADED_SUCCESSFULLY',
          error: false,
        };
        dispatch(setModelMessage(data));
        if (callback) callback(apiData?.object_name, fileObj, true);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getURLAndUploadFile(params, formData, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`cms/v2/api/documents/signed?languageCode=${decode(params?.selectedLanguage, 1)}&orgId=${orgId}&documentType=${params.documentType}&urlType=putObject${params.fileUrl ? `&fileUrl=${params.fileUrl}` : ''}`, "GET", {}, false)
      .then((response) => {
        console.log('getURLToUploadFile response=', response);
        dispatch(uploadFileToServer(response?.data, formData, callback));
      })
      .catch((err) => {
        console.log('1err=', err)
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getJurisdictionLangugagesAndPageData(params) {
  const orgId = params.OrgID.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`cms/v2/api/languages?OrgID=${orgId}&Pages=true`, "GET")
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setJurisdictionLanguagesWithPageData({
          pages: response?.data?.pages,
          languages: response?.data?.languages
        }));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getSearchDataForPage(params, payload, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setPageContentSectionAllAPIs({
      counter: +1,
    }));
    fetcher(`cms/v2/api/keydetails?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}`, "POST", payload)
      .then((response) => {
        if (response?.data?.hasUnpublishedChanges && params?.displaySection === DISPLAY_SECTION.PAGE_CONTENT_SECTION) {
          dispatch(setPageContentHasUnpublishedChanges(true));
        }

        if (payload.searchType === SEARCH_TYPE.BY_PAGE) {
          // for page content accordion flow cases
          if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_REGULAR && params.displaySection === DISPLAY_SECTION.VALUES_SECTION) {
            dispatch(setSearchDataByPage(response?.data?.details || []));
          } else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_REGULAR && params.displaySection === DISPLAY_SECTION.PAGE_CONTENT_SECTION) {
            dispatch(setSearchPageDisplayedValueData(response?.data?.details || []));
          } else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_ALL_PAGE_DISPLAYED_ICON_KEYS) {
            dispatch(setSearchPageDisplayedIconsData(response?.data?.details || []));
          } else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_CARD) {
            dispatch(setSearchPageCardsData(response?.data?.details || []));
          } else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_TOAST) {
            dispatch(setSearchPageSuccessToastData(response?.data?.successToasts || []));
            dispatch(setSearchPageErrorToastData(response?.data?.failedToasts || []));
          } else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_DENIAL_REASON) {
            dispatch(setDenialReasonList(response?.data?.details || []));
          }
          else if (payload.keyType === SEARCH_KEY_CATEGORY.CATEGORY_OTHER) {
            dispatch(setSearchPageOtherData(response?.data?.otherCards || []));
            dispatch(setSearchPageOtherErrorcardData(response?.data?.errorCards || []));
          } else {
            // values accordion flow cases
            dispatch(setSearchDataByPage(response?.data?.details || []));
          }
        } else if (payload.searchType === SEARCH_TYPE.BY_KEY) {
          dispatch(setSearchDataByKey(response?.data?.details || []))
        } else if (payload.searchType === SEARCH_TYPE.BY_DISPLAYED_VALUE) {
          dispatch(setSearchDataByDisplayedValue(response?.data?.details || []))
        }
        if (callback) callback(response?.data?.hasUnpublishedChanges);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_E_TAG__EXCEPTION",
          error: true,
        };
        dispatch(setModelMessage(data));
      }).finally(() => {
        dispatch(setPageContentSectionAllAPIs({
          counter: -1,
        }));
      });
  };
}

// This is used to call API with edited key data to be saved or published
export function saveOrPublishEditedData(params, payload, editedKeyData, searchedData, callback, isPublish = false, isJSAUser = true, hasUnpublishedChanges = false) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setPageContentSectionAllAPIs({
      counter: +1,
    }));
    let keyDataToPublish;
    if (params.displaySection === DISPLAY_SECTION.VALUES_SECTION) {
      keyDataToPublish = prepareDataForSaveOrPublish(editedKeyData, isJSAUser, isPublish, searchedData, hasUnpublishedChanges);
    } else {
      keyDataToPublish = prepareDataForSaveOrPublishForPageContentSection(editedKeyData, isJSAUser, isPublish, searchedData, hasUnpublishedChanges);
    }

    const payloadToSend = {
      details: keyDataToPublish,
    }
    fetcher(`cms/v2/api/keydetails?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}${isPublish ? '&isPublish=true' : ''}`, "PUT", payloadToSend)
      .then((response) => {
        if (params.displaySection === DISPLAY_SECTION.PAGE_CONTENT_SECTION) {
          dispatch(getSearchDataForPage(params,
            {
              searchType: SEARCH_TYPE.BY_PAGE,
              searchInput: payload.searchInput,
              keyType: SEARCH_KEY_CATEGORY.CATEGORY_REGULAR
            },
            callback))
          dispatch(getSearchDataForPage(params,
            {
              searchType: SEARCH_TYPE.BY_PAGE,
              searchInput: payload.searchInput,
              keyType: SEARCH_KEY_CATEGORY.CATEGORY_ALL_PAGE_DISPLAYED_ICON_KEYS
            },
            callback))
          dispatch(getSearchDataForPage(params,
            {
              searchType: SEARCH_TYPE.BY_PAGE,
              searchInput: payload.searchInput,
              keyType: SEARCH_KEY_CATEGORY.CATEGORY_CARD
            },
            callback))
          dispatch(getSearchDataForPage(params,
              {
                searchType: SEARCH_TYPE.BY_PAGE,
                searchInput: PAGE_FOR_DENIAL_REASON,
                keyType: SEARCH_KEY_CATEGORY.CATEGORY_DENIAL_REASON
              },
              callback))
          dispatch(getSearchDataForPage(params,
            {
              searchType: SEARCH_TYPE.BY_PAGE,
              searchInput: payload.searchInput,
              keyType: SEARCH_KEY_CATEGORY.CATEGORY_TOAST
            },
            callback))
          dispatch(getSearchDataForPage(params,
            {
              searchType: SEARCH_TYPE.BY_PAGE,
              searchInput: payload.searchInput,
              keyType: SEARCH_KEY_CATEGORY.CATEGORY_OTHER
            },
            callback))
        } else {
          dispatch(getSearchDataForPage(params, payload, callback));
        }
        const data = {
          title: SUCCESS,
          message1: isPublish ? "SUCCESS_CMS_PUBLISH_MSG" : "SUCCESS_CMS_SAVE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: isPublish ? "ERROR_CMS_PUBLISH_MSG" : "ERROR_CMS_SAVE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      }).finally(() => {
        dispatch(setPageContentSectionAllAPIs({
          counter: -1,
        }));
      });
  };
}

export function getDataForSearchAndReplace(params, payload, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`cms/v2/api/keydetails?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}`, "POST", payload)
      .then((response) => {
        if (callback) callback(response?.data?.details || []);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_E_TAG__EXCEPTION",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function applyReplaceAllSearchedValues(params, payloadValues, keyInfoToReplaceWith, callback, isJSAUser = true) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    // This will prepare data to publish only as by default publish is true in search n replace case
    const finalPayload = {
      ...payloadValues,
      details: prepareDataForSaveOrPublish({}, isJSAUser, true, keyInfoToReplaceWith, true, true)
    }
    fetcher(`cms/v2/api/searchreplace?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}&isPublish=true`, "PUT", finalPayload)
      .then((response) => {
        console.log('response=', response);
        if (callback) callback();
        const data = {
          title: SUCCESS,
          message1: "SUCCESS_REPLACE_KEY_VALUES",
          error: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_E_TAG__EXCEPTION",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getCMSDocsList(params, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`cms/v2/api/documents?languageCode=${decode(params?.selectedLanguage, 1)}&orgId=${orgId}&documentType=${params.documentType}`, "GET")
      .then((response) => {
        console.log('response=', response);
        switch (params.documentType) {
          case DOCUMENT_TYPE.LEGAL:
            dispatch(setLegalDocumentsData(response?.data));
            break;
          case DOCUMENT_TYPE.FORMS:
            dispatch(setFormsDocumentsData(response?.data));
            break;
          case DOCUMENT_TYPE.RESOURCES:
            dispatch(setResourcesDocumentsData(response?.data));
            break;
          default:
            break;
        }
        if (callback) callback(response?.data);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_E_TAG__EXCEPTION",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function saveOrPublishDocumentData(documentsPayloadData, callback, isPublish = false) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher('cms/v2/api/documents', "POST", documentsPayloadData)
      .then((response) => {
        switch (documentsPayloadData.documentType) {
          case DOCUMENT_TYPE.LEGAL:
            dispatch(setLegalDocumentsData(response?.data));
            break;
          case DOCUMENT_TYPE.FORMS:
            dispatch(setFormsDocumentsData(response?.data));
            break;
          case DOCUMENT_TYPE.RESOURCES:
            dispatch(setResourcesDocumentsData(response?.data));
            break;
          default:
            break;
        }
        const data = {
          title: SUCCESS,
          message1: isPublish ? "SUCCESS_DOC_PUBLISH_MSG" : "SUCCESS_DOC_SAVE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
        if (callback) callback(response?.data);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: isPublish ? "ERROR_CMS_PUBLISH_MSG" : "ERROR_CMS_SAVE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function deleteCMSDocument(params, callback) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`cms/v2/api/documents?documentId=${params?.documentId}`, "DELETE")
      .then(() => {
        const data = {
          title: SUCCESS,
          message1: "SUCCESS_DOC_DELETE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
        callback();
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "ERROR_DOC_DELETE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function postCloneOrAddLanguage(payload, callback) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`cms/v2/api/language`, "POST", payload)
      .then((response) => {
        console.log('response=', response);
        dispatch(getJurisdictionLangugages({
          OrgID: DEFAULT_ORG_ID
        },
        callback));
        const data = {
          title: SUCCESS,
          message1: "SUCCESS_ADD_LANGUAGE",
          error: false,
        };
        dispatch(setModelMessage(data));
       
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_ADD_LANGUAGE",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}


export function getFAQData(params, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    fetcher(`cms/v2/api/common?LanguageCode=${decode(params?.selectedLanguage, 1)}&OrgID=${orgId}`, "GET")
      .then((response) => {
        dispatch(setFAQData(response?.data));
        dispatch(setLoader(false))
        if (callback) callback(response?.data?.hasUnpublishedChanges);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_FAQ_FETCH",
          error: true,
        };
        dispatch(setModelMessage(data));
      })
  };
}

// This is used to call API with edited FAQ data to be saved or published
export function saveOrPublishFAQData(params, editedKeyData, faqFetchedData, callback, isPublish = false, isJSAUser = true, hasUnpublishedChanges = false) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    const keyDataToPublish = prepareFAQDataForSaveOrPublish(editedKeyData, isJSAUser, isPublish, faqFetchedData, hasUnpublishedChanges);
    const payloadToSend = {
      details: keyDataToPublish,
    }
    fetcher(`cms/v2/api/common?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}${isPublish ? '&isPublish=true' : ''}`, "PUT", payloadToSend)
      .then((response) => {
        dispatch(getFAQData(params, callback))
        const data = {
          title: SUCCESS,
          message1: isPublish ? "SUCCESS_FAQ_PUBLISH_MSG" : "SUCCESS_FAQ_SAVE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: isPublish ? "ERROR_FAQ_PUBLISH_MSG" : "ERROR_FAQ_SAVE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function deleteFAQItem(params, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`cms/v2/api/common?itemId=${params?.itemId}&OrgID=${orgId}&LanguageCode=${decode(params.language, 1)}`, "DELETE")
      .then(() => {
        const data = {
          title: SUCCESS,
          message1: "SUCCESS_FAQ_ITEM_DELETE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
        callback();
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "ERROR_FAQ_ITEM_DELETE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getFixedOrInputListsDataForPage(params, payload, callback) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`cms/v2/api/keydetails?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}`, "POST", payload)
      .then((response) => {
        switch(payload.searchInput) {
          case PAGE_FOR_STATUSES:
            dispatch(setCMSStatuses(response?.data?.details || []))
            break;
          case PAGE_FOR_PASSPHRASE:
            dispatch(setPassphraseRules(response?.data?.details || []))
            break;
          case PAGE_FOR_ROLES:
            dispatch(setRolesData(response?.data?.details || []))
            break;
          case PAGE_FOR_ROLE_TYPES:
            dispatch(setRoleTypesData(response?.data?.details || []))
            break;
          case PAGE_FOR_JURISDICTIONS:
            dispatch(setJurisdictionHasUnpublishedChanges(response?.data?.hasUnpublishedChanges));
            dispatch(setJurisdictionList(response?.data?.details || []))
            break;
          case PAGE_FOR_COUNTRIES:
            dispatch(setCountriesList(response?.data?.details || []))
            break;
          case PAGE_FOR_STATES:
            dispatch(setStateList(response?.data?.details || []))
            break;
          default:
            break;
        }
        if (callback) callback();
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_E_TAG__EXCEPTION",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function saveOrPublishEditedFixedListsData(params, payload, editedKeyData, fixedListsData, callback, isPublish = false, isJSAUser = true, hasUnpublishedChanges = false) {
  const orgId = params.currentOrgId.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true));
    let keyDataToPublish = [];
    switch(payload.searchInput) {
      case PAGE_FOR_JURISDICTIONS:
      case PAGE_FOR_COUNTRIES:
      case PAGE_FOR_STATES:
        keyDataToPublish = prepareDataForSaveOrPublishDynamicColumn(editedKeyData, isJSAUser, isPublish, fixedListsData, hasUnpublishedChanges);
        break;
      default:
        keyDataToPublish = prepareDataForSaveOrPublish(editedKeyData, isJSAUser, isPublish, fixedListsData, hasUnpublishedChanges);
        break;
    }
    const payloadToSend = {
      details: keyDataToPublish,
    }
    fetcher(`cms/v2/api/keydetails?LanguageCode=${decode(params.selectedLanguage, 1)}&OrgID=${orgId}${isPublish ? '&isPublish=true' : ''}`, "PUT", payloadToSend)
      .then((response) => {
        dispatch(getFixedOrInputListsDataForPage(params,payload,callback));
        const data = {
          title: SUCCESS,
          message1: isPublish ? "SUCCESS_CMS_PUBLISH_MSG" : "SUCCESS_CMS_SAVE_MSG",
          error: false,
        };
        dispatch(setModelMessage(data));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: isPublish ? "ERROR_CMS_PUBLISH_MSG" : "ERROR_CMS_SAVE_MSG",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function getJurisdictionLangugages(params, callback) {
  const orgId = params.OrgID.replace("#", "%23");
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`cms/v2/api/languages?OrgID=${orgId}`, "GET")
      .then((response) => {
        dispatch(setJurisdictionLanguagesWithPageData({
          pages: store?.getState()?.cms?.juriLangWithPageData?.pages || [],
          languages: response?.data?.languages
        }));
        if (callback) callback(response?.data?.languages);
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
      });
  };
}

export function fetchGeneratedReports(params, payload, callback) {
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`reports/api/jurisdictions/${params?.orgMainInd}/report-list`, "POST", payload)
      .then((response) => {
        dispatch(setReportData(response?.data));
        // Need later
        // if (!params?.hideSuccessMsg) {
        //   const data = {
        //     title: SUCCESS,
        //     message1: "SUCCESS_FETCH_REPORT",
        //     error: false,
        //   };
        //   dispatch(setModelMessage(data));
        // }
        if (callback) callback()
        else dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_REPORT_FETCH",
          error: true,
        };
        dispatch(setModelMessage(data));
      })
  };
}

export function generateReportsForCMS(params, payload, callback) {
  return (dispatch) => {
    dispatch(setLoader(true))
    fetcher(`reports/api/jurisdictions/${params?.orgMainInd}/report-generate`, "POST", payload)
      .then((response) => {
        dispatch(fetchGeneratedReports(params, {
          reportName: CMS_COMPARE_REPORT,
        }, callback));
      })
      .catch((err) => {
        dispatch(setLoader(false))
        const data = {
          title: ERROR,
          message1: "MSG_ERR_REPORT_FETCH",
          error: true,
        };
        dispatch(setModelMessage(data));
      })
  };
}

export const getLinkForDownload = (params, downloadLinkId) => (dispatch) => {
  return fetcherGet(`reports/api/jurisdictions/${params?.orgMainInd}/report-download/${downloadLinkId}`).finally(() => {
    dispatch(setLoader(false));
  });
};

// This will fetch updated cms pages based on timestamp given
export function fetchUpdatedCMSPages() {
  const timeStamp = localStorage.getItem("CMSTimeStamp") || 1; // 1 for fetching all pages
  return () => {
    fetcher(`cms/v2/api/requestedPageList?timeStamp=${timeStamp}`, "GET")
      .then((response) => {
        if (response?.data?.timeStamp) {
          localStorage.setItem("CMSTimeStamp", response?.data?.timeStamp);
          if (response?.data?.pages?.length > 0) {
            response?.data?.pages?.forEach(pageName => clearPage(pageName));
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  };
}

// This will fetch updated cms pages based on timestamp given
export function getCMSTnC() {
  const languageCode = localStorage.getItem("languageCode") || DEFAULT_LANGUAGE_CODE;
  const orgID = !isEmpty(localStorage.getItem("jurisdiction"))
  ? localStorage.getItem("jurisdiction")?.split("#")?.pop()
  : DEFAULT_UNKNOWN_PAGE_ORG_ID;
  return () => {
    fetcher(`cms/v2/public/api/documents?orgId=ORG%23${orgID}&languageCode=${languageCode}&documentType=legal&keys=TERMS_AND_CONDITIONS_TEXT`, "GET")
      .then()
      .catch()
  };
}

export function getPageDataForPreview(page, language, callback) {
  const orgID = !isEmpty(localStorage.getItem("jurisdiction"))
        ? localStorage.getItem("jurisdiction")?.split("#")?.pop()
        : DEFAULT_UNKNOWN_PAGE_ORG_ID;
  return (dispatch) => {
    fetcher(`cms/v2/api/details?OrgID=ORG%23${orgID}&Page=${encodeURIComponent(page)}&LanguageCode=${decode(language, 1)}`, "GET")
      .then((response) => {
        dispatch(setPreviewPageData(response));
        callback();
      })
      .catch((err) => {
        const data = {
          title: ERROR,
          message1: err ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true,
        };
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      })
  };
}