import { createSlice } from "@reduxjs/toolkit";
import parse from "html-react-parser";
import { removeAccessToken } from "store/authSlice";
import { APIS, ChangeJuriRequest, ERROR, SUCCESS, getCMSKeySnakeCase, parseOptions } from "utils/constants";
import { deleteCookie } from "utils/deleteCookie";
import fetcher, { fetcherPatch } from "utils/fetcher";
import logger from "utils/logger";

import sleep from "utils/sleep";
import { setIsLocked, setLoader, setModelMessage } from "./commonSlice";
import { setJurisdiction, setSelectedJurisdictionId } from "./jurisdictionSlice";
import { approveUserRegistration } from "./userSlice";

const initialState = {
  userDetails: {},
  referenceCode: '',
  securityQuestions: {},
}

const userHomeSlice = createSlice({
  name: "userHome",
  initialState,
  reducers: {
    setUserRegDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setSecurityQuestions: (state, action) => {
      state.securityQuestions = action.payload;
    },
    resetUserState: () => initialState,
    setReferenceCode: (state, action) => {
      state.referenceCode = action.payload;
    },
  },
});

export const { setUserRegDetails, setSecurityQuestions, resetUserState, setReferenceCode } = userHomeSlice.actions;
export default userHomeSlice.reducer;

export function getUserRegDetails(params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setUserRegDetails({
      loading: true
    }));
    fetcher(`registration/registrationstatus?UID=U%23${params.UID}&UType=User&LanguageCode=${localStorage.getItem('languageCode')}`, "GET", {}, false)
      .then((response) => {
        dispatch(setUserRegDetails(response.Items[0]));
        dispatch(setJurisdiction(response?.Items?.[0]?.OrgID))

          fetcher(APIS.GET_JURISDICTIONS, "GET", {}, false)
          .then(({ data }) => {
          dispatch(setSelectedJurisdictionId(data.id))
          })
          .catch((error) => {
            logger({ error, name: "getJurisdictions" });
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(setLoader(false));
        dispatch(setUserRegDetails({
          loading: false
        }));
      });
  };
}

export function getReferenceCode(params) {
  return (dispatch) => {
    fetcher(`publicregistration/registrationstatus?UID=U%23${params.UID}&UType=User&LanguageCode=${localStorage.getItem('languageCode')}`, "GET", {}, false)
      .then(async (response) => {
        dispatch(setReferenceCode(response));
        if (params.linkId) {
          await sleep(2000);
          await dispatch(updateInitialUserDatails({ linkId: params.linkId, referenceCode: response?.ReferenceCode ,uId: params.UID }));
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(setLoader(false));
      });
  };
}

export function getSecurityQuestions(UID) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`publicregistration/securityquestions?UID=U%23${UID}`, "GET", {}, false)
      .then((response) => {
        // console.log(response);
        dispatch(setSecurityQuestions(response));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));

        const data = {
          title: ERROR,
          /* Conflict Resolution Candidate
                    message1: (err) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
           */
          message1: (err) ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
      });
  };
}

export function verifyResetPassphraseLink(UID, params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`public/forgotpassphrase/verify/U%23${UID}`, "POST", params, false)
      .then(() => {
        dispatch(getSecurityQuestions(UID));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: ERROR,
          message1: err?.response?.data?.message || err?.message || "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
      });
  };
}

export function resetSecurityQuestions(UID, params, onSuccessCallback) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: "Page_ResetPass&SecurityQuestion", componentName: 'updatedSecurityQuestion' };
    fetcher(`registration/securityquestions/U%23${UID}/update`, "PUT", params, false, pageDetails)
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
        if (onSuccessCallback) onSuccessCallback();
        deleteCookie("FirstTimeLogin");
      })
      .catch((err) => {

        const data = {
          title: ERROR,
          /* Conflict Resolution Candidate
                    message1: (err) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
           */
          message1: (err) ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setLoader(false));
        dispatch(setModelMessage(data));
      });
  };
}

export function verifySecurityQuestions(UID, params) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`publicregistration/securityquestions/U%23${UID}/verifyanswer`, "POST", params, false)
      .then((response) => {
        // console.log(response);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        dispatch(setIsLocked(response?.isLocked));  // RPM-2119
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));

      })
      .catch((err) => {

        const data = {
          title: ERROR,
          message1: err?.response?.data?.message?.includes("##") ? parse(err?.response?.data?.message?.split("##")[1], parseOptions) : (err?.response?.data?.message || "MSG_ERR_INTERNAL_SERVER"),
          error: true,
          isDefaultDisplay: err?.response?.data?.message?.includes("##")
        }
        dispatch(setIsLocked(err?.response?.data?.isLocked));  // RPM-2119
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };

}

export function cancelUserRegistration(UID, changestatus, params, signOut, navigate) {
  return (dispatch) => {
    dispatch(setLoader(true))
    const pageDetails = { pageName: "Page_UserProfile", componentName: 'initiateCancelRegistration' };
    fetcher(`registration/registrationstatus/U%23${UID}/${changestatus}`, "PUT", params, false, pageDetails)
      .then((response) => {
        // console.log(response);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
          dispatch(setLoader(false))
          dispatch(setModelMessage(data));
        // Delay signOut() by 5 seconds
          setTimeout(() => {
              signOut();
              removeAccessToken();
              navigate("/");
          }, 5000);
      })
      .catch((err) => {

        const data = {
          title: ERROR,
          /* Conflict Resolution Candidate
                    message1: (err) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
           */
          message1: (err) ? err.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setLoader(false))
        dispatch(setModelMessage(data));
      });
  };
}

export function closeUserAccount(params, signOut, navigate, flpData) {
  return (dispatch) => {
    dispatch(setLoader(true));
    fetcher(`actionrequest/user-history`, "PUT", params, false, flpData)
      .then((response) => {
        dispatch(setLoader(false));
        // console.log('res',response);
        const data = {
          title: SUCCESS,
          message1: response.message,
          error: false
        }
        dispatch(setLoader(false))
        dispatch(setModelMessage(data));
        signOut();
        removeAccessToken();
        navigate("/");
      })
      .catch((err) => {
        dispatch(setLoader(false));

        const data = {
          title: ERROR,
          /* Conflict Resolution Candidate
                    message1: (err) ? err?.response?.data?.message : 'MSG_ERR_INTERNAL_SERVER',
           */
          message1: (err) ? err.message : 'MSG_ERR_INTERNAL_SERVER',
          error: true
        }
        dispatch(setModelMessage(data));
      });
  };
}

/* Conflict Resolution Candidate
export function updateUserProfile(UID, params, navigate = null, navUrl = '',isShowModal = true) {
 */
export function updateUserProfile(UID, params, navigate = null, navUrl = '') {
  params.MailCountry = getCMSKeySnakeCase(params.MailCountry)
  params.MailState = getCMSKeySnakeCase(params.MailState)
  params.ResCountry  = getCMSKeySnakeCase(params.ResCountry)
  params.ResState = getCMSKeySnakeCase(params.ResState)
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: "Page_UserDetail", componentName: 'registrationStatusPut' };
    fetcher(`registration/registrationstatus?UID=U%23${UID}&UType=User`, "PUT", params, false, pageDetails)
      .then((response) => {
        // console.log(params, "params");
        if (params?.object_url) {
          dispatch(setUserRegDetails({}));
          dispatch(setLoader(false));
        } else {
          if (params?.IsChangeRequest) {
            dispatch(approveUserRegistration(UID, ChangeJuriRequest, params?.changePayload, navigate, navUrl));
          }
          dispatch(setUserRegDetails(response?.Items?.[0]));
        }
        const data = {
          title: SUCCESS,
          message1: response?.message,
          error: false
        }
        if (navigate && !params?.IsChangeRequest) {
          dispatch(getUserRegDetails({ UID }));
          navigate(navUrl);
        }
        /* Conflict Resolution Candidate
                if ((response?.message && !params?.IsChangeRequest) || isShowModal) {
         */
        if (response?.message && !params?.IsChangeRequest) {
          dispatch(setModelMessage(data));
          dispatch(setLoader(false));
        }

        // dispatch(setLoader(false));

      })
      .catch((err) => {

        const data = {
          title: ERROR,
          message1: (err.response) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
}

export function updateInitialUserDatails(payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.ONBOARD_JURISDICTION}/jurisdictionOnboarding/updateUserDetails`, {
      ...payload,
    }).catch((err)=>{
     console.error(err)
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// Update terms and condition with terms of use acceptance for logged in user once after other sequentially
export function updateTermsAndConditionInfo(UID, payloadArray, navigate = null, navUrl = '', callback = null) {
  return (dispatch) => {
    dispatch(setLoader(true));
    const pageDetails = { pageName: "Page_UserDetail", componentName: 'registrationStatusPut' };
    fetcher(`registration/registrationstatus?UID=U%23${UID}&UType=User`, "PUT", payloadArray[0], false, pageDetails)
      .then((response) => {
          const data = {
            title: SUCCESS,
            message1: response?.message,
            error: false
          }
          dispatch(setUserRegDetails({}));
          if (payloadArray?.length === 2) {
            // array values 0 and 1 are hardcoded as there will always be max 2 usecases
            // i.e 1 for terms and condition and 2nd for terms of use to display in modal
            const pageDetails = { pageName: "Page_UserDetail", componentName: 'registrationStatusPut' };
            fetcher(`registration/registrationstatus?UID=U%23${UID}&UType=User`, "PUT", payloadArray[1], false, pageDetails)
              .then(() => {
                dispatch(getUserRegDetails({ UID }));
                navigate(navUrl);
                dispatch(setModelMessage(data));
                dispatch(setLoader(false));
                if (callback) {
                  callback(false);
                }
              })
              .catch((err) => {
                const dta = {
                  title: ERROR,
                  message1: (err.response) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
                  error: true
                }
                dispatch(setModelMessage(dta));
                dispatch(setLoader(false));
              });
          } else {
            dispatch(getUserRegDetails({ UID }));
            navigate(navUrl);
            dispatch(setModelMessage(data));
            dispatch(setLoader(false));
            if (callback) {
              callback(false);
            }
          }
      })
      .catch((err) => {
        const data = {
          title: ERROR,
          message1: (err.response) ? err?.response?.data?.message : "MSG_ERR_INTERNAL_SERVER",
          error: true
        }
        dispatch(setModelMessage(data));
        dispatch(setLoader(false));
      });
  };
} 