const COMMON = {
    "ACADEMIC": "Academic",
    "ACCEPTED": "Accepted",
    "ACCOUNTS": "ACCOUNTS",
    "ACCOUNT_APPLICATION_CHECKLIST_FORM": "Account Application Checklist",
    "ACCOUNT_APPLICATION_WITH_ATTESTATIONS_FORM": "Account Application with Attestations Form",
    "ACCOUNT_BALANCE": "Account Balance",
    "ACCOUNT_NUMBER": "Account Number",
    "ACCOUNT_STATUS": "Account Status",
    "ACCOUNT_TYPE": "Account Type",
    "ACCOUNT_VIEWING_AGENT_AVA": "Account Viewing Agent (AVA)",
    "ACCOUNT_VIEWING_AGENT": "Account Viewing Agent",
    "ACCOUNT_VIEWING_AGENT_ONCE_EAA_IS_APPROVED": "Account Viewing Agent (once EAA is approved)",
    "ACTIONS": "ACTIONS",
    "ACTIVE": "Active",
    "ADD": "ADD",
    "ADDED_FACILITIES": "Added Facilities",
    "ADDITIONAL_CONTACT_INFORMATION": "Additional Contact Information",
    "ADDRESS_LINE_1": "Address Line 1",
    "ADDRESS_LINE_1_REQUIRED": "Address Line 1 Required",
    "ADDRESS_LINE_2": "Address Line 2",
    "ADD_ADDITIONAL_REPRESENTATIVES": "Add Additional Representatives",
    "ADD_FACILITIES": "Add Facilities",
    "ADD_FACILITY": "Add Facility",
    "ADD_FACILITY_FACILITY_INFORMATION_FOR_FACILITY_MANAGEMENT": "Add Facility-Facility Information for Facility Management",
    "ADD_MORE": "ADD MORE",
    "ADD_REPRESENTATIVES": "Add Representatives",
    "ADD_SELF_AS_A_REPRESENTATIVES": "Add Self as a Representatives",
    "AFGHANISTAN": "Afghanistan",
    "ALABAMA": "Alabama",
    "ALAND_ISLANDS": "Aland Islands",
    "ALASKA": "Alaska",
    "ALASKA_CAPITAL": "ALASKA",
    "ALBANIA": "Albania",
    "ALBERTA": "Alberta",
    "ALGERIA": "Algeria",
    "ALLOW_ACCESS": "Allow Access",
    "ALLOW_THIS_USER_TO_EDIT_ENTITY_PROFILE": "Allow this user to edit Entity Profile",
    "ALL_OTHER_STATES_AND_PROVINCES": "All other States and Provinces",
    "ALTERNATE_ACCOUNT_REPRESENTATIVE": "Alternate Account Representative",
    "ALTERNATE_CONTACT_PERSON": "Alternate Contact Person",
    "AMERICAN_SAMOA": "American Samoa",
    "ANDORRA": "Andorra",
    "ANGOLA": "Angola",
    "ANGUILLA": "Anguilla",
    "ANNUAL_ALLOCATION_HOLDING": "(c) Annual Allocation Holding",
    "ANNUAL_ALLOCATION_HOLDING_ACCOUNT": "(g) Annual Allocation Holding Account",
    "ANTARCTICA": "Antarctica",
    "ANTIGUA_AND_BARBUDA": "Antigua And Barbuda",
    "APPLICATION_APPROVED_CAPITAL": "APPLICATION_APPROVED",
    "APPLICATION_CANCELLED_BY_JURISDICTION_CAPITAL": "APPLICATION_CANCELLED_BY_JURISDICTION",
    "APPLICATION_CANCELLED_BY_USER_CAPITAL": "APPLICATION_CANCELLED_BY_USER",
    "APPLICATION_DENIED_CAPITAL": "APPLICATION_DENIED",
    "APPLICATION_IN_PROGRESS": "Application in Progress",
    "APPLICATION_IN_PROGRESS_CAPITAL": "APPLICATION_IN_PROGRESS",
    "APPLICATION_IS_REMOVED_FROM_EDIT_MODE": "Application Is Removed From Edit Mode",
    "APPLICATION_IS_SET_TO_EDIT_MODE": "Application Is Set To Edit Mode",
    "APPLICATION_PENDING_CAPITAL": "APPLICATION_PENDING",
    "ARGENTINA": "Argentina",
    "ARIZONA": "Arizona",
    "ARKANSAS": "Arkansas",
    "ARMENIA": "Armenia",
    "ARUBA": "Aruba",
    "AUCTION_AND_RESERVE_SALE_INFORMATION": "Auction and Reserve Sale Information",
    "AUCTION_AND_RESERVE_SALE_INFORMATION_NO": "No, do not share my entity\u2019s information with the Auction and Reserve Sale Administrator and the Financial Service Administrator for the purpose of facilitating participation in an upcoming auction or reserce sale.",
    "AUCTION_AND_RESERVE_SALE_INFORMATION_YES": "Yes, I agree to share my entity\u2019s information with the Auction and Reserve Sale Administrator and the Financial Service Administrator for the purpose of facilitating participation in an upcoming auction or reserve sale.",
    "AUCTION_PARTICIPATION": "Auction Participation",
    "AUCTION_PARTICIPATION_REQUIRED": "Auction Participation Required",
    "AUSTRALIA": "Australia",
    "AUSTRIA": "Austria",
    "AUTHORIZED_PROJECT_DESIGNEE": "Authorized Project Designee",
    "AVAILABLE_ACCOUNT_BALANCE": "Available Account Balance",
    "AZERBAIJAN": "Azerbaijan",
    "A_CONFIRMATION_HAS_BEEN_EMAILED_TO_THE_ADDRESS_PROVIDED": "A confirmation has been emailed to the address provided.",
    "BAHRAIN": "Bahrain",
    "BAKER_ISLAND": "Baker Island",
    "BANGLADESH": "Bangladesh",
    "BARBADOS": "Barbados",
    "BEGIN_ENTITY_ACCOUNT_APPLICATION": "Begin Entity Account Application",
    "BELARUS": "Belarus",
    "BELGIUM": "Belgium",
    "BELIZE": "Belize",
    "BENIN": "Benin",
    "BERMUDA": "Bermuda",
    "BHUTAN": "Bhutan",
    "BOLIVIA": "Bolivia",
    "BONAIRE_SINT_EUSTATIUS_AND_SABA": "Bonaire, Sint Eustatius and Saba",
    "BOSNIA_AND_HERZEGOVINA": "Bosnia and Herzegovina",
    "BOTSWANA": "Botswana",
    "BOUVET_ISLAND": "Bouvet Island",
    "BRAZIL": "Brazil",
    "BRITISH_COLUMBIA": "British Columbia",
    "BRITISH_INDIAN_OCEAN_TERRITORY": "British Indian Ocean Territory",
    "BROKERAGE_INVESTMENT_FIRM": "Brokerage/Investment Firm",
    "BRUNEI": "Brunei",
    "BULGARIA": "Bulgaria",
    "BURKINA_FASO": "Burkina Faso",
    "BURUNDI": "Burundi",
    "BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY": "Business Number (assigned by the Canada Revenue Agency)",
    "BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY_REQUIRED": "Business Number (assigned by the Canada Revenue Agency) Required",
    "CALIFORNIA": "California",
    "CAMBODIA": "Cambodia",
    "CAMEROON": "Cameroon",
    "COUNTY_CANADA": "Canada",
    "CANCEL": "CANCEL",
    "CANCELLED": "Cancelled",
    "CANCEL_APPLICATION": "CANCEL APPLICATION",
    "CANCEL_ENTITY_ACCOUNT_APPLICATION": "Clicking \"Cancel Entity Account Application\" will erase all entered data.",
    "CANCEL_ENTITY_APPLICATION_CAPTITAL": "CANCEL ENTITY APPLICATION",
    "CANCEL_ENTITY_REVISON_WARNING_CONTENT": "Clicking \"Exit Application\" will save all completed sections.",
    "CANCEL_ENTITY_REVISON_WARNING_CONTENT1": "You can return to this Entity Account Application from the \"Account Applications\" section on your home page.",
    "CANCEL_ENTITY_REVISON_WARNING_CONTENT2": "Click \"Continue\" to exit the application and return to your homepage or \"Cancel\" to exit this message.",
    "CANCEL_WARNING_CONTENT1": "Clicking 'Cancel' will erase all entered data.",
    "CANCEL_WARNING_CONTENT2": "The Entity Account Application will need to be restarted.",
    "CANCEL_WARNING_CONTENT3": "Click 'Continue' to return to the home page or exit this error message by clicking 'Cancel'.",
    "CANCEL_WARNING_CONTENT5": "Clicking 'Cancel Application' will delete your Entity Registration Application.",
    "CAPE_VERDE": "Cape Verde",
    "CAYMAN_ISLANDS": "Cayman Islands",
    "CENTRAL_AFRICAN_REPUBLIC": "Central African Republic",
    "CHAD": "Chad",
    "CHANGE_DATE": "Change Date (yyyy/mm/dd)",
    "CHANGE_ENTITY_TYPE_WARNING": "This action will erase all entered data.",
    "CHARACTERS": "Characters",
    "CHARITABLE_TRUST": "Charitable Trust",
    "CHECK_IF_SAME_AS_PHYSICAL_ADDRESS_CHECKBOX": "Check if same as Physical Address",
    "CHILE": "Chile",
    "CHINA": "China",
    "CHRISTMAS_ISLAND": "Christmas Island",
    "CITY": "City",
    "CITY_REQUIRED": "City Required",
    "CLEARING_SERVICE_PROVIDER": "Clearing Service Provider",
    "CLOSED": "Closed",
    "CLOSED_CAPITAL": "CLOSED",
    "CLOSE_ACCOUNT": "CLOSE ACCOUNT",
    "COCOS_KEELING_ISLANDS": "Cocos (Keeling) Islands",
    "COLOMBIA": "Colombia",
    "COLORADO": "Colorado",
    "COMMENT": "Comment",
    "COMMERCIAL_USE": "Commercial Use",
    "COMMERICAL_USE": "Commercial Use",
    "COMMERTIAL_USE": "Commertial Use",
    "COMOROS": "Comoros",
    "COMPANY": "Company",
    "COMPLETE": "Complete",
    "COMPLIANCE_ACCOUNT": "Compliance Account",
    "COMPLIANCE_ACCOUNT_DETAILS": "Compliance Account Details",
    "CONGO": "Congo",
    "CONNECTICUT": "Connecticut",
    "DENY_CONFIRMATION_MESSAGE": "Clicking 'Cancel' will delete the Proposed Wiring Instructions.",
    "DENY_CONFIRMATION_MESSAGE_2": "Fedwire Wiring Instructions will be blank.",
    "DENY_CONFIRMATION_MESSAGE_3": "Click 'Deny' to delete the Proposed Wiring Instructions or 'Cancel' to exit this message.",
    "CONTACT_EMAIL_ADDRESS": "Contact Email Address",
    "CONTACT_FIRST_NAME": "Contact First Name",
    "CONTACT_INFORMATION": "Contact Information",
    "CONTACT_INFORMATION_MAILING_ADDRESS": "Contact Information - Mailing Address",
    "CONTACT_INFORMATION_PHYSICAL_ADDRESS": "Contact Information - Physical Address",
    "CONTACT_JOB_TITLE": "Contact Job Title",
    "CONTACT_LAST_NAME": "Contact Last Name",
    "CONTACT_MOBILE_PHONE": "Contact Mobile Phone",
    "CONTACT_MOBILE_PHONE_NUMBER": "Contact Mobile Phone Number",
    "CONTACT_PHONE_NUMBER": "Contact Phone Number",
    "CONTACT_POSITION": "Contact Position",
    "CONTACT_TELEPHONE": "Contact Telephone",
    "CONTINUE": "CONTINUE",
    "COOK_ISLANDS": "Cook Islands",
    "CORPORATION": "Corporation",
    "COSTA_RICA": "Costa Rica",
    "COTE_D_IVOIRE_IVORY_COAST": "Cote D'Ivoire (Ivory Coast)",
    "COUNTRY": "Country",
    "COUNTRY_REQUIRED": "Country Required",
    "COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY": "Covered Entity, Covered Source, or Opt-In Entity",
    "COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY_CAPITAL": "COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY",
    "CROATIA": "Croatia",
    "CURACAO": "Curacao",
    "CURRENT_AND_FUTURE_VINTAGE_PURCHASE_LIMITS": "Current and Future Vintage Purchase Limits",
    "CURRENT_VINTAGE_PURCHASE_LIMIT": "Current Vintage Purchase Limit",
    "CYPRUS": "Cyprus",
    "CZECH_REPUBLIC": "Czech Republic",
    "DATA_UPDATED_SUCCESSFULLY": "Data Updated Successfully",
    "DATE_OF_INCORPORATION": "Date of Incorporation",
    "DATE_OF_INCORPORATION_REQUIRED": "Date of Incorporation Required",
    "DATE_TIME_UPDATED": "Date/Time Updated",
    "DECLINED": "Declined",
    "DEFAULT_USERS_NAME": "- default user\u2019s name -",
    "DELAWARE": "Delaware",
    "DEMOCRATIC_REPUBLIC_OF_THE_CONGO": "Democratic Republic of the Congo",
    "DENIAL_REASON": "Denial Reason",
    "DENIED": "Denied",
    "DENMARK": "Denmark",
    "DISTRICT_OF_COLUMBIA": "District of Columbia",
    "DJIBOUTI": "Djibouti",
    "DOMINICA": "Dominica",
    "DOMINICAN_REPUBLIC": "Dominican Republic",
    "DOWNLOAD_OR_PRINT_FORMS_REQUIRED_FOR_THE_ENTITY_ACCOUNT_APPLICATION": "Download or print forms required for the Entity Account Application:",
    "DUNS_NUMBER": "DUNS Number",
    "DUPLICATE_USER_REFERENCE_CODE_IS_NOT_ALLOWED": "Duplicate user reference code is not allowed!",
    "EARLY_ACTION_OFFSET_PROGRAM": "Early Action Offset Program",
    "EARLY_ACTION_OFFSET_PROJECT_OPERATOR": "Early Action Offset Project Operator",
    "EAST_TIMOR": "East Timor",
    "ECUADOR": "Ecuador",
    "EDIT": "EDIT",
    "EDIT_ACCESS": "Edit Access",
    "EDIT_ACCESS_PAR": "Edit Access (PAR)",
    "EDIT_ACCESS_REQUIRED": "Edit Access Required",
    "EDIT_FACILITY": "EDIT FACILITY",
    "EDIT_FACILITY_INFORMATION": "EDIT FACILITY INFORMATION",
    "EFFECTIVE_DATE": "Effective Date",
    "EGYPT": "Egypt",
    "EL_SALVADOR": "El Salvador",
    "EMAIL": "Email",
    "EMAIL_ADDRESS": "Email address",
    "EMAIL_ADDRESS_REQUIRED": "Email address Required",
    "EMAIL_REQUIRED": "Email Required",
    "ENTER_ENTITY_INFORMATION": "Enter Entity Information",
    "ENTER_ENTITY_INFORMATION_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "ENTER_PASSPHRASE": "Enter Passphrase",
    "ENTER_PASSPHRASE_TO_SUBMIT": "Enter Passphrase To Submit",
    "ENTER_USER_REFERENCE_CODE": "Enter User Reference Code...",
    "ENTITY_ACCOUNT_APPLICATION": "Entity Account Application",
    "ENTITY_ACCOUNT_APPLICATION_CAPS": "ENTITY ACCOUNT APPLICATION",
    "ENTITY_CONTACT_INFORMATION": "Entity Contact Information",
    "ENTITY_CONTACT_INFORMATION_TXT": "Entity Contact Information",
    "ENTITY_DETAILS": "Entity Details",
    "ENTITY_ID": "Entity ID",
    "ENTITY_INFORMATION": "Entity Information",
    "ENTITY_LEGAL_NAME": "Entity Legal Name",
    "ENTITY_OPERATING_NAME": "Entity Operating Name",
    "ENTITY_REFERENCE_CODE": "Entity Reference Code",
    "ENTITY_STATUS": "Entity Status",
    "ENTITY_STATUS_HISTORY": "Entity Status History",
    "ENTITY_TYPE": "Entity Type",
    "ENTITY_TYPE_CHANGE_HISTORY": "Entity Type Change History",
    "EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID": "EPA or Washington Greenhouse Gas Reporting ID",
    "EQUATORIAL_GUINEA": "Equatorial Guinea",
    "ERITREA": "Eritrea",
    "ERROR": "Error!",
    "ESTONIA": "Estonia",
    "ETHIOPIA": "Ethiopia",
    "EVENT_DATE": "Event Date",
    "EVENT_DATE(YYYY/MM/DD)": "Event Date(YYYY/MM/DD)",
    "EXIT_APPLICATION": "Exit Application",
    "EXPIRED": "Expired",
    "EXPORT_IN_EXCEL_OR_AS_CSV": "Export in Excel or as CSV",
    "EXT": "Ext.",
    "FACILITIES": "Facilities",
    "FACILITY_GHG_REPORTER_CONTACT": "Facility GHG Reporter Contact",
    "FACILITY_ID": "Facility ID",
    "FACILITY_IDENTIFIERS": "Facility Identifiers",
    "FACILITY_IDENTIFIERS_REQUIRED": "Facility Identifiers Required",
    "FACILITY_INFORMATION": "Facility Information",
    "FACILITY_INFORMATION_IDENTIFIERS": "Facility Information and Identifiers",
    "FACILITY_INFORMATION_FOR_FACILITY_MANAGEMENT": "Facility Information for Facility Management",
    "FACILITY_INFORMATION_REQUIRED": "Facility Information Required",
    "FACILITY_IN_REGISTRY": "Facility in Registry",
    "FACILITY_IN_REGISTRY_REQUIRED": "Facility in Registry Required",
    "FACILITY_IS_IN_REGISTRY": "Facility is in Registry",
    "FACILITY_MAILING_ADDRESS": "Facility Mailing Address",
    "FACILITY_NAME": "Facility Name",
    "FACILITY_OWNERSHIP_HISTORY": "Facility Ownership History",
    "FACILITY_PHYSICAL_ADDRESS": "Facility Physical Address",
    "FACSIMILE_NUMBER": "Facsimile Number",
    "FAILURE": "Failure!",
    "FALKLAND_ISLANDS": "Falkland Islands",
    "FAROE_ISLANDS": "Faroe Islands",
    "FIJI_ISLANDS": "Fiji Islands",
    "FINISH": "Finish",
    "FINLAND": "Finland",
    "FIRST_NAME": "First Name",
    "FLORIDA": "Florida",
    "FOOTER_DISCLAIMER_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibe ndum laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aen ean euismod bibendum laoreet. Proin gravida dolor sit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Aenean euismod bibendum laoreet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "FORMS": "Forms",
    "FORM_1": "Form 1",
    "FORM_2": "Form 2",
    "FORM_3": "Form 3",
    "FRANCE": "France",
    "FRENCH_GUIANA": "French Guiana",
    "FRENCH_POLYNESIA": "French Polynesia",
    "FRENCH_SOUTHERN_TERRITORIES": "French Southern Territories",
    "FUTURE_VINTAGE_PURCHASE_LIMIT": "Future Vintage Purchase Limit",
    "GABON": "Gabon",
    "GAMBIA_THE": "Gambia The",
    "GENERAL": "General",
    "GENERAL_ACCOUNT": "General Account",
    "GENERAL_ACCOUNT_DETAILS": "General Account Details",
    "GENERAL_ENTITY_DETAILS": "General Entity Details",
    "GENERAL_MARKET_PARTICIPANT_INDIVIDUAL": "General Market Participant - Individual",
    "GENERAL_MARKET_PARTICIPANT_ORGANIZATION": "General Market Participant - Organization",
    "GENERAL_PARTNERSHIP": "General Partnership",
    "GEORGIA": "Georgia",
    "GERMANY": "Germany",
    "GHANA": "Ghana",
    "GHG_EMISSIONS_REPORTING_ID": "GHG Emissions Reporting ID",
    "GHG_EMISSIONS_REPORTING_ID_REQUIRED": "GHG Emissions Reporting ID Required",
    "GHG_EMISSIONS_REPORT_ID": "GHG Emissions Reporting ID",
    "GHG_ID_ALREADY_EXIST": "GHG_ID_ALREADY_EXIST",
    "GHG_ID_IS_UNIQUE": "GHG ID is Unique",
    "GHG_ID_UNIQUE": "GHG_ID_UNIQUE",
    "GHG_REPORTING_VERIFIER": "GHG Reporting Verifier",
    "GHG_VERIFY_ALREADY_EXIST": "GHG Verify Already Exist",
    "GIBRALTAR": "Gibraltar",
    "GOVERNMENT": "Government",
    "GOVERNMENT_ISSUED_TAXPAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER": "Government Issued Taxpayer or Employer Identification Number",
    "GOVERNMENT_ISSUED_TAXPAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER_REQUIRED": "Government Issued Taxpayer or Employer Identification Number Required",
    "GOVERNMENT_ISSUED_TAXPLAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER": "Government Issued Taxpayer or Employer Identification Number",
    "GOVERNMENT_ISSUED_TAXPLAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER_REQUIRED": "Government Issued Taxpayer or Employer Identification Number Required",
    "GREECE": "Greece",
    "GREENLAND": "Greenland",
    "GRENADA": "Grenada",
    "GROUP_CURRENT_VINTAGE_PURCHASE_LIMIT": "Group Current Vintage Purchase Limit",
    "GROUP_FUTURE_VINTAGE_PURCHASE_LIMIT": "Group Future Vintage Purchase Limit",
    "GUADELOUPE": "Guadeloupe",
    "GUAM": "Guam",
    "GUATEMALE": "Guatemala",
    "GUERNSEY_AND_ALDERNEY": "Guernsey and Alderney",
    "GUINEA": "Guinea",
    "GUINEA_BISSAU": "Guinea-Bissau",
    "GUYANA": "Guyana",
    "HAITI": "Haiti",
    "HAWAII": "Hawaii",
    "HEARD_ISLAND_AND_MCDONALD_ISLANDS": "Heard Island and McDonald Islands",
    "HOLDINGS": "Holdings",
    "HONDURAS": "Honduras",
    "HONG_KONG_S_A_R": "Hong Kong S.A.R.",
    "HOWLAND_ISLAND": "Howland Island",
    "HOW_WOULD_USER_CLASSIFY_SELF": "How would user classify self?",
    "HOW_WOULD_USER_CLASSIFY_SELF_OTHER": "How would user classify self? Other",
    "HOW_WOULD_USER_CLASSIFY_SELF_OTHER_REQUIRED": "How would user classify self? Other Required",
    "HOW_WOULD_USER_CLASSIFY_SELF_REQUIRED": "How would user classify self? Required",
    "HUNGARY": "Hungary",
    "ICELAND": "Iceland",
    "IDAHO": "Idaho",
    "IDENTIFIERS": "Identifiers",
    "ID_NUMBER_ASSIGNED_BY_INCORPORATING_AGENCY": "ID Number Assigned by Incorporating Agency",
    "ID_NUMBER_ASSIGNED_BY_INCORPORATING_AGENCY_REQUIRED": "ID Number Assigned by Incorporating Agency Required",
    "ID_NUMBER_ASSIGNED_BY_THE_CALIFORNIA_SECRETARY_OF_STATE": "ID Number Assigned by the California Secretary of State",
    "ID_NUMBER_ASSIGNED_BY_THE_CALIFORNIA_SECRETARY_OF_STATE_REQUIRED": "ID Number Assigned by the California Secretary of State Required",
    "ILLINOIS": "Illinois",
    "INACTIVATE_ACTIVATE_ENTITY_ACCOUNT": "INACTIVATE (ACTIVATE) ENTITY ACCOUNT",
    "INACTIVE": "Inactive",
    "INCORPORATION_COUNTRY": "Incorporation Country",
    "INCORPORATION_COUNTRY_REQUIRED": "Incorporation Country Required",
    "INCORPORATION_PROVINCE_OR_STATES_REQUIRED": "Incorporation Province or State Required",
    "INCORPORATION_REGION_IF_NOT_A_CANADIAN_PROVINCE_OR_U_S_STATE": "Incorporation Region \u2013 if not a Canadian Province or U.S. State",
    "INCORPORATION_REGION_IF_NOT_A_CANADIAN_PROVINCE_OR_U_S_STATE_REQUIRED": "Incorporation Region \u2013 if not a Canadian Province or U.S. State Required",
    "INCORPORATION_STATE_OR_PROVINCE": "Incorporation State or Province",
    "INCORPORATION_STATE_OR_PROVINCE_REQUIRED": "Incorporation State or Province Required",
    "INDIA": "India",
    "INDIANA": "Indiana",
    "INDICATE_REQUIRED_FIELDS": "Indicate required fields\n",
    "INDIVIDUAL_BROKER": "Individual Broker",
    "INDIVIDUAL_MEMBER_OF_THE_PUBLIC": "Individual - Member of the Public",
    "INDONESIA": "Indonesia",
    "INFO_ABOUT_FACILITY_IN_REGISTRY": "If the facility or facilities that will be associated with this account are already in CITSS, check the box below. The Registrar will assign existing facilities to the new account when the application is approved. Caution: Entering information for a facility that already exists in CITSS creates a duplicate record.",
    "INFO_ABOUT_GHG_EMISSION_ID": "If this facility has reported emissions under your jurisdiction GHG reporting regulation, the GHG Reporting ID used in Registry is the same as that used under the GHG reporting regulation. For numbers shorter than 6 digits, precede the ID number with zeros in order to have at least 6 digits, e.g. 001234. If you do not have a GHG Reporting ID or if you have not historically reported emissions under your jurisdiction GHG reporting regulation, you may enter all zeros in the field.",
    "INVALID_REFERENCE_CODE": "Invalid Reference code",
    "INPUT": "Input",
    "IOWA": "Iowa",
    "IRAN": "Iran",
    "IRAQ": "Iraq",
    "IRELAND": "Ireland",
    "ISO_LIST_OF_COUNTRIES": "Iso List of Countries",
    "ISRAEL": "Israel",
    "IS_REQUIRED": "Is Required",
    "IS_THIS_ACCOUNT_FOR_PERSONAL_OR_COMMERCIAL_USE": "Is this account for personal or commercial use?",
    "IS_THIS_ACCOUNT_FOR_PERSONAL_OR_COMMERCIAL_USE_REQUIRED": "Is this account for personal or commercial use? Required",
    "ITALY": "Italy",
    "JAMAICA": "Jamaica",
    "JAPAN": "Japan",
    "JARVIS_ISLAND": "Jarvis Island",
    "JERSEY": "Jersey",
    "JOHNSTON_ATOLL": "Johnston Atoll",
    "JORDAN": "Jordan",
    "JURISDICTION": "Jurisdiction",
    "JURISDICTION_REVIEW": "Jurisdiction Review",
    "JURISDICTION_REVIEW_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "KANSAS": "Kansas",
    "KAZAKHSTAN": "Kazakhstan",
    "KENTUCKY": "Kentucky",
    "KENYA": "Kenya",
    "KINGMAN_REEF": "Kingman Reef",
    "KIRIBATI": "Kiribati",
    "KOSOVO": "Kosovo",
    "KUWAIT": "Kuwait",
    "KYRGYZSTAN": "Kyrgyzstan",
    "LAOS": "Laos",
    "LAST_NAME": "Last Name",
    "LAST_STATUS_CHANGE_COMMENT": "Last Status Change Comment",
    "LAST_UPDATED_COLUMN_HEADER": "Last Updated (yyyy/mm/dd)",
    "LAST_UPDATED": "Last Updated",
    "LATVIA": "Latvia",
    "LEBANON": "Lebanon",
    "LEGAL_NAME": "Legal Name",
    "LEGAL_NAME_REQUIRED": "Legal Name Required",
    "LESOTHO": "Lesotho",
    "LIBERIA": "Liberia",
    "LIBYA": "Libya",
    "LIECHTENSTEIN": "Liechtenstein",
    "LIMITED_PARTNERSHIP": "Limited Partnership",
    "LIMITED_USE_HOLDING": "Limited Use Holding",
    "LIMITED_USE_HOLDING_ACCOUNT": "Limited Use Holding Account",
    "LIMITED_USE_HOLDING_ACCOUNT_DETAILS": "Limited Use Holding Account Details",
    "LITHUANIA": "Lithuania",
    "LOUISIANA": "Louisiana",
    "LUXEMBOURG": "Luxembourg",
    "MACAU_S_A_R": "Macau S.A.R.",
    "MACEDONIA": "Macedonia",
    "MADAGASCAR": "Madagascar",
    "MAILING_ADDRESS": "Mailing Address",
    "MAILING_ADDRESS_TEXT": "Mailing Address",
    "MAINE": "Maine",
    "MALAWI": "Malawi",
    "MALAYSIA": "Malaysia",
    "MALDIVES": "Maldives",
    "MALI": "Mali",
    "MALTA": "Malta",
    "MANITOBA": "Manitoba",
    "MAN_ISLE_OF": "Man (Isle of)",
    "MARSHALL_ISLANDS": "Marshall Islands",
    "MARYLAND": "Maryland",
    "MASSACHUSETTS": "Massachusetts",
    "MAURITANIA": "Mauritania",
    "MAURITIUS": "Mauritius",
    "MAX_LENGTH": "Max Length",
    "MAYOTTE": "Mayotte",
    "MEXICO": "Mexico",
    "MICHIGAN": "Michigan",
    "MICRONESIA": "Micronesia",
    "MIDWAY_ATOLL": "Midway Atoll",
    "MINNESOTA": "Minnesota",
    "MIN_LENGTH": "Min Length",
    "MISSISSIPPI": "Mississippi",
    "MISSOURI": "Missouri",
    "MOBILE_PHONE": "Mobile Phone",
    "MOLDOVA": "Moldova",
    "MONACO": "Monaco",
    "MONGOLIA": "Mongolia",
    "MONTANA": "Montana",
    "MONTENEGRO": "Montenegro",
    "MONTSERRAT": "Montserrat",
    "MOROCCO": "Morocco",
    "MOZAMBIQUE": "Mozambique",
    "MYANMAR": "Myanmar",
    "NA": "N/A",
    "NAICS_CODE": "NAICS Code",
    "NAICS_CODE_REQUIRED": "NAICS Code Required",
    "NAME": "Name",
    "NAME_CHANGE_HISTORY": "Name Change History",
    "NAME_REQUIRED": "Name Required",
    "NAMIBIA": "Namibia",
    "NAURU": "Nauru",
    "NAVASSA_ISLAND": "Navassa Island",
    "NA_CAPITAL": "NA",
    "NEBRASKA": "Nebraska",
    "NEPAL": "Nepal",
    "NETHERLANDS": "Netherlands",
    "NEVADA": "Nevada",
    "NEWFOUNDLAND_AND_LABRADOR": "Newfoundland and Labrador",
    "NEW_BRUNSWICK": "New Brunswick",
    "NEW_CALEDONIA": "New Caledonia",
    "NEW_HAMPSHIRE": "New Hampshire",
    "NEW_JERSEY": "New Jersey",
    "NEW_MEXICO": "New Mexico",
    "NEW_YORK": "New York",
    "NEW_ZEALAND": "New Zealand",
    "NICARAGUA": "Nicaragua",
    "NIGER": "Niger",
    "NIGERIA": "Nigeria",
    "NIUE": "Niue",
    "NON_GOVERNMENTAL_ORGANIZATION": "Non Governmental Organization",
    "NON_PROFIT": "Non-Profit",
    "NORFOLK_ISLAND": "Norfolk Island",
    "NORTHERN_MARIANA_ISLANDS": "Northern Mariana Islands",
    "NORTHWEST_TERRITORIES": "Northwest Territories",
    "NORTH_CAROLINA": "North Carolina",
    "NORTH_DAKOTA": "North Dakota",
    "NORTH_KOREA": "North Korea",
    "NORWAY": "Norway",
    "NOVA_SCOTIA": "Nova Scotia",
    "NO_COMMENTS": "No Comments",
    "NO_REPRESENTATIVES_ADDED": "No Representatives Added",
    "NUNAVUT": "Nunavut",
    "OFFSET_PROJECT_ID": "Offset Project ID",
    "OFFSET_PROJECT_OPERATOR": "Offset Project Operator",
    "OFFSET_PROJECT_REGISTRY": "Offset Project Registry",
    "OFFSET_PROJECT_VERIFIER": "Offset Project Verifier",
    "OFFSET_TYPE": "Offset Type",
    "OHIO": "Ohio",
    "OK": "Ok",
    "OKLAHOMA": "Oklahoma",
    "OMAN": "Oman",
    "ONLY_SINGLE_PAR_ROLE_IS_ALLOWED": "Only Single PAR role is allowed!",
    "ONTARIO": "Ontario",
    "ONTARIO_FOR_ARCHIVES": "Ontario (For Archives)",
    "OPERATING_NAME": "Operating Name",
    "OPERATING_NAME_REQUIRED": "Operating Name Required",
    "OPTION_A": "OPTION A",
    "OREGON": "Oregon",
    "OTHER_CLASSIFICATION": "Other Classification",
    "OTHER_CLASSIFICATION...": "Other Classification...",
    "OTHER_PLEASE_SPECIFY": "Other (Please Specify)",
    "OTHER_PLEASE_SPECIFY_BELOW": "Other (Please specify below)",
    "OTHER_TYPE_OF_ORGANIZATION": "Other Type of Organization",
    "OTHER_TYPE_OF_ORGANIZATION_REQUIRED": "Other Type of Organization Required",
    "PAKISTAN": "Pakistan",
    "PALAU": "Palau",
    "PALESTINIAN_TERRITORY_OCCUPIED": "Palestinian Territory Occupied",
    "PALMYRA_ATOLL": "Palmyra Atoll",
    "PANAMA": "Panama",
    "PAPUA_NEW_GUINEA": "Papua new Guinea",
    "PARAGUAY": "Paraguay",
    "PASSPHRASE_REQUIRED": "Passphrase Required",
    "PENDING": "Pending",
    "PENDING_TRANSFERS": "Pending Transfers",
    "PENNSYLVANIA": "Pennsylvania",
    "PERSONAL_USE": "Personal Use",
    "PERU": "Peru",
    "PHILIPPINES": "Philippines",
    "PHYSICAL_ADDRESS": "Physical Address",
    "PHYSICAL_ADDRESS_TEXT": "Physical Address",
    "PITCAIRN_ISLAND": "Pitcairn Island",
    "POLAND": "Poland",
    "PORTUGAL": "Portugal",
    "POSITION": "Position",
    "POSTAL_CODE": "Postal Code",
    "POSTAL_CODE_REQUIRED": "Postal Code Required",
    "PRIMARY_ACCOUNT_REPRESENTATIVE": "Primary Account Representative",
    "PRINCE_EDWARD_ISLAND": "Prince Edward Island",
    "PROPOSED": "Proposed",
    "PROPOSED_DATA_CHANGES": "Proposed Data Changes",
    "PROVINCE_OR_STATES": "Province or State",
    "PROVINCE_OR_STATES_REQUIRED": "Province or State Required",
    "PUERTO_RICO": "Puerto Rico",
    "PURPOSE_OF_ACCOUNT": "Purpose of Account",
    "PURPOSE_OF_ACCOUNT_REQUIRED": "Purpose of Account Required",
    "QATAR": "Qatar",
    "QUANTITY": "Quantity",
    "QUANTITY_PROPOSED": "Quantity Proposed",
    "QUANTITY_TRANSFERRED": "Quantity Transferred",
    "QUEBEC": "Quebec",
    "QUEBEC_ENTERPRISE_NUMBER": "Qu\u00e9bec Enterprise Number",
    "QUEBEC_ENTERPRISE_NUMBER_REQUIRED": "Qu\u00e9bec Enterprise Required",
    "QUICK_ACTIONS": "Quick Actions",
    "READ_LESS": "Read less",
    "READ_MORE": "Read more",
    "RECEIVING_ACCOUNT_NAME": "Receiving Account Name",
    "RECEIVING_ACCOUNT_TYPE": "Receiving Account Type",
    "REGION": "Region",
    "REGION_REQUIRED": "Region Required",
    "REGISTER_AN_ENTITY": "Register An Entity",
    "REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES": "Registry ID (assigned by Service Nova Scotia under the Registry of Joint Stock Companies)",
    "REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES_REQUIRED": "Registry ID (assigned by Service Nova Scotia under the Registry of Joint Stock Companies) Required",
    "REMOVE": "REMOVE",
    "REMOVE_FACILITY": "REMOVE FACILITY",
    "REMOVE_USER_FROM_ROLE": "REMOVE USER FROM ROLE",
    "REPRESENTATIVES": "Representatives",
    "REPRESENTATIVE_TYPE": "Representative Type",
    "REPRESENTATIVE_STATUS": "Representative Status",
    "REPRESENTATIVE_STATUS_HISTORY": "Representative Status History",
    "REQUEST_STATUS": "Request Status",
    "REQUEST_STATUS_UPDATE_DATE_COLUMN_HEADER": "Request Status Update Date (yyyy/mm/dd)",
    "RESET_CLEAR": "RESET/CLEAR",
    "GENERATED_ON": "Generated On",
    "GENERATED_ON_DATE": "Generated On Date",
    "RESTRICTED_CANNOT_RECEIVE": "Restricted - cannot receive",
    "RESTRICTED_CANNOT_TRANSFER": "Restricted - cannot transfer",
    "RESTRICTED_CANNOT_TRANSFER_OR_RECEIVE": "Restricted - cannot transfer or receive",
    "RESTRICT_ACCOUNT": "RESTRICT ACCOUNT",
    "RETIRED": "Retired",
    "RETIREMENT_DATE": "Retirement Date",
    "REUNION": "Reunion",
    "REVIEW_AND_SUBMIT": "Review and Submit",
    "REVIEW_AND_SUBMIT_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "REVISIONS_REQUESTED": "Revisions Requested",
    "REVISIONS_REQUESTED_CAPITAL": "REVISIONS_REQUESTED",
    "REVISION_REQUESTED": "REVISION_REQUESTED",
    "RHODE_ISLAND": "Rhode Island",
    "ROLE": "Role",
    "ROLE_PAR": "Role (PAR)",
    "ROLE_REQUIRED": "Role Required",
    "ROMANIA": "Romania",
    "RUSSIA": "Russia",
    "RWANDA": "Rwanda",
    "SAINT_BARTHELEMY": "Saint-Barthelemy",
    "SAINT_HELENA": "Saint Helena",
    "SAINT_KITTS_AND_NEVIS": "Saint Kitts And Nevis",
    "SAINT_LUCIA": "Saint Lucia",
    "SAINT_MARTIN_FRENCH_PART": "Saint-Martin (French part)",
    "SAINT_PIERRE_AND_MIQUELON": "Saint Pierre and Miquelon",
    "SAINT_VINCENT_AND_THE_GRENADINES": "Saint Vincent And The Grenadines",
    "SAMOA": "Samoa",
    "SAMPLE_ENTITY_NAME": "Sample Entity Name",
    "SAN_MARINO": "San Marino",
    "SAO_TOME_AND_PRINCIPE": "Sao Tome and Principe",
    "SASKATCHEWAN": "Saskatchewan",
    "SAUDI_ARABIA": "Saudi Arabia",
    "SAVE": "Save",
    "SELECT": "Select...",
    "SELECT_CLASSIFICATION": "Select Classification...",
    "SELECT_COUNTRY": "Select Country",
    "SELECT_ENTITY_TYPE": "Select Entity Type",
    "SELECT_ENTITY_TYPE_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "SELECT_ENTITY_TYPE_REQUIRED": "Select Entity Type Required",
    "SELECT_JURISDICTION": "Select Jurisdiction",
    "SELECT_JURISDICTION_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "SELECT_JURISDICTION_REQUIRED": "Select Jurisdiction Required",
    "SELECT_ORGANIZATION_TYPE": "Select Organization Type...",
    "SELECT_PURPOSE": "Select Purpose...",
    "SELECT_ROLE": "Select Role",
    "SELECT_STATE_OR_PROVINCE": "Select State or Province",
    "SELF": "Self",
    "SENEGAL": "Senegal",
    "SERBIA": "Serbia",
    "SEYCHELLES": "Seychelles",
    "SIERRA_LEONE": "Sierra Leone",
    "SINGAPORE": "Singapore",
    "SINT_MAARTEN_DUTCH_PART": "Sint Maarten (Dutch part)",
    "SLOVAKIA": "Slovakia",
    "SLOVENIA": "Slovenia",
    "SOLOMON_ISLANDS": "Solomon Islands",
    "SOMALIA": "Somalia",
    "SOUTH_AFRICA": "South Africa",
    "SOUTH_CAROLINA": "South Carolina",
    "SOUTH_DAKOTA": "South Dakota",
    "SOUTH_GEORGIA": "South Georgia",
    "SOUTH_KOREA": "South Korea",
    "SOUTH_SUDAN": "South Sudan",
    "SPAIN": "Spain",
    "SPECIAL_CATEGORY": "Special Category",
    "SRI_LANKA": "Sri Lanka",
    "STATE_OR_PROVINCE": "State or Province",
    "STATE_OR_PROVINCE_REQUIRED": "State or Province Required",
    "STATUS": "Status",
    "STATUS_CHANGE": "Status Change",
    "STATUS_CHANGE_COMMENT": "Status Change Comment",
    "STREET_1": "Street 1",
    "STREET_1_REQUIRED": "Street 1 Required",
    "STREET_2": "Street 2",
    "SUBMIT": "SUBMIT",
    "SUBMIT_FOOTER_DISCLAIMER_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.",
    "SUBMIT_REQUIRED_DOCUMENTS": "Submit Required Documents",
    "SUBMIT_REQUIRED_DOCUMENTS_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    "SUB_TYPE": "Sub-Type",
    "SUCCESS": "Success!",
    "SUDAN": "Sudan",
    "SURINAME": "Suriname",
    "SUSPENDED": "Suspended",
    "SUSPEND_ENTITY_ACCOUNT": "SUSPEND ENTITY ACCOUNT",
    "SVALBARD_AND_JAN_MAYEN_ISLANDS": "Svalbard And Jan Mayen Islands",
    "SWAZILAND": "Swaziland",
    "SWEDEN": "Sweden",
    "SWITZERLAND": "Switzerland",
    "SYRIA": "Syria",
    "TAIWAN": "Taiwan",
    "TAJIKISTAN": "Tajikistan",
    "TANZANIA": "Tanzania",
    "TELEPHONE": "Telephone",
    "TELEPHONE_EXTENSION": "Telephone Extension",
    "TELEPHONE_NUMBER": "Telephone Number",
    "TELEPHONE_NUMBER_REQUIRED": "Telephone Number Required",
    "TELEPHONE_REQUIRED": "Telephone Required",
    "TENNESSEE": "Tennessee",
    "TEXAS": "Texas",
    "THAILAND": "Thailand",
    "THE_BAHAMAS": "The Bahamas",
    "TOGO": "Togo",
    "TOKELAU": "Tokelau",
    "TONGA": "Tonga",
    "TRANSFERRING_ACCOUNT_NAME": "Transferring Account Name",
    "TRANSFERS": "Transfers",
    "TRANSFERRING_ACCOUNT_TYPE": "Transferring Account Type",
    "TRANSFER_ID": "Transfer ID",
    "TRINIDAD_AND_TOBAGO": "Trinidad And Tobago",
    "TUNISIA": "Tunisia",
    "TURKEY": "Turkey",
    "TURKMENISTAN": "Turkmenistan",
    "TURKS_AND_CAICOS_ISLANDS": "Turks And Caicos Islands",
    "TUVALU": "Tuvalu",
    "TYPE": "Type",
    "TYPE_OF_ORGANIZATION": "Type of Organization",
    "TYPE_OF_ORGANIZATION_AUTHORIZED_PROJECT_DESIGNEE": "Authorized Project Designee",
    "TYPE_OF_ORGANIZATION_REQUIRED": "Type of Organization Required",
    "UGANDA": "Uganda",
    "UKRAINE": "Ukraine",
    "UNITED_ARAB_EMIRATES": "United Arab Emirates",
    "UNITED_KINGDOM": "United Kingdom",
    "COUNTRY_UNITED_STATES": "United States",
    "UNITED_STATES_CAPITAL": "COUNTRY_UNITED_STATES",
    "UNITED_STATES_MINOR_OUTLYING_ISLANDS": "United States Minor Outlying Islands",
    "UNITED_STATES_VIRGIN_ISLANDS": "United States Virgin Islands",
    "UPDATED_AT": "Updated At",
    "UPDATED_BY": "Updated By",
    "LAST_UPDATED_BY": "Last Updated By",
    "LAST_UPDATED_DATE_AND_TIME" : "Last Updated Date and Time ",
    "LAST_UPDATED_DATE": "Last Updated Date",
    "UPDATE_DATE": "Update Date",
    "RETIRE_DATE_COLUMN_HEADER": "Retire date",
    "UPDATE_DATE_COLUMN_HEADER": "Update Date (yyyy/mm/dd)",
    "DESIGNATION_DATE_TIME_H": "Designation Date/Time (yyyy/mm/dd)",
    "RETIRE_DATE": "Retire Date",
    "RETIRE_FACILITY_CAPITAL": "RETIRE FACILITY",
    "URUGUAY": "Uruguay",
    "USER_CLASSIFICATION": "User Classification",
    "USER_CLASSIFICATION_AUTHORIZED_PROJECT_DESIGNEE": "Authorized Project Designee",
    "USER_CLASSIFICATION_REQUIRED": "User Classification Required",
    "USER_REFERENCE_CODE": "User Reference Code",
    "USER_REFERENCE_CODE_SELF_PAR": "User Reference Code (PAR)",
    "USER_REFERENCE_CODE_SELF_PAR_REQUIRED": "User Reference Code (PAR) Required",
    "USER_REPRESENTATIVE_ASSIGNMENT_HISTORY": "User Representative Assignment History",
    "AUCTION_ALLOWANCE_SALE": "Auction/Reserve Sale",
    "AUCTION_AND_RESERVE_SALES_INFORMATION": "Auction and Reserve Sale Information",
    "VIEW_APPLICATIONS_FOR_PAST_AND_UPCOMING_EVENTS": "View Applications For Past And Upcoming Events",
    "USE_ENTITY_DATA": "Use Entity Data",
    "UTAH": "Utah",
    "UZBEKISTAN": "Uzbekistan",
    "VANUATU": "Vanuatu",
    "VATIACAN_CITY_STATE_HOLY_SEE": "Vatican City State (Holy See)",
    "VENEZUELA": "Venezuela",
    "VERIFICATION_BODY": "Verification Body",
    "VERMONT": "Vermont",
    "VIETNAM": "Vietnam",
    "VINTAGE": "Vintage",
    "VIRGINIA": "Virginia",
    "VIRGIN_ISLANDS_US": "Virgin Islands (US)",
    "WAKE_ISLAND": "Wake Island",
    "WALLIS_AND_FUTUNA_ISLANDS": "Wallis And Futuna Islands",
    "WARNING": "Warning!",
    "WASHINGTON": "Washington",
    "WASHINGTON_STATE": "Washington",
    "WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED,_IF_APPLICABLE": "Washington Unified Business Identifier Number (UBI) (required, if applicable)",
    "WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE": "Washington Unified Business Identifier Number (UBI) (required, if applicable)",
    "WEBSITE_ADDRESS": "Website Address",
    "WESTERN_SAHARA": "Western Sahara",
    "WEST_VIRGINIA": "West Virginia",
    "WISCONSIN": "Wisconsin",
    "WYOMING": "Wyoming",
    "YEMEN": "Yemen",
    "YUKON": "Yukon",
    "ZAMBIA": "Zambia",
    "ZIMBABWE": "Zimbabwe",
    "ABOUT_ABC_INC": "About WCI, Inc.",
    "AND": "and",
    "ATTESTATION_FORM": "Attestation Form",
    "CHANGE_PASSPHRASE": "Change Passphrase",
    "CMS_COPY_URL": "Copied Successful",
    "CONTACT_US": "Contact Us",
    "CONTENT_MANAGEMENT_SYSTEM": "Content Management System",
    "COPYRIGHT_2019": "Copyright \u00a9 2019",
    "DEACTIVATED_USERS": "Deactivated Users",
    "DISCLAIMER": "Disclaimer",
    "FAQS_RESOURCES": "FAQs & RESOURCES",
    "FORM_SECOND_CHECKBOX_TXT": "Check Box if Address is Same as Above",
    "FORM_SECOND_CITY": "City",
    "FORM_SECOND_COUNTRY": "Country",
    "FORM_SECOND_DATE_OF_BIRTH": "Date of Birth",
    "FORM_SECOND_DEPARTMENT": "Department",
    "FORM_SECOND_EMAIL_ADDRESS": "Email Address",
    "FORM_SECOND_EMAIL_LANGUAGE_PREFERENCE": "Email Language Preference",
    "FORM_SECOND_EMPLOYER": "Employer",
    "FORM_SECOND_FAX": "Fax",
    "FORM_SECOND_FIRST_NAME": "First Name",
    "FORM_SECOND_JOB_TITLE": "Job Title",
    "FORM_SECOND_JURISDICTION": "Jurisdiction",
    "FORM_SECOND_LAST_NAME": "Last Name",
    "FORM_SECOND_LOGIN_INFORMATION": "Login Information",
    "FORM_SECOND_MAILING_ADDRESS": "Mailing Address",
    "FORM_SECOND_MAIN_FORM_TXT": "This form presents the User Registration information and the Terms and Conditions that you electronically accepted. You must print, sign, and submit this form electronically to CACITSSUserManagement@arb.ca.gov or by postal mail to the Jurisdiction Registrar along with the notarized #3 Proof of Identity Form and documents California Air Resources Board (CARB) staff will review these documents and compare the information with your User Registration submitted in WCI, Inc. If the information provided is inaccurate or incomplete, or the Jurisdiction Registrar cannot verify receipt of all required hard copy documents, CARB will deny your user application.",
    "FORM_SECOND_MIDDLE_NAME": "Middle Name",
    "FORM_SECOND_MOBILE_TELEPHONE": "Mobile Telephone",
    "FORM_SECOND_ORGANIZATION": "Organization",
    "FORM_SECOND_POSTAL_CODE": "Postal Code",
    "FORM_SECOND_PRIMARY_RESIDENCE": "Primary Residence",
    "FORM_SECOND_REGION": "Region",
    "FORM_SECOND_REVISION": "Revision",
    "FORM_SECOND_SALUTATION": "Salutation",
    "FORM_SECOND_STATE_OR_PROVINCE": "State/Province",
    "FORM_SECOND_STREET_1": "Street 1",
    "FORM_SECOND_STREET_2": "Street 2",
    "FORM_SECOND_SUFFIX": "Suffix",
    "FORM_SECOND_TELEPHONE": "Telephone",
    "FORM_SECOND_TELEPHONE_EXT": "Telephone Extension",
    "FORM_SECOND_USER_ID": "User ID",
    "FORM_SECOND_USER_INFORMATION": "User Information",
    "FORM_SECOND_USER_REFERENCE_CODE": "User Reference Code",
    "FORM_SECOND_USER_REGISTRATION_FORM": "#2 User Registration Form",
    "FORM_SECOND_USER_REGISTRATION_FORM_TXT": "Print this form (page) using your browser print function. After printing, you should close this window/tab (not your browser) to return to the WCI, Inc application where you can select the print windows for the Checklist Form, User Registration Form, and Proof of Identity Form.",
    "FORM_SECOND_WCI_USER_REFERENCE_CODE": "WCI, Inc User Reference Code",
    "FORM_SECOND_WCI_USER_REGISTRATION_FORM": "WCI, Inc. User Registration Form",
    "HEADER_ANNOUNCEMENTS": "ANNOUNCEMENTS",
    "HEADER_CONFIGURATION_SETTINGS": "Configuration Settings",
    "HEADER_HOME": "HOME",
    "HEADER_JURISDICTION": "Jurisdiction",
    "HEADER_MENU_SEARCH": "Search",
    "HEADER_PRIVILEGE_MANAGEMENT": "Privilege Management",
    "HEADER_USER_PROFILE": "User Profile",
    "HEADER_USER_REGISTRATION": "USER REGISTRATION",
    "IDENTIFICATION_VERIFICATION_FORM": "Identification Verification Form",
    "JURISDICTION_TERMS_AND_CONDITIONS_MODEL": "I accept the Jurisdiction",
    "LANGUAGE": "Language",
    "LAST_LOGIN": "Last Log in",
    "LOGIN": "Log in",
    "LOGOUT": "Logout",
    "MSG_ERR_DATA_NOT_PROVIDED": "No data has been provided",
    "MSG_ERR_E_TAG__EXCEPTION": "Oops something went wrong, Please try again later",
    "MSG_ERR_INCORRECT_USR_PASS": "Incorrect username or password",
    "MSG_ERR_INTERNAL_SERVER": "Internal Server Error",
    "MSG_ERR_INV_COGNITO_TRIGGER": "Invalid Cognito Trigger",
    "MSG_ERR_INV_UID": "Invalid UID.",
    "MSG_ERR_PJ_DATA_WITH_ID_NOT_EXIST": "Organization data with ID {to_id} does not exist",
    "MSG_ERR_PJ_NOT_EXIST": "Organization does not exist",
    "MSG_ERR_SOMETHING_WENT_WRG": "Oops, something went wrong!",
    "MSG_ERR_UID_NOT_EXIST": "UID does not exist",
    "MSG_ERR_UID_NOT_FOUND": "UID not found!",
    "MSG_ERR_USER_DISABLED": "User is disabled",
    "MSG_ERR_USER_NOT_EXIST": "User does not exist",
    "MSG_ERR_YOU_CAN_NOT_PERFORM_THIS_ACTION": "You can't perform this action.",
    "MSG_SUC": "Success",
    "MSG_SUC_CONTACT_REQ_PROCESSED": "Your request have been processed.",
    "MUI_ROWS_PER_PAGE": "Rows per page",
    "MUI_TOTAL_ROWS": "Total Rows",
    "NEW_REGISTRATION": "New Registration",
    "PASSPHRASE_SUCCESS_MSG": "Passphrase changed successfully.",
    "PRINTED_MATERIALS_MUST_HAVE_ORIGINAL_SIGNATURES": "Printed materials must have original signatures.",
    "PRINT_AND_SUBMIT_ORIGINAL_SIGNATURE_ON": "Print and submit original signature on",
    "REGISTRATION_APPLICATION_MAY_REQUIRE": "If the information provided is inaccurate or incomplete, your User Registration application may require revision or be denied.",
    "SEARCH_USER": "Search User",
    "SETTING": "SETTINGS",
    "SUBMITTED_DOCUMENT_TITLE_FIRST": "Form 1",
    "SUBMITTED_DOCUMENT_TITLE_SECOND": "Form 2",
    "SUBMITTED_DOCUMENT_TITLE_THIRD": "Form 3",
    "SUBMIT_ALL_REQUIRED_DOCUMENTS_AS_INDICATED_ON_THE": "Submit all required documents as indicated on the",
    "TC_MODEL_CONTINUE": "Continue",
    "TERMS_AND_CONDITIONS": "WCI, Inc. Terms of Use",
    "TERMS_AND_CONDITIONS_TEXT": "Terms and Conditions",
    "TERMS_AND_CONDITION_UPDATE_TEXT": "Updated Terms and Conditions",
    "THIRD_FORM_SUBMIT_TXT": "Submit",
    "TO_CONTINUE_TEXT": "to continue",
    "UPDATE_TERMS_CONDITION_LABEL_TEXT": "Please review and accept the updated",
    "USER_REGISTRATION_CHECKLIST": "User Registration Checklist",
    "USER_REGISTRATION_FORM": "User Registration Form",
    "VALIDATION_MESSAGE": "Invalid",
    "IS": "Is",
    "DIGITS": "Digits",
    "WCI_PRIVACY_POLICY": "Privacy Policy",
    "WCI_TERMS_OF_USE": "Terms of Use",
    "WELCOME_LOGIN_TXT": "Welcome,",
    "WITH_SUPPORTING_ORIGINAL_DOCUMENTATION": "with supporting original documentation",
    "REPRESENTATIVE_ADDED_SUCCESSFULLY": "Representative added successfully",
    "APPROVE_STATUS_UPDATED": "Approve status udpated.",
    // ERROR MESSAGES
    "REGION_IS_REQUIRED": 'Region is required',
    "STATE_IS_NOT_NULL_FOR_CANADA_AND_USA": 'State is not null for Canada and USA',
    "PLEASE_USE_COVERED_ENTITY": 'Please use covered entity',
    "NAICS_CODE_NOT_NULL": 'NAICS code not null',
    "ANNUAL_ALLOCATION_ACCOUNT_DATA_NOT_FOUND": 'Annual allocation account data not found',
    "COUNTRY_DATA_NOT_FOUND": 'Country data not found',
    "DENIAL_REASONS_DATA_NOT_FOUND": 'Denial reasons data not found',
    "ENTITY_STATUS_HISTORY_DATA_NOT_FOUND": 'Entity status history data not found',
    "REQUEST_STATUS_DATA_NOT_FOUND": 'Request status data not found',
    "SPECIAL_PURPOSE_ACCOUNT_DATA_NOT_FOUND": 'Special purpose account data not found',
    "STATE_DATA_NOT_FOUND": 'State data not found',
    "CLASSIFICATION_DATA_NOT_FOUND": 'Classification data not found',
    "DUPLICATE_ENTITIES_DATA_NOT_FOUND": 'Duplicate entities data not found',
    "ENTITY_TYPE_DATA_NOT_FOUND": 'Entity type data not found',
    "JURISDICATION_DATA_NOT_FOUND": 'Jurisdiction data not found',
    "ORGANIZATION_TYPE_DATA_NOT_FOUND": 'Organization type data not found',
    "PURPOSE_DATA_NOT_FOUND": 'Purpose data not found',
    "ENTITY_DATA_NOT_FOUND": 'Entity data not found',
    "STATUS_DATA_NOT_FOUND": 'Status data not found',
    "THERE_IS_ANOTHER_USER_WORKING_ON_THE_APPLICATION_PLEASE_TRY_AGAIN_LATER": 'There is Another user working on the application. Please try again later.',
    "CAN_NOT_CHANGE_ENTITY_TYPE_TO_GMPI": 'Can not change entity type to GMPI',
    "CAN_NOT_CHANGE_ENTITY_TYPE_FROM_GMPI_TO_COVERED_OR_GMPO": 'Can not change entity type from GMPI to Covered or GMPO.',
    "CAN_NOT_CHANGE_JURIDICTION": 'Can not change jurisdiction',
    "ENTITY_HISTORY_DATA_NOT_INSERTED": 'Entity history data not inserted.',
    "ENTITY_STATUS_HISTORY_NOT_FOUND": 'Entity status history not found.',
    "REQUEST_STATUS_NOT_FOUND": 'Request status not found.',
    "ROLE_SHOULD_BE_REGISTER_OR_INDIVIDUAL": "Role should be registrar or individual",
    "REPRESENTATIVE_ADDED_SUCCESSFULLY_AND_MAIL_SENT_TO_AR": "Representative added successfully and mail sent to AR.",
    "REPRESENTATIVE_MODIFIED_SUCCESSFULLY": "Representative added/modified successfully!",
    "ENTITY_ACCOUNT_TYPE": "Entity Account Type",
    "EXCHANGE_CLEARING_HOLDING_ACCOUNT": "Exchange clearing holding account",
    "G_ANNUAL_ALLOCATION_HOLDING": "Annual allocation holding (g)",
    "AUCTION_RESERVE_SALE": "AUCTION / RESERVE SALE ",
    "REGISTRAR_BRACKET": "(Registrar)",
    "COMPLIANCE": "Compliance",
    "PAR_NEEDS_TO_BE_PRESENT": "PAR need to be present.",
    "ROLE_SHOILD_BE_REGISTER": "Role should be registrer",
    "REQUEST_REVISION_STATUS_UPDATED": "Request revision status updated.",
    "REQUEST_REVISION_ENTITY_APPLICATION_MESSAGE": "Are you sure you want to request revision the application?",
    "YOU_CAN_NOT_EDIT_ENTITY_ACCOUNT_APPLICATION": "You can not edit entity account application.",
    "Can_not_cancel_Entity_Because_Entity_is_in_DENIED": "Can not cancel entity because entity is in Denied",
    "Can_not_cancel_Entity_Because_Entity_is_in_CANCELLED": "Can not cancel entity because entity is in Cancelled.",
    "ASSOCIATE_FACILITY": "ASSOCIATE FACILITY",
    "CLOSE_ENTITY": "CLOSE ENTITY",
    "ACTIVATE_ENTITY": "ACTIVATE ENTITY",
    "ACTIVATE_ACCOUNT": "ACTIVATE ACCOUNT",
    "CLOSE_ENTITY_ACCOUNT": "Close Entity Account",
    "ACTIVATE_ENTITY_ACCOUNT": "Activate Entity Account",
    "CLOSE_ENTITY_ACCOUNT_CONTENT_LINE1": "Clicking 'Close Entity' will send a notification to all account representatives and the entity status will be changed to 'Closed'. ",
    "ACTIVATE_ENTITY_ACCOUNT_CONTENT_LINE1": "Clicking 'Activate Entity' will send a notification to all account representatives and the entity status will be changed to 'Active'. ",
    "ADD_SPECIAL_ACCOUNT": "ADD SPECIAL ACCOUNT",
    "ENTITY_IS_CLOSED": "Entity Profile Is Successfully Closed",
    "ENTITY_IS_ACTIVE": "Entity Profile Is Successfully Activated",
    "THERE_ARE_PROPOSED_CHANGES_ON_THE_ENTITY_PROFILE": "There are proposed changes on the Entity Profile.",
    "THERE_ARE_ACTIVE_ENTITY_ACCOUNTS_ASSOCIATED": "There are active Entity Accounts associated with the Entity that are not closed.",
    "FACILTY_IS_NOT_RETIRED": "There is an active Entity-Facility relationship associated with the Entity.",
    "COMMENTS_ISREQUIRED": "Comment required",
    "SUCCESSFULLY_ADDED_NEW_REPRESENTATIVE": "Successfully added representative.",
    "PROPOSED_CHANGES_APPROVED": "Successfully Approved the Proposed Changes",
    "PROPOSED_CHANGES_DENIED": "Successfully Denied the Proposed Changes",
    "SUCCESSFULLY_REMOVED_NEW_REPRESENTATIVE": "Successfully removed representative.",
    "SUCCESSFULLY_CANCELLED_THE_PROPOSED_CHANGES": "Successfully Cancelled the Proposed Changes",
    "ACTIVATE_CONTINUE_CONFIRM": "Click 'Continue' to Activate Entity profile successfully. Click on Cancel to exit this message and redirect to Entity profile.",
    "CLOSE_CONTINUE_CONFIRM": "Click 'Continue' to Close Entity profile successfully. Click on Cancel to exit this message and redirect to Entity profile.",
    "FORBIDDEN_ERROR": "This action is not allowed.",
    "SINGLE_PAR_ROLE_ALLOWED": "Only one PAR roles is allowed.",
    "PROPOSED_REPRESENTATIVE_ADDED_SUCCESSFULLY": "Proposed Changes Saved successfully.",
    "EXISTING_PROPOSED_CHANGES": "There are proposed changes on the Entity Profile.",
    "PROPOSED_ROLE": "Proposed Role",
    "ACTIVATE_REPRESENTATIVE": "ACTIVATE REPRESENTATIVE",
    "SUSPEND_REPRESENTATIVE": "SUSPEND REPRESENTATIVE",
    "RETIRE_ACCOUNT_REPRESENTATIVE": "RETIRE ACCOUNT REPRESENTATIVE",
    "ACTIVATE_ACCOUNT_REPRESENTATIVE": "ACTIVATE ACCOUNT REPRESENTATIVE",
    "CANCEL_FACILITY_REMOVE": "Do you want to cancel?",
    "CONTINUE_FACILITY_REMOVE": "Cancel will remove the Facility from entity.",
    "MY_REPRESENTATIVE_ASSIGNMENTS": "My Representative Assignments",
    "REPRESENTATIVES STATUS HISTORY": "Representatives Status History",
    "REMOVE_USER_FROM_ROLE_MESSAGE1": "Do you want to remove User?",
    "REMOVE_USER_FROM_ROLE_MESSAGE2": "This action will remove selected user from role.",
    "DESIGNATION_DATE": "Designation Date (yyyy/mm/dd)",
    "CANCEL_REPRESENATATIVE_PROPOSED_MESSAGE1": "Do you want to cancel User?",
    "CANCEL_REPRESENATATIVE_PROPOSED_MESSAGE2": "This action will cancel selected user from role.",
    "RETIRE_REPRESENATATIVE_MESSAGE1": "Do you want to retire User?",
    "RETIRE_REPRESENATATIVE_MESSAGE2": "This action will retire selected user from role.",
    "SUSPEND_REPRESENTATIVE_MESSAGE1": "Do you want to suspend User?",
    "SUSPEND_REPRESENTATIVE_MESSAGE2": "This action will suspend selected user from role.",
    "ACTIVATE_REPRESENTATIVE_MESSAGE1": "Do you want to activate User?",
    "ACTIVATE_REPRESENTATIVE_MESSAGE2": "This action will activate selected user from role.",
    "RETIRE_USER_FROM_ROLE": "RETIRE USER FROM ROLE",
    "SUSPEND_USER_FROM_ROLE": "SUSPEND USER FROM ROLE",
    "SUSPEND_ACCOUNT_REPRESENTATIVE": "SUSPEND REPRESENTATIVE",
    "SUSPEND_ACCOUNT_REPRESENTATIVE_TITLE": "Suspend Representative",
    "CANCEL_REQUEST": "Cancel Request",
    "CAG_MEMBER_ALREADY_EXIST": "The Entity is Already in Group",
    "GROUP_MEMBER_ADDED_SUCCESSFULLY": "Changes Saved successfully",
    "DESIGNATION_DATE_COLUMN_HEADER": "Designation Date/Time",
    "YYYYDDMM_EDT": "(yyyy/mm/dd EDT)",
    "USER_REFERENCE_CODE_REQUIRED": "User Reference Code Required",
    "C_ANNUAL_ALLOCATION_HOLDING": "Annual Allocation Holding Account(c)",
    "AAR_MAXIMUM_VALIDATION_FAILED": "AAR Maximum value reached. Max AAR allowed is ",
    "AAR_MINIMUM_VALIDATION_FAILED": "Minimum AAR value not present. Min AAR required is",

    "AVA_MAXIMUM_VALIDATION_FAILED": "AVA Maximum value reached. Max AVA allowed is ",
    "AVA_MINIMUM_VALIDATION_FAILED": "Minimum AVA value not present. Min AVA required is",

    "PAR_MAXIMUM_VALIDATION_FAILED": "PAR Maximum value reached. Max PAR allowed is ",
    "PAR_MINIMUM_VALIDATION_FAILED": "Minimum PAR value not present. Min PAR required is",
    "THIS_FORM_CAN_ALSO_BE_FOUND_IN_THE_FORMS_SECTION_OF_THE_ENTITY_PROFILE": 'This form can also be found in the "Forms" section of the Entity Profile.',
    "REQUEST_TO_CHANGE_THE_ACCOUNT_REPRESENTATIVE_FORM": "Request to Change The Account Representative Form",
    "PLEASE_SUBMIT_THE_FOLLOWING_FORM_TO_COMPLETE_THE_REQUEST_TO_ADD_NEW_ACCOUNT_REPRESENTATIVE": "Please submit the following form to complete the request to add new account representative:",
    "ADD_NEW_ACCOUNT_REPRESENTATIVE": "Add New Account Representative",
    "DUPLICATE_REFERENCE_CODE_NOT_ALLOWED": "Duplicate Reference Code not allowed",
    "ACCOUNT_REPRESENTATIVE_REMOVED_SUCCESSFULLY": "Account Representative Removed Sucessfully",
    "ACCOUNT_REPRESENTATIVE_SUSPENDED_SUCCESSFULLY": "Account Representative Suspended Sucessfully",
    "ACCOUNT_REPRESENTATIVE_RETIRED_SUCCESSFULLY": "Account Representative Retired Sucessfully",
    "ACCOUNT_REPRESENTATIVE_CANCELED_SUCCESSFULLY": "Account Representative Canceled Sucessfully",
    "PAR_AAR_CANNOT_BE_ZERO": "Primary AR and Alternate AR can not be zero",
    "CURRENT_HOLDING_LIMIT_SHARE": "Current Holding Limit Share %",

    // Event Application 
    "EDIT_BID_GUARANTEE_RETURN": "EDIT BID GUARANTEE RETURN",
    "EDIT_APPLICATION": "EDIT APPLICATION",
    "EVENT": "Event",
    "EVENT_APPLICATION_INFORMATION": "Event Application Information",
    "CONFIRM_EXISTING_EVENT_APPLICATION_DATA": "Create Event Application/Confirm Existing Data for Event",
    "FORMS_OF_BID_GUARANTEE": "Forms of Bid Guarantee",
    "CURRENCY": "Currency",
    "LETTER_OF_CREDIT": "Letter of Credit",
    "BOND": "Bond",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_CASH": "Bid Guarantee Return Instructions - Cash",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_SHORT_CONTENT": "Lorem ipsum dolor sit amet consectetur. Vel diam feugiat vehicula mattis. Rhoncus hendrerit diam ut vitae turpis pretium et ornare. Ornare hendrerit risus porttitor nulla felis aliquam id arcu tortor. Consectetur in leo odio libero. Mauris amet pulvinar ut sit ac fermentum. Eget in rhoncus lorem...",
    "BENEFICIARY_BANK_NAME": "Beneficiary Bank Name",
    "BENEFICIARY_BANK_NAME_PLACEHOLDER": "Beneficiary Bank Name...",
    "BENEFICIARY_BANK_ROUTING_NUMBER": "Beneficiary Bank Routing Number (ABA Routing Number or Canadian Routing Number)",
    "BENEFICIARY_BANK_ROUTING_NUMBER_PLACEHOLDER": "Beneficiary Bank Routing Nu...",
    "BENEFICIARY_BANK_SWIFT_BANK_IDENTIFIER_CODE_BIC": "Beneficiary Bank SWIFT/Bank Identifier Code (BIC)",
    "BENEFICIARY_BANK_SWIFT_BANK_IDENTIFIER_CODE_BIC_PLACEHOLDER": "Beneficiary Bank SWIFT/Bank...",
    "BENEFICIARY_ACCOUNT_NAME": "Beneficiary Account Name",
    "BENEFICIARY_ACCOUNT_NAME_PLACEHOLDER": "Beneficiary Account Na...",
    "BENEFICIARY_ACCOUNT_NUMBER": "Beneficiary Account Number",
    "BENEFICIARY_ACCOUNT_NUMBER_PLACEHOLDER": "Beneficiary Account Nu...",
    "RE_ENTER_BENEFICIARY_ACCOUNT_NUMBER": "Re-enter Beneficiary Account Number",
    "RE_ENTER_BENEFICIARY_ACCOUNT_NUMBER_PLACEHOLDER": "Re-enter Beneficiary Acc...",
    "LAST_FOUR_CHARACTERS_OF_THE_BENEFICIARY_ACCOUNT_NUMBER": "Last four characters of the Beneficiary Account Number",
    "LAST_FOUR_CHARACTERS_OF_THE_BENEFICIARY_ACCOUNT_NUMBER_PLACEHOLDER": "Last four characters of the Be...",
    "PAYMENT_DETAILS": "Payment Details",
    "PAYMENT_DETAIL": "Payment Detail",
    "PAYMENT_DETAILS_PLACEHOLDER": "Payment Details...",
    "BENEFICIARY_BANK_STATE_PROVINCE": "Beneficiary Bank State/Province",
    "BENEFICIARY_BANK_CITY": "Beneficiary Bank City",
    "BENEFICIARY_BANK_CITY_PLACEHOLDER": "Beneficiary Bank City...",
    "INTERMEDIARY_BANK_INFORMATION": "Intermediary Bank Information",
    "INTERMEDIARY_BANK_INFORMATION_SHORT": "Lorem ipsum dolor sit amet consectetur. Vel diam feugiat vehicula mattis. Rhoncus hendrerit diam ut vitae turpis pretium et ornare. Ornare hendrerit risus porttitor nulla felis aliquam id arcu tortor. Consectetur in leo odio libero. Mauris amet pulvinar ut sit ac fermentum. Eget in rhoncus lorem...",
    "INTERMEDIARY_BANK_NAME": "Intermediary Bank Name",
    "INTERMEDIARY_BANK_NAME_PLACEHOLDER": "Intermediary Bank Name...",
    "INTERMEDIARY_BANK_ROUTING_NUMBER": "Intermediary Bank Routing Number (ABA Routing Number or Canadian Routing Number)",
    "INTERMEDIARY_BANK_ROUTING_NUMBER_PLACEHOLDER": "Intermediary Bank Rou...",
    "INTERMEDIARY_BANK_SWIFT_BANK_IDENTIFIER_CODE_BIC": "Intermediary Bank SWIFT/Bank Identifier Code (BIC)",
    "INTERMEDIARY_BANK_SWIFT_BANK_IDENTIFIER_PLACEHOLDER": "Intermediary Bank SWIFT/Bank...",
    "FINANCIAL_CONTACT_INFORMATION": "Financial Contact Information",
    "FINANCIAL_CONTACT_INFORMATION_SHORT": "Lorem ipsum dolor sit amet consectetur. Vel diam feugiat vehicula mattis. Rhoncus hendrerit diam ut vitae turpis pretium et ornare. Ornare hendrerit risus porttitor nulla felis aliquam id arcu tortor. Consectetur in leo odio libero. Mauris amet pulvinar ut sit ac fermentum. Eget in rhoncus lorem...",
    "FINANCIAL_NAME": "Name",
    "FINANCIAL_NAME_PLACEHOLDER": "Name...",
    "FINANCIAL_COMPANY": "Company",
    "FINANCIAL_COMPANY_PLACEHOLDER": "Company...",
    "FINANCIAL_TITLE": "Title",
    "FINANCIAL_TITLE_PLACEHOLDER": "Title...",
    "FINANCIAL_TELEPHONE": "Telephone",
    "FINANCIAL_TELEPHONE_PLACEHOLDER": "Telephone...",
    "FINANCIAL_EMAIL": "Email",
    "FINANCIAL_EMAIL_PLACEHOLDER": "Email...",
    "ATTESTATION": "Attestation",
    "ATTESTATION_SHORT": "Lorem ipsum dolor sit amet consectetur. Vel diam feugiat vehicula mattis. Rhoncus hendrerit diam ut vitae turpis pretium et ornare. Ornare hendrerit risus porttitor nulla felis aliquam id arcu tortor. Consectetur in leo odio libero. Mauris amet pulvinar ut sit ac fermentum. Eget in rhoncus lorem...",
    "ATTESTATION_RESPONSE": "Attestation Response",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_LETTER_OF_CREDIT_BOND": "Bid Guarantee Return Instructions - Letter of Credit / Bond",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_LETTER_OF_CREDIT_BOND_SHORT": "Lorem ipsum dolor sit amet consectetur. Vel diam feugiat vehicula mattis. Rhoncus hendrerit diam ut vitae turpis pretium et ornare. Ornare hendrerit risus porttitor nulla felis aliquam id arcu tortor. Consectetur in leo odio libero. Mauris amet pulvinar ut sit ac fermentum. Eget in rhoncus lorem...",
    "LETTER_OF_CREDIT_NAME_OF_A_CONTACT_INDIVIDUAL": "Name of A Contact Individual",
    "LETTER_OF_CREDIT_NAME_OF_A_CONTACT_INDIVIDUAL_PLACEHOLDER": "Name of a contact in...",
    "LETTER_OF_CREDIT_PHYSICAL_RETURN_ADDRESS": "Physical Return Address (PO Box not allowed)",
    "LETTER_OF_CREDIT_PHYSICAL_RETURN_ADDRESS_PLACEHOLDER": "Physical Return Add...",
    "LETTER_OF_CREDIT_RETURN_CITY": "Return City",
    "LETTER_OF_CREDIT_RETURN_CITY_PLACEHOLDER": "Return City...",
    "LETTER_OF_CREDIT_RETURN_POSTAL_CODE": "Return Postal Code",
    "LETTER_OF_CREDIT_RETURN_POSTAL_CODE_PLACEHOLDER": "Return Postal Code...",
    "LETTER_OF_CREDIT_RETURN_STATE_PROVINCE": "Return State/Province",
    "LETTER_OF_CREDIT_RETURN_REGION_CANADIAN": "Return Region-if not a Canadian Province or U.S. State",
    "LETTER_OF_CREDIT_RETURN_REGION_CANADIAN_PLACEHOLDER": "Return Region if no...",
    "LETTER_OF_CREDIT_RETURN_COUNTRY": "Return Country",
    "LETTER_OF_CREDIT_RETURN_CONTACT_PHONE_NUMBER": "Return Contact Phone Number",
    "LETTER_OF_CREDIT_RETURN_CONTACT_PHONE_NUMBER_PLACEHOLDER": "Return Contact Phone Nu...",
    "INPUT_MUST_BE": "Input Must Be",
    "SPECIAL_CHARACTER_ERROR": "No Special Characters Allowed",
    "ALPHABET_NOT_ALLOWED_ERROR": "No Alphabets Allowed",
    "RESUBMIT": "RESUBMIT",
    "LUHA_STATUS": "LUHA Status",
    "LAST_DATE_STATUS_UPDATED_TO_CONFIRMED_BY_REPRESENTATIVE": "Last Date Status Updated to Confirmed by Representative",
    "CONFIRMED_BY_REPRESENTATIVE": "Confirmed by Representative",
    "LAST_DATE_STATUS_UPDATED_TO_VERIFIED": "Last Date Status Updated to Verified",
    "VERIFIED_BY": "Verified By",
    "LAST_DATE_STATUS_UPDATED_TO_CONFIRMED_BY_FSA": "Last Date Status Updated to Confirmed by FSA",
    "CONFIRMED_BY_FSA_BENEFICIARY_BANK_NAME": "Confirmed by FSA Beneficiary Bank Name",
    "CONFIRMED_BY_FSA_BENEFICIARY_BANK_ABA_ROUTING_NUMBER": "Confirmed by FSA Beneficiary Bank ABA Routing Number",
    "CONFIRMED_BY_FSA_BENEFICIARY_ACCOUNT_NAME": "Confirmed by FSA Beneficiary Account Name",
    "CONFIRMED_BY_FSA_LAST_FOUR_CHARACTERS_OF_THE_BENEFICIARY_ACCOUNT_NUMBER": "Confirmed by FSA Last Four Characters of the Beneficiary Account Number",
    "CONFIRMED_BY_FSA_PAYMENT_DETAIL": "Confirmed by FSA Payment Detail",
    "LAST_DATE_STATUS_UPDATED_TO_INVALID": "Last Date Status Updated to Invalid",
    "CURRENT_WIRING_INSTRUCTIONS_STATUS": "Current Wiring Instructions Status",
    "CURRENT_STATUS_BENEFICIARY_BANK_NAME": "Current Status Beneficiary Bank Name",
    "CURRENT_STATUS_BENEFICIARY_BANK_ABA_ROUTING_NUMBER": "Current Status Beneficiary Bank ABA Routing Number",
    "CURRENT_STATUS_BENEFICIARY_ACCOUNT_NAME": "Current Status Beneficiary Account Name",
    "CURRENT_STATUS_LAST_FOUR_CHARACTERS_OF_THE_BENEFICIARY_ACCOUNT_NUMBER": "Current Status Last Four Characters of the Beneficiary Account Number",
    "CURRENT_STATUS_PAYMENT_DETAIL": "Current Status Payment Detail",
    "CURRENT_HOLDING_LIMIT": "Current Holding Limit",
    "FUTURE_HOLDING_LIMIT": "Future Holding Limit",
    "CURRENT_PURCHASE_LIMIT": "Current Purchase Limit",
    "FUTURE_PURCHASE_LIMIT": "Future Purchase Limit",
    "SUBMITTED_DATE_TIME": "Submitted Date/Time",
    "BENEFICIARY_BANK_ABA_ROUTING_NUMBER": "Beneficiary Bank ABA Routing Number",
    "CWI_CONFIRMED": "CWI Confirmed",
    "CWI_VERIFIED": "CWI Verified",
    "CWI_PROPOSED": "CWI Proposed",



    /* Event Application Management */
    "EVENT_APPLICATION_MANAGEMENT": "Event Applications Management",
    "EVENTS": "Events",
    "EVENT_INFORMATION": "Event Information",
    "EVENT_APPLICATIONS": "Event Applications",
    "EVENT_APPLICATION_DETAIL": "Event Application Details",
    "APPROVE": "Approve",
    "REJECT": "Reject",
    "REJECTED": "Rejected",
    "APPLICANT": "Applicant",
    "UNDO_REJECTION": "Undo Rejection",
    "QUALIFIED_APPLICANT": "Qualified Applicant",
    "QUALIFIED_BIDDER": "Qualified Bidder",
    "APPLICATION_STATUS": "Application Status",
    "APPROVE_REJECT_UNDO_REJECTION": "Approve, Reject or Undo Rejection",
    "BTN_CANCEL": "CANCEL",
    "BTN_SUBMIT": "SUBMIT",

    "EVENT_APPLICATION_PERIOD_STATUS": "Event Application Period Status",
    "GENERAL_ACCOUNT_NUMBER": "General Account Number",
    "GENERAL_ACCOUNT_STATUS": "General Account Status",
    "EVENT_APPLICATION_STATUS": "Event Application Status",
    "FORMS_OF_BID_GUARANTEE_SUBMITTEDS": "Form(s) of Bid Guarantee to be Submitted",
    "EVENT_APPLICATION_STATUS_HISTORY": "Event Application Status History",

    "SUBMITTED_DATE": "Submitted Date",
    "SUBMITTED_BY": "Submitted By",
    "EVENT _APPLICATION_STATUS": "Event Application Status",
    "EVENT _APPLICATION_STATUS_HISTORY": "Event Application Status History",
    "ACTIVE_AARS": "Active AARs",
    "ACTIVE_PAR": "Active PAR",
    "GENERAL _ACCOUNT _STATUS": "General Account Status",
    "GENERAL_ACCOUNT _NUMBER": "General Account Number",
    "EVENT _APPLICATION_PERICO_STATUS": "Event Application Period Status",
    "BIDDING_LIMITATION_DATA": "Bidding Limitation Data",
    "CASH_BID_GUARANTEE": "Cash Bid Guarantee Value",
    "PHYSICAL_BID_GUARANTEE": "Physical Bid Guarantee Value",
    "TOTAL_BID_GUARANTEE": "Total Bid Guarantee Value",
    "CASH": "Cash",
    "BENEFICIARY_BANK_INFORMATION": "Beneficiary Bank Information",
    "BENEFICIARY_BANK_COUNTRY": "Beneficiary Bank Country",
    "BID_GUARANTEE_RETURN_REGION": "Return Region – if not a Canadian Province or U.S. State",
    "LAST_UPDATED_DATE_TIME": "Last Updated Date(YYYY/MM/DD)/Time(HH:MM)",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_CASH_BID_GUARANTEE": "Bid Guarantee Return Instructions - Cash Bid Guarantee",
    "BID_GUARANTEE_RETURN_INSTRUCTIONS_BID_GUARANTEE_INSTRUMENT": "Bid Guarantee Return Instructions - Bid Guarantee Instrument",
    "CONFIRM_CODE_UPDATE_MESSAGE": "Click \"Yes\" to Confirm and \"No\" to Cancel Your Selection.",
    "GROUP_ID": "Group ID",
    /* EVENT CREATION AND MANAGEMENT */
    "EVENT_MANAGEMENT": "Event Management",
    "CREATE_NEW_EVENT": "Create new Event",
    "LIST_OF_EVENTS": "List of Events",
    "EVENT_TYPE": "Event Type",
    "EVENT_ID": "Event ID",
    "EVENT_NAME": "Event Name",
    "EVENT_STATUS": "Event Status",
    "PARTICIPATING_JURISDICTIONS": "Participating Jurisdictions",
    "JURISDICTIONS": "Jurisdiction",
    "UPDATED_DATE/TIME": "Updated Date(YYYY/MM/DD)/Time(HH:MM)",
    "UPDATED_DATE": "Updated Date",
    "PROPOSED_BY_JURIDICTION_ADMINISTRATOR": "Proposed by Jurisdiction Administrator",

    "EVENTS_CREATION": "Event Creation",
    "EVENT_BASICS": "Event Basics",
    "TRUNCATE_TEXT_READ_MORE": "Read more",
    "TRUNCATE_TEXT_READ_LESS": "Read less",
    "EVENT_NAME_-_ENGLISH": " Event Name - English",
    "EVENT_NAME_-_FRENCH": "Event Name - French",

    "EVENT_DETAILS": "Event Details",
    "CURRENCY_ACCEPTED": "Currency Accepted",
    "VINTAGES": "Vintages",
    "VINTAGE_TYPE": "Vintage Type",
    "PROCESSING_ORDER": "Processing Order",
    "TOTAL_ALLOWANCES_AVAILABLE": "Total Allowances Available",
    "ALLOWANCES_AVAILABLE": "Allowances Available",
    "ANNUAL_AUCTION_RESERVE_PRICE": "Annual Auction Reserve Price",

    "TIERS": "Tiers",
    "TIERS_PRICE": "Tier Prices",
    "ADD_NEW": "+ Add New",

    "AUCTION": "Auction",
    "RESERVE_SALE": "Reserve Sale",
    "CAD": "CAD",
    "USD": "USD",
    "CURRENT": "Current",
    "FUTURE": "Future",
    "TIER_ONE": "Tier One",
    "TIER_TWO": "Tier Two",
    "TIER_THREE": "Tier Three",

    "DATES_&_TIMES": "Dates & Times",
    "BIDDING_PERIOD": "Bidding Period",
    "BIDDING_PERIOD_OPEN": "Bidding Period Open",
    "BIDDING_PERIOD_OPEN_DATE": "Bidding Period Open Date",
    "BIDDING_PERIOD_OPEN_TIME": "Bidding Period Open Time",
    "BIDDING_PERIOD_CLOSE": "Bidding Period Close",
    "BIDDING_PERIOD_CLOSE_DATE": "Bidding Period Close Date",
    "BIDDING_PERIOD_CLOSE_TIME": "Bidding Period Close Time",

    "EVENT_APPLICATION": "Event Application",
    "EVENT_APPLICATION_OPEN": "Event Application Open",
    "EVENT_APPLICATION_OPEN_DATE": "Event Application Open Date",
    "EVENT_APPLICATION_OPEN_TIME": "Event Application Open Time",
    "EVENT_APPLICATION_CLOSE": "Event Application Close",
    "EVENT_APPLICATION_CLOSE_DATE": "Event Application Close Date",
    "EVENT_APPLICATION_CLOSE_TIME": "Event Application Close Time",

    "BID_GUARANTEE_SUBMISSION_DEADLINE": "Bid Guarantee Submission Deadline",
    "BID_GUARANTEE_SUBMISSION_DEADLINE_DATE": "Bid Guarantee Submission Deadline Date",
    "BID_GUARANTEE_SUBMISSION_DEADLINE_TIME": "Bid Guarantee Submission Deadline Time",

    "RESULTS_AVAILABLE": "Results Available",
    "RESULTS_AVAILABLE_DATE": "Results Available Date",
    "RESULTS_AVAILABLE_TIME": "Results Available Time",

    "FINANCIAL_SETTLEMENT": "Financial Settlement",
    "FINANCIAL_SETTLEMENT_DATE": "Financial Settlement Date",
    "FINANCIAL_SETTLEMENT_TIME": "Financial Settlement Time",

    "EVENT_CLOSURE": "Event Closure",
    "EVENT_CLOSURE_DATE": "Event Closure Date",
    "EVENT_CLOSURE_TIME": "Event Closure Time",

    "DATE_SHOULD_BE_BEFORE_BIDDING_PERIOD_CLOSE_DATE": "Date and Time should be before Bidding Period Close Date and Time",
    "DATE_SHOULD_BE_BEFORE_RESULTS_AVAILABLE_DATE": "Date and Time should be before Results Available Date and Time",
    "THIS_DATE_SHOULD_BE_BEFORE_EVENT_APPLICATION_CLOSE_DATE": "Date and Time should be before Event Application Close Date and Time",
    "DATE_SHOULD_BE_BEFORE_BID_GUARANTEE_SUBMISSION_DEADLINE_DATE": "Date and Time should be before Bid Guarantee Submission Deadline Date and Time",
    "DATE_SHOULD_BE_BEFORE_BIDDING_PERIOD_OPEN_DATE": "Date and Time should be before Bidding Period Open Date and Time",
    "DATE_SHOULD_BE_BEFORE_FINANCIAL_SETTLEMENT_DUE_DATE": "Date and Time should be before Financial Settlement Due Date and Time",
    "THIS_DATE_SHOULD_BE_BEFORE_EVENT_CLOSURE_DATE": "Date and Time should be before Event Closure Date and Time",

    "BIDDERS_CONFERENCE": "Bidders Conference",
    "ADD_ANOTHER_CONFERENCE": "Add Another Conference",

    "CANCEL_EVENT": "CANCEL EVENT",
    "MORE_ACTIONS": "More Actions",
    "EDIT_EVENT": "EDIT EVENT",
    "EDIT_AUCTION_EVENT": "Edit Auction Event",
    "EDIT_RESERVE_SALE_EVENT": "Edit Reserve Sale Event",

    "EVENT_HISTORY": "Event History",

    "EVENT_NAME_ALREADY_EXISTS": "Event Name Already Exists",
    "NUMBER_OF_APPLICANTS_FOR_THIS_EVENT": "Number of Applicants for this Event",
    "ONLY_3_DIGITS_ACCEPTED": "Only 3 Digits Accepted",
    "EVENT_UPDATED_SUCCEFULLY": "Event updated succefully",
    "EVENT_CREATED_SUCCESSFULLY": "Event created successfully",
    "NOT_ALLOWED": "Not Allowed",
    "INVALID_DATE": "Invalid Date",

    "CANCEL_MODAL_TITLE": "Warning!",
    "CANCEL_MODAL_MESSAGE": "Clicking “Proceed” will Clear all the field details entered. Click Cancel to exit this message or Proceed to continue.",
    "CANCEL_MODAL_MESSAGE_EVENT": "Clicking “Continue” will Clear all the field details entered. Click Cancel to exit this message.",
    "CANCEL_EVENT_MODAL_MESSAGE": "Clicking 'Cancel Event' will Successfully cancel the event. Click Cancel to Exit this message or Cancel Event to continue.",
    "PROCEED": "Proceed",
    "CANCEL_EVENT_TITLE": "CANCEL EVENT",
    "YES": "Yes",
    "NO": "No",
    "UNCANCEL_EVENT": "UNCANCEL EVENT",
    /* Event Status */
    "SAVED": "Saved",
    "EVENT_APPLICATION_PERIOD_OPENED": "Event Application Period Opened",
    "EVENT_APPLICATION_PERIOD_CLOSED": "Event Application Period Closed",
    "EVENT_BIDDING_WINDOW_OPENED": "Event Bidding Window Opened",
    "EVENT_BIDDING_WINDOW_CLOSED": "Event Bidding Window Closed",
    "EVENT_CLOSED": "Event Closed ",

    /* ALL REPORTS PAGE */
    "ALL_REPORT": "ALL REPORTS",
    "COMPLIANCE_REPORT": "COMPLIANCE REPORTS",
    "EVENT_REPORTS": "EVENT REPORT",
    "MORE_OPTIONS_IN_EVENT_REPORT": "More Options In Event Report",
    "EMISSION": "EMISSIONS",
    "BATCH_TRUE_UP_QUANTITY": "BATCH TRUE-UP QUANTITY",
    "USERS_AUDIT_REPORT": "USER AUDIT REPORT",
    "FACILITY_AUDIT_REPORT": "FACILITY AUDIT REPORTS",
    "ENTITY_AUDIT_REPORT": "ENTITY AUDIT REPORTS",
    "ENTITY_INFORMATIONS": "ENTITY INFORMATION",
    "NOTIFICATION": "NOTIFICATIONS",
    "ACCOUNTS_BALANCE": "ACCOUNT BALANCE",
    "EVENT_RECONCILIATION_REPORT": "EVENT RECONCILIATION REPORTS",
    "ENTITY_NAME_HISTORY_REPORT": "Entity Name History Report",

    "EVENT_NAME_IN_ENGLISH": "Event Name In English",
    "EVENT_NAME_IN_FRENCH": "Event Name In French",
    "EVENT_DATE_ON_OR_AFTER_DATE": "Event Date on or After Date",
    "EVENT_DATE_ON_OR_BEFORE_DATE": "Event Date on or Before Date",
    "EVENT_DATE_ON_OR_AFTER": "Event Date on or After",
    "EVENT_DATE_ON_OR_BEFORE": "Event Date on or Before",
    "ACTION": "Action",

    "THE_ENTITY_NAME_HISTORY_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "The Entity Name History Report Is Ready! The Report Will Be Available For 7 Days after it has been generated..",
    "THE_ENTITY_AUDIT_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "The Entity Audit Report is ready! The report will be available for 7 days after it has been generated.",
    "THE_EVENT_APPLICATIONS_DATA_EXPORT_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "The Event Applications Data Export Report Is Ready. The Report Will Be Available For 7 Days after it has been generated.",
    "THE_EVENT_PARTICIPAT_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "The Event Participant Report Is Ready The report Will Be Available For 7 Days after it has been generated.",
    "ENTITY_USER_RELATIONSHIPS_RECONCILIATION_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "Entity User Relationships Reconciliation Report Is Ready The Report Will Be Available For",
    "QUALIFIED_BIDDERS_RECONCILIATION_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "Qualified Bidders Reconciliation Report Is Ready The Report Will Be Available For 7 Days after it has been generated.",
    "BID_LIMITATIONS_RECONCILIATION_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR_7_DAYS_AFTER_IT_HAS_BEEN_GENERATED": "Bid Limitations Reconciliation Report Is Ready The Report Will Be Available For 7 Days after it has been generated.",

    "SEARCH_RESULTS": "Search Results",

    "MORE_OPTIONS_IN_COMPLIANCE_REPORTS": "More Options in Compliance Reports",
    "MORE_OPTIONS_IN_ENTITY_AUDIT_REPORTS": "More Options in Entity Audit Reports",
    "MORE_OPTIONS_IN_ENTITY_INFORMATION": "More Options in Entity Information",

    "BUDGET_YEAR": "Budget Year",
    "RESET": "RESET",
    "GENERATE": "GENERATE",

    "ALL_REPORTS": "All Reports",
    "REPORTS": "Reports",
    "SEARCH": "Search",
    "COMPLIANCE_REPORTS": "Compliance Reports",
    "OBLIGATION_MANAGEMENT": "Obligation Management",
    "ENTITY_APPROVED_OBLIGATION_REPORT": "Entity Approved Obligation Report",
    "OBLIGATION_PERIOD_MANAGEMENT": "Obligation Period Management",
    "COMPLIANCE_STATUS_DETAILS": "Compliance Status Details",
    "ELIGIBLE_INSTRUMENTS_FOR_RETIREMENT": "Eligible Instruments for Retirement",
    "COMPLIANCE_OBLIGATIONS_DATA_EXPORT": "Compliance Obligations (Data Export)",
    "ENTITY_OBLIGATION_PERIOD_HOLDINGS_REPORT": "Entity Obligation Period Holdings Report",
    "TOTAL_QUANTITY_RETIRED_BY_OBLIGATION_PERIOD": "Total Quantity Retired by Obligation Period",
    "SUMMARY_OF_INSTRUMENTS_RETIRED_BY_OBLIGATION_PERIOD_VINTAGE_AND_TYPE": "Summary of Instruments Retired by Obligation Period, Vintage, and Type",
    "EMISSIONS": "Emissions",
    "FACILITY_EMISSIONS_REPORT": "Facility Emissions Report",
    "BATCH_EMISSIONS": "Batch Emissions",
    "BATCH_TRUE_-_UP_QUANTITY": "Batch True-Up Quantity",
    "TRUE_-_UP_QUANTITY": "True-Up Quantity",
    "USER_AUDIT_REPORT": "User Audit Report",
    "USER_AUDIT_REPORTS": "User Audit Reports",
    "USER_AUDIT_LOG_REPORT": "User Audit Log Report",
    "USER_CHANGES_REPORT": "User Changes Report",
    "USER_CHANGE_COMMENT_REPORT": "User Change Comment Report",
    "FACILITY_AUDIT_REPORTS": "Facility Audit Reports",
    "FACILITY_GHG_IDENTIFIER_CHANGE": "Facility GHG Identifier Change",
    "FACILITY_NAME_OPERATING_NAME_CHANGE": "Facility Name/Operating Name Change",
    "ENTITY_AUDIT_REPORTS": "Entity Audit Reports",
    "ENTITY_AUDIT": "Entity Audit",
    "ENTITY_CHANGE_COMMENT_REPORT": "Entity Change Comment Report",
    "ENTITY_LEGAL_NAME_OPERATING_NAME_CHANGE": "Entity Legal Name/Operating Name Change",
    "ENTITY_TYPE_HISTORY": "Entity Type History",
    "ENTITY_NAME_HISTORY": "Entity Name History",
    "SUSPENDED_REPRESENTATIVE_HISTORY": "Suspended Representative History",
    "REGISTERED_ENTITY_-_FACILITY": "Registered Entity - Facility",
    "ENTITY_REPRESENTATIVES": "Entity Representatives",
    "CROSS_JURISDICTION_ENTITY_REPRESENTATIVES": "Cross Jurisdiction Entity Representatives",
    "CROSS_JURISDICTION_USERS": "Cross Jurisdiction Users",
    "HOLDING_LIMIT_AND_LIMITED_EXEMPTION": "Holding Limit and Limited Exemption",
    "PURCHASE_LIMITS": "Purchase Limits",
    "NOTIFICATIONS": "Notifications",

    "ENTITY_ACCOUNT_BALANCE_REPORTS": "Entity Account Balance Reports",
    "JURISDICTION_ACCOUNT_BALANCE": "Jurisdiction Account Balance",
    "EVENT_REPORT": "Event Report",
    "EVENT_APPLICATIONS_DATA_EXPORT_REPORT": "Event Applications(Data Export) Report",
    "EVENT_PARTICIPANT_REPORT": "Event Participant Report",
    "AUCTION_HOLDING_LIMIT_FOR_AUCTION_PARTICIPANTS": "Auction: Holding Limit for Auction Participants",
    "AUCTION_CURRENT_VINTAGE_PURCHASE_LIMIT_FOR_AUCTION_PARTICIPANTS": "Auction: Current Vintage Purchase Limit for Auction Participants",
    "AUCTION_FUTURE_VINTAGE_PURCHASE_LIMIT_FOR_AUCTION_PARTICIPANTS": "Auction: Future Vintage Purchase Limit for Auction Participants",
    "AUCTION_PURCHASE_LIMIT_HOLDING_LIMIT_COMBINED_REPORT_FOR_AUCTION_PARTICIPANTS": "Auction Purchase Limit/Holding Limit Combined Report for Auction Participants",
    "WIRING_INSTRUCTIONS_STATUS_REPORT": "Consignment Wiring Instructions Status Report",
    "CONSIGNMENT_WIRING_INSTRUCTIONS_REPORT": "Consignment Wiring Instructions Report",
    "EVENT_RECONCILIATION_REPORTS": "Event Reconciliation Reports",
    "ENTITY_USER_RELATIONSHIPS_RECONCILIATION_REPORT": "Entity User Relationships Reconciliation Report",
    "QUALIFIED_BIDDERS_RECONCILIATION_REPORT": "Qualified Bidders Reconciliation Report",
    "BID_LIMITATIONS_RECONCILIATION_REPORT": "Bid Limitations Reconciliation Report",
    "FACILITY_OPERATING_NAME": "Facility Operating Name",
    "FACILITY_GHG_ID": "Facility GHG Emissions Reporting ID",
    "ENTITY_FACILITY_STATUS": "Entity-Facility Relationship Status",
    "STATUS_DATE_CHANGE": "Status Change Date",
    "ENTITY_REL_STATUS_DATE": "Relationship Status Change",
    "ENTITY_REG_DATE": "Entity Registration",
    "REGISTERED_ENTITY_FACILITY_REPORT": "Registered Entity Facility Report",
    "ENTITY_CHANGE_STATUS": "Entity Change Status",
    "ENTITY_LEGAL_NAME/OPERATING_NAME_CHANGE_REPORT": "Entity Legal Name/Operating Name Change report",
    "STATUS_NAME_CHANGE": "Status of Name Change",
    "THE_CONSIGNMENT_WIRING_INSTRUCTIONS_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "The Consignment Wiring Instructions Report is Ready. The Report Will be Available For",

    "PLEASE_SELECT_FUTURE_DATE_AND_TIME": "Please Select Current or Future Date And Time",
    "DATE_SHOULD_BE_BEFORE_EVENT_AFTER_DATE_ON_OR_AFTER": "Date Should Be Before Event After Date On Or After",
    "STATUS_OF_IDENTIFIER_CHANGE": "Status of Identifier Change",
    "NAICS_CODE_DISPLAY_MANAGEMENT": "NAICS Code Display Management",
    "NAICS_CODE_IN_ENGLISH": "NAICS code in English",
    "NAICS_CODE_IN_FRENCH": "NAICS code in French",
    "DISPLAYED_IN_DROP_DOWN": "Displayed in Drop-Down?",

    /* FSA All Reports */
    "FSA_REPORTS": "FSA REPORTS",
    "CURRENT_EVENT_APPLICATION": "current-event-applications_",
    "THE_FSA_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "The FSA Report is ready! The report will be available for",
    "AFTER_IT_HAS_BEEN_GENERATED": "after it has been generated.",
    "PASSWORD": "Password",
    "FAILED": "Failed",
    "EVENT_APPLICATIONS_FSA_REPORT": "Event Applications FSA Report",
    "REPORT_GENERATED_SUCCESSFULLY": "Report generated successfully! Password is required after downloading the Event Application FSA report. If you reload the page or log out of Registry, the report will no longer be available. ",
    "REPORT_GENERATED_SUCCESSFULLY_CWIR": "Report generated successfully! Password is required after downloading the Consignment Wiring Instructions Report. If you reload the page or log out of Registry, the report will no longer be available. ",
    "REPORT_GENERATE_SUCCESSFULLY": "Report generated successfully!",
    "WIV_REPORT_NAME": "WiringInstructionsVerifiedReport",

    /* Individual User All Reports */
    "INDIVIDUAL_USER_REPORTS": "ALL REPORTS",
    "TRANSFER_REPORTS": "TRANSFER REPORT",
    "TRANSFER_REPORT": "Transfer Report",
    "TRANSFER_HISTORY": "Transfer History",
    "JURISDICTION_BATCH_TRANSFER": "Jurisdiction Batch Transfer",
    "ADMINISTRATIVE_TRANSFERS": "Administrative Transfers",
    "ISSUANCE": "Issuance",
    "TRANSFER_EVENT_AUDIT": "Transfer Event Audit",
    "THE_EVENT_PARTICIPANT_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "The Event Participant Report is being generated! The report will be available for",

    /* FSA USER */
    "FSAA_ACTIONS": "FSA Actions",
    "AUCTION_REGISTRATION_XML": "Auction Registration XML",
    "REGISTRATION_SUPPORT_OF_AUCTION": "Information related to Registry support of auction registration is listed below. There is a button to generate the current Registry auction registration XML. The XML generated using this button is current as of the moment the button is clicked. Once the XML has been generated, it will be available for download via a link below",
    "GENERATE_XML": "Generate xml",
    "UPLOAD_CASH_FILE": "Upload Cash file",
    "BROWSE_FILES": "BROWSE FILES",
    "UPLOAD_HOLDING_FILE": "Upload Holding file",
    "FSA_GENERATE_XML_REPORT": "FSA Generate XML Report",
    "COMPLETED": "Completed",
    "IN_PROGRESS": "In Progress",
    "FILE_NAME": "File Name",
    "EMPTY_REPORTS_MESSAGE": "The report you are trying to generate is empty.",
    "Auction_Registration_": "Auction Registration",
    "REPORT_DOWNLOAD_SUCCESSFULLY": "Report Downloaded Successfully",
    "DUPLICATE_FILES_MESSAGE": "Duplicate files cannot be submitted",
    "FILE_SUCCESSFULLY_SUBMITTED": "File Submitted Successfully",
    "AUCTION_REGISTRATION": "auctionRegistration_",
    "GENERATE_MESSAGE": "Password is required after downloading the Event Application FSA report. If you download the report, the password will no longer be available.",
    "FILE_GENERATE_SUCCESSFULLY": "File generated successfully!",

    // market monitor
    "MARKET_MONITOR_REPORTS": "Market Monitor Reports",
    "AUCTION_ADMINISTRATOR_EVENT": "Auction Administrator Event",
    "EVENT_PARTICIPANT_REPORT_GENERATE_SUCCESSFULLY": "Event Participant Report Generated Successfully",
    "DATA_EXPORT_REPORT_GENERATE_SUCCESSFULLY": "Event Applications(Data Export) Report Generated Successfully",
    "REPORT_GENERATION_FAILED": "Report Generation Failed",
    "THE_DATA_EXPORT_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "The Event Applications(Data Export) Report is being generated! The report will be available for",
    "MARKET_MONITOR_ACTIONS": "MARKET MONITOR ACTIONS",


    /*  Reconciliation */
    "RECONCILE_SHORT_CONTENT": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet_.",
    "WELCOME": "Welcome!",
    "AUCTION_ADMINISTRATOR_ACTIONS": "AUCTION ADMINISTRATOR ACTIONS",
    "API_MGMT": "API Management",
    "RECONCILIATION": "RECONCILIATION",
    "UPDATED_DATE_AND_TIME": "Updated Date/Time",
    "ENTITY_USER_RE_REPORT": "EntityUsersReconciliationReport_",
    "QUALIFIED_BIDDER_RE_REPORT": "QualifiedBiddersReconciliationReport_",
    "BID_LIMIT_RE_REPORT": "BidLimitationsReconciliationReport_",
    "ENTITY_RELATIONSHIPS_RECONCILIATION_REPORT_GENERATE_SUCCESSFULLY": "Entity User Relationships Reconciliation Report Generated Successfully",
    "THE_ENTITY_RELATIONSHIP_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "Entity User Relationships Reconciliation Report is being generated! The report will be available for",
    "ENTITY_RELATIONSHIP_REPORT_GENERATE_SUCCESSFULLY": "Entity User Relationships Reconciliation Report Generated Successfully",
    "BID_LIMITATIONS_RECONCILIATION_REPORT_GENERATE_SUCCESSFULLY": "Bid Limitations Reconciliation Report Generated Successfully",
    "BID_LIMITATIONS_RECONCILIATION_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "The Bid Limitations Reconciliation Report is being generated! The report will be available for",
    "QUALIFIED_BIDDERS_RECONCILIATION_REPORT_GENERATE_SUCCESSFULLY": "Qualified Bidders Reconciliation Report Generated Successfully",
    "QUALIFIED_BIDDERS_RECONCILIATION_REPORT_IS_READY_THE_REPORT_WILL_BE_AVAILABLE_FOR": "Qualified Bidders Reconciliation Report is being generated! The report will be available for",

    /* EAR REPORT */
    "EVENT_APPLICATIONS_REPORT": "Event Applications Report",
    "EVENT_APPLICATION_REPORT": "Event Applications Report",
    "UPDATED_DATE(YYYY/MM/DD)_TIME(HH:MM)": "Updated Date(YYYY/MM/DD)/Time(HH:MM)",
    "UPDATED_DATE_FORMATED": "Updated Date(YYYY/MM/DD)/Time(HH:MM)",
    "SELECT_DATES": "Select Dates",
    "PAST_DATES": "Past Dates",
    "FUTURE_DATES": "Future Dates",
    "RESET_FILTER": "Reset Filter",
    "ENTITY_CHANGE_PROPOSED": "Entity Change Proposed",
    "ENTITY_CHANGE_APPROVED": "Entity Change Approved",

    // Report section
    "PAST_EVENT_APPLICATIONS": "Past-Event-Applications",
    "CURRENT_EVENT_APPLICATIONS": "Current-Event-Applications",
    /* MFS - Financial Services Delivery Instruction */
    "FINANCIAL_SERVICES_DELIVERY_INSTRUCTION": "Financial Services Delivery Instructions",
    "EDIT_FINANCIAL_SERVICE": "Edit Financial Service",
    "INSTRUCTIONS_FOR_WIRING_USD": "Instructions for Wiring USD",
    "CITY_COMPLETE_ADDRESS": "City Address",
    "BANK_NAME": "Bank Name",
    "BANK_NAME_PLACEHOLDER": "Bank Name",
    "ROUTING_NUMBER": "Routing Number",
    "ROUTING_NUMBER_PLACEHOLDER": "Routing Number",
    "SWIFT_BANK_IDENTIFIER_CODE_FOR_FINANCIAL_INSTITUTIONS_OUTSIDE_US": "SWIFT/Bank Identifier Code(For Financial Institutions Outside US)",
    "SWIFT_BANK_IDENTIFIER_CODE_FOR_FINANCIAL_INSTITUTIONS_OUTSIDE_US_PLACEHOLDER": "SWIFT/Bank Identifier Code..",
    "CITY_PLACEHOLDER": "City",
    "COMPLETE_ADDRESS": "Complete Address",
    "COMPLETE_ADDRESS_PLACEHOLDER": "Complete Address",
    "INSTRUCTIONS_FOR_WIRING_CAD": "Instructions for Wiring CAD",
    "DDA_NUMBER": "DDA Number",
    "DDA_NUMBER_PLACEHOLDER": "DDA Number",
    "BENEFICIARY_STREET_ADDRESS": "Beneficiary Street Address",
    "BENEFICIARY_STREET_ADDRESS_PLACEHOLDER": "Beneficiary Street Address",
    "BENEFICIARY_CITY": "Beneficiary City",
    "BENEFICIARY_CITY_PLACEHOLDER": "Beneficiary City",
    "BENEFICIARY_CITY_PROVINCE_STATE": "Beneficiary City, Province/State",
    "BENEFICIARY_PROVINCE_STATE": "Beneficiary Province/State",
    "BRANCH_TRANSIT_NUMBER": "Branch Transit Number",
    "BRANCH_TRANSIT_NUMBER_PLACEHOLDER": "Branch Transit Number",
    "BENEFICIARY_BANK_IBAN_OR_ACCOUNT_NUMBER": "Beneficiary Bank IBAN or Account Number",
    "BENEFICIARY_BANK_IBAN_OR_ACCOUNT_NUMBER_PLACEHOLDER": "Beneficiary Bank IBAN or Account Nu..",
    "BENEFICIARY_BANK_ACCOUNT_NAME": "Beneficiary Bank Account Name",
    "BENEFICIARY_BANK_ACCOUNT_NAME_PLACEHOLDER": "Beneficiary Bank Account Name",
    "INSTRUCTIONS_FOR_BID_GUARANTEE_INSTRUMENT": "Instructions for Bid Guarantee Instrument",
    "BENEFICIARY": "Beneficiary",
    "BENEFICIARY_PLACEHOLDER": "Beneficiary",
    "MFS_INSTRUCTIONS_FOR_BID_GUARANTEE_SHORT": "Instructions for Bid Guarantee Instrument Instructions for Bid Guarantee Instrument Instructions for Bid Guarantee Instrument",
    "C_O": "C/O",
    "ADDRESS_1": "Address 1",
    "ADDRESS_2": "Address 2",
    "DIRECTIONS": "Directions",
    "PHYSICAL_BID_GUARANTEE_INSTRUMENT": "Physical Bid Guarantee Instrument",
    "PHYSICAL_BID_GUARANTEE_INSTRUMENT_SHORT": "Please include your Entity Legal Name and Financial Services Account Number when mailing the bid guarantee forms. For questions on bid guarantee submittal or the financial settlement process, please contact Bank Company at bank.wcisupport@db.com. For additional contact information, please see the Auction Notice.",
    "ELECTRONIC_BID_GUARANTEE_INSTRUMENT": "Electronic Bid Guarantee Instrument",
    "ELECTRONIC_BID_GUARANTEE_INSTRUMENT_SHORT": "To submit an electronic copy of a bid guarantee, the issuing bank should deliver the electronic document with wet signatures (pdf) by email to bank.wcisupport@db.com and include the following statement in the email: “This [Letter of Credit or Letter of Guarantee] signed by the issuing bank is legally valid and enforceable. The issuing bank is transmitting this [Letter of Credit or Letter of Guarantee] to the beneficiary by electronic transmission (PDF via email) to BANK.WCISUPPORT@DB.COM. For the avoidance of doubt, any electronic PDF version of this [Letter of Credit or Letter of Guarantee] received by the beneficiary shall be the operative instrument and may be used by the beneficiary as it would be a hardcopy original. The beneficiary is able to submit a draw request on the operative instrument. In addition when applicable, the beneficiary will reply to all addressees on this email to confirm cancellation of the electronic operative instrument. The reply email will serve as the return of the bid guarantee.”",
    "MAILING_POSTALCODE_NU": "Postal Code",
    "MAILING_POSTALCODE_NU_PLACEHOLDER": "10005-2836",
    "PAYMENT_DETAILS_IN_ENGLISH": "Payment Details in English",
    "PAYMENT_DETAILS_IN_FRENCH": "Payment Details in French",

    "ENTITY_APPLICATION_PENDING": "Entity Application Pending",
    "GENERATE_FINANCIAL_SERVICE_INSTRUCTIONS": "GENERATE FINANCIAL SERVICE INSTRUCTIONS",
    "GENERATE_FINANCIAL_SERVICE_INSTRUCTIONS_TITLE": "Generate Financial Service Instructions",
    "BENEFICIARY_DETAILS": "Beneficiary Details",
    "BID_GUARANTEE": "Bid Guarantee",
    "FINANCIAL_SERVICES_ACCOUNT_NAME": "Financial Services Account Name",
    "FINANCIAL_SERVICES_ACCOUNT_NUMBER": "Financial Services Account Number",
    "SWIFT_BANK_IDENTIFIER_CODE_FINANCIAL_INS": "SWIFT/Bank Identifier Code (for financial institutions outside US)",
    "CITY_COMPLETE_ADDRESS_FINANCIAL_INS": "City/Complete Address",
    "INSTRUCTIONS_USD_BENEFICIARY": "Beneficiary",
    "PORT": "Port",

    // Service Provider Selection & Management
    "SERVICE_PROVIDER_SELECTION_MANAGEMENT": "Service Provider Selection & Management",
    "SERVICE_PROVIDERS": "Service Providers",
    "AUCTION_ADMINISTRATIVE": "Auction Administrator",
    "RESET_EFFECTIVE_DATE": "Reset Effective Date",
    "RESET_EFFECTIVE_DATE_CONTENT": "Reset Effective Date for Event Last Four Characters of the Beneficiary Account Number",
    "RESET_EFFECTIVE_DATE_LOREM_CONTENT": "Lorem ipsum dolor sit amet consectetur. Posuere at arcu congue maecenas quam suscipit. Blandit non volutpat et mauris commodo gravida ut diam. Mus orci leo sed at dictum varius adipiscing sem. Nulla aenean molestie consectetur ac. Nunc et aliquet condimentum nulla nunc suspendisse faucibus velit et. Tortor sed pulvinar mollis sit convallis diam enim.",
    "CONFIRM_UPDATE_EFFECTIVE_DATE": "Confirm Update Effective Date",
    "CONFIRMATION": "Confirmation",


}

export const LocalizationData = {
    "Page_JurisdictionEntityAction": {
        ...COMMON,
        "(G)_ANNUAL_ALLOCATION_HOLDING": "(G) ANNUAL ALLOCATION HOLDING",
        "(C)_ANNUAL_ALLOCATION_HOLDING": "(C) ANNUAL ALLOCATION HOLDING",
        "NEITHER": "NEITHER",
        "ALL_OTHER_STATES_AND_PROVINCES": "All other States and Provinces",
        "ALTERNATE_ACCOUNT_REPRESENTATIVE": "Alternate Account Representative",
        "ALTERNATE_CONTACT_PERSON": "Alternate Contact Person",
    },
    "Page_UserHome": {
        ...COMMON,
        "Can_not_cancel_Entity_Because_Entity_is_in_CANCELLED": "Can not cancel entity because entity is in Cancelled."
    },
    "Page_JurisdictionUserHome": {
        ...COMMON
    },
    "Page_EntityRegistration": {
        ...COMMON,
        'CHANGE_ENTITY_TYPE_WARNING_MESSAGE3': 'Click "Continue" with new entity type or exit this error message by clicking "Cancel"'
    },
    "Page_AssociatedRetiredFacility": {
        ...COMMON
    },
    "Page_EntityProfile": {
        ...COMMON
    },
    "Page_UserProfile": {
        ...COMMON
    },
    "Page_WiringInstructions": {
        ...COMMON
    },
    "Page_OffsetManagement": {
        ...COMMON
    },
    "Page_ConfigurationSetting": {
        ...COMMON
    },
    // "EventListing": {
    //     ...COMMON
    // },
    // "EventViewAndManagement": {
    //     ...COMMON
    // },
    // "AddEventCreationAndManagement": {
    //     ...COMMON
    // },
    // "Page_EventCreationAndManagement": {
    //     ...COMMON
    // },
    // "EventListingPage": {
    //     ...COMMON
    // },
    // "Page_EventApplication": {
    //     ...COMMON
    // },
    // "Page_EventApplicationManagement": {
    //     ...COMMON
    // },
    // "Page_EventApplicationJuri": {
    //     ...COMMON
    // },
    // "Page_AllReports": {
    //     ...COMMON
    // },
    // "Page_FSAAllReports": {
    //     ...COMMON
    // },
    // "Page_IndividualUsersAllReports": {
    //     ...COMMON
    // },
    // "Page_EarReports": {
    //     ...COMMON
    // },
    // "Page_EventParticipantReport": {
    //     ...COMMON
    // },
    // "Page_EADEReport": {
    //     ...COMMON
    // },
    // "Page_EURelationshipsReconciliationReport": {
    //     ...COMMON
    // },
    // "Page_QualifiedBidderReconciliationReport": {
    //     ...COMMON
    // },
    // "Page_BidLimitationsReconciliationReport": {
    //     ...COMMON
    // },
    // "Page_FSAHome": {
    //     ...COMMON
    // },
    // "Page_RegistryEntityFacilityReport": {
    //     ...COMMON
    // },
    // "Page_EntityLegalNameOperatingNameChange": {
    //     ...COMMON
    // },
    // "Page_FinancialServicesDeliveryInstruction": {
    //     ...COMMON
    // },
    // "Page_FSAReport": {
    //     ...COMMON
    // },
    // "Page_FacilityGHGIdentifierChange": {
    //     ...COMMON
    // },
    // "Page_FacilityLegalOpNameChange": {
    //     ...COMMON
    // },
    // "Page_NAICSCodeDisplayManagement": {
    //     ...COMMON
    // },
    // "Page_WiringInstructionsStatusReport": {
    //     ...COMMON
    // },
    // "Page_AuctionAdmin": {
    //     ...COMMON
    // },
    // "Page_MarketMonitor": {
    //     ...COMMON
    // },
    // "Page_ConsignmentWiringInstructionsStatusReport": {
    //     ...COMMON
    // },
    // "Page_ServiceProviderSelectionManagement": {
    //     ...COMMON
    // },
    // "Page_ConsignmentWiringInstructionsReport": {
    //     ...COMMON
    // },
    // "Page_MarketMonitorAllReport": {
    //     ...COMMON
    // },
    // "Page_AuctionAdministrtorAllReport": {
    //     ...COMMON
    // },
}