import { setEntityGridFilter } from "store/jurisdictionUserHomeEntityGridFilterSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useAuth from "hooks/useAuth";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setRouteState } from "store/userSlice";
import { NAvalue } from "utils/constants";
import useMenu from "./useMenu";

function MenuComponent() {
  const theme = useTheme();
  const { routesMenu } = useMenu();
  const { routeState } = useSelector(({ user }) => user);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElP, setAnchorElP] = useState(null);
  const [activeSubRoute, setActiveSubRoute] = useState(null);
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const { menu } = useSelector(({ auth }) => auth);

  const { PrivAttributes } = menu;

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, path) => {
    setActiveSubRoute(path);
    if (event?.target?.id === path) {
      setAnchorEl(event.currentTarget);
    }
  };

  const popupStateClose = (popupState) => {
    popupState.close();
  };

  const onRouteClick = (route, popupState) => {
    popupStateClose(popupState);
    dispatch(setRouteState({ path: route?.id, toggle: !routeState.toggle }));
  };

  const handleClickPOBtn = (event) => {
    setAnchorElP(event.currentTarget);
  };

  const showSubmenu = (route, popupState) => {
    if (route?.subRoutes && route?.subRoutes?.length) {
      return (
        <div>
          <Box
            onClick={(e) => handleClick(e, route.path)}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Button
              disableRipple
              id={route.path}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                color: "#fff",
                fontSize: "14px",
                textTransform: "none",
                padding: "0 8px",
                "&:focus-visible": {
                  backgroundColor: "transparent",
                  textTransform: "none",
                  textDecoration: "underline"
                },
                "&:hover": {
                  backgroundColor: "transparent",                  
                  textTransform: "none",
                },
              }}
            >
              {route.name}
              {open && route.path === activeSubRoute ? (
                <KeyboardArrowDownIcon id={route.path} />
              ) : (
                <KeyboardArrowRightIcon id={route.path} />
              )}
            </Button>
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && route.path === activeSubRoute}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": route.name,
            }}
            sx={{
              "& .MuiMenu-list": {
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                listStyle: "none",
              },
            }}
          >
            {route.path === activeSubRoute &&
              route?.subRoutes?.map((item) => [
                <MenuItem
                sx={{ 
                  fontSize: "14px", 
                  listStyleType: "none", 
                  color: "common.white",
                  "&:focus-visible": {
                    backgroundColor: "transparent",
                    textTransform: "none",
                    textDecoration: "underline"
                  },
                  "&:hover": {
                    backgroundColor: "transparent",                  
                    textTransform: "none",
                    fontWeight: 700, cursor: "pointer"
                  },
                }}
                  key={item.path}
                  onClick={() => {
                    handleClose();
                    onRouteClick(item, popupState);
                    navigate(item?.path);
                  }}
                >
                  <Link
                    to={item.path}
                    key={item.path}
                    style={{color:"white", textDecoration: "none"}}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClose();
                      onRouteClick(item, popupState);
                    }}
                  >
                    {item?.name}
                  </Link>
                </MenuItem>,
              ])}
          </Menu>
        </div>
      );
    }

    return (
      //  main route
      <MenuItem
        sx={{
          fontSize: "14px",
          pl: 1,
          py: 0,
          "&:hover": { cursor: "pointer", textDecoration: "underline", fontWeight: 500 },
        }}
        key={route.path}
        onClick={() => {
          handleClose();
          onRouteClick(route, popupState);
        }}
      >
        <Link
          to={route.path}
          key={route.path}
          style={{
            textDecoration: "none",
            color: "white",
            "&:focus": { cursor: "pointer", textDecoration: "underline", fontWeight: 500 },
          }}
        >
          {route?.name}
        </Link>
      </MenuItem>
    );
  };

  return (
    <PopupState
      variant="popover"
      popupId="demo-popup-popover"
      disableAutoFocus
    >
      {(popupState) => (
        <div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: { lg: "80px", md: "64px", sm: "40px" },
            }}
            {...bindTrigger(popupState)}
          >
            <Button
              disableRipple
              onClick={handleClickPOBtn}
              sx={{
                color: "#fff",
                textTransform: "none",
                "&:hover": { fontWeight: 700, cursor: "pointer" },
                // border: popupState?.isOpen ? "1px solid #fff" : "none",
                fontWeight: popupState?.isOpen ? 700 : 500,
                fontSize: ["7px","14px"],
                minWidth:["auto","64px"]
              }}
            >
              MENU
              <ArrowDropDownIcon sx={{ color: "#fff", width: ["12px", "24px"], height: ["12px", "24px"] }} />
            </Button>
          </Box>

          <Popover
            {...bindPopover(popupState)}
            anchorEl={anchorElP}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ mt: 1, borderRadius: "10px", border: "1px solid #fff" }}
            className="header-menu-popup-state"
          >
            <Box
              sx={{
                minHeight: "100px",
                background: "#283459",
                color: "#fff",
                p: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: "1rem 0",
                }}
              >
                {routesMenu
                  .filter((item) => {
                    let data;
                    if (item.display === false) {
                      return null;
                    }
                    if (
                      item.signIn &&
                      isSignedIn &&
                      !item.isComponent &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (
                      item.signIn === false &&
                      !isSignedIn &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (
                      item.display &&
                      !item.isComponent &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (item.signIn && isSignedIn && item.display && item.isComponent) {
                      data = item;
                    }
                    return data;
                  })
                  .map((header, index) => (
                    <Box
                      key={header.id}
                      // sx={{ flex: "33.33%" }}
                      sx={{
                        flex: "1 0 100%", 
                        '@media (min-width: 600px)': {
                          flex: "0 0 50%",
                        },
                        '@media (min-width: 900px)': {
                          flex: "0 0 33.33%",
                        },
                      }}
                    >
                      <Typography>{header.name}</Typography>

                      {header.routes
                        ?.filter((item) => {
                          let data;
                          if (item.display === false) {
                            return null;
                          }
                          if (
                            item.signIn &&
                            isSignedIn &&
                            !item.isComponent &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (
                            item.signIn === false &&
                            !isSignedIn &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (
                            item.display &&
                            !item.isComponent &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (item.signIn && isSignedIn && item.display && item.isComponent) {
                            data = item;
                          }
                          return data;
                        })
                        .map((route) => (
                          <Box
                            key={route.path}
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={
                              () => {
                                if (route.name === "Account Applications") {
                                  dispatch(setEntityGridFilter({ 
                                    filter: {
                                      filterModel: {
                                        items: [{ columnField: 'requestStatus', operatorValue: 'contains', "id": 1, value: 'Entity Application Pending' }],
                                      },
                                    }
                                  }
                                  ))
                                }
                                if (route.name === "Proposed Entity Changes") {
                                  dispatch(setEntityGridFilter({ 
                                    filter: {
                                      filterModel: {
                                        items: [{ columnField: 'requestStatus', operatorValue: 'contains', "id": 2, value: 'Entity Change Proposed' }],
                                      },
                                    }
                                  }
                                  ))
                                }
                              }
                            }
                          >
                            {showSubmenu(route, popupState)}
                          </Box>
                        ))}
                    </Box>
                  ))}
              </Box>
            </Box>{" "}
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default MenuComponent;
