import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ENTITY_TYPE_NAMES,
  formatDate,
  JURISDICTIONS_NAMES,
  COUNTRY_KEY_NAMES,
  t,
} from "utils/constants";
import Style from "./ApplicationChecklist.style";

const PageName = "Page_ApplicationWithAttenstation";
const EntityRegistrationPage = "Page_EntityRegistration";

function EntityIdentification({ entityData }) {
  const {
    entityIdentifier,
    entityType,
    jurisdiction,
    proposeChanges,
  } = entityData || {};

  const {
    incorporationCountry: proposedIncorporationCountry,
    incorporationRegion: proposedIncorporationRegion,
    incorporationStateOrProvince: proposedIncorporationStateOrProvince,
    dateOfIncorporation: proposedDateOfIncorporation,
    governmentIssedTaxpayerOrEmployerIdentificationNumber: proposedGovernmentIssedTaxpayerOrEmployerIdentificationNumber,
    idNumberAssignedByIncorporatingAgency: proposedIdNumberAssignedByIncorporatingAgency,
    idNumberAssignedByTheCaliforniaSecretaryOfState: proposedIdNumberAssignedByTheCaliforniaSecretaryOfState,
    québecEnterpriseNumber: proposedQuébecEnterpriseNumber,
    dunsNumber: proposedDunsNumber
  } = proposeChanges?.entityIdentifier || {}

  const showState = (proposedIncorporationCountry?.name === COUNTRY_KEY_NAMES.UNITED_STATES || proposedIncorporationCountry?.name === COUNTRY_KEY_NAMES.CANADA || entityIdentifier?.incorporationCountry?.name === COUNTRY_KEY_NAMES.UNITED_STATES || entityIdentifier?.incorporationCountry?.name === COUNTRY_KEY_NAMES.CANADA);
  const { t: t1 } = useTranslation(PageName);
  const { t: tEntityRegistration } = useTranslation(EntityRegistrationPage);
  
  const incorporationRegion = t(tEntityRegistration, proposedIncorporationStateOrProvince?.name) || proposedIncorporationRegion ||  t(tEntityRegistration, entityIdentifier?.incorporationStateOrProvince?.name) || entityIdentifier?.incorporationRegion
  const incorporationCountry = t(tEntityRegistration, proposedIncorporationCountry?.name || entityIdentifier?.incorporationCountry?.name) || "NA"
  const dateOfIncorporation = formatDate(proposedDateOfIncorporation || entityIdentifier?.dateOfIncorporation)
  const entityName = proposeChanges?.entity?.entityType?.name || entityType?.name
  const governmentIssedTaxpayerOrEmployerIdentificationNumber = proposedGovernmentIssedTaxpayerOrEmployerIdentificationNumber || entityIdentifier?.governmentIssedTaxpayerOrEmployerIdentificationNumber
  const idNumberAssignedByIncorporatingAgency = proposedIdNumberAssignedByIncorporatingAgency || entityIdentifier?.idNumberAssignedByIncorporatingAgency
  const idNumberAssignedByTheCaliforniaSecretaryOfState = proposedIdNumberAssignedByTheCaliforniaSecretaryOfState || entityIdentifier?.idNumberAssignedByTheCaliforniaSecretaryOfState
  const québecEnterpriseNumber = proposedQuébecEnterpriseNumber || entityIdentifier?.québecEnterpriseNumber
  const dunsNumber = proposedDunsNumber || entityIdentifier?.dunsNumber
  
  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, "ENTITY_IDENTIFICATION")}</Typography>
    <Box sx={Style.entry}>
    <Typography sx={Style.entryAttribute}> {showState ? `${t(t1, "INCORPORATION_PROVINCE_OR_STATE")}` : `${t(t1, "REGION")}`}</Typography>
    <Typography sx={Style.entryValue}>{incorporationRegion}</Typography> 
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "INCORPORATION_COUNTRY")}</Typography>
      <Typography sx={Style.entryValue}>{incorporationCountry}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "DATE_OF_INCORPORATION")}</Typography>
      <Typography sx={Style.entryValue}>{dateOfIncorporation}</Typography>
    </Box>
    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.CALIFORNIA].includes(jurisdiction?.name)) ||
    ([ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) && [JURISDICTIONS_NAMES.QUEBEC].includes(jurisdiction?.name)) ?
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "GOVERNMENT_ISSUED_TAXPAYER_OR_EMPLOYER_IDENTIFICATION_NUMBER")}</Typography>
      <Typography sx={Style.entryValue}>{governmentIssedTaxpayerOrEmployerIdentificationNumber}</Typography>
    </Box> :
      null}
    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
        [JURISDICTIONS_NAMES.CALIFORNIA, JURISDICTIONS_NAMES.QUEBEC, JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(jurisdiction?.name)) ?
      <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ID_NUMBER_ASSIGNED_BY_INCORPORATING_AGENCY")}</Typography>
      <Typography sx={Style.entryValue}>{idNumberAssignedByIncorporatingAgency}</Typography>
    </Box> :
      null}
    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.CALIFORNIA].includes(jurisdiction?.name)) ?
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, "ID_NUMBER_ASSIGNED_BY_THE_CALIFORNIA_SECRETARY_OF_STATE")}</Typography>
      <Typography sx={Style.entryValue}>{idNumberAssignedByTheCaliforniaSecretaryOfState}</Typography>
    </Box> :
      null }

    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.NOVA_SCOTIA].includes(jurisdiction?.name)) ?
      <>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, "BUSINESS_NUMBER_ASSIGNED_BY_THE_CANADA_REVENUE_AGENCY")}</Typography>
          <Typography sx={Style.entryValue}>{entityIdentifier?.businessNumberAssignedByTheCanadaRevenueAgency}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, "REGISTRY_ID_ASSIGNED_BY_SERVICE_NOVA_SCOTIA_UNDER_THE_REGISTRY_OF_JOINT_STOCK_COMPANIES")}</Typography>
          <Typography sx={Style.entryValue}>{entityIdentifier?.registryIdAssignedByServiceNovaScotiaUnderTheRegistryOfJointStockCompanies}</Typography>
        </Box>
      </>:
      null }

    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.QUEBEC].includes(jurisdiction?.name)) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "QUEBEC_ENTERPRISE_NUMBER")}</Typography>
        <Typography sx={Style.entryValue}>{québecEnterpriseNumber}</Typography>
      </Box> :
      null }

    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.QUEBEC, JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(jurisdiction?.name)) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "DUNS_NUMBER")}</Typography>
        <Typography sx={Style.entryValue}>{dunsNumber}</Typography>
      </Box> :
      null }

    {([ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION, ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY].includes(entityName) &&
      [JURISDICTIONS_NAMES.WASHINGTON_STATE].includes(jurisdiction?.name)) ?
      <Box sx={Style.entry}>
        <Typography sx={Style.entryAttribute}>{t(t1, "WASHINGTON_UNIFIED_BUSINESS_IDENTIFIER_NUMBER_UBI_REQUIRED_IF_APPLICABLE")}</Typography>
        <Typography sx={Style.entryValue}>{entityIdentifier?.washingtonUnifiedBusinessIdentifierNumberUBI}</Typography>
      </Box> :
      null }
  </>;
}

export default EntityIdentification;
