import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StyledCardTitle } from "pages/AccountDetails/AccountDetails.style";
import { StyledForm } from "pages/ManageBatchTransfer/ManageBatchTransfer.style";
import { NASlashValue, TitleTypography } from "utils/constants";

export default function KeyValueCard({ title, data, flp = false, subtitle = "" }) {
  const theme = useTheme();
  const _htmlField = (item) => (
    <Box
      key={item.id}
      sx={{ gridColumn: item?.gridColumn || "auto", alignSelf: item?.value ? 'self-end' : 'auto' }}
    >
      <TitleTypography>
        {item.title}
        {item.required && <Box sx={{ color: theme.palette.common.red, ml: 1 }}>*</Box>}
      </TitleTypography>
      {item.value || item?.noNASlashValue ? (
        <Typography
          variant="body2"
          sx={{ maxHeight: item?.maxHeight || "60px", overflowY: "auto" }}
        >
          {typeof item.value === "string" ? item.value?.replace("---", "") : item.value}
        </Typography>
      ) : (
        item.title && (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.common.textGray }}
          >
            {NASlashValue}
          </Typography>
        )
      )}
    </Box>
  );

  const renderBlock = (item) => {
    if (!("access" in item)) return null;

    if (item.access?.includes("read") || item.access?.includes("write")) {
      return _htmlField(item);
    }
    return null;
  };
  return (
    <StyledForm>
      <StyledCardTitle variant="h2">{title}</StyledCardTitle>
      {subtitle && <Typography sx={{ fontSize: "16px", mt: 3, mb: -1.5 }}>{subtitle}</Typography>}
      <Box
        sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr 1fr" }, gap: '20px 32px' }}
      >
        {flp ? data?.map((item) => renderBlock(item)) : data?.map((item) => _htmlField(item))}
      </Box>
    </StyledForm>
  );
}
