import React, { useEffect, useState } from "react";
import i18n from "config/lang/i18n";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/* Conflict Resolution Candidate
import { useTranslation } from "react-i18next";
 */
import FormControl from "@mui/material/FormControl";
import { useEffectOnce } from "hooks/useEffectOnce";
import MenuItem from "@mui/material/MenuItem";
import { getCMSDetails, getFileUrl } from "store/configSlice";
import { setLanguage } from "store/jurisdictionSlice";
/* Conflict Resolution Candidate
import { setDialogMessage } from "store/commonSlice";
import Select from "@mui/material/Select";
import { defaultORGID, getAllLang, getAllData, LANGUAGES, PAGES, enLanguage, docFilesPageName, getFormLabels, getAllLabels, LandingPageName, getCurrentORGID } from "utils/constants";

export default function SelectLanguage() {
  // const { i18n } = useTranslation();
 */
import Select from "@mui/material/Select";
import { defaultORGID, getAllLang, getAllData, LANGUAGES, PAGES, enLanguage, docFilesPageName, getFormLabels, getAllLabels, LandingPageName, DEFAULT_LANGUAGE_CODE } from "utils/constants";

export default function SelectLanguage() {
  const dispatch = useDispatch();
  const [languagesData, setLanguageData] = useState([]);
  const { cmsLanguagePage } = useSelector(({ config }) => config);
  const {formSteps: { activeStep }, filledData } = useSelector(({ createUserProfile }) => createUserProfile);
/* Conflict Resolution Candidate
  const { selectedJurisdiction, Jurisdictions } = useSelector(({ jurisdiction }) => jurisdiction);
  const languages = [];
  const language = enLanguage()
 */
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const languages = [];
  const language = localStorage.getItem("i18nextLng")

  // router hooks and methods
  const location = useLocation();
  const currentPageName = PAGES?.find((eachPage) => eachPage?.path === location?.pathname)?.name; // To find current page name from constants

/* Conflict Resolution Candidate
  // console.log(currentPageName);

  const handleLanguageChange = (event) => {
    if (currentPageName === "Page_Content_Management") window.location.reload();
      // window.location.reload();
      // console.log(event);
      // console.log(currentPageName, enLanguage());
      dispatch(setLanguage(`${event.target.value}`));
      i18n.changeLanguage(`${getCurrentORGID()}#${event.target.value}`);
 */

  const handleLanguageChange = (event) => {
    if (currentPageName === "Page_Content_Management") window.location.reload();
      dispatch(setLanguage(`${event.target.value}`));
      i18n.changeLanguage(`${selectedJurisdiction}#${event.target.value}`);
      localStorage.setItem('languageCode', languagesData?.find(item => event.target.value === item.value)?.languageCode || DEFAULT_LANGUAGE_CODE)
      const getDocFilePayload = {
        OrgID: filledData?.[1]?.jurisdiction?.OrgID || selectedJurisdiction,
        QueryType: getFormLabels,
        PageLanguage: event.target.value || enLanguage(),
        Page: docFilesPageName,
      };
      dispatch(getFileUrl(getDocFilePayload));

      window.location.reload();
/* Conflict Resolution Candidate
      // i18n.loadNamespaces(localStorage.getItem("pageName") || "Page_LandingPageKnown"); // to get current page labels
 */
  };

  useEffectOnce(() => {
    const LanguageParams = {
      OrgID: selectedJurisdiction || defaultORGID,
      QueryType: getAllLang,
    };

    dispatch(getCMSDetails(LanguageParams));
  }, []);

  useEffect(() => {
    cmsLanguagePage?.data?.Languages?.map((item, index) => {
      languages.push({
        value: item?.SK?.split("#")?.pop(),
        title: item?.SK?.split("#")?.pop(),
        languageCode: item?.LanguageCode || DEFAULT_LANGUAGE_CODE,
      });
      return null;
    });
    setLanguageData(languages);
  }, [cmsLanguagePage]);

  return (
    <FormControl>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={language}
        onChange={handleLanguageChange}
        sx={{
          fontSize: "0.875rem",
          color: "common.textColor",
          ml: 0.5,
          ".MuiSelect-select": {
            p: 0,
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            outline: "none",
          },
        }}
      >
        {languagesData.map((language, index) => (
/* Conflict Resolution Candidate
          <MenuItem key={index} value={language.value}>
*/
          <MenuItem  key={`lang_${index}`} value={language.value}>
            {language.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
