import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { Cookies } from 'react-cookie';
import { useForm } from "react-hook-form";
/* Conflict Resolution Candidate
import { useTranslation } from "components/Loader";
 */
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { FormProvider } from "components/FormProvider";
import { RHFCheckbox } from "components/RHFCheckbox";
import { SecurityQuestionModal } from "components/SecurityQuestionModal";
import useAuth from "hooks/useAuth";
import useIsMountedRef from "hooks/useIsMountedRef";
import { setLoader, setModelMessage } from "store/commonSlice";
import { getConfigurations } from "store/configSlice";
import { setJurisdiction } from "store/jurisdictionSlice";
import { checkUserIfIsBlocked } from "store/userSlice";
import { emailPattern, ERROR, getCurrentPageName, isEmpty, JURISDICTION_USER_ACTION_FORM_FIELDS, notAuthorizedException, parseOptions, spaceImportant, specialCharImportant, SUCCESS, t, USER_ID_VALIDATION, userNotAuthorizedException, userNotFoundException, validateUserIDPattern } from "utils/constants";
import { decrypt, encrypt } from "utils/EncryptDecrypt";
import { Loader } from "components/Loader";
import CommonModal from "../Modal/AuthCommonModal";
import Style from "./styles";

function LoginForm({ loginOnSubmit }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const cookies = new Cookies();

  // ContexAPI states and methods
  const { signIn, forgotpassword, forgotUserID,loader } = useAuth();

  // router hooks and methods
  const location = useLocation();

  // Redux states
  // const { securityQuestions } = useSelector(({ userHome }) => userHome);
/* Conflict Resolution Candidate
  const { modelMessage, loader } = useSelector(({ common }) => common);
  const { user } = useSelector(({ auth }) => auth);
  const { config } = useSelector(({ config }) => config);
  const { PrivAttributes } = user;
 */
  const { modelMessage } = useSelector(({ common }) => common);
  const { config } = useSelector(({ config }) => config);

  // React states
  const [showPassword, setShowPassword] = useState(false);
  const [isUserModal, setIsUserModal] = useState(false);
  const [isPassModal, setIsPassModal] = useState(false);
  const [isUserId, setIsUserId] = useState(null);
  const [response, setResponse] = useState(null);
  const [isInValidEmailMessage, setIsInValidEmailMessage] = useState(false);
  const [isInValidUserIdMessage, setIsInValidUserIdMessage] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);
  const [securityQuestions, setSecurityQuestions] = useState({});
  const [cognitoUser, setCognitoUser] = useState({});
  const [shrink1, setShrink1] = useState(false);

  // Ref's
  const isMounted = useIsMountedRef();
  const textRef = useRef();
  const userNameRef = useRef();

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
    notify: "",
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues,
  });

  const [isRememberMe, setIsRememberMe] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting, touchedFields },
    setValue,
    watch
  } = methods;

  const email = watch("email")

  // Set's remembered UserName in "email" textfield
  useEffect(() => {
    if (config['login-settings']?.remember_userId?.AttributeValue && decrypt(cookies.get('userName'))) {
/* Conflict Resolution Candidate
      userNameRef?.current?.focus();
      setShrink1(true)
      setTimeout(() => setValue('email', decrypt(cookies.get('userName'))), 10);
      clearErrors();
    } else if ((!isEmpty(getValues('email')) && isEmpty(getValues('password')))) {
      setShrink1(false);
      setValue('email', '');
 */
      setShrink1(true)
      setTimeout(() => setValue('email', decrypt(cookies.get('userName'))), 100);
      clearErrors();
    }
    setIsRememberMe(config['login-settings']?.remember_userId?.AttributeValue && decrypt(cookies.get('userName')))
  }, [config]);

  const onSubmit = async (data) => {
    const clientMetaData = {
        clientTokenCandidate: localStorage.getItem('clientTokenCandidate'),
    };
    data.clientMetaData = clientMetaData;
    if (typeof loginOnSubmit === "function") {
      await loginOnSubmit(data.email?.trim(), data.password?.trim(), clientMetaData);
    } else {
      try {
/* Conflict Resolution Candidate
        textRef?.current?.blur();
        const response = await signIn(data);

        if (isRememberMe) {
          cookies.set('userName', encrypt(methods.getValues('email')?.toLowerCase()), { path: '/', maxAge: parseInt(config['login-settings']?.remember_userId_days?.AttributeValue || 1, 10) * 86400, sameSite: 'strict', secure: true });
 */
        textRef?.current.blur();
        const response = await signIn(data);

        if (isRememberMe) {
          cookies.set('userName', encrypt(methods.getValues('email')), { path: '/', maxAge: parseInt(config['login-settings']?.remember_userId_days?.AttributeValue || 1, 10) * 86400, sameSite: 'strict', secure: true });
        } else {
          cookies.remove('userName');
        }
        if (response && response.challenge) {
          setCognitoUser(response.cognitoUser);
          setSecurityQuestions(response.cognitoUser.challengeParam);
          setSecurityModal(true);
/* Conflict Resolution Candidate
          // document.body.style.overflow="hidden";
        }
        else if (response) {
          // dispatch(setJurisdiction(response?.OrgID));
 */
        }
        else if (response) {
          dispatch(setJurisdiction(response?.OrgID));
        }
      } catch (error) {
        clearErrors();
        setShowPassword(false);
        // If user/registrar has raised Reset passphrase request then show below message.
        if (error?.message?.includes('RESET_PASSPHASE_REQUESTED_AND_ACTIVE')) {
          const data = {
            title: ERROR,
            message1: `${t(t1, ('RESET_PASSPHASE_REQUESTED_AND_ACTIVE'))}.`,
            error: true,
            isDefaultDisplay: true
          };
          dispatch(setModelMessage(data));
        } else if (isMounted.current) {
/* Conflict Resolution Candidate
          let errorMessage = parse(error?.message, parseOptions);
          if (error?.message === `${t(t1, ('MSG_ERR_USER_NOT_EXIST'))}.` || error?.message === `${t(t1, ('MSG_ERR_INCORRECT_USR_PASS'))}.`) {
            errorMessage = t(t1, "USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID")
          } else if (error?.message === `${t(t1, ('MSG_ERR_USER_DISABLED'))}.`) {
            errorMessage = t(t1, "INVALID_USER_ID_AND_PASSPHRASE")
          } else if (error?.message.includes("##")) {
            errorMessage = parse(error?.message.split("##")[1], parseOptions)
          }

          const data = {
            title: "ERROR",
            // eslint-disable-next-line no-nested-ternary
            message1: errorMessage,
 */
          if (error?.name === notAuthorizedException) {
            dispatch(checkUserIfIsBlocked(t1, data?.email));
          } else {
            const data = {
              title: ERROR,
              // eslint-disable-next-line no-nested-ternary
              message1: error?.message === `${t(t1, ('MSG_ERR_USER_NOT_EXIST'))}.` || error?.message === `${t(t1, ('MSG_ERR_INCORRECT_USR_PASS'))}.` || error?.message === `${t(t1, ('MSG_ERR_USER_DISABLED'))}.` ? t(t1, "USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID") : (error?.message.includes("##") ? parse(error?.message.split("##")[1], parseOptions) : parse(error?.message, parseOptions)),
              error: true,
              isDefaultDisplay: true
            };
            dispatch(setModelMessage(data));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage?.getItem('jurisdiction')) dispatch(getConfigurations(localStorage?.getItem('jurisdiction'), true));
  }, [location, localStorage?.getItem('jurisdiction')]);

  const handleClick = async () => {
/* Conflict Resolution Candidate
    dispatch(setLoader(true));
    handleClose();
    forgotpassword(isUserId?.toLowerCase().trim())
      .then((response) => {
        // console.log(response,"response")
        // const data = {
        //   title: SUCCESS,
        //   message1: response?.message1,
        //   error: false
        // };
        dispatch(setModelMessage(response));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        const data = {
          title: "ERROR",
          message1: (err) ? err.message : "Internal Server Error",
          error: true,
 */

    forgotpassword(isUserId?.toLowerCase()?.trim())
      .then((response) => {
        // const data = {
        //   title: SUCCESS,
        //   message1: response.msg,
        //   error: false,
        //   isDefaultDisplay:true
        // };
        dispatch(setModelMessage(response));
      })
      .catch((err) => {
        let data;
        if (err?.name === userNotFoundException || err?.name === userNotAuthorizedException) {
          data = {
            title: SUCCESS,
            message1: "MSG_FORGOT_PASS_SUCCESS",
            error: false,
            statusCode: 200,
          };
        } else {
          data = {
            title: SUCCESS,
            message1: (err) ? err?.message : "Internal Server Error",
            error: false,
            isDefaultDisplay: true
          };
        }
        dispatch(setModelMessage(data));
      })
/* Conflict Resolution Candidate
  }

  const handleForgetUserIDClick = async () => {
    handleClose();
    dispatch(setLoader(true));
    try {
      const response = await forgotUserID(isUserId.toLowerCase().trim());
      setResponse(response);
      // const data = {
      //   title: SUCCESS,
      //   message1: response.message1,
      //   error: false
      // };
      dispatch(setLoader(false));
      dispatch(setModelMessage(response));
      clearErrors("notify");
    } catch (error) {
      dispatch(setLoader(false));
 */
    handleClose();
  }

  const handleForgetUserIDClick = async () => {
    try {
      const response = await forgotUserID(isUserId?.trim());
      // console.log(response, 'response');
      setResponse(response);
      handleClose();
      // const data = {
      //   title: SUCCESS,
      //   message1: response.msg,
      //   error: false,
      //   isDefaultDisplay:true
      // };
      dispatch(setModelMessage(response));
      clearErrors("notify");
    } catch (error) {
      const data = {
        title: "Failure!",
        message1: error.message,
        error: true,
      };
      dispatch(setModelMessage(data));
    }
  };

  const handleToastClose = () => {
    setResponse(null);
    dispatch(setModelMessage(false));
  };

  const userIsUnique = async (value, type) => {
    return validateUserIDPattern(value)?null:`${t(t1, "ERR_ENTER_VALID_USER_ID")}`
  };

  const userIdInputChange = (e) => {
    setIsUserId(e.target.value);

    if (e.target.value.length > JURISDICTION_USER_ACTION_FORM_FIELDS.EMAIL_MAX_CHAR) {
      setIsInValidEmailMessage(`${t(t1, "ERR_EMAIL_ADDRESS_MAX_LENGTH_CHAR")}`)
      return;
    }
    // eslint-disable-next-line no-useless-escape
    if (!e.target.value.match(emailPattern)) {
      setIsInValidEmailMessage(`${t(t1, "ERR_ENTER_VALID_EMAIL_ADDRESS")}`);
      // eslint-disable-next-line no-else-return
      return;
    }
    setIsInValidEmailMessage("");
  };

  const passwordInputChange = (e) => {
    setIsUserId(e.target.value);
    // eslint-disable-next-line no-useless-escape
    if (e.target.value.length < USER_ID_VALIDATION.USER_ID_MIN_LENGTH) {
      setIsInValidUserIdMessage(`${t(t1, "ERR_ENTER_VALID_USER_ID")}`)
      return
    }
    if (e.target.value.length > USER_ID_VALIDATION.USER_ID_MAX_LENGTH) {
      setIsInValidUserIdMessage(`${t(t1, "ERR_ENTER_VALID_USER_ID")}`)
      return
    }
    if (!validateUserIDPattern(e.target.value)) {
      // eslint-disable-next-line consistent-return
      setIsInValidUserIdMessage(`${t(t1, "ERR_ENTER_VALID_USER_ID")}`);
      return
    }
    setIsInValidUserIdMessage(false);
  };

  const handleOpen = (value) => {
    clearErrors("notify");
    setIsUserId(null);
    setIsInValidEmailMessage("");
    setIsInValidUserIdMessage("");
    if (value === "isUser") {
      setIsUserModal(true);
      setIsPassModal(false);
    } else {
      setIsPassModal(true);
      setIsUserModal(false);
    }
  };

  const handleClose = () => {
    setIsUserModal(false);
    setIsPassModal(false);
  };

  const handleCloseSecurityModal = () => {
    setSecurityModal(false);
  };

  const handleChange = (e) => {
    setIsRememberMe(e.target.checked);
  };

  const passphraseFocus = () => {
    setShowPassword(!showPassword)
/* Conflict Resolution Candidate
    textRef?.current?.focus()
 */
    textRef.current.focus()
    if (typeof window.getSelection !== "undefined"
      && typeof document.createRange !== "undefined") {
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== "undefined") {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(textRef.current);
      textRange.collapse(false);
      textRange.select();
    }
  };

  const inputProps = {
    autoComplete: "new-password",
/* Conflict Resolution Candidate
    dataTestid: "password"
 */
  };

  useEffect(() => {
    if (!isEmpty(modelMessage) || !isEmpty(config)) {
/* Conflict Resolution Candidate
      userNameRef?.current?.focus();
 */
      clearErrors();
    }
  }, [config, location, modelMessage]);

/* Conflict Resolution Candidate
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setSecurityModal(true)
  //     userNameRef?.current?.blur()
  //   }, 3000);
  //   return () => {
  //     clearTimeout(timer)
  //     userNameRef?.current?.focus()
  //   };
  // }, []);

  useEffect(() => {
    if (securityModal) {
      userNameRef?.current?.blur()
    } else {
      userNameRef?.current?.focus()
    }
  }, [securityModal, securityQuestions?.Question1])

 */
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: 0 }}>
          <Stack direction="row" alignItems="center">
            {/* Conflict Resolution Candidate
            {PrivAttributes?.priv_h3_Log_In?.PrivRole !== 'NA' && <Typography variant="h4" gutterBottom sx={Style.LoginTital} data-testid="login_heading">
              {t(t1, "LOG_IN")}
            </Typography>}
          </Stack>

          <Stack spacing={2}>
            {PrivAttributes?.priv_input_User_ID?.PrivRole !== 'NA' &&
              <TextField
              disabled={PrivAttributes?.priv_input_User_ID?.PrivRole === 'RO'}
                {...register("email", {
                  required: `${t(t1, "USER_ID")} ${t(t1, "IS_REQUIRED")}`,
                  validate: userIsUnique,
                  minLength: {
                    value: 6,
                    message: `${t(t1, "USER_ID")} ${t(t1, "MUST_BE")} 6 ${t(t1, "CHAR_LONG")}`,
                  },
                  maxLength: {
                    value: 15,
                    message: `${t(t1, "USER_ID")} ${t(t1, "MAX_LENGTH")} 15 ${t(t1, "CHARACTERS")}`,
                  },
                })}
                // inputRef={userNameRef}
                variant="outlined"
                label={t(t1, "USER_ID")}
                type="text"
                size="small"
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
                inputProps={{ autoComplete: "off", "data-testid": 'username' }}
                onSelect={() => { userNameRef?.current?.focus(); setShrink1(true); }}
                InputLabelProps={{ shrink: shrink1 }}
              />}
            {PrivAttributes?.priv_input_Passphrase?.PrivRole !== 'NA' &&
              <TextField
                disabled={PrivAttributes?.priv_input_Passphrase?.PrivRole === 'RO'}
                {...register("password", {
                  required: `${t(t1, "PASSPHRASE")} ${t(t1, "IS_REQUIRED")}`,
                  minLength: {
                    value: 8,
                    message: `${t(t1, "PASSPHRASE")} ${t(t1, "MUST_BE")} 8 ${t(t1, "CHAR_LONG")}`,
                  },
                  maxLength: {
                    value: 50,
                    message: `${t(t1, "PASSPHRASE")} ${t(t1, "MAX_LENGTH")} 50 ${t(t1, "CHARACTERS")}`,
                  },
                })}
                label={t(t1, "PASSPHRASE")}
                type={showPassword ? "text" : "password"}
                size="small"
                error={Boolean(errors?.password)}
                helperText={errors?.password?.message}
                inputRef={textRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={passphraseFocus} edge="end">
                        <Icon icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...inputProps
                }}
              />}
          </Stack>

          {PrivAttributes?.priv_check_Remember_Me?.PrivRole !== 'NA' && <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            {config['login-settings']?.remember_userId?.AttributeValue && (<RHFCheckbox btnDisabled={PrivAttributes?.priv_check_Remember_Me?.PrivRole === 'RO'} value={isRememberMe} onChange={handleChange} className={Style.ErrorCheckbox} name="remember" label={t(t1, "REMEMBER_ME")} />)}
          </Stack>}

          <Stack sx={Style.LoginButton}>
            {PrivAttributes?.priv_btn_LOG_IN?.PrivRole !== 'NA' &&
              <LoadingButton
                disableFocusRipple
                id="login_btn"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                data-testid="login_btn"
                disableElevation
                disabled={PrivAttributes?.priv_btn_LOG_IN?.PrivRole === 'RO'}
              >
                {t(t1, "LOG_IN")}
              </LoadingButton>}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1, ...Style.Forgotline }}>
            {PrivAttributes?.priv_lnk_Forgot_User_ID?.PrivRole !== 'NA' && <Link variant="subtitle2" onClick={() => PrivAttributes?.priv_lnk_Forgot_User_ID?.PrivRole !== 'RO' && handleOpen("isUser")}>{t(t1, "FORGOT_USER_ID")}</Link>}
            {PrivAttributes?.priv_lnk_Forgot_Passphrase?.PrivRole !== 'NA' && <Link onClick={() => PrivAttributes?.priv_lnk_Forgot_Passphrase?.PrivRole !== "RO" && handleOpen("isPass")}>{t(t1, "FORGOT_PASSPHRASE")}</Link>}
          */}
            <Typography variant="h4" gutterBottom sx={Style.LoginTital} data-testid="login_heading">
              {t(t1, "LOG_IN")}
            </Typography>
          </Stack>

          <Stack spacing={2}>
            <TextField
              {...register("email", {
                required: `${t(t1, "ERR_USER_ID_IS_REQUIRED")}`,
                validate: userIsUnique,
                minLength: {
                  value: 0,
                  message:  `${t(t1, "ERR_ENTER_VALID_USER_ID")}`
                  // message: `${t(t1, "USER_ID")} ${t(t1, "MUST_BE")} 6 ${t(t1, "CHAR_LONG")}`,
                },
                maxLength: {
                  value: 32,
                  message:  `${t(t1, "ERR_ENTER_VALID_USER_ID")}`
                  // message: `${t(t1, "USER_ID")} ${t(t1, "MAX_LENGTH")} 32 ${t(t1, "CHARACTERS")}`,
                },
              })}
              inputRef={userNameRef}
              variant="outlined"
              label={t(t1, "USER_ID")}
              type="text"
              size="small"
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
              inputProps={{ autoComplete: "off" }}
              onSelect={() => { setShrink1(true); }}
              // onBlur={() => { setShrink1(false); }}
              InputLabelProps={{ shrink: !isEmpty(email) || shrink1 }}
              tabIndex={"0"}
            />
            {/* {console.log(errors)} */}
            <TextField
              {...register("password", {
                required: `${t(t1, "ERR_PASSPHRASE_IS_REQUIRED")}`,
                minLength: {
                  value: 0,
                  message:  `${t(t1, "ERR_ENTER_VALID_PASSPHRASE")}`
                  // message: `${t(t1, "PASSPHRASE")} ${t(t1, "MUST_BE")} 8 ${t(t1, "CHAR_LONG")}`,
                },
                maxLength: {
                  value: 50,
                  message:  `${t(t1, "ERR_ENTER_VALID_PASSPHRASE")}`
                  // message: `${t(t1, "PASSPHRASE")} ${t(t1, "MAX_LENGTH")} 50 ${t(t1, "CHARACTERS")}`,
                },
              })}
              label={t(t1, "PASSPHRASE")}
              type={showPassword ? "text" : "password"}
              size="small"
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              inputRef={textRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={passphraseFocus} edge="end">
                      <Icon icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                    </IconButton>
                  </InputAdornment>
                ),
                ...inputProps
              }}
              tabIndex={"0"}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            {config['login-settings']?.remember_userId?.AttributeValue && (<RHFCheckbox tabIndex={"0"} value={isRememberMe} onChange={handleChange} sx={Style.checkBoxLabelBold} name="remember" label={t(t1, "REMEMBER_ME")} />)}
          </Stack>

          <Stack sx={Style.LoginButton}>
            <LoadingButton
              disableFocusRipple
              id="login_btn"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              data-testid="login_btn"
              disableElevation
            >
              {t(t1, "LOG_IN")}
            </LoadingButton>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1, ...Style.Forgotline }}>
            <Link tabIndex={"0"} variant="subtitle2" onKeyDown={(e)=>{ if (e.key === "Enter" )  handleOpen("isUser")}} onClick={() => handleOpen("isUser")}>{t(t1, "FORGOT_USER_ID")}</Link>
            <Link tabIndex={"0"} onKeyDown={(e)=>{ if (e.key === "Enter" )  handleOpen("isPass")}} onClick={() => handleOpen("isPass")}>{t(t1, "FORGOT_PASSPHRASE")}</Link>
          </Stack>
        </Box>
      </FormProvider>
      {isUserModal && (
        <CommonModal
          isModal={isUserModal}
          isClose={handleClose}
          header={t(t1, "FORGOT_USER_ID")}
          body={t(t1, "ERR_ENTER_VALID_EMAIL_ADDRESS")}
          placeHolder={t(t1, "ENTER_AN_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_ACCOUNT")}
          value={isUserId}
          inputChange={userIdInputChange}
          handleClick={handleForgetUserIDClick}
          errorMessage={isInValidEmailMessage}
        />
      )}
      {isPassModal && (
        <CommonModal
          isModal={isPassModal}
          isClose={handleClose}
          header={t(t1, "FORGOT_PASSPHRASE")}
          body={t(t1, "PLEASE_ENTER_YOUR_USER_ID")}
          placeHolder={t(t1, "ENTER_YOUR_USER_ID")}
          value={isUserId}
          inputChange={passwordInputChange}
          handleClick={handleClick}
          errorMessage={isInValidUserIdMessage}
        />
      )}
      {/* Conflict Resolution Candidate
      {securityModal && securityQuestions.Question1 &&
        <SecurityQuestionModal
          isOpen={securityModal}
          securityQuestions={securityQuestions}
          cognitoUser={cognitoUser}
          handleClose={handleCloseSecurityModal}
          isRedirect
        />}
      {loader && <Loader loader={loader} />}
      */}
      {securityModal && securityQuestions.Question1 && <SecurityQuestionModal securityQuestions={securityQuestions} cognitoUser={cognitoUser} handleClose={handleCloseSecurityModal} isRedirect />}
       {loader && <Loader loader={loader} />}
    </>
  );
}

export default LoginForm;
