import { createSlice } from "@reduxjs/toolkit";
import { setLoader, setModelMessage } from "store/commonSlice";
import { APIS, FSA_COMPONENT_NAMES, SUCCESS, etTimeFormat } from "utils/constants";
import fetcher, { fetcherGet, fetcherPut, fetcherPost, fetcherPatch } from "utils/fetcher";
import logger from "utils/logger";
import { getJurisdictionUUID } from "utils/support";


const initialState = {
  wiringInstructionsList: [],
  isLoadingWiringInstructionsList: false,
  isUpdatingWiringInstructions: false,
  eventsForUploadingList: {isloading:false , data:[]},
  linkForDownload:{isloading:false , data:[]},
  reportListingData:{isloading:false , data:[]},
  secKeyData:{isloading:false , data:[]}

};

const fsaHomeSlice = createSlice({
  name: "fsaHomeSlice",
  initialState,
  reducers: {
    setWiringInstructionsList: (state, action) => {
      state.wiringInstructionsList = action.payload;
    },
    setIsLoadingWiringInstructionsList: (state, action) => {
      state.isLoadingWiringInstructionsList = action.payload;
    },
    setIsUpdatingWiringInstructions: (state, action) => {
      state.isUpdatingWiringInstructions = action.payload;
    },
    resetWiringInstructionsData: (state) => {
      state.wiringInstructionsList = initialState.wiringInstructionsList;
      state.isLoadingWiringInstructionsList = initialState.isLoadingWiringInstructionsList;
      state.isUpdatingWiringInstructions = initialState.isUpdatingWiringInstructions;
    },
    setEventsForUploadingList: (state, action) => {
      state.eventsForUploadingList = action.payload;
    },
    setLinkForDownoad: (state, action) => {
      state.linkForDownload = action.payload;
    },
    setReportListingData: (state, action) => {
      state.reportListingData = action.payload;
    },   
    setIsUpdateSeckey: (state, action) => {
      state.secKeyData = action.payload;
    },
  },
});

export const {
  setWiringInstructionsList,
  setIsLoadingWiringInstructionsList,
  setIsUpdatingWiringInstructions,
  resetWiringInstructionsData,
  setEventsForUploadingList,
  setLinkForDownoad,
  setReportListingData,
  setIsUpdateSeckey,
} = fsaHomeSlice.actions;
export default fsaHomeSlice.reducer;

export const getWiringInstructionList = () => (dispatch) => {
  dispatch(setIsLoadingWiringInstructionsList(true));
  fetcherGet(`${APIS.CWI}/verify-cwi`)
    .then((response) => {
      dispatch(setWiringInstructionsList(response?.data));
      dispatch(setIsLoadingWiringInstructionsList(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingWiringInstructionsList(false));
    });
};

export const updateWiringInstruction = (id, payload, successCallback) => (dispatch) => {
  dispatch(setIsUpdatingWiringInstructions(true));
  fetcherPut(`${APIS.CWI}/confirm-invalidate/${id}`, payload)
    .then((response) => {
      const data = {
        title: SUCCESS,
        message1: response?.messageKey,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      dispatch(getWiringInstructionList());
      dispatch(setIsUpdatingWiringInstructions(false));
      successCallback?.();
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsUpdatingWiringInstructions(false));
    });
};

export const resetData = () => (dispatch) => {
  dispatch(resetWiringInstructionsData());
};

export function getEventsForUploadingList(PageName) {
  const pageDetails = { componentName: FSA_COMPONENT_NAMES.FSA_CREATE_UPLOAD_FILE, pageName: PageName };
  return (dispatch) => {
    const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant
    dispatch(setEventsForUploadingList({ isLoading: true, data: [] }));
    if (jurisdictionID) {
      fetcherGet(`${APIS.EVENT_API}/${jurisdictionID}/fsa-jurisdiction-events`, {}, pageDetails)
        .then((response) => {
          dispatch(setEventsForUploadingList({ isLoading: false, data: response?.data }));
        })
        .catch((error) => {
          dispatch(setEventsForUploadingList({ isLoading: false, data: [] }));
        });
    } else {
      dispatch(setEventsForUploadingList({ isLoading: false, data: [] }));
    }
  };
}


export function generateXml(payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant
    
    if (jurisdictionID) {
      try {
        await fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-generate`, {
          ...payload,
        });
      } finally {
        dispatch(setLoader(false));
      }
    } else {
      dispatch(setLoader(false)); // Stop loader if jurisdictionID is not available
    }
  };
}


export function getFileUploadValidation(payload,eventId,PageName) {
  const pageDetails = { componentName: FSA_COMPONENT_NAMES.FSA_CREATE_UPLOAD_FILE, pageName: PageName };
  return async (dispatch) => {
    dispatch(setLoader(true));
    const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant
    if(jurisdictionID){
    return fetcherPost(`${APIS.EVENT_API}/${jurisdictionID}/event/${eventId}/application-details-file-upload`, {
        ...payload,
    },pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  }
  };
}


export function reportListing() {
  const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant
  const payload = {
    reportName: "FSA_GENERATE_XML_REPORT",
  };

  return (dispatch) => {
    dispatch(setReportListingData({ isLoading: true, data: [] }));
      if (jurisdictionID) {
      fetcherPost(`${APIS.REPORTS_API}/${jurisdictionID}/report-list`, { ...payload })
        .then((response) => {
          dispatch(setReportListingData({ isLoading: false, data: response?.data }));
        })
        .catch((err) => {
          dispatch(setReportListingData({ isLoading: false, data: [] }));
        });
    } else {
      dispatch(setReportListingData({ isLoading: false, data: [] }));
    }
  };
}


export const getLinkForDownload = (downloadLinkId) => (dispatch) => {
  const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant

  if (jurisdictionID) {
    return fetcherGet(`${APIS.REPORTS_API}/${jurisdictionID}/report-download/${downloadLinkId}`)
      .finally(() => {
        dispatch(setLoader(false));
      });
  }
  dispatch(setLoader(false)); // Stop loader if jurisdictionID is not available
  return Promise.resolve(); // Return a resolved promise to keep the function signature consistent
};


export function submitFileUpload (payload,eventId,fileIDParams,PageName) {
  const pageDetails = { componentName: FSA_COMPONENT_NAMES.FSA_CREATE_UPLOAD_FILE, pageName: PageName };
  return async (dispatch) => {
    dispatch(setLoader(true));
    const jurisdictionID =getJurisdictionUUID();
    if (jurisdictionID) {
    return fetcherPost(`${APIS.EVENT_API}/${jurisdictionID}/event/${eventId}/application-details-file-submit${fileIDParams}`, {
      ...payload,
    },pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}
}

export function updateSecretKey(payload) {
  return async (dispatch) => {
    dispatch(setIsUpdateSeckey(true));
    try{
      const response = await fetcherPatch(`sync/api/reset-secret-key`, payload);
      return response;
    }finally{
      dispatch(setLoader(false));
      }
  };
}



