import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
// eslint-disable-next-line import/no-named-as-default
import commonSlice from "./commonSlice";
// eslint-disable-next-line import/no-named-as-default
/* Conflict Resolution Candidate
import createUserProfileSlice from "./createUserProfileSlice";
import jurisdictionSlice from "./jurisdictionSlice";
import privilegeSlice from "./privilegeSlice";
import  userHomeSlice  from "./userHomeSlice";
import configSlice from "./configSlice";
 */
import IssuanceSlice from "./IssuanceSlice";
import NAICSCodeSlice from "./NAICSCodeSlice";
import administrativeTransferSlice from "./administrativeTransferSlice";
import batchTransferSlice from "./batchTransferSlice";
import configSlice from "./configSlice";
import createUserProfileSlice from "./createUserProfileSlice";
import cronJobsSlice from "./cronJobsSlice";
import customModalSlice from "./customModalSlice";
import entityAccountSlice from "./entityAccountSlice";
import entityAccountWiringSlice from "./entityAccountWiringSlice";
import entityApplicationSlice from "./entityApplicationSlice";
import entitiyProfileSlice from "./entityProfileSlice";
import entityTransferSlice from "./entityTransferSlice";
import exchangeContractCodeSlice from "./exchangeContractCodeSlice";
import fsaHomeSlice from "./fsaHomeSlice";
import holdingLimitsSlice from "./holdingLimitsSlice";
import jurisdictionSlice from "./jurisdictionSlice";
import limitedExemptionSlice from "./limitedExemptionSlice";
import manageJurisdictionBudgetSlice from "./manageJurisdictionBudgetSlice";
import managePurchaseLimitSlice from "./managePurchaseLimitSlice";
import modifiedRecordsForTransfersSlice from "./modifiedRecordsForTransfersSlice";
import offsetManagementSlice from "./offsetManagementSlice";
import permissionSlice from "./permissionSlice";
import privilegeSlice from "./privilegeSlice";
import replenishmentTransferSlice from "./replenishmentTransferSlice";
import userHomeSlice from "./userHomeSlice";
import userSlice from "./userSlice";
import eventApplicationSlice from "./eventApplicationSlice";
import cmsSlice from "./cmsSlice";
import eventSlice from "./eventSlice";
import setInitialUserSlice from "./setInitialUserSlice";
import setJuriAccountsSlice from "./setJuriAccountsSlice";
import setManageJuriAccountsSlice from "./manageJuriAccountsSlice";
import onboardJurisdictionSlice from "./onboardJurisdictionSlice";
import reportsSlice from "./reportsSlice";
import eventApplicationJuriSlice from "./eventApplicationJuriSlice";
import fsaAllReportsSlice from "./fsaAllReportsSlice";
import mfsFinancialServicesSlice from "./mfsFinancialServicesSlice";
import eventAllReportsSlice from "./eventAllReportsSlice";
import jurisdictionUserHomeEntityGridFilterSlice from "./jurisdictionUserHomeEntityGridFilterSlice";
import manageTransfersSlice from "./manageTransfersSlice";
import serviceProSelectionMgmSlice from "./serviceProSelectionMgmSlice";
import eventApplicationReportSlice from "./eventApplicationReportSlice";


const store = configureStore({
  reducer: {
    createUserProfile: createUserProfileSlice,
    common: commonSlice,
    auth: authSlice,
    privilege: privilegeSlice,
    jurisdiction: jurisdictionSlice,
    userHome: userHomeSlice,
    config: configSlice,
    /* Conflict Resolution Candidate
    user:userSlice,
 */
    user: userSlice,
    entityApplication: entityApplicationSlice,
    entityProfile: entitiyProfileSlice,
    customModal: customModalSlice,
    accountDetails: entityAccountSlice,
    wiring: entityAccountWiringSlice,
    NAICSCode: NAICSCodeSlice,
    issuance: IssuanceSlice,
    manageTransfers: manageTransfersSlice,
    offsetManagement: offsetManagementSlice,
    limitedExemption: limitedExemptionSlice,
    ManageJurisdictionBudget: manageJurisdictionBudgetSlice,
    ManagePurchaseLimit: managePurchaseLimitSlice,
    exchangeContractCode: exchangeContractCodeSlice,
    fsa: fsaHomeSlice,
    batchTransfer: batchTransferSlice,
    holdingLimits: holdingLimitsSlice,
    entityTransfer: entityTransferSlice,
    administrativeTransfer: administrativeTransferSlice,
    replenishmentTransfer: replenishmentTransferSlice,
    modifiedRecordsForTransfers: modifiedRecordsForTransfersSlice,
    cronJobs: cronJobsSlice,
    permissions: permissionSlice,
    event: eventSlice,
    eventApplication: eventApplicationSlice,
    cms: cmsSlice,
    setInitialUser: setInitialUserSlice,
    setJuriAccounts: setJuriAccountsSlice,
    manageJuriAccount: setManageJuriAccountsSlice,
    onboardJurisdiction: onboardJurisdictionSlice,
    reports: reportsSlice,
    eventApplicationJuri:eventApplicationJuriSlice,
    fsaAllReports:fsaAllReportsSlice,
    mfsFinancialServices: mfsFinancialServicesSlice,
    eventAllReportSlice: eventAllReportsSlice,
    jurisdictionUserHomeEntityGridFilter: jurisdictionUserHomeEntityGridFilterSlice,
    serviceProSelectionMgm: serviceProSelectionMgmSlice,
    eventApplicationReport : eventApplicationReportSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store;
