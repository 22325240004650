import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box, List, ListItem, Typography, Modal, IconButton } from "@mui/material";
import { MUITableGrid } from "components/MUITableGrid";
import Spinner from "components/Spinner";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getJurisdictionAccounts } from "store/jurisdictionSlice";
import { getCurrentPageName, t, showOnlyPermittedFields, ACCOUNT_ACTION_PAGE, STATUSES, ENTITY_ACCOUNT_STATUSES, JURISDICTION_STATUS, getFilterForColumn } from "utils/constants";
import { encryptStr } from "utils/support";
import { useJurisdictionUserRole } from "hooks/useUserRole";
import { getAccountsStatusList } from "store/entityAccountSlice";
import AccountQuickActions from "./AccountQuickActions";

// TODO: priv has to be changed

function JurisdictionAccounts({ noAccordion = false, tableProps = {} }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const {
    routeState: { path, toggle },
  } = useSelector(({ user }) => user);
  // if user clicks on the jur accounts, accordion should be expanded
  const [expanded, setExpanded] = useState();
  const { selectedJurisdictionId } = useSelector(({ jurisdiction }) => jurisdiction);
  const { isLoadingAccountUpdate } = useSelector(({ setJuriAccounts }) => setJuriAccounts);
  const { jurisdictionAccounts } = useSelector(({ jurisdiction }) => jurisdiction);
  const { jurisdictioninformation } = useSelector(({ onboardJurisdiction }) => onboardJurisdiction);

  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const { isJurisdictionRegistrarUser } = useJurisdictionUserRole();

  useEffect(() => {
    if (isJurisdictionRegistrarUser) dispatch(getAccountsStatusList(STATUSES.JURISDICTION_ACCOUNTS));
  }, [dispatch, isJurisdictionRegistrarUser]);
  
  const jAccountsColumns = useMemo(
    () => [
      {
        id: 1,
        field: "accountNumber",
        headerName: t(t1, "ACCOUNT_NUMBER"),
        minWidth: 100,
        maxWidth: 1000,
        flex: 1,
        hideable: false,
        align: "center",
        renderCell: (cellValues) => {
          if (cellValues?.row?.accountStatus && encryptStr(cellValues?.row?.accountNumber)) {
            return (
              <Link to={`/jurisdiction-account/${encryptStr(cellValues?.row?.accountNumber)}`}>
                <Typography
                  variant="body2"
                  className="link-cell"
                >
                  {cellValues?.row?.accountNumber}
                </Typography>
              </Link>
            );
          }
          return (
            <Typography
              sx={{ color: "#333", "&:hover": { cursor: "auto" } }}
              variant="body2"
            >
              {cellValues?.row?.accountNumber}
            </Typography>
          );
        },
      },

      {
        id: 2,
        field: "accountType",
        headerName: t(t1, "ACCOUNT_TYPE"),
        minWidth: 100,
        maxWidth: 1000,
        flex: 1,
        align: "center",
        valueGetter: (params) => params?.row?.accountTypeLanguage?.[localStorage.getItem("languageCode")] || '',
      },

      {
        id: 3,
        field: "accountStatus",
        headerName: t(t1, "ACCOUNT_STATUS"),
        minWidth: 100,
        maxWidth: 1000,
        flex: 1,
        align: "center",
        valueGetter: (cellValues) => t(t1, cellValues?.row?.accountStatus),
        type: 'singleSelect',
        valueOptions: getFilterForColumn(jurisdictionAccounts.data.data, t, t1, ['accountStatus'])?.accountStatus
      },
      ...(jurisdictioninformation?.Status?.Name &&
        ![JURISDICTION_STATUS.ONBOARDING, JURISDICTION_STATUS.OFFBOARDED].includes(jurisdictioninformation?.Status?.Name)
        ? [
          {
            id: 4,
            field: "quickActions",
            resizable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            minWidth: 120,
            maxWidth: 120,
            flex: 1,
            headerName: t(t1, "QUICK_ACTIONS"),
            disableExport: true,
            align: "center",
            renderCell: (params) => (
              <AccountQuickActions
                accountId={params?.row?.accountId}
                accountStatus={params?.row?.accountStatus}
                isDotIcon={!!true}
                accountName={params?.row?.accountTypeLanguage?.[localStorage.getItem('languageCode')] || ''}
                page={ACCOUNT_ACTION_PAGE.JURISDICTION_ACCOUNTS}
                disabled={params?.row?.accountType === "Issuance"}
              />
            ),
          },
        ]
        : []),
    ],
    [t1, jurisdictioninformation?.Status?.Name, jurisdictionAccounts]
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const executeScroll = () => scrollRef?.current?.scrollIntoView();

  function transformData(data) {
    return data?.map((acc, index) => ({
      id: index,
      accountId: acc?.id,
      accountNumber: acc?.accountNumber,
      accountType: acc?.accountType,
      accountTypeLanguage: acc?.accountTypeLanguage,
      accountStatus: acc?.accountStatus,
    }));
  }

  useEffect(() => {
    setTimeout(() => {
      if (path === "jurisdictionAccounts") {
        setExpanded("panel1");
        executeScroll();
      } else {
        setExpanded("");
      }
    }, 1100);
  }, [path, toggle]);

  useEffect(() => {
    if (selectedJurisdictionId) {
      dispatch(getJurisdictionAccounts(selectedJurisdictionId));
    }
  }, [dispatch, selectedJurisdictionId]);

  const accounts = useMemo(
    () =>
      jurisdictionAccounts.isLoading || isLoadingAccountUpdate ? (
        <Spinner />
      ) : (
        <Box sx={{ paddingBottom: "12px", paddingTop: "20px" }}>
          <MUITableGrid
            // access={PrivAttributes?.priv_grid_pendingUsers?.PrivRole}
            fileName={t(t1, "ALL_JURISDICTION_ACCOUNTS")}
            columns={showOnlyPermittedFields(
              jAccountsColumns,
              jurisdictionAccounts?.data?.metaData,
              jurisdictionAccounts?.data?.fieldAccess
            )}
            rows={transformData(jurisdictionAccounts.data.data) || []}
            count={jurisdictionAccounts.data?.length}
            isSearch
            isExport
            isSettings
            enablePrintView
            customPinnedColumns={{ left: ["accountNumber"], right: ["quickActions"] }}
            initialSortedFields={[{ field: "accountNumber", sort: "desc" }]}
            {...tableProps}
          />
        </Box>
      ),
    [jurisdictionAccounts, jAccountsColumns, t1, tableProps]
  );
  if (noAccordion) {
    return accounts;
  }

  return (
    <Grid ref={scrollRef}>
      <Typography
        sx={{ fontWeight: 650, textTransform: "uppercase" }}
        variant="h4"
        gutterBottom
      >
        {t(t1, "JURISDICTION_ACCOUNTS")}
      </Typography>
      <List className="commonlistul">
        <ListItem className="pending-accordion">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t(t1, "ALL_JURISDICTION_ACCOUNTS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {accounts}</AccordionDetails>
          </Accordion>
        </ListItem>
      </List>
    </Grid>
  );
}

export default JurisdictionAccounts;
