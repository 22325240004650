import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import SiteLogo from "assets/images/logo.png";
import { ModelMessage } from "components";
import { Loader } from "components/Loader";
import { CustomizeButton } from "components/CustomizeButton";
import i18n from "config/lang/i18n";
import { useClickOutside } from "hooks";
import useAuth from "hooks/useAuth";
import { checkIsAuthenticated, getUserDetails, removeAccessToken } from "store/authSlice";
import { setDialogMessage, setModelMessage,securityQuestions } from "store/commonSlice";
import { getCMSDetails, getConfigurations } from "store/configSlice";
import { setRedirectionPath } from "store/createUserProfileSlice";
import { setJurisdiction } from "store/jurisdictionSlice";
import { getUserRegDetails, resetUserState } from "store/userHomeSlice";
import { setUserStatusHistory } from "store/userSlice";
import clientTokenCandidate from "utils/clienttoken";
import fetcher, { fetcherPost } from "utils/fetcher";
import {
  COMPONENT_NAMES,
  DEFAULT_ORG_ID,
  enLanguage,
  getAllLang,
  getCurrentPageName,
  getJurisdictionName,
  isEmpty,
  PAGE_USER_REGISTRATION,
  PAGES,
  parseOptions,
  RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY,
  t,
} from "utils/constants";
import { preloadPages } from "utils";

// import useAuth from "hooks/useAuth";
import Category from "./Category";
import Style from "./Header.styles";
import HeaderMenu from "./HeaderMenu/HeaderMenu";


let inactivityTimerId;

let inactivityResetTime = -1;

export default function Header() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { isSignedIn, signOut, user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // router hooks and methods
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const currentPageName = PAGES?.find((eachPage) => eachPage?.path === location?.pathname)?.name; // To find current page name from constants
  const { steps } = useSelector(({ entityApplication }) => entityApplication);

  // Redux States
  const {
    formSteps: { activeStep },
    filledData,
    submitted,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const Jurisdiction = submitted?.[1] ? filledData?.[1]?.jurisdiction : "-";
  /* Conflict Resolution Candidate
  const { modelMessage } = useSelector(({ common }) => common);
 */
  const { dialogMessage, modelMessage } = useSelector(({ common }) => common);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { Jurisdictions, selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { header, footer } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = header;
  const { config } = useSelector(({ config }) => config);
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');
  /* Conflict Resolution Candidate

  // React States
  const [userMenuToggle, setUserMenuToggle] = useState(false);
  const [signedJurisdiction, setSignedJurisdiction] = useState(getCurrentORGID());

  const search = useLocation().search;
  const OrgID = new URLSearchParams(search)?.get("org_id");
 */
  // const { user } = useSelector(({ auth }) => auth);

  // React States
  const [userMenuToggle, setUserMenuToggle] = useState(false);
  const [showAPIModal, setShowAPIModal] = useState(modelMessage);
  const [signedJurisdiction, setSignedJurisdiction] = useState(
    localStorage.getItem("jurisdiction") || process.env.REACT_APP_DEFAULT_ORGID
  );

  const inactivityTime = () => {
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.onclick = resetTimer;
    window.onscroll = resetTimer;
    window.onkeypress = resetTimer;
    function logout() {
      if (checkIsAuthenticated() && isSignedIn) {
        signOut();
        navigate("/");
      }
    }
    function resetTimer() {
      clearTimeout(inactivityTimerId);
      inactivityResetTime = parseInt(config["login-settings"]?.session_timeout_time?.AttributeValue, 10)
      inactivityTimerId = setTimeout(logout, inactivityResetTime * 60 * 1000 || 1800000);
    }
  };

  /* Conflict Resolution Candidate
  useEffect(() => {
    if (user) {
      dispatch(getUserRegDetails({ UID: user.userId }));
      inactivityTime();
 */
  inactivityTime();

   // Load security questions
  useEffect(() => {
    dispatch(securityQuestions(t, t1));
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getUserRegDetails({ UID: user.userId }));
    }

    // This would fetch configuration Settings everytime the page reloads so that config related functionalities (Eg. Inactivity Timeout) work correctly.
    dispatch(getConfigurations(localStorage?.getItem('jurisdiction'), true));

  }, [user]);

  useEffect(() => {
    /* Conflict Resolution Candidate
    if (checkIsAuthenticated() && userDetails?.OrgID?.replace("#", "%23") === process.env.REACT_APP_DEFAULT_ORGID) {
 */
    if (!checkIsAuthenticated() && userDetails?.OrgID?.replace("#", "%23") === process.env.REACT_APP_DEFAULT_ORGID) {
      dispatch(setJurisdiction(userDetails?.OrgID));
      const signedOrgName = getJurisdictionName(Jurisdictions, userDetails?.OrgID);
      setSignedJurisdiction(signedOrgName?.OrgName);
    }
  }, [Jurisdictions, userDetails]);

  useEffect(() => {
    setUserMenuToggle(false);
    const isResetMessagedDisplayed = localStorage.getItem(RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY);
    if (
      isEmpty(isResetMessagedDisplayed) &&
      !isEmpty(userDetails?.PassphraseReminderDays) &&
      userDetails?.PassphraseReminderDays?.days > 0 &&
      !isEmpty(t(t1, "MSG_SUC_PASS_REMINDER")) &&
      t(t1, "MSG_SUC_PASS_REMINDER") !== "MSG_SUC_PASS_REMINDER" &&
      !userDetails?.IsPassphraseChangeRequired
    ) {
      const message = t(t1, "MSG_SUC_PASS_REMINDER");
      const updatedMessage = message?.replace("_", userDetails?.PassphraseReminderDays?.days);
      const data = {
        title: "PASSWORD_EXPIRE_REMINDER",
        message1: updatedMessage,
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
      localStorage.setItem(RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY, true);
    }

    const page = PAGES.find((page) => page?.path?.split("/*")?.[0] === location.pathname);
    // console.log(selectedJurisdiction, getCurrentPageName());
    // console.log('Called', user, selectedJurisdiction);
    if (user && user.userId && !isEmpty(selectedJurisdiction)) {
      dispatch(
        getUserDetails({
          jurisdiction: selectedJurisdiction,
          roleId: encodeURIComponent(`U#${user.userId}`),
          pageName: getCurrentPageName(),
        })
      );

      // To set language dropdown list as per the jurisdiction
      const LanguageParams = {
        /* Conflict Resolution Candidate
        OrgID: userDetails?.OrgID || defaultORGID,
 */
        OrgID: userDetails?.OrgID,
        QueryType: getAllLang,
      };

      dispatch(getCMSDetails(LanguageParams));

      // To set default language as English after user is successfully logged in.
      if (currentPageName !== "Page_Content_Management") {
        /* Conflict Resolution Candidate
        localStorage.setItem("i18nextLng", enLanguage());
        // i18n.changeLanguage("English");
        i18n.changeLanguage(`${getCurrentORGID()}#${enLanguage()}`);
      }
    }

    if (!checkIsAuthenticated() && isEmpty(user) && !isEmpty(getCurrentORGID())) dispatch(getUserDetails({ jurisdiction: getCurrentORGID(), pageName: getCurrentPageName() }));
 */
        if (!localStorage.getItem("i18nextLng")) {
          i18n.changeLanguage("English");
          localStorage.setItem("i18nextLng", "English");
        }
      }
    }

    if (!checkIsAuthenticated() && isEmpty(user) && !isEmpty(localStorage.getItem("jurisdiction")))
      dispatch(getUserDetails({ jurisdiction: localStorage.getItem("jurisdiction"), pageName: getCurrentPageName() }));

    if (Object.keys(header)?.length === 0 && !checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction))
      dispatch(getUserDetails({ jurisdiction: selectedJurisdiction, pageName: "Page_Header" }));
    if (Object.keys(footer)?.length === 0 && !checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction))
      dispatch(getUserDetails({ jurisdiction: selectedJurisdiction, pageName: "Page_Footer" }));

    /* Conflict Resolution Candidate
    // inactivityTime(); // comment this if sys signout even user interacting.
  }, [location, user]);
 */
  }, [location, user]);

  // useEffectOnce(() => {
  //   const page = PAGES.find((page) => page.path === location.pathname)
  //   if (user && user.userId)
  //     dispatch(getUserDetails(selectedJurisdiction, encodeURIComponent(`U#${ user.userId }`), page?.name));
  //   else
  //     dispatch(getUserDetails(selectedJurisdiction, "U%23Guest", page?.name));
  // }, [user, dispatch, location, selectedJurisdiction]);

  function matchPattern(inputString, pattern) {
		// Replace '*' with '.*' to create a regular expression pattern
		const regexPattern = pattern.replace(/\*/g, '.*');

		// Create a regular expression object
		const regex = new RegExp(`^${regexPattern}$`);

		// Use the test method to check if the input string matches the pattern
		return regex.test(inputString);
	}
  
  const handleSubmit = async (event) => {
    navigate("/");
    if(
      !(
        location.pathname.includes('entity-registration') && 
        [COMPONENT_NAMES.SELECT_ENTITY_TYPE, COMPONENT_NAMES.ENTITY_INFORMATION, COMPONENT_NAMES.CONTACT_INFORMATION,COMPONENT_NAMES.ADD_FACILITIES,
        COMPONENT_NAMES.ADD_REPRESENTATIVES,COMPONENT_NAMES.AUCTION,COMPONENT_NAMES.REVIEW_AND_SUBMIT,COMPONENT_NAMES.SUBMIT_REQUIRED_DOCUMENTS,COMPONENT_NAMES.ENTER_ENTITY_INFORMATION].includes(steps.filter((item) => item.selected)?.[0]?.key)
      )
    ) {
      event.preventDefault();
      handleUserMenu();
      signOut();
      removeAccessToken();
      dispatch(resetUserState());
    }
    /* Conflict Resolution Candidate
    i18n.changeLanguage(`${getCurrentORGID()}#${enLanguage()}`);
    localStorage.removeItem("isResetMessagedDisplayed")
 */
    i18n.changeLanguage(localStorage.getItem("i18nextLng") || enLanguage());
  };

  const handleUserMenu = () => {
    setUserMenuToggle(!userMenuToggle);
  };
  const subMenuRef = useRef(null);
  const selectedLanguageLocal = localStorage.getItem("i18nextLng");
  const selectedJurisdictionLocal = localStorage.getItem("jurisdiction");
  useClickOutside(subMenuRef, handleUserMenu);

  const handleClick = (path) => {
    if (location.pathname === "/create-user-profile" && activeStep !== 6) {
      /* Conflict Resolution Candidate
      dispatch(setRedirectionPath({route : path ,jurisdiction :""}))
 */
      dispatch(setRedirectionPath(path));
      dispatch(setDialogMessage(true));
    } else {
      navigate("/");
    }
  };

  /* Conflict Resolution Candidate
  return (
    <>
      {modelMessage && (
        <ModelMessage title={modelMessage?.title} message1={modelMessage?.message1} error={modelMessage?.error} isDefaultDisplay={modelMessage?.isDefaultDisplay} />
 */
  useEffect(() => {
    // RPM-1766
    clientTokenCandidate();
    (
      () => { 
        document.querySelector('body').addEventListener('DOMNodeInserted', (e) => {
          const hyperlinks = document.querySelectorAll('a[href^="https"]')
          hyperlinks.forEach((hyperlink) => {
            const addedEventListener = hyperlink.getAttribute('data-event-listener')
            if (!addedEventListener && hyperlink.getAttribute('href')?.includes('s3.')) {
              hyperlink.addEventListener('click', (e) => {
                e.preventDefault()
                setLoader(true);
                let href = hyperlink.getAttribute('href')
                href = href.replace("#", "%23");
                // Added to handle local file view using blob url
                if (href.startsWith('blob') || !href.includes('.s3')) {
                  setLoader(false);
                  window.open(href, '_blank')
                } else {
                  const orgId = href?.split("/")?.[4] || localStorage?.getItem('jurisdiction')?.replace("#", "%23") || DEFAULT_ORG_ID.replace("#", "%23");
                  fetcher(`cms/v2/api/documents/signed?orgId=${orgId}&languageCode=${localStorage.getItem('languageCode')}&urlType=getObject&fileUrl=${href}`, 'GET')
                  .then((response) => {
                    setLoader(false);
                    if (href.includes('http')) {
                      window.open(response?.data?.data, '_blank')
                    } else {
                      navigate(href)
                    }
                  })
                  .catch((err) => {
                    setLoader(false);
                    console.log('err=', err);
                  });
                }
              })
            }
            hyperlink.setAttribute('data-event-listener', true)
            
          })
        })
      }
    )()
    if (isSignedIn) {
      setLoader(true);
      i18n.loadNamespaces(preloadPages()).finally(() => {
          setLoader(false);
      });
      
      setInterval(() => {
        fetcherPost(
          "auth/verifytoken",
          {
            userName: user?.userId,
          },
          {
            clientTokenCandidate: localStorage.getItem("clientTokenCandidate"),
          }
        )
          .then((response) => {
            if (response?.token_verification_success === false) {
              signOut();
              navigate("/");
            }
          }).catch(() => {
            // console.log(error)
          });
      }, 1000 * 10);
    }

    if (modelMessage) {
      setShowAPIModal(true);
    }
  }, [modelMessage, isSignedIn, user, navigate, signOut]);
  return (
    <>
      {modelMessage && (
        <ModelMessage
          multipleErrorKeys={modelMessage?.multipleErrorKeys}
          title={modelMessage?.title}
          message1={modelMessage?.message1}
          groupId={modelMessage?.groupId}
          error={modelMessage?.error}
          isDefaultDisplay={modelMessage?.isDefaultDisplay}
        />
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        className="headerMain"
        sx={{ zIndex: 10, position: "relative" }}
      >
        <Box
          className="TopBar"
          sx={Style.TopBar}
        >
          <Container maxWidth="xl">
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ color: "common.textLightGray", "& p": { margin: 0 } }}
            >
              {getCurrentPageName() === "Page_LandingPageKnown"
                ? t(t1, "ANNOUNCEMENTS")
                : t(t1, "HEADER_ANNOUNCEMENTS")}
              {/* {t(t1, "HEADER_ANNOUNCEMENTS")} */}
            </Typography>
          </Container>
        </Box>
        <Box
          className="logoBar"
          sx={{ bgcolor: "common.white" }}
        >
          <Container maxWidth="xl">
            <Box sx={Style.logoBar}>
              <img
                src={SiteLogo}
                alt="logo"
                width="115"
                height="75"
              />
              {/* Conflict Resolution Candidate
              { localStorage.getItem("i18nextLng") && localStorage.getItem("jurisdiction") ? (
              */}
              {(selectedLanguageLocal && selectedJurisdictionLocal) || linkId ? (
                <Box
                  className="buttonWrapper"
                  sx={Style.buttonWrapper}
                >
                  {userDetails && userDetails?.OrgID?.replace("#", "%23") !== process.env.REACT_APP_DEFAULT_ORGID ? (
                    <>
                      {currentPageName !== PAGE_USER_REGISTRATION && <Category
                        label={t(t1, "HEADER_JURISDICTION")}
                        name={Jurisdiction}
                        loggedInJurisdiction={signedJurisdiction}
                      />}
                      <Category
                        label={t(t1, "LANGUAGE")}
                        selectBox="language"
                      />
                    </>
                  ) : null}
                  {/* <UsersMenu /> */}
                  {isSignedIn ? (
                    <List sx={Style.menuWrapper}>
                    {userDetails?.Name &&  <Button
                        variant="contained"
                        disableElevation
                        disableRipple
                        onClick={handleUserMenu}
                        sx={{
                          backgroundColor: "transparent",
                          color: "#757575",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          mr: 0,
                          pr: 0,
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        {t(t1, "WELCOME_LOGIN_TXT")} {userDetails?.Name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          width="24"
                        >
                          <path d="m12 15-5-5h10Z" />
                        </svg>
                      </Button>}
                      {userMenuToggle && isSignedIn && (
                        <Box ref={subMenuRef}>
                          <List sx={Style.userMenuWrapper}>
                            {userDetails && !isEmpty(userDetails?.LastLogin) && (
                              <ListItem
                                sx={Style.userMenuItem}
                                onClick={handleUserMenu}
                                disablePadding
                              >
                                <p className="text">
                                  {t(t1, "LAST_LOGIN")}:{" "}
                                  <span style={{ fontSize: "12px", fontWeight: 500 }}>
                                    {userDetails?.LastLogin?.split(".")[0]}
                                  </span>
                                </p>
                              </ListItem>
                            )}
                            {PrivAttributes?.priv_lnk_changePassphrase?.PrivRole !== "NA" && (
                              <ListItem
                                disabled={PrivAttributes?.priv_lnk_changePassphrase?.PrivRole === "RO"}
                                sx={Style.userMenuItem}
                                onClick={handleUserMenu}
                                disablePadding
                              >
                                <NavLink to={"/reset-password"}>{t(t1, "CHANGE_PASSPHRASE")}</NavLink>
                              </ListItem>
                            )}
                            {PrivAttributes?.priv_lnk_myprofile?.PrivRole !== "NA" && (
                              <ListItem
                                disabled={PrivAttributes?.priv_lnk_myprofile?.PrivRole === "RO"}
                                sx={Style.userMenuItem}
                                onClick={handleUserMenu}
                                disablePadding
                              >
                                <NavLink
                                  to={"/user-profile"}
                                  onClick={() => {
                                    dispatch(setUserStatusHistory({}));
                                    navigate("/user-profile");
                                  }}
                                >
                                  {t(t1, "HEADER_USER_PROFILE")}
                                </NavLink>
                              </ListItem>
                            )}
                            {PrivAttributes?.priv_lnk_logout?.PrivRole !== "NA" && (
                              <ListItem
                                disabled={PrivAttributes?.priv_lnk_logout?.PrivRole === "RO"}
                                sx={Style.userMenuItem}
                                disablePadding
                              >
                                <NavLink
                                  to={"/"}
                                  onClick={handleSubmit}
                                >
                                  {t(t1, "LOGOUT")}
                                </NavLink>
                              </ListItem>
                            )}
                          </List>
                        </Box>
                      )}
                    </List>
                  ) : (
                    /* Conflict Resolution Candidate */
                    /* ) : (!OrgID && location.pathname !== "/" )&& ( */
                    location.pathname !== "/" && (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <Box
                        sx={{
                          ml: 5,
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          position: "relative",
                          left: "8px",
                        }}
                      >
                        <CustomizeButton
                          variant="contained"
                          name="priv_btn_login"
                          label={t(t1, "LOGIN")}
                          handleClick={() => handleClick("/")}
                        />
                      </Box>
                    )
                  )}
                </Box>
              ) : (
                <Box sx={Style.SelectorSection}>
                  <CustomizeButton
                    variant="contained"
                    name="priv_btn_login"
                    label={t(t1, "LOGIN")}
                    handleClick={() => handleClick("/")}
                  />
                </Box>
              )}
            </Box>
          </Container>
        </Box>
        <Box
          className="MenuBar"
          sx={{ bgcolor: "primary.main" }}
        >
          <Container maxWidth="xl">
            <HeaderMenu
              isParentTabOpen={userMenuToggle}
              closeParentTab={() => {
                setUserMenuToggle(false);
              }}
            />
          </Container>
        </Box>
        {/* {dialogMessage ? (
          <DialogMessageForGoback
            warning
            title={t(t1, "WARNING")}
            message1={t(t1, "DIALOG_ERASE_MESSAGE")}
            message2={t(t1, "DIALOG_RESTART_MESSAGE")}
            handleModalClick={outSideFromRegistration}
          />
        ) : null} */}
      </Box>
      {loader && <Loader loader={loader} />}
    </>
  );
}
